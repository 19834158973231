export const ONLY_ALPHABET = /^[a-zA-Z\s]+$/;
export const ONLY_ALPHANUMERIC = /^[a-zA-Z0-9]+$/;
export const ALPHANUMERIC_WITH_SPACE = /^[a-zA-Z0-9\s]+$/;
export const URL_REGEX = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
export const CAPITAL_LETTER_REGEX=/^[A-Z ]+$/

//regix for validate email 
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const ALPHABET_WITH_SPECIAL_CHAR = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s'’\-.~(),]+$/

export const EMAIL_OR_URL_REGEX = /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?.*)$/;