import React, { Suspense } from 'react';

import { MainLayout } from "../components/layouts/MainLayout";
import { Profile } from "../pages/Profile/routes";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Roles } from "../pages/Roles-Permission/routes";
import { Employees } from "../pages/Employees/routes";
import { ExternalLink } from "../pages/External-Link/routes";
import { Postalcode } from "../pages/Postal-code/routes";
import { Formbuilder } from "../pages/Formbuilder/routes";
 
import {
  DispatchOrders,
  GoodsInward,
  OpeningStocks,
  PurchaseOrders,
  Warehouse,
} from "../pages/Warehouse/routes";
import { LetterTemplate } from "../pages/Letter-Templates/routes";
import { Asset } from "../pages/Assets/routes";
import { LandingPage } from "../pages/Landingpage/index";
import { Vendor } from "../pages/Vendor/routes";
import { Deals } from "../pages/Deals/routes";
import { Projects } from "../pages/Projects/routes";
import { Product } from "../pages/Product/routes";
import { FinanceProjects } from "../pages/Finance/Projects/routes";
import { Holidays } from "../pages/Holidays/routes";
import { Meetings } from "../pages/Meetings/routes";
import { TeamMeetings } from "../pages/Team-Meetings/routes";
import { MyTeam } from "../pages/My-Team/routes";
import { Libraries } from "../pages/Libraries/routes";
import { Leaves } from "../pages/Leaves/routes";
import { Survey } from "../pages/Survey/routes";
import { InternalTickets } from "../pages/Tickets/routes";
import { FinanceInvoices } from "../pages/Finance/Invoices/routes";
import { Hmrc } from "../pages/Hmrc/routes";
import { Medical } from "../pages/Medical/routes";
import { Announcements } from "../pages/Announcement/routes";
import { Fleet, MyFleet } from "../pages/Fleet/routes";
import {Planner} from "../pages/Planner/routes";
import { Expenses } from "../pages/Expenses/routes";
import { FinanceExpenses } from "../pages/Finance/Expenses/routes";
import { FinanceExternalExpenses } from "../pages/Finance/External-Expenses/routes";
import { Installer } from "../pages/Installer/routes";
import { Tasks } from "../pages/Task/routes";
import path from "path";
import { DashboardMaster } from "../pages/Dashboard/DashboardMaster";
import { ServiceWarranty } from "../pages/Service-Warranty/routes";
import { ProfileChangeRequest } from "../pages/Profile-Change-Request/routes";
import { FinanceDashboard } from "../pages/Finance/Dashboard";
import {HR_DASHBOARD} from "../pages/HR-Dashboard/routes";

/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 */
export const protectedRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/dashboard/*", element: <Dashboard /> },
      { path: "/dashboard-master", element: <DashboardMaster /> },
      { path: "/deals/*", element: <Deals /> },
      { path: "/product/*", element: <Product /> },
      { path: "/profile/*", element: <Profile /> },
      { path: "roles/*", element: <Roles /> },
      { path: "vendor/*", element: <Vendor /> },
      { path: "/employees/*", element: <Employees /> },
       
      { path:"/external-link/*", element : <ExternalLink />},
      { path:"/holidays/*", element : <Holidays />},
      { path:"/library/*", element : <Libraries />},
      { path:"/leaves/*", element : <Leaves />},
      { path:"/announcement/*", element : <Announcements />},
      { path: "/external-link/*", element : <ExternalLink />},
      { path: "/Postalcode*", element: <Postalcode /> },
      { path: "formbuilder/*", element: <Formbuilder /> },
      { path: "/warehouse/*", element: <Warehouse /> },
      { path: "/opening-stocks/*", element: <OpeningStocks /> },
      { path: "/purchase-orders/*", element: <PurchaseOrders /> },
      { path: "/good-inward/*", element: <GoodsInward /> },
      { path: "/dispatch-orders/*", element: <DispatchOrders /> },
      { path: "/letter-templates/*", element: <LetterTemplate /> },
      { path: "/assets/*", element: <Asset /> },
      { path: "/projects/*", element: <Projects /> },
      { path: "/finance/dashboard", element: <FinanceDashboard /> },     
      { path: "/finance/projects/*", element: <FinanceProjects /> },
      { path: "/finance/invoices/*", element: <FinanceInvoices /> },
      { path: "/finance/expenses/*", element: <FinanceExpenses /> },
      { path: "/finance/external-expenses/*", element: <FinanceExternalExpenses /> },
      { path: "/meetings/*", element: <Meetings /> },
      { path: "/team-meetings/*", element: <TeamMeetings /> },
      { path: "/my-team/*", element: <MyTeam /> },
      { path: "/survey/*", element: <Survey /> },
      { path: "/tickets/*", element: <InternalTickets />},
      { path: "/service-warranty/*", element: <ServiceWarranty />},
      { path: "/hmrc/*", element: <Hmrc /> },
      { path: "/medical/*", element: <Medical /> },
      { path: "/fleet/*", element: <Fleet /> },  
      { path: "/my-fleet/*", element: <MyFleet /> },  
      { path: "/installation/*", element: <Installer /> },      
      { path: "/expenses/*", element: <Expenses /> },
      { path: "/planner/*", element: <Planner /> },
      { path: "/tasks/*", element: <Tasks /> },
      { path: "/hr-dashboard/*", element: <HR_DASHBOARD /> },
      { path: '/profile-change-requests/*', element: <ProfileChangeRequest />}
      
    ],
  }, 
  { path: "/home", element: <LandingPage /> },
];

export const nameBasedProtectedRoutes = {
  dashborad: {
    name: "Dashboard",
    path: `/dashboard`,
  },
  roles: { name: "Roles", path: `/roles` },
  addRole: { name: "Add Role", path: "add-role" },
  addLink: { name: "Add External Link", path: "add-external-link" },
  addLetterTemplate: {
    name: "Add Letter Template",
    path: "add-letter-template",
  },
};
