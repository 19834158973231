import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  ROLE_DESC_REQUIRED,
  REQUIRED,
  VALID_EMAIL,
  ALPHABET_WITH_SPECIAL_CHAR_ALLOW,
  INVALID_DATE
} from "../../../validations/ValidationErrors";
import { EMAIL_REGEX, ONLY_ALPHABET, ALPHABET_WITH_SPECIAL_CHAR } from "../../../validations/regex";

export const addEmployeeSchema = yup.object({
  first_name: yup
    .string()
    .trim()
    .required()
    //.matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
    .matches(ALPHABET_WITH_SPECIAL_CHAR, ALPHABET_WITH_SPECIAL_CHAR_ALLOW)
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("First Name"),

  family_name: yup
    .string()
    .trim()
    .required()
    .matches(ALPHABET_WITH_SPECIAL_CHAR, ALPHABET_WITH_SPECIAL_CHAR_ALLOW)
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Family Name"),
  designation: yup.object().required() .label("Designation"),
  department: yup.object().required() .label("Department"),
  email: yup.string()
     .matches(EMAIL_REGEX, VALID_EMAIL) 
    .required()
    .max(255, MAXIMUM_255_CHAR)
    .label("Email"),
    
    
  contact_no: yup
    .number()
    .required()
    .typeError('Contact number must be a number')
    .min(999999999, 'Contact number should be  atleast 10 digits') // Maximum 15 digits
    .max(999999999999999, 'Contact number must be at most 15 digits') // Maximum 15 digits
    .label("Contact No"),
  id_card_issued: yup
    .boolean()
    .required()
    .label("ID Card Issued"),
  customer_facing: yup
  .boolean()
  // .oneOf([true], 'customer_facing must be allow') // Allow only true
  .required()
  .label("customer_facing"),
  status :yup
  .boolean()
  // .oneOf([true], 'status must be allow') // Allow only true
  .required()
  .label("Status"),


  address: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Address"),
  city: yup.string().trim().required().min(3, MINIMUM_3_CHAR).max(30, MAXIMUM_30_CHAR) .label("City"),
  state: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("County"),
  postal_code: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Postal Code"),
  reporting_manager: yup.object().required() .label("Reportiing Manager"),
  ni_number: yup
    .string()
    .trim()
    /* .required() */
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("NI Number"),
  employee_type: yup.object().required().label("Employee Type"),
  employment_status :yup.object().required() .label("Employment Status"),
  base_salary: yup
    .number()    
    //.required()
    .typeError('Base Salary must be a number')
    .positive('Base Salary must be positive')
    .integer('Base Salary must be an integer')
    
    .label("Base Salary"),
  role: yup.object().required().label("Role"),
  leave_allocated_cfy: yup
  .number()    
  .required()
  .typeError('Holiday Allotted CY must be a number')
  .integer('Holiday Allotted CY must be an integer')
  .max(999, 'Holiday Allotted CY must be at most 3 digits') // Maximum 15 digits
  
    .label("Holiday Allotted CY"),

  leave_allocated_nfy: yup
  .number()  
  .required()  
  .typeError('Holiday Allotted NY must be a number')
  .integer('Holiday Allotted NY must be an integer')
  .max(999, 'Holiday Allotted NY must be at most 3 digits') // Maximum 15 digits
 
    .label("Holiday Allotted NY"),

  driver_id_fleet: yup
    .string()
    .trim()
    // .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Driver Id Fleet"),

  keen_name1: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Kin Name 1"),

  keen_contact_no1: yup
  .number()
  .required()
  .typeError('Kin Contact number must be a number')
  .positive('Kin Contact number must be positive')
  .integer('Kin Contact number must be an integer')
  .max(999999999999999, 'Kin Contact number must be at most 15 digits') // Maximum 15 digits
    .label("Kin Contact 1"),

  keen_relation1: yup.object().required() .label("Keen Relation 1 "),

  keen_name2: yup
    .string()
    .trim()
    //.required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Kin  Name 2"),

  keen_contact_no2: yup
  .number()
  //.required()
  .typeError('Kin Contact number must be a number')
  .positive('Kin Contact number must be positive')
  .integer('kin Contact number must be an integer')
  .max(999999999999999, 'Contact number must be at most 15 digits') // Maximum 15 digits
    .label("Kin Contact 2 "),

  keen_relation2: yup.object()/* .required() */ .label("Kin Relation 2"),

  bank_name: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Bank Name"),

  account_name: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Account Name"),

  account_number: yup
  .number()
  .required()
  .typeError('Account Number must be a number')
  .positive('Account Number must be positive')
  .integer('Account Number must be an integer')
  .min(999, 'Account Number  must be minimum 4 digits') // Maximum 15 digits
  .max(999999999999999, 'Account Number must be at most 15 digits') // Maximum 15 digits

    .label("Account Number"),

  sort_code: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Sort Code"),

  job_description: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)    
    .label("Job Description"),
    personal_email: yup.string()
    .matches(EMAIL_REGEX, VALID_EMAIL) 
   
   .max(255, MAXIMUM_255_CHAR)
   .label("Personal Email"),
   
  contract_start_date: yup
  .date()
  .required()
  .typeError(INVALID_DATE)
  .label("Contract Start Date"),
});
