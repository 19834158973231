import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { MdEdit, MdDelete } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { jobTypePlanner } from "../../constants/constants";
import { getJobTypeClass } from "../../utility/common";
import { getProjectUploadedSignedUrl } from "../../services/project.service";
import { toast } from "react-toastify";
import { HiLocationMarker } from "react-icons/hi";

import DeletePopup from "../../components/common/deletePopup";
import {
  deleteTaskApiCall,
  markAsCompleteTaskApiCall,
} from "../../services/task.service";

//const avtarImg = "https://via.placeholder.com/50";
const avtarImg = "https://i.pravatar.cc/50";
const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ms-n1 me-3">
    <span>circle</span>
    <sapn>tra</sapn>
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
  <div className="mt-3">
    {getCircleStackIcon(icon)}
    <div className="flex-1">
      <h6>{heading}</h6>
      {children || <p className="mb-0 text-justify">{content}</p>}
    </div>
  </div>
);

const CalendarEventModal = ({
  setIsOpenEventModal,
  isOpenEventModal,
  modalEventContent,
  handleEventEdit = {},
  onlyEmployeeView,

  projectId = "",
}) => {
  const handleClose = () => {
    setIsOpenEventModal(!isOpenEventModal);
  };

  const { title, end, start, id } = isOpenEventModal && modalEventContent.event;
  const { description, assignee, job_type, status, isPrevious, project } =
    isOpenEventModal && modalEventContent.event.extendedProps;

  const [isDeletePopupOpen, setIsDeletePopupOpen] = React.useState(false);
  const [selectDeleteId, setSelectDeleteId] = React.useState(null);
  const [projectDocuments, setProjectDocuments] = React.useState([]);

  const [isMarkAsCompletePopupOpen, setIsMarkAsCompletePopupOpen] =
    React.useState(false);
  const [selectMarkAsCompleteId, setSelectMarkAsCompleteId] =
    React.useState(null);
  useEffect(() => {
    if (project && project.project_documents) {
      setProjectDocuments(project.project_documents);
    }
  }, [isOpenEventModal]);

  /**
   * Download Document click handler
   */
  const handleDownloadClick = (docType: string, docId: any = null) => {
    getProjectUploadedSignedUrl(docType.toLowerCase(), project.id, docId)
      .then((response: any) => {
        const signedFileUrl = response.data.file;
        // window.open(signedFileUrl, '_blank');
        window.open(signedFileUrl, "_blank");
        //console.log(signedFileUrl);
      })
      .catch((error: any) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {});
  };

  // delete event
  const handleEventDelete = (id) => {
    setSelectDeleteId(id);
    setIsDeletePopupOpen(true);
  };

  // mark as complete
  const handleEventComplete = (id) => {
    setSelectMarkAsCompleteId(id);
    setIsMarkAsCompletePopupOpen(true);
  };

  const handleMarkAsComplete = () => {
    markAsCompleteTaskApiCall(selectMarkAsCompleteId)
      .then((response) => {
        setIsMarkAsCompletePopupOpen(false);
        setIsOpenEventModal(false);
        toast.success(response.msg);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleDelete = () => {
    deleteTaskApiCall(selectDeleteId)
      .then((response) => {
        console.log(response);
        setIsDeletePopupOpen(false);
        setIsOpenEventModal(false);
        toast.success(response.msg);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleCloseDeletePopup = () => {
    setIsDeletePopupOpen(false);
    setSelectDeleteId(null);
  };

  const handleRedirectToMap = (address) => {
    //redirect to google map with address
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`);
  };
  return (
    <>
      <Modal
        show={isOpenEventModal}
        onHide={handleClose}
        contentClassName="border"
        className="assign-modal-main"
        centered
      >
        <Modal.Header
          closeButton
          closeVariant="gray-700"
          className="pb-0 px-x1 border-bottom-0 d-flex"
        >
          <Modal.Title className="w-100 d-flex justify-content-between">
            <h4 className="align-items-center d-flex fs-18 fw-bold info-text  w-100 mb-0">
              {title}
              <div
                className={` fs-14 p-2 ms-2 fw-semibold rounded-1 opacity30  ${getJobTypeClass(
                  job_type
                )}`}
              >
                {job_type}
              </div>
            </h4>

            {!onlyEmployeeView && status !== "completed" && (
              <span className="d-flex">
                <span className="float-end me-3" title="Mark as complete">
                  <a
                    href="javascript:void(0)"
                    className="text-primary fs-24 lh-20"
                    onClick={() => handleEventComplete(id)}
                  >
                    <FaCheckCircle />{" "}
                  </a>
                </span>
                {(!isPrevious && job_type != "Installation Task") && (
                  <span className="d-flex">
                    <span className="float-end me-3" title="Task Delete">
                      <a
                        href="javascript:void(0)"
                        className="text-danger fs-24 lh-20"
                        onClick={() => handleEventDelete(id)}
                      >
                        <MdDelete />{" "}
                      </a>
                    </span>
                    <span className="float-end me-3" title="Task Edit">
                      <a
                        href="javascript:void(0)"
                        className="text-primary fs-24 lh-20"
                        onClick={() => handleEventEdit(id)}
                      >
                        <MdEdit />{" "}
                      </a>
                    </span>
                  </span>
                )}
              </span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-1 fs-14">
          {
            (project?.project_no_str && project?.name) &&
            <div
              className="d-flex align-items-center mt-1 text-primary"
            >
              {`${project?.project_no_str} - ${project?.name}`}
            </div>
          }
          
          {(end || start) && (
            <span>
              {moment(start).format("Do MMMM")} -{" "}
              {moment(end).format("Do MMMM")}{" "}
              <span className="me-2 ms-2">|</span>{" "}
              {moment(start).format("hh:mm A")} -{" "}
              {moment(end).format("hh:mm A")}
            </span>
          )}

          {project?.address && (
            <div
              className="d-flex align-items-center mt-1"
              onClick={() => handleRedirectToMap(project?.address)}
            >
              <HiLocationMarker className="text-danger" />
              &nbsp;{project?.address}
            </div>
          )}
          {/* job type */}

          <div className="d-flex mt-3">
            <div className="me-3">
              {assignee &&
              assignee.employeeDetails &&
              assignee.employeeDetails.user_photo ? (
                <img
                  src={assignee.employeeDetails.user_photo}
                  alt="avtar"
                  className="rounded-circle w-34 h-34"
                />
              ) : (
                <img
                  src={avtarImg}
                  alt="avtar"
                  className="rounded-circle w-34 h-34"
                />
              )}
            </div>
            <div>
              {assignee && (
                <h2 className="text-primary fs-16 mb-2">
                  {assignee?.full_name}
                </h2>
              )}
              {description && <div className="fs-14">{description}</div>}
            </div>
          </div>

          {projectDocuments.length > 0 && (
            <div className="mt-3">
              <h2 className="fs-14 control-label mb-1 fw-bold">Documents</h2>
               
              <div className="fs-14 link-text">
                {projectDocuments.map((doc, index) => {
                  return (
                    <>
                      <button
                        type="button"
                        className="btn btn-link link-text p-0 text-decoration-none"
                        onClick={() =>
                          handleDownloadClick(doc.doc_key, doc?.id)
                        }
                      >
                        <span className="me-3">{doc.doc_name}</span>
                        {projectDocuments.length - 1 == index ? null : (
                          <span>|</span>
                        )}
                      </button>
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {isDeletePopupOpen && (
        <DeletePopup
          isOpen={isDeletePopupOpen}
          onClose={handleCloseDeletePopup}
          onDelete={handleDelete}
          label="Delete Task"
          text="Are you sure you want to delete this Task?"
        />
      )}
      {isMarkAsCompletePopupOpen && (
        <DeletePopup
          isOpen={isMarkAsCompletePopupOpen}
          onClose={() => setIsMarkAsCompletePopupOpen(false)}
          onDelete={handleMarkAsComplete}
          label="Mark as complete"
          text="Are you sure you want to mark this task as complete?"
          actionType="Mark as complete"
        />
      )}
    </>
  );
};

EventModalMediaContent.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node,
};

CalendarEventModal.propTypes = {
  setIsOpenEventModal: PropTypes.func.isRequired,
  isOpenEventModal: PropTypes.bool.isRequired,
  modalEventContent: PropTypes.object.isRequired,
};

export default CalendarEventModal;
