
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Card, Col, Image, Row, Spinner } from "react-bootstrap";

import EditIcon from "@mui/icons-material/Edit";
import Button from "react-bootstrap/Button";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InputSwitchBox from "../../../components/common/InputSwitchBox";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  getEmployeeDetailsApi,
  updateEmployeeStatusApi,
} from "../../../services/employeeService"
import Loader from "../../../components/common/Loader";
import EmployeeDetails from './EmployeeDetails';
import { checkAbility } from "../../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import EmployeeSentEmails from "./EmployeeSentEmails";
import EmployeeMeetings from "./Meetings";
import EmployeeDocuments from "./Documents";
import EmployeeInduction from "./EmployeeInduction";
import EmployeeAllocatedAssets from "./EmployeeAllocatedAssets";
import EmployeeLeaves from "./EmployeeLeaves";
import {
  MEETING_SOURCE_PAGES
} from "../../../constants/constants";

export default function ViewEmployee() {
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState<any>({});
  const navigate = useNavigate();
  const [isMutation, setIsMutation] = useState(false);
  const [checkEditPermission, setCheckEditPErmission] = useState(() => {
    return checkAbility(PERMISSION_ACCESS.EDIT,PERMISSION_MODULE.ALL_EMPLOYEE)
  });
  let { id, tabKey } = useParams<string>();
  if(tabKey === undefined){
    tabKey = 'home';
  }

  const employeeDetails = employee?.employeeDetails ? employee?.employeeDetails : {};
  const kinDetails = employee?.kinDetails ? employee?.kinDetails : {};
  const employeeBankDetails = employee?.emplyeeBankDetails ? employee?.emplyeeBankDetails : {};

  /**
   * Get employee details from server
   * @async
   * @param {id} ID of employee
   */
  const getEmployeeDetail = async (id: string) => {
    setLoading(true);
    await getEmployeeDetailsApi(id)
      .then((response: any) => {
        setEmployee(response.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
        navigate(-1);
      });
  };


  /**
   * Update status of employee
   * @async
   * @param {id} ID of employee
   */
  const handleStatusChange = async(status: boolean) => {
    const id =  employee.id;
    const formData = {
      status  : employee.status
    }   
      updateEmployeeStatusApi(id, formData)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
      })
      .catch((error) => {
        toast(error.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {
        setIsMutation(!isMutation)
      });
  };

  // Edit handler to navigate to edit employee page
  const handleEditClick = (id: string | undefined) => () => {
    navigate(`/employees/edit-employee/${id}`);
  };

  // This is handler which gets called when a tab selected.
  const handleTabSelect = (key: string | null) => {
    navigate(`/employees/view-employee/${id}/${key}`);
  };

  useEffect(() => {
    if (id) {
      getEmployeeDetail(id);
    }
    else {
      navigate(-1);
    }
  }, [isMutation]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">{`${employee.first_name} ${employee.last_name}`}
                  <span title="Conditional Onboarding" className="py-1 px-2 rounded-1 Onboarding-tag d-inline-block fs-11">{employeeDetails.designation}</span>
                </h1>
              </div>
              {checkEditPermission && <div>
                <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={handleEditClick(id)}
                >
                  <EditIcon className="me-2 fs-15" />
                  Edit Employee
                </Button>
              </div>}
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" w-100 pageContentInner">
              <div className="d-flex flex-column h-100 rounded-0">
                <div className="d-flex justify-content-between border-bottom p-3 align-items-center">
                  {checkEditPermission && <div className="d-flex emp-detail align-items-center">
                    <InputSwitchBox
                      controlId="status"
                      placeholder="status"
                      label=""
                      handleChange={(event: any) => {
                        handleStatusChange(employee.status)
                      }}
                      value={employee.status=='active' ? true :false}

                    />
                    
                    <span className="ms-2">Active</span>
                  </div>}
                  <div className="d-flex">
                    <div className="fs-14 me-3">Driver ID: <span className="fw-bold">{employeeDetails.driver_id_fleet ? employeeDetails.driver_id_fleet : 'N/A'}</span></div>
                    <div className="fs-14">Type: <span className="fw-bold">{employeeDetails.employee_type ? employeeDetails.employee_type : 'N/A'}</span></div>
                  </div>


                </div>
                <div className="p-3 ">
                  <div className="row">
                    <div className="col-md-2">
                      <label className="control-label">Department</label>
                      <p className="fs-14 mb-2">{employeeDetails.department}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="control-label">Reporting Manager </label>
                      <p className="fs-14 mb-2">{employeeDetails?.manager?.full_name}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="control-label">Contact No. </label>
                      <p className="fs-14 mb-2">{employee.phone_no}</p>
                    </div>
                    <div className="col-md-2">
                      <label className="control-label">Contract Start Date</label>
                      <p className="fs-14 mb-2">{employee?.contract_start_date ? employee.contract_start_date : "-"}</p>
                    </div>
                    <div className="col-md-4">
                      <label className="control-label">Address</label>
                      <p className="fs-14 mb-2">{`${employeeDetails.address}, ${employeeDetails.city}, ${employeeDetails.state}, ${employeeDetails.postal_code}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <Tabs
              defaultActiveKey={tabKey}
              id="uncontrolled-tab-example"
              className="mb-3 emp-tabs mt-3"
              activeKey={tabKey}
              onSelect={(k: string | null) => handleTabSelect(k)}
            >
              <Tab eventKey="home" title="Details">
              {tabKey === 'home' && <EmployeeDetails source={MEETING_SOURCE_PAGES.hr} employeeDetails={employeeDetails} kinDetails={kinDetails} employeeBankDetails={employeeBankDetails} />}
              </Tab>
              <Tab eventKey="document" title="Documents">              
              {tabKey === 'document' && <EmployeeDocuments employeeID = {employee.id} />}
              </Tab>
              <Tab eventKey="assets" title="Assets" >
                {tabKey === 'assets' && <EmployeeAllocatedAssets employeeID = {employee.id} />}
              </Tab>
              <Tab eventKey="induction" title="Induction" >
                {tabKey === 'induction' && <EmployeeInduction employeeID = {employee.id} />}
              </Tab>
              <Tab eventKey="meetings" title="Meetings" >
                {tabKey === 'meetings' && <EmployeeMeetings employeeID = {employee.id} source={MEETING_SOURCE_PAGES.hr} />}
              </Tab>

              <Tab eventKey="leaves" title="Leaves & History" >
               {tabKey === 'leaves' && <EmployeeLeaves employeeID = {employee.id} />}               
              </Tab>
              <Tab eventKey="emails" title="Emails" >
                {tabKey === 'emails' && <EmployeeSentEmails employeeID = {employee.id} />}
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
