import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FieldArray, Formik, getIn } from "formik";
import { addEmployeeSchema } from "./validation";
import { toast } from "react-toastify";
import moment, { Moment } from "moment";
import InputText from "../../../components/common/InputText";
import { Card, Col, Image, Row, Spinner } from "react-bootstrap";
import InputTextArea from "../../../components/common/InputTextArea";
import InputDatePicker from "../../../components/common/InputDatePicker";
import {
  createEmployeeApi,
  uploadEmployeeImageApi,
  getEmployeeDetailsApi,
  updateEmployeeApi,
} from "../../../services/employeeService";
import { getAllManagerList } from "../../../services/authService";
import InputCheckBox from "../../../components/common/InputCheckBox";
import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import CustomSelect from "../../../components/common/CustomSelect";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";
import ProfileDummy from "../../../assets/images/profile-dummy.png";
import ProfileEditIcon from "../../../assets/images/profile-edit-icon.svg";
import FormSelect from "../../../components/common/FormSelect";
import { EmployeeData } from "./employee.type";
import { getRolesList } from "../../../services/roleService";
import constants, {
  allowedFileTypes,
  commonMasking,
  departmentOptions,
  designationOptions,
  employeeTypeOptions,
  employmentStatusOptions,
  maxFileSizeMB,
  relatioinOptions,
  reportingManagerOptions,
  rolesDefaultParams,
} from "../../../constants/constants";
import { checkAbility, phoneMask } from "../../../utility/common";
import FileUploader from "../../../components/common/FileUploader";
import {
  FILE_SIZE_ERROR,
  FILE_TYPE_ERROR,
} from "../../../validations/ValidationErrors";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";

export default function AddRoles() {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);
  const [employeeData, setEmployeeData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [departmentDesignationAssociations, setDepartmentDesignationAssociations] = useState<any>([]);
  const navigate = useNavigate();
  const defaultOptionRelation2 = {key:"", value:"Select Relation"};
  let { id } = useParams();

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: EmployeeData, { setSubmitting }: any) => {
    const formData = {
      ...values,
    };

    setBtnLoading(true);
    if (id) {
      updateEmployeeApi(id, formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/employees");
          setBtnLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createEmployeeApi(values)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/employees");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  interface RoleOption {
    key: string;
    value: string;
  }

  interface RoleData {
    id: number;
    role: string;
    description: string;
    created_at: string;
  }

  useEffect(() => {
    updateDepartmentAssociations();
    fetchRolls().then(() => {
      if (id) {
        getEmployeeDetails(id);
      } else {
        setLoading(false);
      }
    });
  }, []);
  const handleCancel = () => {
    navigate(-1);
  };

    const updateDepartmentAssociations = () => {
      const associations: { [key: string]: any } = {};
      designationOptions.forEach(designation => {
        if (associations[designation.department]) {
          associations[designation.department].push(designation);
        } else {
          associations[designation.department] = [designation];
        }
      });
    // console.log(associations);
      setDepartmentDesignationAssociations(associations);
    };

  const getEmployeeDetails = (id: string) => {
    setLoading(true);
    getEmployeeDetailsApi(id)
      .then((response) => {
        setEmployeeData(response.data);
        if (response.usePhoto) {
          setImagePreview(response.usePhoto);
        }

        setLoading(false);
      })

      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchRolls = async () => {
    return new Promise((resolve, reject) => {
      getRolesList(rolesDefaultParams).then((response) => {
        const roleModifyOption = response.data?.resultSet.map((item: any) => ({
          key: String(item.id),
          value: item.role,
        }));
        setRoleOptions(roleModifyOption);
        return resolve(true);
      });
    });
  };

  const getReportingManagers = (params: any) => {
    return new Promise((resolve, reject) => {
      if (id) {
        params["employee_id"] = id;
      }
      getAllManagerList(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const uploadProfileImage = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast(FILE_TYPE_ERROR, { type: toast.TYPE.ERROR });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(FILE_SIZE_ERROR, { type: toast.TYPE.ERROR });

      return;
    } else {
      setImageLoading(true);
      if (imageFile) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setImagePreview(reader.result);
        };

        reader.readAsDataURL(imageFile);
      } else {
        setImagePreview(null);
      }

      // Create FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append("image", imageFile);

      uploadEmployeeImageApi(formData)
        .then((response) => {
          setImageLoading(false);
          setFieldValue("user_photo", response.data);
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };

  const initialValues = {
    first_name: employeeData?.first_name ? employeeData?.first_name : "",
    family_name: employeeData?.last_name ? employeeData?.last_name : "",
    designation: employeeData?.employeeDetails
      ? designationOptions.find(
          (item) => item.key == employeeData.employeeDetails.designation
        )
      : designationOptions[0],
    department: employeeData?.employeeDetails
      ? departmentOptions.find(
          (item) => item.key == employeeData.employeeDetails.department
        )
      : departmentOptions[0],
    email: employeeData.email ? employeeData.email : "",
    contact_no: employeeData.phone_no ? employeeData.phone_no : "",
    id_card_issued: employeeData?.employeeDetails?.id_card_issued
      ? employeeData?.employeeDetails?.id_card_issued
      : false,
    customer_facing: employeeData?.employeeDetails?.customer_facing
      ? employeeData?.employeeDetails?.customer_facing
      : false,
    address: employeeData?.employeeDetails?.address
      ? employeeData?.employeeDetails?.address
      : "",
    city: employeeData?.employeeDetails?.city
      ? employeeData?.employeeDetails?.city
      : "",
    state: employeeData?.employeeDetails?.state
      ? employeeData?.employeeDetails?.state
      : "",
    postal_code: employeeData?.employeeDetails?.postal_code
      ? employeeData?.employeeDetails?.postal_code
      : "",
    reporting_manager: employeeData?.employeeDetails?.reporting_manager
      ? employeeData?.employeeDetails?.manager
      : null,
    ni_number: employeeData?.employeeDetails?.ni_number
      ? employeeData?.employeeDetails?.ni_number
      : "",
    employee_type: employeeData?.employeeDetails
      ? employeeTypeOptions.find(
          (item) => item.key == employeeData.employeeDetails.employee_type
        )
      : employeeTypeOptions[0],
    base_salary: employeeData?.employeeDetails?.base_salary
      ? employeeData?.employeeDetails?.base_salary
      : "",
    role: employeeData
      ? roleOptions.find((item) => item.key == employeeData.role_id)
      : {},
    leave_allocated_cfy: employeeData?.employeeDetails?.leave_allocated_cfy
      ? employeeData?.employeeDetails?.leave_allocated_cfy
      : "",
    job_description: employeeData?.employeeDetails?.job_description
      ? employeeData?.employeeDetails?.job_description
      : "",
    leave_allocated_nfy: employeeData?.employeeDetails?.leave_allocated_nfy
      ? employeeData?.employeeDetails?.leave_allocated_nfy
      : "",
    driver_id_fleet: employeeData?.employeeDetails?.driver_id_fleet
      ? employeeData?.employeeDetails?.driver_id_fleet
      : "",
    keen_name1: employeeData?.kinDetails?.keen_name1
      ? employeeData?.kinDetails?.keen_name1
      : "",
    keen_contact_no1: employeeData?.kinDetails?.keen_contact_no1
      ? employeeData?.kinDetails?.keen_contact_no1
      : "",
    keen_relation1: employeeData?.kinDetails
      ? relatioinOptions.find(
          (item) => item.key == employeeData.kinDetails.keen_relation1
        )
      : relatioinOptions[0],
    keen_name2: employeeData?.kinDetails?.keen_name2
      ? employeeData?.kinDetails?.keen_name2
      : "",
    keen_contact_no2: employeeData?.kinDetails?.keen_contact_no2
      ? employeeData?.kinDetails?.keen_contact_no2
      : "",
    keen_relation2: employeeData?.kinDetails
      ? relatioinOptions.find(
          (item) => item.key == employeeData.kinDetails.keen_relation2
        )
      : defaultOptionRelation2,//relatioinOptions[0],
    bank_name: employeeData?.emplyeeBankDetails?.bank_name
      ? employeeData?.emplyeeBankDetails?.bank_name
      : "",
    account_name: employeeData?.emplyeeBankDetails?.account_name
      ? employeeData?.emplyeeBankDetails?.account_name
      : "",
    account_number: employeeData?.emplyeeBankDetails?.account_number
      ? employeeData?.emplyeeBankDetails?.account_number
      : "",
    sort_code: employeeData?.emplyeeBankDetails?.sort_code
      ? employeeData?.emplyeeBankDetails?.sort_code
      : "",
    user_photo: employeeData?.employeeDetails?.user_photo
      ? employeeData?.employeeDetails?.user_photo
      : "",
    status:
      employeeData.status && employeeData.status
        ? employeeData.status == "active"
          ? true
          : false
        : false,
    employment_status: employeeData?.employeeDetails
      ? employmentStatusOptions.find(
          (item) => item.key == employeeData.employeeDetails.employment_status
        )
      : employmentStatusOptions[0],
      personal_email : employeeData?.employeeDetails?.personal_email
      ? employeeData?.employeeDetails?.personal_email
      : "",
      contract_start_date: employeeData?.contract_start_date ? employeeData.contract_start_date : null
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  {id ? "Edit" : "Add"} Employee
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Formik
              validationSchema={addEmployeeSchema}
              initialValues={initialValues}
              // onSubmit={(values: FormValues, actions) => {
              //   submitForm(values, actions);
              // }}
              onSubmit={(values: EmployeeData, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Card className="p-3 w-100 pageContentInner mb-3">
                    <div>
                      <Row>
                        <div className="col-md-8">
                          <Row>
                            <div className="col-md-6">
                              <InputText
                                controlId="first_name"
                                label="First Name"
                                placeholder="Enter first name"
                                touched={touched.first_name}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.first_name}
                                value={values.first_name}
                              />
                            </div>

                            <div className="col-md-6">
                              <InputText
                                controlId="family_name"
                                label="Family Name"
                                placeholder="Enter family name"
                                touched={touched.family_name}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.family_name}
                                value={values.family_name}
                              />
                            </div>
                            <div className="col-md-6">
                              <FormSelect
                                placeholder="Department"
                                label="Department"
                                name="department"
                                options={departmentOptions}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                  setFieldValue('designation', null);
                                }}
                                value={values.department}
                                error={errors.department}
                                touched={touched.department}
                              />
                            </div>
                            <div className="col-md-6">
                              <FormSelect
                                placeholder="Designation"
                                label="Designation"
                                name="designation"
                                options={values.department?.key ? departmentDesignationAssociations[values.department?.key] : []}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.designation}
                                error={errors.designation}
                                touched={touched.designation}
                              />
                            </div>
                            <div className="col-md-6">
                              <InputText
                                controlId="email"
                                label="Email "
                                //isDisable={id ? true : false}
                                placeholder="Enter email"
                                touched={touched.email}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.email}
                                value={values.email}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="lh-sm control-label fs-14 fw-bold form-label">
                                Contact Number
                              </label>
                              <div className="row">
                                <div className="col-md-12">
                                  <InputText
                                    controlId="contact_no"
                                    label=""
                                    placeholder="Enter contact number"
                                    touched={touched.contact_no}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    errorsField={errors.contact_no}
                                    value={phoneMask(values.contact_no)}
                                    mask={commonMasking}
                                  />
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 custom-from-group">
                            <label className="lh-sm control-label fs-14 fw-bold form-label">
                              Profile Photo
                            </label>
                            <div className="position-relative ">
                              <FileUploader
                                uploadProfileImage={(e) =>
                                  uploadProfileImage(e, setFieldValue)
                                }
                                imageLoading={imageLoading}
                                imagePreview={imagePreview}
                              />
                            </div>
                            <div className="col-md-12">
                              <div className="d-flex ">
                                {/* <Form.Check className="d-inline-block me-4" type="checkbox" id="check1"> */}
                                <InputCheckBox
                                  controlId="id_card_issued"
                                  label="ID card issued"
                                  touched={touched.id_card_issued}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.id_card_issued}
                                  value={values.id_card_issued}
                                />
                                <div className="ms-4">
                                  <InputCheckBox
                                    controlId="customer_facing"
                                    label="Customer Facing ?"
                                    touched={touched.customer_facing}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    errorsField={errors.customer_facing}
                                    value={values.customer_facing}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-4">
                          <InputText
                            controlId="address"
                            label="Address"
                            placeholder="Enter address"
                            touched={touched.address}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.address}
                            value={values.address}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputText
                            controlId="city"
                            label="City"
                            placeholder="Enter city"
                            touched={touched.city}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.city}
                            value={values.city}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputText
                            controlId="state"
                            label="County"
                            placeholder="Enter County"
                            touched={touched.state}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.state}
                            value={values.state}
                          />
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-4">
                          <InputText
                            controlId="postal_code"
                            label="Postal Code"
                            placeholder="Enter postal code"
                            touched={touched.postal_code}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.postal_code}
                            value={values.postal_code}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormAsyncSelect
                            id="reporting_manager"
                            label="Reporting Manager"
                            name="reporting_manager"
                            isAsync
                            isClearable
                            getOptions={getReportingManagers}
                            value={values.reporting_manager}
                            onChange={(name: any, value: any) => {
                              setFieldValue(name, value);
                            }}
                            error={errors.reporting_manager}
                            touched={touched.reporting_manager}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputText
                            controlId="ni_number"
                            label="NI Number"
                            placeholder="Enter NI number"
                            touched={touched.ni_number}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.ni_number}
                            value={values.ni_number}
                          />
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-12">
                              <FormSelect
                                placeholder="Employee Type"
                                label="Employee Type"
                                name="employee_type"
                                options={employeeTypeOptions}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.employee_type}
                                error={errors.employee_type}
                                touched={touched.employee_type}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-12">
                              <FormSelect
                                placeholder="Role"
                                label="Role"
                                name="role"
                                options={roleOptions}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.role}
                                error={errors.role}
                                touched={touched.role}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="col-sm-12">
                            <label className="lh-sm control-label fs-14 fw-bold"></label>
                            <InputCheckBox
                              controlId="status"
                              label="Active"
                              touched={touched.status}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.status}
                              className="mt-custom"
                              value={values.status}
                            />
                          </div>
                        </div>
                      </Row>

                      <Row>
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-12">
                              <FormSelect
                                placeholder="Employment Status"
                                label="Employment Status"
                                name="employment_status"
                                options={employmentStatusOptions}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.employment_status}
                                error={errors.employment_status}
                                touched={touched.employment_status}
                              />
                            </div>
                            <div className="col-md-12">
                              <InputText
                                controlId="leave_allocated_cfy"
                                label="Holiday Allocation – Current Year "
                                placeholder="Enter holidays allotted"
                                touched={touched.leave_allocated_cfy}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                isDisable={employeeData?.employeeLeave?.length ?? false}
                                errorsField={errors.leave_allocated_cfy}
                                value={values.leave_allocated_cfy}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-12">
                              <InputText
                                controlId="base_salary"
                                label="Base Salary (£) "
                                placeholder="Enter base salary"
                                touched={touched.base_salary}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.base_salary}
                                value={values.base_salary}
                              />
                            </div>
                            <div className="col-md-12">
                              <InputText
                                controlId="leave_allocated_nfy"
                                label="Holiday Allocation – Next Year "
                                placeholder="Enter holiday allotted"
                                touched={touched.leave_allocated_nfy}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.leave_allocated_nfy}
                                value={values.leave_allocated_nfy}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 custom-from-group">
                            <div className="position-relative ">
                              <InputTextArea
                                controlId="job_description"
                                label=" Job Description"
                                placeholder="Enter Description"
                                touched={touched.job_description}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.job_description}
                                name="job_description"
                                value={values.job_description}
                                className="max-height300 resize-none"
                              />
                              <div className="invalid-feedback"></div>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-4">
                          <InputText
                            controlId="driver_id_fleet"
                            label="Driver ID - Fleet"
                            placeholder="Enter driver ID"
                            touched={touched.driver_id_fleet}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.driver_id_fleet}
                            value={values.driver_id_fleet}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputText
                            controlId="personal_email"
                            label="Personal Email"
                            placeholder="Enter Personal Email"
                            touched={touched.personal_email}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.personal_email}
                            value={values.personal_email}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputDatePicker
                              name="contract_start_date"
                              label="Contract Start Date"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  placeholder: "Select",
                                  className: "form-control",
                                  readOnly: true,
                                  id: "contract_start_date",
                                },
                              }}  
                              isDisable={false}                          
                              value={values.contract_start_date}
                              onChange={(name, newValue) =>
                                setFieldValue(name, newValue)
                              }
                              touched={touched.contract_start_date}
                              errors={errors.contract_start_date}
                            />
                        </div>
                      </Row>
                    </div>
                  </Card>
                  <Card className="p-3 w-100 pageContentInner mb-3">
                    <h6 className="fs-18 fw-bold mb-3 info-text">
                      Next of Kin Details
                    </h6>
                    <Row>
                      <div className="col-md-4">
                        <InputText
                          controlId="keen_name1"
                          label="Kin 1 - Name"
                          placeholder="Enter Kin 1 - name"
                          touched={touched.keen_name1}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.keen_name1}
                          value={values.keen_name1}
                        />
                      </div>

                      <div className="col-md-4">
                        <InputText
                          controlId="keen_contact_no1"
                          label="Kin 1 - Contact Number"
                          placeholder="Enter Kin 1 - contact number"
                          touched={touched.keen_contact_no1}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.keen_contact_no1}
                          value={phoneMask(values.keen_contact_no1)}
                          mask={commonMasking}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormSelect
                          placeholder="Relation"
                          label="Relation"
                          name="keen_relation1"
                          options={relatioinOptions}
                          getOptionLabel={(option: any) => option.value}
                          getOptionValue={(option: any) => option.key}
                          onChange={(name: string, value: string) => {
                            setFieldValue(name, value);
                          }}
                          value={values.keen_relation1}
                          error={errors.keen_relation1}
                          touched={touched.keen_relation1}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-4">
                        <InputText
                          controlId="keen_name2"
                          label="Kin  2- Name"
                          placeholder="Enter Kin 2 - name"
                          touched={touched.keen_name2}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.keen_name2}
                          value={values.keen_name2}
                        />
                      </div>

                      <div className="col-md-4">
                        <InputText
                          controlId="keen_contact_no2"
                          label="Kin 2 - Contact Number"
                          placeholder="Enter Kin 2 - contact number"
                          touched={touched.keen_contact_no2}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.keen_contact_no2}
                          value={phoneMask(values.keen_contact_no2)}
                          mask={commonMasking}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormSelect
                          placeholder="Relation"
                          label="Relation"
                          name="keen_relation2"
                          //options={relatioinOptions}
                          options={[defaultOptionRelation2,...relatioinOptions]}
                          getOptionLabel={(option: any) => option.value}
                          getOptionValue={(option: any) => option.key}
                          onChange={(name: string, value: string) => {
                            setFieldValue(name, value);
                          }}
                          value={values.keen_relation2}
                          error={errors.keen_relation2}
                          touched={touched.keen_relation2}
                        />
                      </div>
                    </Row>
                  </Card>
                  { (!id || ( id && checkAbility(PERMISSION_ACCESS.SENSITIVE_DATA, PERMISSION_MODULE.ALL_EMPLOYEE)))&& (
                  <Card className="p-3 w-100 pageContentInner mb-3">
                    <h6 className="fs-18 fw-bold mb-3 info-text">
                      Bank Details
                    </h6>
                    <Row>
                      <div className="col-md-4">
                        <InputText
                          controlId="bank_name"
                          label="Bank Name"
                          placeholder="Enter bank name"
                          touched={touched.bank_name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.bank_name}
                          value={values.bank_name}
                        />
                      </div>

                      <div className="col-md-4">
                        <InputText
                          controlId="account_name"
                          label="Account Name"
                          placeholder="Enter account name"
                          touched={touched.account_name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.account_name}
                          value={values.account_name}
                        />
                      </div>
                      <div className="col-md-4">
                        <InputText
                          controlId="account_number"
                          label="Account Number"
                          placeholder="Enter account number"
                          touched={touched.account_number}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.account_number}
                          value={values.account_number}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-4">
                        <InputText
                          controlId="sort_code"
                          label="Sort Code"
                          placeholder="Enter sort code"
                          touched={touched.sort_code}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.sort_code}
                          value={values.sort_code}
                        />
                      </div>
                    </Row>
                  </Card>
                  )}

                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-end my-3">
                        <CustomButtonCancel label="Cancel" />

                        <CustomButton
                          type="submit"
                          loading={btnLoading}
                          className="fw-semibold fs-13 text-white mw-90 mt-2"
                          variant="primary"
                        >
                          {id ? "Update" : "Add"} Employee
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
