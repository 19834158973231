var CryptoJS = require("crypto-js");

const storagePrefix = 'EA_';


/**
 * Storage management in app via localStorage
 *
 * @type {{ getToken: () => any; setToken: (token: string) => void; getRefreshToken: () => any; setRefreshToken: (token: string) => void; clearToken: () => void; setData: (key: string, data: any) => void; getData: (key: string) => any; }}
 */
const storage = {
  getToken: () => {
    return window.localStorage.getItem(`${storagePrefix}token`)
  
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`,token)
   // window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  getRefreshToken: () => {
   return window.localStorage.getItem(`${storagePrefix}refreshToken`);
   //return JSON.parse(window.localStorage.getItem(`${storagePrefix}refreshToken`) as string);
  },
  setRefreshToken: (token: string) => {
    // window.localStorage.setItem(`${storagePrefix}refreshToken`, JSON.stringify(token));
    window.localStorage.setItem(`${storagePrefix}refreshToken`,token);
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}refreshToken`);
  },
  setData: (key: string, data: any) => {
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), storagePrefix);
    window.localStorage.setItem(key, cipherText);
  },
  getData: (key: string) => {
    const dataString = window.localStorage.getItem(key)?window.localStorage.getItem(key):'';
    if(dataString){
      const bytes = CryptoJS.AES.decrypt(dataString, storagePrefix);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decrypted);
    }
  },
  savePlannerProject: (projectData: any) => {
    window.sessionStorage.setItem(`${storagePrefix}plannerProject`, JSON.stringify(projectData));
  },
  savePlannerTeam: (teamData: any) => {
    window.sessionStorage.setItem(`${storagePrefix}plannerTeam`, JSON.stringify(teamData));
  },
  getPlannerFilter: (key: any) => {
    let data = window.sessionStorage.getItem(`${storagePrefix}${key}`);
    return (data) ? JSON.parse(data) : null;
  },
  clearPlannerFilter: () => {
    window.sessionStorage.removeItem(`${storagePrefix}plannerProject`);
    window.sessionStorage.removeItem(`${storagePrefix}plannerTeam`);
  }
};

export default storage;
