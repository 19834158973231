import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { getDashboardPendingPoApi } from "../../../services/warehouseService";
import { checkAbility, getLocalDateFormat } from "../../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import { Link } from "react-router-dom";

export const DashboardPendingPo = () => {
  const [pendingPo, setPendingPo] = useState<any>([]);

  const getDashboardPendingPo = () => {
    // setLoading(true);
    getDashboardPendingPoApi()
       .then((response) => {
         setPendingPo(response.data);
        // setLoading(false);
       })
       .catch((error) => {
       //  setLoading(false);
       })
       .finally(() => {
        // setLoading(false);
       });
   };
  useEffect(() => {
    getDashboardPendingPo();
  }, []);

  return (
    <div className="d-flex flex-column pageContainer  w-100 pb-sm-1 h-100">
      <h5 className="fs-20 info-text fw-bold">Pending Purchase Orders</h5>
      <Card className="p-3 w-100 pageContentInner warehouse-box-green max-h-250">
        <Table className="table-custom">
          <thead>
            <tr>
              <th>PO Number</th>
              <th>Vendor</th>
              <th>Expected Delivery Date</th>
            </tr>
          </thead>
          <tbody>
            {
              pendingPo?.length > 0 ? (
                pendingPo.map((poData: {
                  id: any; po_number_str: any; vendor: { vendor_name: string | number | null | undefined; }; due_date: any; vendor_name: any; 
}) => (
                <tr>
                  <td>
                    {
                      checkAbility(
                        PERMISSION_ACCESS.VIEW,
                        PERMISSION_MODULE.WAREHOUSE
                      ) ? (
                        <Link
                            target="_blank"
                            className="blue-text text-decoration-none fw-semibold"
                            to={`/purchase-orders/view/${poData.id}`}
                          >
                            {" "}
                            {poData?.po_number_str}
                          </Link>
                      )
                      :
                      (
                        poData?.po_number_str
                      )
                    }
                    </td>
                  <td>{poData?.vendor?.vendor_name ? poData.vendor.vendor_name : (poData?.vendor_name ? poData.vendor_name : "-")}</td>
                  <td>{getLocalDateFormat(poData?.due_date)}</td>
                </tr>
                ))

              ) : (
                <tr>
                  <td colSpan={3}>No Records Available</td>
                </tr>
                
              )
            }
          </tbody>
        </Table>
      </Card>
    </div>
  );
};
