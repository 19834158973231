import { formatValue } from "react-currency-input-field";
import ability from "../role-manager/ability";
import moment from "moment";
import constants from "../constants/constants";

export const phoneMask = (phoneNumber) => {
    let maskedNumber = phoneNumber;    
    if (phoneNumber) {
      const formattedNumber = phoneNumber.replace(/\D/g, "");
      maskedNumber = formattedNumber.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})$/,
        "+$1 $2 $3 $4"
      );
    }
 
    return maskedNumber;
  
  };


export const checkAbility = (operation, module) =>{
  return ability.can(operation, module);
}

export const currencyMask = (currentNumber) => {
  const formattedValue = formatValue({
    value: String(currentNumber),
    fixedDecimalLength: 2,
    decimalScale:2,
    intlConfig: { locale: 'en-GB', currency: 'GBP' },
  });
  return formattedValue
}

export const getLocalDateFormat = (datestring) => {
  let returnValue = datestring;
  if(datestring){
    returnValue = moment(datestring).format(constants.dateFormats.slashSeparatedDate)
  }
  return returnValue;
}

export const getTagClass = (tag) => {
  switch (tag) {
    case "Conditional Onboarding":
      {
        return "Onboarding-tag";
      }
      break;
    case "Probation":
      {
        return "Probation-tag";
      }
      break;
    case "Permanent":
      {
        return "Permanent-tag";
      }
      break;
    case "Permanent":
      {
        return "Permanent-tag";
      }
      break;
    case "Notice Period":
      {
        return "Notice-Period-tag";
      }
      break;
    default:
      return "Onboarding-tag";
      break;
  }
};

export const getFirstLetters = (str) => {
  // Split the string into words
  let returnString = "";
  if(str){
    const words = str.trim().split(/\s+/);
    // Get the first letter of each word
    const firstLetters = words.map(word => word.charAt(0));
    
    // Join the first letters of the first two words
    returnString =  firstLetters.slice(0, 2).join('');
    console.log(returnString);
  }
  return returnString;
}


export const getUniqueDates = (employeeLeave) => {
  const dateSet = new Set();
  employeeLeave.forEach(leaveEntry => {
      const dateFrom = moment(leaveEntry.date_from);
      const dateTo = moment(leaveEntry.date_to);
      const diffInDays = dateTo.diff(dateFrom, 'days');
      for (let i = 0; i <= diffInDays; i++) {
          const date = dateFrom.clone().add(i, 'days').format('YYYY-MM-DD');
          //date 1 day less
          //const dateSubtract = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
          dateSet.add({date:date, type: leaveEntry.session});
      }
  });
  return Array.from(dateSet).sort();
}

export const getDirtyValues = (values, initialValues) => {
  const data = { ...values };
  const keyValues = Object.keys(values);

  const dirtyValues = keyValues.filter(
    (keyValue) => data[keyValue] !== initialValues[keyValue],
  );

  keyValues.forEach((key) => {
    if (!dirtyValues.includes(key)) 
      delete data[key];
  });

  return data;
};




export const  getJobTypeClass = (job_type='') => {
  switch (job_type) {
    case "Projects":
      return "task-color-light";
    case "Electrician":
      return "task-color-orange";
    case "EA Install Floorer":
      return "task-color-pink";
    case "Sub - Contract Floorer":
      return "task-color-green";
    case "Remedial Works":
      return "task-color-purple";
    case "Delivery Collection":
        return "task-color-sky-navy";
    case "Installation Task":
        return "task-color-yellow";
    default:
      return "task-color-gray";
  }
}
