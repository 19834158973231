import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";

import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import { getEmployeeList, updateEmployeeStatusApi } from "../../services/employeeService";
import InputSwitchBox from "../../components/common/InputSwitchBox";
import { Card } from "react-bootstrap";
import AddCircle from '../../assets/images/add_circle.svg';
import constants, { pageSetting } from "../../constants/constants";
import { deleteFormApi, getFieldSheetApiCall } from "../../services/formbuilder/fieldsheet.services";
import moment from "moment";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";

const FormList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  const addFormbuilder = () => {
    navigate('/formbuilder/add-form');
  };

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/formbuilder/edit-form/${id}`);
  };



  const handleDelete = () => {
    setLoading(false);
    deleteFormApi(deletedId)
      .then((response:any) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getFieldSheetApiCall(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        const convertData = convertDataToObjects(response.data?.resultSet)
        setData(convertData);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const getTagClass = (tag:string) =>{
      switch (tag) {
        case "Conditional Onboarding" :{
          return "Onboarding-tag"
        }          
      break;
      case "Probation":{
        return "Probation-tag"
      }
      break;
      case "Permanent":{
        return "Permanent-tag"
      }
      break;
      case "Permanent":{
        return "Permanent-tag"
      }
      break;
      case "Notice Period":{
        return "Notice-Period-tag"
      }
      break;
      default:return "Onboarding-tag"        
      break;
      }
  }
  const handleStatusChange = (data: any) =>{
   const id =  data.row.id;
   const formData = {
    status  : data.row.status
   }   
    updateEmployeeStatusApi(id, formData)
    .then((response) => {
      setIsMutation(!isMutation)
      toast(response.message, { type: toast.TYPE.SUCCESS });
    })
    .catch(() => {
      setIsMutation(!isMutation)
    })
    .finally(() => {
      setIsMutation(!isMutation)
    });
  }
  
  const columns: GridColDef[] = [
    

    { field: "field_sheet_title", headerName: "Form Name", flex: 1, sortable: true,hideable:false },
    { field: "description", headerName: "Description", flex: 1, sortable: true },   
    { field: "$CreatedBy.full_name$", headerName: "Created By", flex: 1, sortable: true,   renderCell(params) {
      return   <span className={`py-1 px-2 rounded- d-inline-block fs-11`}>{params.row.CreatedBy?.full_name}</span>
     
     } },
     { 
      field: "createdAt", 
      headerName: "Created At", 
      flex: 1, 
      sortable: true,
      type:'date',
      renderCell(params) {
        return (
          <span className={`py-1 px-2 rounded- d-inline-block fs-11`}>
            {moment(params.row.createdAt).format(constants.dateFormats.hyphenSeparatedDate)}
          </span>
        );
      }
    }, 
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",    
      getActions: (data: any) => [
    
        <div className='table-tags d-flex'>
          {checkAbility(PERMISSION_ACCESS.EDIT,PERMISSION_MODULE.FORM_BUILDER) && 
          <div className='me-3 cursor-pointer'   onClick={handleEditClick(data.row.id)}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 18V13.75L13.2 0.575C13.4 0.391667 13.6208 0.25 13.8625 0.15C14.1042 0.05 14.3583 0 14.625 0C14.8917 0 15.15 0.05 15.4 0.15C15.65 0.25 15.8667 0.4 16.05 0.6L17.425 2C17.625 2.18333 17.7708 2.4 17.8625 2.65C17.9542 2.9 18 3.15 18 3.4C18 3.66667 17.9542 3.92083 17.8625 4.1625C17.7708 4.40417 17.625 4.625 17.425 4.825L4.25 18H0ZM14.6 4.8L16 3.4L14.6 2L13.2 3.4L14.6 4.8Z" fill="#21AC96"/>
            </svg>
          </div>}
          {data.row.sheet_type!=="last_day" &&  data.row.sheet_type!=="first_day" && checkAbility(PERMISSION_ACCESS.DELETE,PERMISSION_MODULE.FORM_BUILDER) && 
          <div className='me-3 cursor-pointer'   onClick={handleDeleteClick(data.row.id)}>
            
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#21AC96"/>
            </svg>
          </div>
    }
        </div>
      ],

    },
  ];

  const convertDataToObjects = (data:any) => {
    return data.map((item:any) => ({
      ...item,
      createdAt: new Date(item.createdAt),
    }));
  };


  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []); 
    const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search,sortColumn,sortDirection]);

  // useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     pageInfo?.totalRowCount !== undefined
  //       ? pageInfo?.totalRowCount
  //       : prevRowCountState,
  //   );
  // }, [pageInfo?.totalRowCount, setRowCountState]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader  px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Form Builder</h1>
              </div>
              <div>
              {checkAbility(PERMISSION_ACCESS.ADD,PERMISSION_MODULE.FORM_BUILDER) && 
                <Button
                  className="fw-semibold fs-12 text-white  btn btn-primary"
                  variant="primary"
                  type="button"
                  onClick={() => addFormbuilder()}
                >
                  <img src={AddCircle} className='me-2 fs-15' alt="File Upload Icon"/>
                  Add New Form
                </Button>}
               

              </div>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <Card className="w-100 pageContentInner">
          <div className="d-flex flex-column  rounded-0 dataGridListMain">
          <DataGrid
                rows={data}
                {...data}
                columns={columns}
                rowCount={totalRow}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                onSortModelChange={handleSortModelChange}
                loading={loading}               
                sortingMode="server" 
                pageSizeOptions={[10,20,50,100]}
                className="border-0 rounded-2"
                

              />
              <DeletePopup
                isOpen={isDeletePopupOpen}
                onClose={handleClose}
                onDelete={handleDelete}
                label="Delete Form"
                text="Are you sure you want to delete this Form?"
              />
          </div>
        </Card>
      </div>
          
        </div>
      )}
    </React.Fragment>
  );
};

export default FormList;
