import React, { FC } from "react";
import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";
import { Input, InputLabel, FormControl } from "@mui/material";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite-no-reset.min.css";
import {
  addDays,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  subDays,
  subYears,
  subMonths
} from "date-fns";
 
interface IInputDateRangePicker {
  controlId: string;
  label: string;
  placeholder: string;
  handleChange: any;
  handleClose: any;
  handleBlur: any;
  errorsField: any;
  touched: any;
  value?: any;
  autofocus?: any;
  readOnly?: any;
  disabled?: any;
  position?: any;
  defaultValue?: any;
}

interface RangeType {
  label: string;
  value: [Date, Date];
}

/**
 * Input Type Text Reusable Component
 *
 * @param {{ controlId: any; label: any; placeholdgr: any; handleChange: any; handleClose: any; handleBlur: any; errorsField: any; touched: any; }} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, readOnly, disabled
}
 * @returns {*}
 */
const InputDateRangePicker: FC<IInputDateRangePicker> = ({
  controlId,
  label,
  placeholder,
  handleChange,
  handleClose,
  handleBlur,
  errorsField,
  touched,
  value,
  autofocus,
  readOnly,
  disabled,
  position,
  defaultValue,
}) => {
  const Ranges: RangeType[] = [
    {
      label: "today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "yesterday",
      value: [
        startOfDay(addDays(new Date(), -1)),
        endOfDay(addDays(new Date(), -1)),
      ],
    },
    {
      label: "Last 7 Days",
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    },
    {
      label: "Last 30 Days",
      value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
    },
    {
      label: "Last 6 Months",
      value: [
        startOfMonth(subMonths(new Date(), 6)), // Start date: First day of the month, 6 months ago
        endOfMonth(subMonths(new Date(), 1)), // End date: Last day of the previous month
      ],
    },
    {
      label: "Last Year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1), // Start date: January 1st of the previous year
        new Date(new Date().getFullYear() - 1, 11, 31), // End date: December 31st of the previous year
      ],
    },    
    {
      label: "Year to date",
      value: [
        new Date(new Date().getFullYear(), 0, 1), // Start date: January 1st of the current year (2024)
        new Date(), // End date: Current date
      ],
    }
  ];

  return (
    <FormControl fullWidth error={errorsField && touched}>
      {label && (
        <Form.Label className="lh-sm control-label fs-14 fw-bold">
          {label}
        </Form.Label>
      )}
      <Form.Group className="mb-3 pb-1" controlId={controlId}>
        <div className="position-relative date-picker-icon"></div>
        <DateRangePicker
          name={controlId}
          onChange={handleChange}
          format="dd/MM/yyyy"
          editable={false}
          showHeader={false}
          placeholder={placeholder}
          block
          placement={position ? position : "bottomStart"}
          defaultValue={defaultValue}
          ranges={Ranges}
        />
      </Form.Group>
    </FormControl>
  );
};

export default InputDateRangePicker;
