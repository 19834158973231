import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import CustomButton from "../CustomButton";
import {
  getProjectDetails,
  quickUpdateProjectApi,
} from "../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../Loader";
import InputDatePicker from "../InputDatePicker";
import moment from "moment";
import constants, { PROJECT_PREFIX } from "../../../constants/constants";
import { INVALID_DATE } from "../../../validations/ValidationErrors";
import DeletePopup from "../deletePopup";

const addSchema = yup.object({
  order_date: yup.date().required().typeError(INVALID_DATE).label("Order Date"),
  contract_posted_date: yup.date().nullable().typeError(INVALID_DATE).label("Contract Posted"),
  contract_received_date: yup.date().nullable().typeError(INVALID_DATE).label("Contract Signed"),
  survey_plan_date: yup.date().nullable().typeError(INVALID_DATE).label("Planned Survey Date"),
  survey_complete_date: yup.date().nullable().min(yup.ref('survey_plan_date'),'Date Survey Completed must be after Planned Survey Date').typeError(INVALID_DATE).label("Date Survey Completed"), 
  warranty_expiry_date: yup.date().nullable().typeError(INVALID_DATE).label("Warranty Expiry Date"),
  delivery_call_date: yup.date().nullable().typeError(INVALID_DATE).label("Confirmation of Delivery Call"),
  mid_installation_call_date: yup.date().nullable().typeError(INVALID_DATE).label("Mid Instalation Call"),
  post_installation_review_call_date: yup.date().nullable().typeError(INVALID_DATE).label("Post Installation Review Call"),
});

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  projectsData: any;
}

interface ProjectOption {
  id: string;
  full_name: string;
}

interface FormValues {
  projectsData: string[];
  selectedPostalCodeOwners: string[];
  project_owner: any;
  populate_all: boolean;
}
const ProjectQuickEdits: React.FC<AddProps> = ({
  isOpen,
  onClose,
  projectsData,
}) => {
  const [projectDataUpdated, setProjectDataUpdated] = useState<any | null>({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  const projectId = projectsData[0]?.id;

  useEffect(() => {
    fetchData().then(() => {
      // Call this function after fetchData completes
    });
  }, []);

  const fetchData = async () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getProjectDetails(projectId)
        .then((response) => {
          //console.log(modifiedOption);
          setProjectDataUpdated(response.data.project);
          setLoading(false);
          resolve(true);
          if(response.data?.project.status == 'cp'){
            onClose();
          }
        })
        .catch((error) => {
          setLoading(false);
          onClose();
          reject(error);
        });
    });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
    if(values?.contract_received_date && values?.contract_posted_date){
      const dayDiff = moment(values?.contract_received_date).diff(moment(values?.contract_posted_date),'days');
      //console.log(dayDiff);
      if(dayDiff >=0 && dayDiff <= 14){
        setDeletePopupOpen(true)
        return;
      }
    }
    
    updateProjectData(values);
    return;
  };

  const updateProjectData = async (values: any) => {

    setBtnLoading(true);
    setDeletePopupOpen(false)
    const formData: any = {
      ...values,
      warranty_expiry_date: moment(values.warranty_expiry_date).isValid()
        ? moment(values.warranty_expiry_date).format(
            constants.dateFormats.databaseFormat
          )
        : null,
      survey_complete_date: moment(values.survey_complete_date).isValid()
        ? moment(values.survey_complete_date).format(
            constants.dateFormats.databaseFormat
          )
        : null,
      survey_plan_date: moment(values.survey_plan_date).isValid()
        ? moment(values.survey_plan_date).format(
            constants.dateFormats.databaseFormat
          )
        : null,
      contract_received_date: moment(values.contract_received_date).isValid()
        ? moment(values.contract_received_date).format(
            constants.dateFormats.databaseFormat
          )
        : null,
      contract_posted_date: moment(values.contract_posted_date).isValid()
        ? moment(values.contract_posted_date).format(
            constants.dateFormats.databaseFormat
          )
        : null,
      order_date: moment(values.order_date).isValid()
        ? moment(values.order_date).format(constants.dateFormats.databaseFormat)
        : null,
      delivery_call_date: moment(values.delivery_call_date).isValid()
        ? moment(values.delivery_call_date).format(constants.dateFormats.databaseFormat)
        : null,
      mid_installation_call_date: moment(values.mid_installation_call_date).isValid()
        ? moment(values.mid_installation_call_date).format(constants.dateFormats.databaseFormat)
        : null,
      post_installation_review_call_date: moment(values.post_installation_review_call_date).isValid()
        ? moment(values.post_installation_review_call_date).format(constants.dateFormats.databaseFormat)
        : null,
    };

    quickUpdateProjectApi(projectId, formData)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        onClose();
      })
      .catch((error) => {
        setBtnLoading(false);

        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      });

  };

  const initialValues = {
    contract_posted_date: projectDataUpdated?.projectDetails
      ?.contract_posted_date
      ? moment(projectDataUpdated?.projectDetails?.contract_posted_date)
      : null,
    contract_received_date: projectDataUpdated?.projectDetails
      ?.contract_received_date
      ? moment(projectDataUpdated?.projectDetails?.contract_received_date)
      : null,
    survey_plan_date: projectDataUpdated?.projectDetails?.survey_plan_date
      ? moment(projectDataUpdated?.projectDetails?.survey_plan_date)
      : null,
    survey_complete_date: projectDataUpdated?.projectDetails
      ?.survey_complete_date
      ? moment(projectDataUpdated?.projectDetails?.survey_complete_date)
      : null,
    order_date: projectDataUpdated?.order_date
      ? moment(projectDataUpdated?.order_date)
      : null,
    warranty_expiry_date: projectDataUpdated?.projectDetails
      ?.warranty_expiry_date
      ? moment(projectDataUpdated?.projectDetails?.warranty_expiry_date)
      : null,
    delivery_call_date: projectDataUpdated?.projectDetails
      ?.delivery_call_date
      ? moment(projectDataUpdated?.projectDetails?.delivery_call_date)
      : null,
    mid_installation_call_date: projectDataUpdated?.projectDetails
      ?.mid_installation_call_date
      ? moment(projectDataUpdated?.projectDetails?.mid_installation_call_date)
      : null,
    post_installation_review_call_date: projectDataUpdated?.projectDetails
      ?.post_installation_review_call_date
      ? moment(projectDataUpdated?.projectDetails?.post_installation_review_call_date)
      : null,
    planned_completion_date: projectDataUpdated?.projectDetails?.planned_completion_date ? moment(projectDataUpdated?.projectDetails.planned_completion_date).format(constants.dateFormats.databaseFormat) : null,
    planned_start_date: projectDataUpdated?.projectDetails?.planned_start_date ? moment(projectDataUpdated?.projectDetails.planned_start_date).format(constants.dateFormats.databaseFormat) : null,  
  };
  
  
  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="export-excel-modal"
          aria-labelledby="Assign modal owner"
          centered
          show={isOpen}
          onHide={onClose}
        >
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Quick Edit - ({PROJECT_PREFIX}{projectDataUpdated?.project_no})
                  </h4>
                  <Row>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="order_date"
                          label="Order Date"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              readOnly: true,
                              className: "form-control",
                              id: "order_date",
                            },
                          }}
                          value={values.order_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          touched={touched.order_date}
                          errors={errors.order_date}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="contract_posted_date"
                          label="Contract Posted"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "contract_posted_date",
                            },
                          }}
                          value={values.contract_posted_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          touched={touched.contract_posted_date}
                          errors={errors.contract_posted_date}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="contract_received_date"
                          label="Contract Signed"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "contract_received_date",
                            },
                          }}
                          value={values.contract_received_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          touched={touched.contract_received_date}
                          errors={errors.contract_received_date}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="survey_plan_date"
                          label="Planned Survey Date"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "survey_plan_date",
                            },
                          }}
                          isDisable={projectDataUpdated?.survey?.id ? true : false}
                          value={values.survey_plan_date}
                          onChange={(name, newValue) => {
                            setFieldValue(name, newValue);
                          }}
                          touched={touched.survey_plan_date}
                          errors={errors.survey_plan_date}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="survey_complete_date"
                          label="Date Survey Completed"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "survey_complete_date",
                            },
                          }}
                          isDisable={projectDataUpdated?.survey?.id ? true : false}
                          value={values.survey_complete_date}
                          onChange={(name, newValue) => {
                            setFieldValue(name, newValue);
                          }}
                          touched={touched.survey_complete_date}
                          errors={errors.survey_complete_date}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="planned_start_date"
                          label="Planned Start Date"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "planned_start_date",
                            },
                          }}
                          value={values.planned_start_date}
                          onChange={(name, newValue) => {
                            setFieldValue(name, newValue);
                          }}
                          touched={touched.planned_start_date}
                          errors={errors.planned_start_date}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="planned_completion_date"
                          label="Planned Completion Date"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "planned_completion_date",
                            },
                          }}
                          value={values.planned_completion_date}
                          onChange={(name, newValue) => {
                            setFieldValue(name, newValue);
                          }}
                          touched={touched.planned_completion_date}
                          errors={errors.planned_completion_date}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="warranty_expiry_date"
                          label="Warranty Expiry Date"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "warranty_expiry_date",
                            },
                          }}
                          value={values.warranty_expiry_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          touched={touched.warranty_expiry_date}
                          errors={errors.warranty_expiry_date}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="delivery_call_date"
                          label="Confirmation of Delivery Call"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "delivery_call_date",
                            },
                          }}
                          value={values.delivery_call_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          touched={touched.delivery_call_date}
                          errors={errors.delivery_call_date}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="mid_installation_call_date"
                          label="Mid Installation Call"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "mid_installation_call_date",
                            },
                          }}
                          value={values.mid_installation_call_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          touched={touched.mid_installation_call_date}
                          errors={errors.mid_installation_call_date}
                        />
                      </div>
                    </Col>
                    <Col className="col-md-6">
                      <div className="mb-3 custom-from-group">
                        <InputDatePicker
                          name="post_installation_review_call_date"
                          label="Post Installation Review Call"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "post_installation_review_call_date",
                            },
                          }}
                          value={values.post_installation_review_call_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          touched={touched.post_installation_review_call_date}
                          errors={errors.post_installation_review_call_date}
                        />
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-90 mt-2"
                    variant="primary"
                  >
                    Update
                  </CustomButton>
                </Modal.Footer>
                <DeletePopup
                              isOpen={isDeletePopupOpen}
                              onClose={() => setDeletePopupOpen(false)}
                              onDelete={() => updateProjectData(values)}
                              actionType="info"
                              label="Are You Sure"
                              text="Are you sure contract start date is within 14 days of the contract being signed?"
                            />
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default ProjectQuickEdits;
