import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridCallbackDetails,
  getGridStringOperators,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../router/protected";
import {
  getProjectList, getPostalCodeList, deleteProject, getProjectAssignTeamAPi
} from "../../services/project.service";
import Loader from "../../components/common/Loader";
import FileUploadIcon from "../../assets/images/file-upload.svg";
import QuickReferenceAll from "../../assets/images/quick_reference_all.svg";
import CachedIcon from "@mui/icons-material/Cached";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from "@mui/icons-material/Edit";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import AddCircle from "../../assets/images/add_circle.svg";
import BlockIcon from "@mui/icons-material/Block";
import { Card, Col, Row } from "react-bootstrap";
import constants, {
  PROJECT_PREFIX,
  pageSetting,
  pageSizeModel,
  projectStatus,
  rolesDefaultParams,
} from "../../constants/constants";
import ViewAgenda from '@mui/icons-material/Visibility';

import { checkAbility, currencyMask } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import ProjectOwnerAssignToProjects from "../../components/common/Projects/project-owner";
import ExportData from "../Deals/List/exportData";
import moment from "moment";
import DeleteWithReasonPopup from "../../components/common/deleteWithReasonPopup";
import ProjectDocumentListPopup from "../../components/common/Projects/project-document-list";
import AssignTeam from "./View/assignTeam";
import TeamAssignToProjects from "../../components/common/Projects/assign-team";
import ProjectQuickEdits from "../../components/common/Projects/project-quick-edit";
import AuditData from "./Audit-trial";
import { AnyARecord } from "dns";
import ProjectPersonalInfoEdits from "../../components/common/Projects/project-personal-info-edit";

interface RoleOption {
  key: string;
  value: string;
}

interface IDeletePopup {  
  deleteRemark: string;
}
const ProjectList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("project_no");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null); 
  const [selectProjectData, setSelectProjectData] = useState<any | null>({})
  const [deletedData, setDeletedData] = useState<IDeletePopup | null>()
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [projectId, setProjectId] = useState<GridRowId | null>(null);
  

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isDocumentPopupOpen, setDocumentPopupOpen] = useState(false);
  const [isAssignTeamPopupOpen, setAssignTeamPopupOpen] = useState(false);
  const [isQuickEditPopupOpen, setQuickEditPopupOpen] = useState(false);
  const [isPDataEditPopupOpen, setPDataEditPopupOpen] = useState(false);
  const [popupLabel, setPopupLabel] = useState("");
  const [popupText, setPopupText] = useState("");
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [projectOwnerOpen, setProjectOwnerOpen] = useState(false);
  const [selectedPostalCodeOwners, setSelectedPostalCodeOwners] = useState([]);
  const [exportPopup,setExportPopup] = useState(false);
  const [auditPopup, setAuditPopup] = useState(false);
 
  const addLink = () => {
    navigate(nameBasedProtectedRoutes.addLink.path);
  };

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/projects/edit/${id}`);
  };
  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/projects/view/${id}`);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setPopupLabel("Cancel Project");
    setPopupText("Are you sure you want to Cancel the Project?");    
    setDeletePopupOpen(true);
    setDeletedId(id);
  };
  const handleDocumentViewClick = (id: GridRowId, projectData:any) => () => {
    setDocumentPopupOpen(true);
    setSelectProjectData(projectData);
  };

  const toggleDocumentPopup = () => {       
    setDocumentPopupOpen(false);
    setSelectProjectData(null);
  };

  const handleQuickEditClick = (id: GridRowId, rowData: any ) => () => {
    const projectIds: any = [
      {
        id: id,
      },
    ];
    setSelectionModel(projectIds);
    setQuickEditPopupOpen(true);    
  }

  const handlePDataEditClick = (id: GridRowId, rowData: any ) => () => {
    const projectIds: any = [
      {
        id: id,
      },
    ];
    setSelectionModel(projectIds);
    setPDataEditPopupOpen(true);    
  }

  const handleAssignTeamClick = (id: GridRowId | Number) => () => {    
    const projectId = id;
    getProjectAssignTeamAPi(projectId)
        .then((response) => {
          setSelectedTeamMembers(response.data);
          const projectIds: any = [
            {
              id: id,
            },
          ];
          setSelectionModel(projectIds);
          setLoading(false);   
          setAssignTeamPopupOpen(true);    
         
        })
        .catch((error) => {
          setLoading(false);         
         
        }); 
  };

  const handleDetailReload = () => {
    setAssignTeamPopupOpen(false);
    setSelectionModel([]);
    setIsMutation(!isMutation);
  }
  const handleQuickEditPopupClose = () => {
    setQuickEditPopupOpen(false);
    setSelectionModel([]);
    setIsMutation(!isMutation);
  }

  const handlePDataEditPopupClose = () => {
    setPDataEditPopupOpen(false);
    setSelectionModel([]);
    setIsMutation(!isMutation);
  }

  

  const handleDelete = (formData:any,deleteId:number) => {
    // console.log(formData,deleteId);    
    setLoading(false);
    deleteProject(deleteId, formData?.deleteRemarks)
    .then((response) => {
      toast(response.msg, { type: toast.TYPE.SUCCESS });
      handleClose();     
    })
    .catch(() => {
      handleClose();
    })
    .finally(() => {
      handleClose();
    });
  }

  const handleClose = () => {
    setDeletePopupOpen(false);
    setAssignTeamPopupOpen(false);
    setDeletedId(null);
    setIsMutation(!isMutation);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const fetchData = (defaultParams: any) => {
    getProjectList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model   
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if(filterModel.items[0].field === "project_no"){
        const regex = new RegExp(`[${PROJECT_PREFIX}]`, 'g');
        filterModel.items[0].value = filterModel.items[0].value.replace(regex, '');
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

 
    const showAuditTrial = (id: GridRowId) => () => {
      
      setProjectId(id);
      setAuditPopup(true);
    };


  

  const columns: GridColDef[] = [
    {
      field: "project_no",
      headerName: "Project Order No.",
      flex: 1,
      sortable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params){
        return `${params.row.project_no_str}`
      }
    },
    { field: "name", headerName: "Customer", flex: 1, sortable: true },
    {
      field: "$projectDetails.planned_start_date$",
      headerName: "Planned Installation Date",
      flex: 1,
      sortable: true,
      

      valueGetter: (params) => new Date(params.row.projectDetails?.planned_start_date),
      renderCell(params) {
        const orderDate = params.row?.projectDetails?.planned_start_date ? new Date(params.row?.projectDetails?.planned_start_date): null
        return (
          <span>
            {orderDate ? moment(orderDate).format(constants.dateFormats.slashSeparatedDate):'-'}
            
          </span>
        );
      },
    },
    {
      field: "contract_type",
      headerName: "Contract Type",
      flex: 0.8,
      sortable: true,
    },
    {
      field: "$postal_code.code$",
      headerName: "Postal Code",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row?.postal_code?.code}>
            {params.row?.postal_code?.code}
          </span>
        );
      },
    },
    {
      field: "order_date",
      headerName: "Order Date",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.order_date),
      renderCell(params) {
        const orderDate = new Date(params.row.order_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "amount",
      type: "number",
      headerName: "Order Value",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row?.amount}>
            {currencyMask(params.row?.amount)}
          </span>
        );
      },
    },
   /* {
      field: "paid_amount",
      type: "number",
      headerName: "Deposit",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          params.row?.paid_amount ? 
          (<span title={params.row?.paid_amount}>
            {currencyMask(params.row?.paid_amount)}
          </span>) : "-"
        );
      },
    },*/
    
    {
      field: "$project_type.name$",
      headerName: "Project Type",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.project_type?.name ? (
          <span title={params.row?.project_type?.name}>
            {params.row?.project_type?.name}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      field: "$projectOwner.full_name$",
      headerName: "Project Owner ",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.project_owner_id ? (
          <span title={params.row?.projectOwner?.full_name}>
            {params.row?.projectOwner?.full_name}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type:"singleSelect",
      valueOptions: projectStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        const statusName = projectStatus.find(
          (item) => item.key == params.row.status
        );
        return (
          <span
            title={statusName?.value}
            className={`py-1 px-2 rounded-1 ${
              params.row.status == "ob"
                ? "Permanent-tag" :
                params.row.status == "cp"
                ? "info-tag"
                : "Probation-tag"
            } d-inline-block fs-11`}
          >
            {statusName?.value}
          </span>
        );
      },
    }
  ];

  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.PROJECTS
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.PROJECTS
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.PROJECTS
    );   

    if (
      checkEditAbilityCondition ||      
      checkDeleteAbilityCondition || 
      checkViewAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          const gridActions = [];
          if(checkViewAbilityCondition ){
            gridActions.push(<GridActionsCellItem
              label="View"
              icon={<ViewAgenda className="fs-16" />}
              showInMenu
              onClick={handleViewClick(id)}
            />)
          }
          if(checkEditAbilityCondition && row.status != 'cp' && row.status != 'cl'){
            gridActions.push(<GridActionsCellItem
              label="Assign Team"
              icon={<Diversity3Icon className="fs-16" />}
              showInMenu
              onClick={handleAssignTeamClick(id)}
            />,)
          } 
          if(checkViewAbilityCondition){
            gridActions.push(<GridActionsCellItem
              label="View Document"
              icon={<ArticleIcon className="fs-16" />}
              showInMenu
              onClick={handleDocumentViewClick(id, row)}
            />)
          }       
          if (checkEditAbilityCondition && row.status != 'cl') {
            if(row.status != 'cp'){
              gridActions.push(
                <GridActionsCellItem
                  label="Quick Edit"
                  icon={<BorderColorIcon className="fs-16" />}
                  showInMenu
                  onClick={handleQuickEditClick(id, row)}
                />,
                <GridActionsCellItem
                  label="Edit Project"
                  icon={<EditIcon className="fs-16" />}
                  showInMenu
                  onClick={handleEditClick(id)}
                />,
              );
            }
            else{
              gridActions.push(
                <GridActionsCellItem
                  label="Customer Profile Edit"
                  icon={<BorderColorIcon className="fs-16" />}
                  showInMenu
                  onClick={handlePDataEditClick(id, row)}
                />              
              );
            }
          } 
          // if(checkViewAbilityCondition){
          //   gridActions.push(<GridActionsCellItem
          //     label="Audit Trail"
          //     icon={<img src={QuickReferenceAll} />}
          //     showInMenu
          //   />,)
          // }        
          if (checkDeleteAbilityCondition && row.status != 'cp' && row.status != 'cl') {
            gridActions.push(
              <GridActionsCellItem
              label="Cancel Project"
              icon={<DeleteIcon className="fs-16"/>}
              showInMenu
              onClick={handleDeleteClick(id)}
            />,
            );
          } 
          if(checkViewAbilityCondition){
            gridActions.push(<GridActionsCellItem
              label="Trail"
              icon={<ContentPasteSearchIcon className="fs-16"/>}
              showInMenu
              onClick={showAuditTrial(id)}
            />,)
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]); 
  

  const toggleProjectOwner = () => {       
    setProjectOwnerOpen(!projectOwnerOpen);
    setIsMutation(!isMutation);
  };
 

  const openProjectOwnerPopup = () => {   
    const defaultParams: any = {      
      project_ids : selectionModel.map((item:any) => item.id)
    }
    setSelectedPostalCodeOwners([]);
   // console.log(defaultParams);
    getPostalCodeList(defaultParams)
      .then((response) => {
        setSelectedPostalCodeOwners(response?.data?.Postalcode_project_owner_ids)
        setProjectOwnerOpen(!projectOwnerOpen);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });    
  };

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }

  const toggleAuditTrailPopup = () => {
    setAuditPopup(!auditPopup);
  }
  
  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
     // console.log(data);
      const filteredArray:any = data.filter((itemTwo:any) =>
      rowSelectionModel.some((itemOne:any) => itemOne === itemTwo.id)
    ).map((item : any) => ({ id: item.id }))
   // console.log(filteredArray);
      setSelectionModel(filteredArray);
    };
  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {exportPopup && (
            <ExportData
              isOpen={exportPopup}
              onClose={toggleExportPopup}
              exportType="project"
              label="Export Projects"
              text="Export Projects"
            />
          )}

          {auditPopup && (
            <AuditData 
            isOpen={auditPopup}
            onClose={toggleAuditTrailPopup}
            projectId={projectId}
            /> )}
           

          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Projects</h1>
              </Col>
              <Col className="text-end">                
                <button
                  className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                  onClick={toggleExportPopup}
                >
                  <img src={FileUploadIcon} alt="File Upload Icon" />
                </button>
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.PROJECTS
                ) && (
                  <button
                    className={`fw-semibold fs-12 me-2 btn ${
                      selectionModel?.length
                        ? "btn-primary text-white"
                        : "btn-secondary text-black "
                    }`}
                    type="button"
                    disabled={selectionModel?.length ? false : true}
                    onClick={() => openProjectOwnerPopup()}
                  >
                    <AssignmentInd className="me-2 fs-18" />
                    Assign Project Manager
                  </button>
                )}
              </Col>
            </Row>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  onRowSelectionModelChange={handleSelectionModelChange}
                  disableRowSelectionOnClick
                  checkboxSelection
                  localeText={{ noRowsLabel: "No Projects found" }}
                  className="border-0 rounded-2"
                />
                {projectOwnerOpen && (
                  <ProjectOwnerAssignToProjects
                    isOpen={projectOwnerOpen}
                    onClose={toggleProjectOwner}
                    projectsData={selectionModel}
                    selectedPostalCodeOwners={selectedPostalCodeOwners}
                  />
                )}                
                {isDocumentPopupOpen && (
                  <ProjectDocumentListPopup
                    isOpen={isDocumentPopupOpen}
                    onClose={toggleDocumentPopup}
                    projectsData={selectProjectData}                     
                  />
                )}                
                <DeleteWithReasonPopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onSubmitData={handleDelete}
                  deletedData={deletedData}
                  deletedId={deletedId}
                  label={popupLabel}
                  text={popupText}
                  reasonText="Cancel"
                />
                {isAssignTeamPopupOpen && (
                  <TeamAssignToProjects
                    isOpen={isAssignTeamPopupOpen}
                    onClose={handleDetailReload}
                    projectsData={selectionModel}
                    selectedTeamMembers={selectedTeamMembers}
                  />
                )}
                {isQuickEditPopupOpen && (
                  <ProjectQuickEdits
                    isOpen={isQuickEditPopupOpen}
                    onClose={handleQuickEditPopupClose}
                    projectsData={selectionModel}                    
                  />
                )}

                {isPDataEditPopupOpen && (
                  <ProjectPersonalInfoEdits
                    isOpen={isPDataEditPopupOpen}
                    onClose={handlePDataEditPopupClose}
                    projectsData={selectionModel}                    
                  />
                )}

 

              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProjectList;
