import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Card, Form,Row,Col } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { departmentCheck } from "../../../constants/constants";
import { getServiceWarrantyData } from "../../../services/dashboardMaster.service";
import { DateRangeInterface } from "../dashboardInterface";
import { Scrollbar } from "react-scrollbars-custom";
import LineSkeleton from "../../../components/common/Skeleton/LineCheck.skeleton";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ServiceData {
  PastMonths: string[];
  salesDepartmentData: number[];
  operationsDepartmentData: number[];
  hrDepartmentData: number[];
  financeDepartmentData: number[];
}

interface VisibleDepartments {
  sales: boolean;
  operations: boolean;
  hr: boolean;
  finance: boolean;
}

const departmentKeyMap: { [key: string]: keyof VisibleDepartments } = {
  OP: "operations",
  SA: "sales",
  HR: "hr",
  FD: "finance"
};

const ServiceWarranty: React.FC<DateRangeInterface> = ({orderDateRange}) => {
  const [loading, setLoading] = useState(true);
  const [serviceData, setServiceData] = useState<ServiceData>({
    PastMonths: [],
    salesDepartmentData: [],
    operationsDepartmentData: [],
    hrDepartmentData: [],
    financeDepartmentData: []
  });
  const [visibleDepartments, setVisibleDepartments] = useState<VisibleDepartments>({
    sales: true,
    operations: true,
    hr: true,
    finance: true,
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchData(signal);
    return () => {
      controller.abort();
    };
  }, [orderDateRange]);

  const fetchData = (signal:any) => {
    getServiceWarrantyData(orderDateRange, signal)
      .then((res) => {
        setServiceData(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckboxChange = (department: keyof VisibleDepartments) => {
    
    setVisibleDepartments((prev) => ({
      ...prev,
      [department]: !prev[department],
    }));
  };

  const options = {
    responsive: true,
   
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart - Multi Axis",
      },
      legend: {
        display: false, // Hide default legend
      },
      datalabels: {
        display: false, // Disable datalabels for this specific chart
      },
    
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
        grid: {
          drawOnChartArea: true,
        },
      },
    },
    maintainAspectRatio: false,
    elements: {
      point: {
        pointStyle: "circle",
        radius: 4,
      },
    },
  };

 
  const data = {
    labels : serviceData.PastMonths.map((item: any)=>item.month),
    datasets: [
      {
        label: "Sales Department",
        data: serviceData.salesDepartmentData.map((item: any)=>item.data),
        borderColor: "rgba(41, 127, 206, 1)",
        backgroundColor: "rgba(41, 127, 206, 1)",
        hidden: !visibleDepartments.sales,
      },
      {
        label: "Operations Department",
        data: serviceData.operationsDepartmentData.map((item: any)=>item.data),
        borderColor: "rgba(146, 112, 248, 1)",
        backgroundColor: "rgba(146, 112, 248, 1)",
        hidden: !visibleDepartments.operations,
      },
      {
        label: "HR Department",
        data: serviceData.hrDepartmentData.map((item: any)=>item.data),
        borderColor: "rgba(238, 48, 48, 1)",
        backgroundColor: "rgba(238, 48, 48, 1)",
        hidden: !visibleDepartments.hr,
      },
      {
        label: "Finance Department",
        data: serviceData.financeDepartmentData.map((item: any)=>item.data),
        borderColor: "rgba(33, 172, 89, 1)",
        backgroundColor: "rgba(33, 172, 89, 1)",
        hidden: !visibleDepartments.finance,
      },
    ],
  };

  return (
    <div>
      <Card className="">
       {loading ? <LineSkeleton /> : <Row>
          <Col className="col-md-9">
            <div className="p-3">
              <h5 className="fs-18 info-text fw-bold mb-3">Service/Warranty Log</h5>
              <div className="w-100" style={{height:'382px'}}>
                <Line data={data} options={options}  />
              </div>
            </div>
          </Col>
          <Col className="col-md-3 dashboard-border-left ps-0">
            <div className="">
              <h5 className="fs-18 info-text fw-bold mb-3 py-3 px-3 border-bottom">Departments</h5>
              <ul className="dashboard-department-list">
              <Scrollbar className="admin-dashboard-deartment-list-scroll">
                {Object.keys(departmentCheck).map((key, index) => (
                  <li key={key} className= {`d-flex dashboard-department-color dashboard-department-color-${index}`}>
                    
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      className="me-2"
                      checked={visibleDepartments[departmentKeyMap[key]]}
                      onChange={() => handleCheckboxChange(departmentKeyMap[key])}  
                    
                    />
                    
                    {departmentCheck[key]}
                  </li>
                ))}
                </Scrollbar>
              </ul>
            </div>
          </Col>
        </Row>
}
      </Card>
    </div>
  );
};

export default ServiceWarranty;
