import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridFilterModel,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Form, Row } from "react-bootstrap";
import constants, {
  pageSetting,
  pageSizeModel,
} from "../../../constants/constants";
import { currencyMask } from "../../../utility/common";
import {
  getAllProductCategory,
  getProductRecievedList,
} from "../../../services/productService";
import moment from "moment";
import { getVendorRelatedItemsList } from "../../../services/vendorService";
import InputText from "../../../components/common/InputText";
import { Formik } from "formik";
import FormSelect from "../../../components/common/FormSelect";
import CustomButton from "../../../components/common/CustomButton";
import SearchIcon from "@mui/icons-material/Search";


interface AddProps {
  vendorId: string | number | undefined;
}

interface FilterValues {
  name: string;
  category: any;
  qty: string;
}

const VendorRelatedItems: React.FC<AddProps> = ({ vendorId }) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [categoryOption, setCategoryOption] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState();
  const [productQty, setProductQty] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    name:productName,
    category:productType,
    qty:productQty,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getVendorRelatedItemsList(vendorId, defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * fetch Categories to list in the category type drop down
   * @async
   */
  const fetchCategories = async () => {
    return new Promise((resolve, reject) => {      
      getAllProductCategory().then((response) => {
        const modifiyOption = response.data.map((item: any) => ({
          key: item.category,
          value: item.category,
        }));
        setCategoryOption(modifiyOption);
        return resolve(true);
      });
    });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Products",
      flex: 3,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell(params) {
        return <span title={params.row.name}>
          {params?.row?.hs_sku ? params.row.hs_sku + " - " : ""}
          {params.row.name}
        </span>;
      },
    },
    {
      field: "category",
      headerName: "Type",
      flex: 1.5,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return <span>{params.row.category}</span>;
      },
    },
    {
      field: "received_qty",
      headerName: "Qty (In Stock)",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return params.row?.stock_qty;
      },
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell(params) {
        return (
          <span title={currencyMask(params.row?.price)}>
            {currencyMask(params.row?.price)}
          </span>
        );
      },
    },
  ];

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    Promise.all([fetchCategories()]).then(() => {});
  }, [vendorId]);
  
  const submitForm = async (values: FilterValues, { setSubmitting }: any) => {
    const formData = {
      ...values
    };
    setProductName(values.name)
    setProductType(values.category?.key)
    setProductQty(values.qty)
    setIsMutation(!isMutation);
   // console.log(formData);
   // return;
  //  setBtnLoading(true);
  }

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  const initialValues = {
    name: "",
    category: "",
    qty: "",
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="flex-grow-1 pageContent pb-2">
          <Card className=" p-3 w-100 pageContentInner mb-3">
            <Row>
              <Col className="mb-3">
                <h6 className="fs-18 fw-bold mb-1 info-text">
                  Vendor-Related Items
                </h6>
                <span>
                  The Supply list displays the items we work with from the
                  vendor
                </span>
              </Col>
            </Row>            
              <Formik
                validationSchema={""}
                initialValues={initialValues}
                onSubmit={(values: FilterValues, actions) => {
                  submitForm(values, actions);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldError,
                  setFieldValue,
                }) => (
                  <Form className="" noValidate onSubmit={handleSubmit}>
                    <Row>
                    <Col className="col-md-4">
                      <InputText
                        controlId="name"
                        label=""
                        placeholder="Search By Name"
                        touched={touched.name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.name}
                        value={values.name}
                      />
                    </Col>
                    <Col className="col-md-4">
                      <FormSelect
                        placeholder="View By Type"
                        label=""
                        name="category"
                        isClearable                        
                        options={categoryOption}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.key}
                        onChange={(name: string, value: string) => {
                          setFieldValue(name, value);
                        }}
                        value={values.category}
                        error={errors.category}
                        touched={touched.category}
                      />
                    </Col>
                    <Col className="col-md-3">
                      <InputText
                        controlId="qty"
                        label=""
                        placeholder="View By qty. available"
                        touched={touched.qty}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.qty}
                        value={values.qty}
                      />
                    </Col>
                    <Col className="col-md-1">
                      <CustomButton
                        type="submit"
                        loading={btnLoading}
                        disabled={btnLoading}
                        className="fw-semibold fs-13 text-white me-1"
                        variant="primary"
                      >
                      <SearchIcon />
                      </CustomButton>
                    </Col>
                    </Row>
                  </Form>
                )}
              </Formik>            
            <Row>
              <Col className="vendor-related-table">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  disableRowSelectionOnClick
                  pageSizeOptions={pageSizeModel}
                  localeText={{ noRowsLabel: "No Related Products" }}
                />
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default VendorRelatedItems;
