import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Row, Form } from "react-bootstrap";
import * as Yup from "yup";
import InputText from "../../../components/common/InputText";
import InputTextArea from "../../../components/common/InputTextArea";
import DeletePopup from "../../../components/common/deletePopup";
import { saveComparativeStudyApi } from "../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";

interface IsubmitedData {
  revisedamount: number;
  doesImpactContractAmount: string;
  doesReviseTheContract: string;
  amountDetails: string;
  updatedTotal?: number;
}

const ComparativeStudeyForm: React.FC<any> = ({
  contract_id,
  setIsMutation,
  contractDetails,
  revisedamount,
  currentTabIsDone,
  productData,
  updatedTotal
}) => {
  const [warningPopup, setWarningPopup] = useState(false);
  const [submitedData, setSubmitedData] = useState<IsubmitedData | any>([]);
  const [loading, setLoading] = useState(true);

  const initialValues = {
    revisedAmount: (contractDetails?.revised_amount!=null || contractDetails?.revised_amount!=undefined || contractDetails?.revised_amount!=0) 
      ? contractDetails?.revised_amount
      : updatedTotal,
    doesImpactContractAmount: contractDetails?.does_impact_contract_amount
      ? contractDetails?.does_impact_contract_amount == true
        ? "Yes"
        : "NO"
      : "No",
    doesReviseTheContract: contractDetails?.does_revise_the_contract
      ? contractDetails?.does_revise_the_contract == true
        ? "Yes"
        : "NO"
      : "No",
    amountDetails: contractDetails?.amount_details
      ? contractDetails?.amount_details
      : "",
  };

  useEffect(() => {  
      console.log("revise amount", revisedamount)
      setLoading(false);
  },
  [])

  const addSchema = Yup.object().shape({
    revisedAmount: Yup.number().min(0).required().label("Revised Amount").typeError("Revised Amount must be a number"),
    doesImpactContractAmount: Yup.string()
      .required()
      .label("Does Impact Contract Amount"),
    amountDetails: Yup.string().trim().label("Details About Amount"),
  });
  const submitForm = (values: any, actions: any) => {
    console.log("values", values);
    setSubmitedData(values);
    setWarningPopup(true);
  };
  const handleClose = () => {
    setSubmitedData([]);
    setWarningPopup(false);
  };

  const submitComaparativeStudy = () => {
     setWarningPopup(false);

    const formatedData = {
      ...submitedData,
      doesImpactContractAmount:
        submitedData.doesImpactContractAmount === "Yes" ? true : false,
      doesReviseTheContract:
        submitedData.doesReviseTheContract === "Yes" ? true : false,
        productData:productData
    };

    saveComparativeStudyApi(contract_id, formatedData)
      .then((res) => {
        toast.success(res.msg);
        setIsMutation((prev: any) => !prev);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
   <>
   {loading ? <Loader isLoading={loading} /> : (
  
  <div>
  {warningPopup && (
    <DeletePopup
      isOpen={warningPopup}
      onClose={handleClose}
      onDelete={submitComaparativeStudy}
      label="Save Comparative Study"
      text="Once you save the comparative study, you will not be able to edit it. Are you sure you want to save it?"
      actionType="warning"
    />
  )}
  <Formik
    validationSchema={addSchema}
    initialValues={initialValues}
    onSubmit={(values: any, actions) => {
      submitForm(values, actions);
    }}
  >
    {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      setFieldValue,
      touched,
      isValid,
      errors,
    }) => (
      <Form className="" noValidate onSubmit={handleSubmit}>
        <Row>
           <Col md={12}>
            <Card className="p-3 w-100 pageContentInner mb-3">
              <Row className="mb-3 d-flex justify-content-between align-items-center">
                <Col md={3}>
                  <h3 className="fs-18 info-text fw-bold d-inline-block mb-3">
                  Do the changes impact the contract value? 
                  </h3>
                </Col>
                <Col md={4}>
                  <div className=" d-flex">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="doesImpactContractAmount"
                        id={`doesImpactContractAmount`}
                        onChange={(e)=>{
                          handleChange(e);
                           
                          if(e.target.value === "Yes"){
                            setFieldValue("doesReviseTheContract", "Yes")
                          } 
                        }}
                        checked={values.doesImpactContractAmount === "Yes"}
                        value="Yes"
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`doesImpactContractAmount`}
                      >
                        &nbsp; Yes
                      </label>
                    </div>
                    <div className="ms-5">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="doesImpactContractAmount"
                        id={`doesImpactContractAmount2`}
                        onChange={(e)=>{
                          handleChange(e);
                           
                          if(e.target.value === "No"){
                            setFieldValue("doesReviseTheContract", "No")
                          } 
                        }}
                        checked={values.doesImpactContractAmount === "No"}
                        value="No"
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`doesImpactContractAmount2`}
                      >
                        {" "}
                        &nbsp; No
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="fs-14 gray-text fw-bold me-2 mb-3">
                      The Revised Amount & Details
                    </div>
                    <div className="w-50">
                      <InputText
                        controlId="revisedAmount"
                        label=""
                        placeholder="Enter Revised Amount"
                        touched={touched.revisedAmount}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.revisedAmount}
                        value={values.revisedAmount}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="">
                <InputTextArea
                  controlId="amountDetails"
                  label="Details of changes"
                  placeholder="Difference amount, postal and other charges will be summed."
                  touched={touched.amountDetails}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errorsField={errors.amountDetails}
                  name="amountDetails"
                  value={values.amountDetails}
                  className="max-height78 resize-none"
                />
              </div>
              <Row className="mb-3 d-flex justify-content-between align-items-center mt-4">
                <Col md={3}>
                  <h3 className="fs-18 info-text fw-bold d-inline-block mb-3">
                    Does contract require revision?
                  </h3>
                </Col>
                <Col md={4}>
                  <div className=" d-flex">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="doesReviseTheContract"
                        id={`doesReviseTheContract`}
                        onChange={handleChange}
                        checked={values.doesReviseTheContract === "Yes"}
                        value="Yes"
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`doesReviseTheContract`}
                      >
                        &nbsp; Yes
                      </label>
                    </div>
                    <div className="ms-5">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="doesReviseTheContract"
                        id={`doesReviseTheContract2`}
                        onChange={handleChange}
                        checked={values.doesReviseTheContract === "No"}
                        value="No"
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`doesReviseTheContract2`}
                      >
                        {" "}
                        &nbsp; No
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}></Col>
              </Row>
            </Card>
          </Col>

          <Col
            md={12}
            className="align-items-center  d-flex justify-content-end text-end"
          >
            {currentTabIsDone && (
              <a
                href="javascript:void(0)"
                className="text-green fs-16 fw-semibold me-2 text-decoration-none"
              >
                <span className="me-2">
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.1665 12.5489H9.83317V7.52933H8.1665V12.5489ZM8.99984 5.85614C9.23595 5.85614 9.43387 5.77597 9.59359 5.61562C9.75331 5.45528 9.83317 5.25659 9.83317 5.01955C9.83317 4.78252 9.75331 4.58383 9.59359 4.42348C9.43387 4.26313 9.23595 4.18296 8.99984 4.18296C8.76373 4.18296 8.56581 4.26313 8.40609 4.42348C8.24637 4.58383 8.1665 4.78252 8.1665 5.01955C8.1665 5.25659 8.24637 5.45528 8.40609 5.61562C8.56581 5.77597 8.76373 5.85614 8.99984 5.85614ZM8.99984 16.7318C7.84706 16.7318 6.76373 16.5122 5.74984 16.073C4.73595 15.6338 3.854 15.0377 3.104 14.2848C2.354 13.5319 1.76025 12.6465 1.32275 11.6286C0.885254 10.6108 0.666504 9.5232 0.666504 8.36592C0.666504 7.20863 0.885254 6.12106 1.32275 5.10321C1.76025 4.08536 2.354 3.19996 3.104 2.44703C3.854 1.6941 4.73595 1.09803 5.74984 0.658816C6.76373 0.219605 7.84706 0 8.99984 0C10.1526 0 11.2359 0.219605 12.2498 0.658816C13.2637 1.09803 14.1457 1.6941 14.8957 2.44703C15.6457 3.19996 16.2394 4.08536 16.6769 5.10321C17.1144 6.12106 17.3332 7.20863 17.3332 8.36592C17.3332 9.5232 17.1144 10.6108 16.6769 11.6286C16.2394 12.6465 15.6457 13.5319 14.8957 14.2848C14.1457 15.0377 13.2637 15.6338 12.2498 16.073C11.2359 16.5122 10.1526 16.7318 8.99984 16.7318Z"
                      fill="#21AC59"
                    />
                  </svg>
                </span>
                Notification Sent to Sales and Operations
              </a>
            )}

            
            {currentTabIsDone ? (
              <button
               disabled
                className="fw-semibold fs-12 fs-14 text-white fw-bold btn text-white me-2 btn btn-secondary disabled"
              >
                Finish & Send Notification
              </button>
            ) : (
              <button
                type="submit"
                className="fw-semibold fs-12 fs-14 text-white fw-bold btn text-white me-2 btn btn-primary"
                
             >  
                Finish & Send Notification
              </button>
            )}
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
</div>
  )}
   </>
  );
};

export default ComparativeStudeyForm;
