import { Card } from "react-bootstrap";
import { Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import projectDummy from "../../../assets/images/project-dummy-icon.png";
import Today from "../../../assets/images/today.svg";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Accordion from "react-bootstrap/Accordion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Form from "react-bootstrap/Form";
import InputText from "../../../components/common/InputText";
import { LoginSchema } from "../../../validations/Auth";
import AddCircle from "../../../assets/images/add_circle.svg";
import DeleteIcon from "../../../assets/images/delete.svg";
import InfoGrayIcon from "../../../assets/images/info-gray.svg";
import UploadPrimaryIcon from "../../../assets/images/upload-primary.svg";
import DraftOrders from "../../../assets/images/draft_orders.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, FC } from "react";
import ProjectOwnerAssignToProjects from "../../../components/common/Projects/project-owner";
import React from "react";
import AssignTeam from "./assignTeam";
import ProjectDocumentList from "./documentList";
import {
  getProjectDetails,
  projectControlSectionMarkComplete,
  getProjectCommentsApi,
} from "../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProjectReleventDetailsAndDates from "./relaventDetailDates";
import { checkAbility, currencyMask } from "../../../utility/common";
import ProjectBasicDetail from "./basicDetail";
import ProjectPaymentDetail from "../../Finance/Projects/View/paymentDetail";
import { getLocalDateFormat } from "../../../utility/common";
import moment from "moment";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import ProjectComments from "./comments";
interface IViewProject {
  setActiveTab?: any;
  activeTabisDone?: string | number;
}

export const ViewProject: FC<IViewProject> = ({
  setActiveTab,
  activeTabisDone,
}) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [projectOwnerOpen, setProjectOwnerOpen] = useState(false);
  const [selectedPostalCodeOwners, setSelectedPostalCodeOwners] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState<any>({});
  const [projectControls, setProjectControl] = useState<any>({});
  const [mainProjectControls, setMainProjectControl] = useState<any>({});
  const [isNextEnable, setIsNextEnable] = useState<boolean>(false);
  const [isMutation, setIsMutation] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [projectChatData, setProjectChatData] = useState([]);

  const toggleProjectOwner = (isReload: boolean = false) => {
    setProjectOwnerOpen(!projectOwnerOpen);
    if (isReload) {
      handleDetailReload();
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProjectDetail(id).then(() => {     
        setLoading(false);      
    });  
  }, []);

  useEffect(() => {
    if (projectData?.id) {
      getProjectComments(projectData?.id);
    }
  }, [projectData])

  useEffect(() => {
    if (projectControls?.subsection) {
      const notSubmitData = projectControls?.subsection.find((control: any) => {
        return !control.value;
      });
      const submitNextPage = notSubmitData ? false : true;
      setIsNextEnable(submitNextPage);
    }
  }, [projectControls]);

  const fetchProjectDetail = (id: any) => {
    return new Promise((resolve, reject) => {
      getProjectDetails(id)
        .then((response) => {
          setProjectData(response.data.project);
          getProjectControl(response.data.project);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/projects");
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
            navigate("/projects");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/projects");
          }
        })
        .finally(() => {
          setLoading(false);
          resolve(true);
        });
    });
  };

  const getProjectControl = (projectDataObj: any) => {
    const projectControls = projectDataObj?.project_page_control.find(
      (item: any) => item.key == "PROJECT_ONBOARDING"
    );
    setMainProjectControl(projectDataObj?.project_page_control);
    setProjectControl(projectControls);
  };

  const handleNextPhaseClick = () => {
    projectControlSectionMarkComplete("PROJECT_ONBOARDING", id)
      .then((response) => {
        // navigate(`/projects/survey/${id}`);

        setActiveTab("SURVEY");
      })
      .catch((error) => {
        if (error.response.status == "406") {
         // navigate(`/projects/survey/${id}`);
          setActiveTab("SURVEY");
        } else if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });
  };

  const openProjectOwnerPopup = () => {
    const defaultParams: any = [projectData?.postal_code_id];

    const projectIds: any = [
      {
        id: id,
      },
    ];
    setSelectionModel(projectIds);
    setSelectedPostalCodeOwners(defaultParams);
    setProjectOwnerOpen(!projectOwnerOpen);
  };

  const handleDetailReload = () => {
    fetchProjectDetail(id);
  };

  const getProjectComments = async (projectId: string | number | undefined) => {
    return new Promise((resolve, reject) => {  
      if(projectId){
        getProjectCommentsApi(projectId).then((response) => {    
          //setProjectChatData(response.data.ticketData); 
          setCommentData(response.data.chatData); 
          return resolve(true);
        })
        .catch(() => {
         
        });
      }    
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer  w-100 ">
          {/* <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col className="col-md-12 col-xl-4">
                <h1 className="fw-bold h4 mt-2 mb-0">{ projectData?.project_no_str}</h1>
              </Col>
              <Col className="text-end col-md-12 col-xl-8">
                {mainProjectControls && mainProjectControls.map((mainControl: any, index: any) => (
                  <button className={`form-builder-link ${mainControl.key == 'PROJECT_ONBOARDING' ? 'active' : mainControl.value  ? 'previous' : ""}`}>
                  {mainControl?.label}  
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.06634 10.0666L10.7663 5.36659L9.83301 4.43325L6.06634 8.19992L4.16634 6.29992L3.23301 7.23325L6.06634 10.0666ZM6.99967 13.6666C6.07745 13.6666 5.21079 13.4916 4.39967 13.1416C3.58856 12.7916 2.88301 12.3166 2.28301 11.7166C1.68301 11.1166 1.20801 10.411 0.858008 9.59992C0.508008 8.78881 0.333008 7.92214 0.333008 6.99992C0.333008 6.0777 0.508008 5.21103 0.858008 4.39992C1.20801 3.58881 1.68301 2.88325 2.28301 2.28325C2.88301 1.68325 3.58856 1.20825 4.39967 0.858252C5.21079 0.508252 6.07745 0.333252 6.99967 0.333252C7.9219 0.333252 8.78856 0.508252 9.59967 0.858252C10.4108 1.20825 11.1163 1.68325 11.7163 2.28325C12.3163 2.88325 12.7913 3.58881 13.1413 4.39992C13.4913 5.21103 13.6663 6.0777 13.6663 6.99992C13.6663 7.92214 13.4913 8.78881 13.1413 9.59992C12.7913 10.411 12.3163 11.1166 11.7163 11.7166C11.1163 12.3166 10.4108 12.7916 9.59967 13.1416C8.78856 13.4916 7.9219 13.6666 6.99967 13.6666Z" fill="#21AC59"/>
                  </svg>
                </button>                       
                ))}
                
                
                
                
              </Col>
            </Row>
          </div> */}
          <div className="flex-grow-1  pageContent pb-2 gfg">
            <Card className=" p-3 w-100 pageContentInner mb-3 project-shadow rounded-4">
              <Row className="align-items-center">
                <Col>
                  {/* { JSON.stringify(projectControls?.subsection)} */}
                  {projectControls?.subsection &&
                    projectControls?.subsection.map(
                      (controls: any, index: any) => (
                        <div
                          className={`d-inline-block fs-14 fw-semibold me-4 ${
                            controls.value
                              ? "project-active"
                              : controls.key == "COLLECT_DOCS"
                              ? "project-next"
                              : "project-default"
                          }`}
                        >
                          <span></span>
                          {controls.label}
                        </div>
                      )
                    )}

                  {/* <div className="d-inline-block fs-14 fw-semibold me-4 project-next"><span></span>Collect all Document</div>
              <div className="d-inline-block fs-14 fw-semibold project-default"><span></span>Assing Team</div> */}
            </Col>
            <Col className="text-end">
              {
                isNextEnable ? (
                  <Button
                className={`fw-semibold fs-12 fs-14 fw-bold btn btn-primary me-2 text-white`}
                variant="primary"
                type="button"
                onClick={handleNextPhaseClick}
              >
                Next Phase
                <ChevronRightIcon />               
              </Button>
                ) : 
                (
                  <Button
                className={`fw-semibold fs-12 fs-14 control-label fw-bold btn btn-secondary me-2`}
                variant="secondary"
                type="button"
              >
                Next Phase
                <ChevronRightIcon />               
              </Button>
                )
              }
              
            </Col>
          </Row>
            </Card>
            <div className="w-100 pageContentInner">
              <Row>
                <Col className="col-md-6 col-xl-3 project-box1">
                  <Card className=" p-3 w-100 pageContentInner mb-3 rounded-3">
                  { projectData && (<ProjectBasicDetail  id={id} projectsData={projectData} />)}
                  <div className="d-flex fs-14 mb-0 justify-content-between">Project Owner <span>
                    {
                      checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.PROJECTS) && projectData.status != 'cp' ? (
                      <button
                        type="button"
                        className="btn btn-link p-0 link-text text-decoration-none fw-bold"
                        onClick={() => openProjectOwnerPopup()}
                      >
                        { projectData?.project_owner_id ? projectData?.projectOwner?.full_name : "Assign Now"}
                      </button>
                      ) :
                      <span className="fw-bold "> {projectData?.project_owner_id ? projectData?.projectOwner?.full_name : "Assign Now" }</span>
                    }
                    </span>
                  </div>
                    
                  </Card>
                  <AssignTeam id={id} projectData={projectData} onAssignTeam={handleDetailReload} />             
                  <Card className="mb-3 p-3 w-100 flex-wrap">
                    <h1 className="fw-bold h4 fs-20 info-text pt-3 pb-3 mt-0 mb-0 p-3 pb-0 border-bottom">
                      Project Notes
                    </h1>
                    <div>
                      <ProjectComments
                      projectId={projectData?.id}
                      projectData={projectData}
                      commentData={commentData}
                      onSubmitData={getProjectComments}
                      />
                    </div>
                  </Card>
                </Col>
                <Col className="col-md-6 col-xl-6 project-box2">
                  { projectData && (<ProjectReleventDetailsAndDates id={id} projectsData={projectData} />)}
                
                </Col>
                <Col className="col-md-6 col-xl-3 project-box1">
                  <Card className=" p-3 w-100 pageContentInner mb-3">
                    <ProjectDocumentList id={id} projectsData={projectData} 
                    onUploadDoc={() => 
                    (handleDetailReload(),
                    setIsMutation(!isMutation))
                    } />
                  </Card>
                  <Card className=" p-3 w-100 pageContentInner mb-3">
                  {projectData && (
                          <ProjectPaymentDetail
                            id={id}
                            projectsData={projectData}
                            isMutation={!isMutation}
                          />
                        )}
                  </Card>
                </Col>
              </Row>          
              <div className="row"></div>
            </div>
          </div>
          {projectOwnerOpen && (
              <ProjectOwnerAssignToProjects
                isOpen={projectOwnerOpen}
                onClose={toggleProjectOwner}
                projectsData={selectionModel}
                selectedPostalCodeOwners={selectedPostalCodeOwners}
                selectedProjectOwner={projectData?.projectOwner}
              />
            )}
        </div>
      )}
    </React.Fragment>
  );
};
