import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import InputText from "../../../../components/common/InputText";
import CustomButton from "../../../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import moment, { Moment } from "moment";
import constants from "../../../../constants/constants";
import * as yup from "yup";
import { MAXIMUM_255_CHAR, MINIMUM_3_CHAR } from "../../../../validations/ValidationErrors";
import { createCustomProductAPI } from "../../../../services/productService";

interface IHolidays {
  id?: number;
  event_name?: string;
  event_date?: string;
  createdAt: string;
  updatedAt: string;
}

interface AddProps {
  isOpen: boolean;
  hId?: number | string | undefined;
  onClose: () => void;
  setFieldValue :any;
   handleProducts  : any
   values: any
}

 

interface FormValues {
  price: number;
  name: string;
  qty: number;
}
const CustomProduct: React.FC<AddProps> = ({ isOpen, onClose,setFieldValue,values:proValue,  handleProducts }) => {
  const [editableData, setEditableData] = useState<IHolidays>();
  const [projectOwnerOption, setProjectOwnerOption] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const filterOptions = (editableOptions: any) => {
    const filteredArray = projectOwnerOption.filter((itemTwo: any) =>
      editableOptions.some((itemOne: any) => itemOne.user_id === itemTwo.key)
    );

    return filteredArray;
  };
  const initialValues = {
    price: 0,
    qty: 0,
    name: "",
  };

  const addSchema = yup.object({
    /* sku: yup
      .string()
      .trim()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .required()
      .label("SKU No."), */
    price: yup
      .number()       
      .required()
      .min(1)
      .max(100000)
      .label("Price")
      .typeError("Price is number only"),
    name: yup
      .string()
      .trim()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .required()
      .label("Custom Product"),
    qty: yup
      .number() 
      .min(1)
      .max(100)
      .required()
      .test(
        "is-integer",
        "Quantity must be an integer",
        (value) => Number.isInteger(value)
      )
      .label("Custom Product"),
  });

  /* const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    setBtnLoading(true);
    const formData = {
      name : values.name,
      price : values.price
     
    };

    createCustomProductAPI(formData)
      .then((response: any) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        console.log("response",response)
        const formValues = {
            orderProducts :    proValue.orderProducts,
            product_id : {id:response.data.id,name : formData.name},
            product_qty : values.qty,
            product_price : formData.price

        }  
        handleProducts(formValues, setFieldValue)
        onClose();
      })
      .catch((error) => {
        setBtnLoading(false);

        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      });
  }; */

  const submitForm = async (values: FormValues, { setSubmitting }: any) => {
    setBtnLoading(true);
    const formData = {
      orderProducts :    proValue.orderProducts,
      product_name : values.name,
      product_qty: values.qty,
      product_price: values.price,
    };
    handleProducts(formData, setFieldValue)
    onClose();
  };

  

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal
          className="export-excel-modal"
          show={isOpen}
          onHide={onClose}
          centered
        >
          <Formik
            validationSchema={addSchema}
            initialValues={initialValues}
            onSubmit={(values: FormValues, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative pb-0">
                  <button
                    type="button"
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">Add Custom Product</h4>

                  <div className="row">
                    {/* <div className="col-md-6">
                      <InputText
                        controlId="sku"
                        label="SKU"
                        placeholder="Enter SKU Number"
                        touched={touched.sku}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.sku}
                        value={values.sku}
                      />
                    </div> */}
                    <div className="col-md-12">
                      <InputText
                        controlId="name"
                        label="Add Custom Product"
                        placeholder="Enter Product Name"
                        touched={touched.name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.name}
                        value={values.name}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <InputText
                        controlId="qty"
                        label="Quantity"
                        type="number"
                        placeholder="Enter Qty"
                        touched={touched.qty}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.qty}
                        value={values.qty}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputText
                        controlId="price"
                        label="price"
                        placeholder="Enter Price"
                        touched={touched.price}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorsField={errors.price}
                        value={values.price}
                        type="number"
                        
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    type="submit"
                    loading={btnLoading}
                    className="fw-semibold fs-13 text-white mw-60 mt-2"
                    variant="primary"
                  >
                    Submit
                  </CustomButton>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default CustomProduct;
