import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Image, Row, Spinner } from "react-bootstrap";

import CallIcon from '@mui/icons-material/Call';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {
  MEETING_SOURCE_PAGES
} from "../../../constants/constants";
import { checkAbility, getTagClass } from "../../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";

interface EmployeeDetailsProps {
  employeeDetails: Record<string, any>; // You can replace 'any' with the actual type of employeeDetails
  kinDetails: Record<string, any>; // You can replace 'any' with the actual type of kinDetails
  employeeBankDetails: Record<string, any>; // You can replace 'any' with the actual type of employeeBankDetails
  source?: string | null;
}

const EmployeeDetails: React.FC<EmployeeDetailsProps> = ({ employeeDetails, kinDetails, employeeBankDetails, source = null }) => {
  return (
    <React.Fragment>
    <div className="row g-3">
      <div className="col-md-6 col-xl-3">
        <Card className="p-3 w-100 pageContentInner mb-3 border-radius-2 min-height-160">
          <h6 className="fs-18 fw-bold mb-3 info-text">
            Next of Kin Details - 1
          </h6>

          <div className="">
            <p className="fs-16 fw-bold mb-0">{kinDetails.keen_name1}</p>
            <p className="fs-14 mb-2"><CallIcon className="me-1 fs-14 text-primary" />{kinDetails.keen_contact_no1}</p>
            <span className="tag-detail p-1 text-white border-radius-1">{kinDetails.keen_relation1}</span>
          </div>




        </Card>
      </div>
      {
        (kinDetails.keen_name2 && kinDetails.keen_contact_no2) && 
        <div className="col-md-6 col-xl-3">
          <Card className="p-3 w-100 pageContentInner mb-3 border-radius-2 min-height-160">
            <h6 className="fs-18 fw-bold mb-3 info-text">
            Next of Kin Details - 2
            </h6>

            <div className="">
              <p className="fs-16 fw-bold mb-0">{kinDetails.keen_name2}</p>
              <p className="fs-14 mb-2"><CallIcon className="me-1 fs-14 text-primary" />{kinDetails.keen_contact_no2}</p>
              <span className="tag-detail p-1 text-white border-radius-1">{kinDetails.keen_relation2}</span>
            </div>




          </Card>
        </div>
      }
      {
        employeeDetails?.ni_number && 
        <div className="col-md-6 col-xl-3">
          <Card className="p-3 w-100 pageContentInner mb-3 border-radius-2 min-height-160">
            <h6 className="fs-18 fw-bold mb-3 info-text mb-0">
              NI Number
            </h6>
            <p className="fs-14 mb-1">{employeeDetails.ni_number}</p>

          </Card>
        </div>
      }
      <div className="col-md-6 col-xl-3">
      {(checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.HMRC_FORM) || (source === 'profile')) &&
        <Card className="p-4 pe-3 ps-3 w-100 pageContentInner mb-2 border-radius-2">
          <h6 className="fs-18 fw-bold mb-0 info-text mb-0">
            <Link className="text-decoration-none" to = {source === 'profile' ? `/hmrc` :`/employees/hmrc/${employeeDetails.customer_id}`}>
              <TextSnippetIcon className="me-2 text-primary" />
              HMRC Form
              <NavigateNextIcon className="float-end control-label" />
            </Link>
          </h6>
        </Card>}
        {(checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.MEDICAL_QUESTIONNAIRE) || (source === 'profile')) &&
        <Card className="p-4 pe-3 ps-3 w-100 pageContentInner mb-0 border-radius-2">
          <h6 className="fs-18 fw-bold mb-0 info-text mb-0">
          <Link className="text-decoration-none" to = {source === 'profile' ? `/medical` :`/employees/medical/${employeeDetails.customer_id}`}>
            <ContactSupportIcon className="me-2 text-primary" />
            Medical Questions
            <NavigateNextIcon className="float-end control-label" />
            </Link>
          </h6>
        </Card>}
      </div>
    </div>
    {
      checkAbility(PERMISSION_ACCESS.SENSITIVE_DATA, PERMISSION_MODULE.ALL_EMPLOYEE) && (
        <Card className="p-3 w-100 pageContentInner mb-3">
          <h6 className="fs-18 fw-bold mb-3 info-text">
            Banking Details
          </h6>
          <Row className="d-flex">
            <div className=" col flex-1">
              <label className="control-label">Bank Name</label>
              <p className="fs-14 mb-2">{employeeBankDetails.bank_name}</p>
            </div>

            <div className=" col flex-1">
              <label className="control-label">Account Name</label>
              <p className="fs-14 mb-2">{employeeBankDetails.account_name}</p>
            </div>
            <div className=" col flex-1">
              <label className="control-label">Account No.</label>
              <p className="fs-14 mb-2">{employeeBankDetails.account_number}</p>
            </div>
            <div className=" col flex-1">
              <label className="control-label">Short Code</label>
              <p className="fs-14 mb-2">{employeeBankDetails.sort_code}</p>
            </div>
            <div className=" col flex-1">
              <label className="control-label">Salary</label>
              <p className="fs-14 mb-2">{ employeeDetails.base_salary ? <span>£ {employeeDetails.base_salary}</span> : "-"}</p>
            </div>
          </Row>
        </Card>
      )
    }
    </React.Fragment>
  );
}

export default EmployeeDetails;
