import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import constants, {
  PoStatus,
  PoPaymentStatus,
} from "../../../../constants/constants";
import Loader from "../../../../components/common/Loader";
import {
  getGrnDetailById,
  getPODetailById,
  markPoComplete,
} from "../../../../services/warehouseService";

import AddCircle from "../../../../assets/images/add_circle.svg";
import moment from "moment";
import CustomButton from "../../../../components/common/CustomButton";
import { checkAbility, currencyMask } from "../../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../../constants/permissionUser";
import PrintIcon from "@mui/icons-material/Print";
import DeletePopup from "../../../../components/common/deletePopup";
import { UpdateGrnNote } from "./update-grn-note";

export const ViewGoodsInwardNote = () => {
  const [loading, setLoading] = useState(true);
  const [grnData, setGrnData] = useState<any>({});
  const navigate = useNavigate();
  let { id } = useParams(); 

  const getGrnDetail = (id: string) => {
    setLoading(true);
    getGrnDetailById(id)
      .then((response) => {
        setGrnData(response.data);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/good-inward");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/good-inward");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/good-inward");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getGrnDetail(id);
    } else {
      navigate("/purchase-orders");
    }
  }, [id]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">Goods Inward Note</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="p-3 w-100 pageContentInner mb-3">
              <Row className="purchase-top-form">
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold ">PO Number</label>
                  <div className="fs-14 mb-3">
                    <Link
                      target="_blank"
                      className="blue-text text-decoration-none fw-semibold"
                      to={`/purchase-orders/view/${grnData.purchaseOrder.id}`}
                    >
                      {" "}
                      {grnData.purchaseOrder.po_number_str}
                    </Link>
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">Good Received No.</label>
                  <div className="fs-14 mb-3">{grnData.gr_number}</div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">Project Order Number</label>
                  <div className="fs-14 mb-3 blue-text">
                    {grnData?.purchaseOrder?.contract_id ? (
                      <>
                      {/* {grnData?.purchaseOrder?.project?.project_no} */}
                      <Link
                        target="_blank"
                        className="blue-text text-decoration-none fw-semibold"
                        to={`/projects/view/${grnData?.purchaseOrder?.contract_id}`}
                      >
                        {" "}
                        {grnData?.purchaseOrder?.project?.project_no}
                      </Link>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">Ref. Number</label>
                  <div className="fs-14 mb-3">
                    {grnData.purchaseOrder.ref_number}
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">Order Date</label>
                  <div className="fs-14 mb-3">
                    {moment(grnData.purchaseOrder.date).format(
                      constants.dateFormats.slashSeparatedDate
                    )}
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">Vendor</label>
                  <div className="fs-14 mb-3">
                    {grnData.purchaseOrder.vendor.vendor_name}
                  </div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fs-14 fw-bold">Received Date</label>
                  <div className="fs-14 mb-3">
                    {grnData.received_date
                      ? moment(grnData.data).format(
                          constants.dateFormats.slashSeparatedDate
                        )
                      : "-"}
                  </div>
                </Col>
              </Row>
            </Card>
            <div>
              {grnData.receivedProducts.map((productItem: any, index: any) => (
                <div key={`order_products_grn${index}`}>
                  <Card className="p-3 w-100 pageContentInner mb-3">
                    <Row className="border-bottom py-3 pt-0">
                      <Col className="fs-16 fw-bold col-8">
                        {productItem?.poProduct?.product?.hs_sku ? productItem.poProduct.product?.hs_sku + " - " : ""}
                        {productItem?.poProduct?.product?.name}
                      </Col>

                      <Col className="text-end fs-14 col-4">
                        <span className="control-label fs-14 fw-bold me-2">Quality Inspector:</span>
                        {productItem?.user?.full_name}
                      </Col>
                    </Row>

                    <Row className="py-3">
                      <Col className="col-md-2">
                        <div><span className="control-label fs-14 fw-bold me-2">Price:</span> {currencyMask(productItem?.poProduct?.price)}</div>
                        <div><span className="control-label fs-14 fw-bold me-2">Qty Ordered:</span> {productItem?.balance_qty}</div>
                      </Col>
                      <Col className="col-md-2">
                        <div><span className="control-label fs-14 fw-bold me-2">Amount:</span> {currencyMask(productItem?.poProduct?.amount)}</div>
                      </Col>
                      <Col className="col-md-2">
                        <label className="control-label fs-14 fw-bold">Qty. Received</label>
                        <div className="fs-14 mb-2">
                          {productItem.qty_received}
                        </div>
                      </Col>
                      <Col className="col-md-2">
                        <label className="control-label fs-14 fw-bold">Qty. Returned</label>
                        <div className="fs-14 mb-2">
                          {productItem.qty_returned}
                        </div>
                      </Col>
                      <Col className="col-md-2">
                        <label className="control-label fs-14 fw-bold">Qty. Discard</label>
                        <div className="fs-14 mb-2">
                          {productItem.qty_discard}
                        </div>
                      </Col>
                      <Col className="col-md-2">
                        <label className="control-label fs-14 fw-bold">Quality Check</label>
                        <div className="fs-14 mb-2">
                          {productItem.quality_status}
                        </div>
                      </Col>
                    </Row>
                    {productItem.quality_status === "Fail" && (
                      <Row className="py-3 pb-0">
                        <Col className="col-md-12">
                          <label className="control-label fs-14 fw-bold">Return Reason</label>
                          <div className="fs-14 mb-2">
                            {productItem.return_remarks}
                          </div>
                        </Col>
                        <Col className="col-md-12">
                          <h3 className="fs-18 fw-bold info-text mt-2">Products Photos</h3>
                        </Col>
                        <Col className="col-md-12 d-flex good-inward-box-cover">
                          
                          {productItem.imagesSigned.map(
                            (imagePath: any, index: any) => (
                              <>
                                <label className="file-box me-3">
                                  <span>
                                    {" "}
                                    <img
                                      className="img-fluid"
                                      src={imagePath}
                                      alt="Logo"
                                    />
                                  </span>
                                </label>
                              </>
                            )
                          )}
                        </Col>
                      </Row>
                    )}
                  </Card>
                </div>
              ))}
            </div>
            
              <UpdateGrnNote grnData={grnData} />              
           
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
