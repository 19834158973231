import React, { useEffect, useState } from "react";
import {  Card, Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import constants, {
  PoStatus,
  PoPaymentStatus,
  PURCHASE_ORDER_STATUS,
} from "../../../../constants/constants";
import Loader from "../../../../components/common/Loader";
import {  
  getPODetailById, markPoComplete
} from "../../../../services/warehouseService";

import AddCircle from "../../../../assets/images/add_circle.svg";
import moment from "moment";
import CustomButton from "../../../../components/common/CustomButton";
import { checkAbility, currencyMask } from "../../../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../../constants/permissionUser";
import PrintIcon from "@mui/icons-material/Print";
import DeletePopup from "../../../../components/common/deletePopup";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PrintCommapnyHeader from "../../../../components/common/PrintHeader";
export const ViewPo = () => {
  const [loading, setLoading] = useState(true);
  const [poData, setPoData] = useState<any>({});
  const [totalQty, setTotalQty] = useState<number| undefined>();
  const [totalAmount, setTotalAmount] = useState<number| undefined>();
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const navigate = useNavigate();

  let { id } = useParams();
  
  const handleEditClick = () =>{
    navigate(`/purchase-orders/edit/${id}`);
  }

  const handleStatusChangeClick = () => {
    setDeletePopupOpen(true);
  }

  const handleDelete = () => {
    setLoading(false);
    markPoComplete(id)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.ERROR });
        setDeletePopupOpen(false);  
        handleClose();      
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    if(id){
      getPoDetail(id);
    }
  };

  const getPoDetail = (id: string) => {
    setLoading(true);
    getPODetailById(id)
      .then((response) => {
        const poDetails = response.data;
        const filteredData = poDetails?.goodsReceivedNotes
        .filter((item: { receivedProducts: any[]; }) => item.receivedProducts.some(product => product.qty_returned > 0));

//console.log(filteredData);
        poDetails.returnItems = filteredData;
        setPoData(poDetails);     
        setTotalQty(response.data?.orderProducts.reduce((sum: any, item: { qty: any; }) => sum + item.qty, 0))   
        setTotalAmount(response.data?.orderProducts.reduce((sum: number, item: { amount: string; }) => sum + parseFloat(item.amount), 0))   
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/purchase-orders");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/purchase-orders");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/purchase-orders");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if(id){
      getPoDetail(id);
    }
    else{
      navigate("/purchase-orders");
    }
  }, [id]);

  const handlePrint = () => {
    window.print();
  };

  // const totalQty = poData.orderProducts.reduce((sum: any, item: { qty: any; }) => sum + item.qty, 0);
  // const totalAmount = poData.orderProducts.reduce((sum: number, item: { amount: string; }) => sum + parseFloat(item.amount), 0);

  const renderStatusDiv = (statusType:string, checkedVal: number | string |undefined) => {
    const statsuArr:any = statusType === 'status' ? PoStatus : PoPaymentStatus
    const statusName = statsuArr.find(
      (item: { key: string | number | undefined; }) => item.key == checkedVal
    );
    return <span className={`${statusName?.tagClass} py-1 px-2 ms-2 rounded-1 d-inline-block fs-14 vertical-middle`}>{statusName?.value}</span>
  }
 
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <PrintCommapnyHeader className="d-none d-print-flex" />
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  Purchase Order Details
                  {renderStatusDiv("status", poData.status)}
                  {renderStatusDiv("pstatus", poData.pay_status)}
                </h1>
              </Col>
              <Col className="text-end d-print-none">
                <CustomButton
                  type="button"
                  className="fw-semibold fs-13 text-white mt-2 ms-2"
                  variant="info"
                  onClick={() => handlePrint()}
                >
                  <PrintIcon className="fs-16" />
                </CustomButton>

                {poData.status !== 4 &&
                  checkAbility(
                    PERMISSION_ACCESS.EDIT,
                    PERMISSION_MODULE.WAREHOUSE
                  ) && (
                    <CustomButton
                      type="button"
                      className="fw-semibold fs-13 text-white mw-90 mt-2 ms-2"
                      variant="primary"
                      onClick={() => handleStatusChangeClick()}
                    >
                      <img
                        src={AddCircle}
                        className="me-2 fs-15"
                        alt="File Upload Icon"
                      />
                      Mark as Complete
                    </CustomButton>
                  )}

                {checkAbility(
                  PERMISSION_ACCESS.EDIT,
                  PERMISSION_MODULE.WAREHOUSE
                ) && poData.status !== PURCHASE_ORDER_STATUS.COMPLETED && (
                  <CustomButton
                    type="button"
                    disabled={poData.status == PURCHASE_ORDER_STATUS.COMPLETED}
                    className="fw-semibold fs-13 text-white mw-90 mt-2 ms-2"
                    variant="primary"
                    onClick={() => handleEditClick()}
                  >
                    <ModeEditIcon className="me-2 fs-15" />
                    Edit Purchase Order
                  </CustomButton>
                )}
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3 purchase-detail">
            <Card className="p-3 w-100 pageContentInner mb-3">
              <Row className="purchase-top-form">
                <Col className="col-md-2 mb-3">
                  <label className="control-label fw-bold">PO Number</label>
                  <div className="fs-14 mb-2">{poData.po_number_str}</div>
                </Col>
                <Col className="col-md-2 mb-3">
                  <label className="control-label fw-bold">
                    Project Order Number
                  </label>
                  <div className="fs-14 mb-2 blue-text text-decoration-none fw-semibold">
                    {poData?.contract_id ? (
                      <>
                      {/* {poData?.project?.project_no} */}
                      <Link
                        target="_blank"                        
                        className="blue-text text-decoration-none fw-semibold"
                        to={`/projects/view/${poData?.contract_id}`}
                      >
                        {" "}
                        {poData?.project?.project_no}
                      </Link>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </Col>
                <Col className="col-md-2 mb-3">
                  <label className="control-label fw-bold">Order Date</label>
                  <div className="fs-14 mb-2">
                    {moment(poData.date).format(
                      constants.dateFormats.slashSeparatedDate
                    )}
                  </div>
                </Col>
                <Col className="col-md-2 mb-3">
                  <label className="control-label fw-bold">Received Date</label>
                  <div className="fs-14 mb-2">
                    {poData.received_date
                      ? moment(poData.received_date).format(
                          constants.dateFormats.slashSeparatedDate
                        )
                      : "-"}
                  </div>
                </Col>
                <Col className="col-md-2 mb-3">
                  <label className="control-label fw-bold">Due Date</label>
                  <div className="fs-14 mb-2">
                    {poData.due_date
                      ? moment(poData.due_date).format(
                          constants.dateFormats.slashSeparatedDate
                        )
                      : "-"}
                  </div>
                </Col>
                <Col className="col-md-2 mb-3">
                  <label className="control-label fw-bold">Vendor</label>
                  <div className="fs-14 mb-2">{poData?.vendor?.vendor_name ? poData?.vendor?.vendor_name : (poData?.vendor_name ? poData.vendor_name : "-")}</div>
                </Col>
              </Row>
              <Row className="purchase-top-form">
                <Col className="col-md-2">
                  <label className="control-label fw-bold">Supplier Ref. Number</label>
                  <div className="fs-14 mb-2">{poData.ref_number}</div>
                </Col>
                <Col className="col-md-2">
                  <label className="control-label fw-bold">GRN</label>
                  <div className="fs-14 mb-2 text-primary">
                    {poData.goodsReceivedNotes.length
                      ? poData.goodsReceivedNotes.map(
                          (grn: any, index: number) => (
                            <Link
                              target="_blank"
                              to={`/good-inward/view/${grn?.id}`}
                              className="blue-text text-decoration-none fw-semibold me-1"
                            >
                              {grn?.gr_number}
                            </Link>
                          )
                        )
                      : "-"}
                  </div>
                </Col>
                <Col className="col-md-8"> </Col>
              </Row>
              <hr />
              <Row className="purchase-address">
                <Col className="col-md-4">
                  <label className="control-label fw-bold">
                    Shipping Address
                  </label>
                  <div className="fs-14 mb-2">{poData.shipping_address}</div>
                </Col>
                <Col className="col-md-4">
                  <label className="control-label fw-bold">
                    Billing Address
                  </label>
                  <div className="fs-14 mb-2">{poData.bill_address}</div>
                </Col>
              </Row>
            </Card>
            <Card className="p-3 w-100 pageContentInner mb-3">
              <Row>
                <Col>
                  <h6 className="fs-18 fw-bold mb-3 info-text">Products</h6>
                </Col>
              </Row>
              <div className="table-responsive">
                <table className="table">
                  <thead key="productHeader0">
                    <tr>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Qty.</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poData.orderProducts.map(
                      (orderProduct: any, index: any) => (
                        <tr
                          className="border-bottom mb-2 py-2"
                          key={`productlist_${index}`}
                        >
                          <td className="w-75">
                            {orderProduct?.product?.hs_sku ? orderProduct?.product?.hs_sku + " - " : ""}
                            {orderProduct?.product?.name}
                          </td>
                          <td>{currencyMask(orderProduct?.price)}</td>
                          <td>{orderProduct?.qty}</td>
                          <td>{currencyMask(orderProduct?.amount)}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>

              <Row className=" mb-2 py-2">
                <Col className="text-end">
                  <div className="d-inline-block me-3 text-black fs-14">
                    Total Qty.:{" "}
                    <span className="text-primary fw-bold">{totalQty}</span>
                  </div>
                  <div className="d-inline-block text-black fs-14">
                    Grand Total:{" "}
                    <span className="text-primary fw-bold">{currencyMask(totalAmount)}</span>
                  </div>
                </Col>
              </Row>
            </Card>
            <Card className="p-3 w-100 pageContentInner mb-3 ">
              <Row>
                <Col>
                  <h6 className="fs-18 fw-bold mb-3 info-text">Return</h6>
                </Col>
              </Row>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="w-150">Qty.</th>
                      <th className="w-150">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    poData.returnItems.length ? 
                    (poData.returnItems.map((returnProduct: any, index: any) =>
                      returnProduct.receivedProducts.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-bottom mb-2 py-2"
                            key={`productlist_${index}`}
                          >
                            <td className="w-75">{product?.product?.name}</td>
                            <td>{product?.qty_returned}</td>
                            <td>
                              {returnProduct?.return_date
                                ? moment(returnProduct?.return_date).format(
                                    constants.dateFormats.slashSeparatedDate
                                  )
                                : "-"}
                            </td>
                          </tr>
                        )
                      )
                    ))
                    :
                    (
                      <tr
                            className="border-bottom mb-2 py-2"
                            key={`productlist_norecord`}
                          >
                          <td colSpan={3} className="text-center">No Record</td>
                      </tr>
                    )
                    }
                  </tbody>
                </table>
              </div>
            </Card>
            <Card className="p-3 w-100 pageContentInner mb-3">
              <Row>
                <Col className="col-md-12 mb-2">
                  <label className="control-label fw-bold">Payment Terms</label>
                  <div className="fs-14 mb-2">
                    {poData.pay_terms ? poData.pay_terms : "-"}
                  </div>
                </Col>
                <Col className="col-md-12">
                  <label className="control-label fw-bold">Description</label>
                  <div className="fs-14 mb-2">
                    {poData.description ? poData.description : "-"}
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
          <DeletePopup
            isOpen={isDeletePopupOpen}
            onClose={handleClose}
            onDelete={handleDelete}
            label="Mark Purchase Order as Complete"
            text="Are you sure you want to mark this Purchase Order as complete?"
            actionType="info"
          />
        </div>
      )}
    </React.Fragment>
  );
};
