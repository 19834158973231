import * as yup from "yup";
import {
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  ALPHABETS_ALLOW,
  VALID_EMAIL,
  INVALID_DATE
} from "../../../validations/ValidationErrors";
import { ONLY_ALPHABET, EMAIL_REGEX } from "../../../validations/regex";
export const projectPersonalDataSchema = yup.object({
  name: yup
    .string()
    .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .required()
    .label("Customer Name"),
  email: yup.string()
     .matches(EMAIL_REGEX, VALID_EMAIL) 
    .required()
    .max(255, MAXIMUM_255_CHAR)
    .label("Email"),
  phone: yup
    .number()
    .required()
    .typeError("Phone number must be a number")
    .max(999999999999999, "Phone number must be at most 15 digits") // Maximum 15 digits
    .label("Phone No"), 
  address: yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .label("Address"),
  city: yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .label("City"),
  state: yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .label("State"),
})
export const projectSchema = yup.object({
  name: yup
    .string()
     
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .required()
    .label("Customer Name"),
  email: yup.string()
     .matches(EMAIL_REGEX, VALID_EMAIL) 
    .required()
    .max(255, MAXIMUM_255_CHAR)
    .label("Email"),
  phone: yup
    .number()
    .required()
    .typeError("Phone number must be a number")
    .max(999999999999999, "Phone number must be at most 15 digits") // Maximum 15 digits
    .label("Phone No"), 
  address: yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .label("Address"),
  city: yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .label("City"),
  state: yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(255, MAXIMUM_255_CHAR)
      .label("County"),
  postal_code: yup.object().required().label("Postal Code"),
  contract_type: yup.object().required().label("Contract Type"),  
  project_type: yup.object().required().label("Project Type"),
  amount: yup
    .number()
    .positive()
    .typeError("Order Value must be a number")
    .required()
    .label("Order Value"),
   
  paid_amount: yup
    .number()
    .positive()
    .typeError("Deposit Amount must be a number")
    .max(yup.ref('amount'), 'Deposit Amount must be less than order value')
    .required()
    .label("Deposit Amount"),
  sales_rep: yup.object().required().label("Home Assessor"),  
  is_cdm_completed: yup.object().required().label("CDM Completed and Filed"),  
  //is_install_date_confirmed: yup.object().required().label("Installation Date Confirmed"),  
  is_install_date_confirmed: yup.string().label("Installation Date Confirmed"),  
  is_interim_payment_ready: yup.object().required().label("Interim Payment Ready for Collection"),  
  is_warranty_cert_posted: yup.object().required().label("Warranty Certificate Logged and Posted"),  
  contract_date: yup.date().nullable().typeError(INVALID_DATE).label("Contract Posted"),
  contract_received_date: yup.date().nullable().typeError(INVALID_DATE).label("Contract Signed"),
  survey_plan_date: yup.date().nullable().typeError(INVALID_DATE).label("Planned Survey Date"),
  survey_complete_date: yup.date().nullable().min(yup.ref('survey_plan_date'),'Date Survey Completed must be after Planned Survey Date').typeError(INVALID_DATE).label("Date Survey Completed"),
  planned_start_date: yup.date().nullable().typeError(INVALID_DATE).label("Planned Start Date"),
  planned_completion_date: yup.date().nullable().min(yup.ref('planned_start_date'),'Planned Completion Date must be after Planned Start Date').typeError(INVALID_DATE).label("Planned Completion Date"),
  warranty_expiry_date: yup.date().nullable().min(yup.ref('planned_completion_date'),'Warranty Expiry Date must be after Planned Completion Date').typeError(INVALID_DATE).label("Warranty Expiry Date"),
  installation_confirm_date: yup.date().nullable().typeError(INVALID_DATE).label("Installation Confirmed Date"),
});
