import { useEffect, useState } from "react";
import React from "react";
import Loader from "../../../../components/common/Loader";
import { Accordion } from "react-bootstrap";
import { checkAbility, currencyMask, getLocalDateFormat } from "../../../../utility/common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoGrayIcon from "../../../../assets/images/info-gray.svg";
import DraftOrders from "../../../../assets/images/draft_orders.svg";
import { getProjectPaymentMilestoneAPi } from "../../../../services/project.service";
import EditProjectPaymentMilestone from "./paymentMilestone";
import EditProjectPaymentReceivedMilestone from "./paymentReceivedMilestone";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../../constants/permissionUser";
interface AddProps {
  id: any;
  projectsData: any;
  isMutation?: boolean;
}
const ProjectPaymentDetail: React.FC<AddProps> = ({ id, projectsData, isMutation = false }) => {
  const [paymentData, setPaymentData] = useState<any>({});
  const [paymentMilestoneData, setPaymentMilestoneData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [milestonePopup, setMilestonePopup] = useState(false);
  const [paymentRecievedPopup, setPaymentRecievedPopup] = useState(false);

  useEffect(() => {
    fetchPaymentMilestoneDate();
    // console.log("yess");
  }, [id, isMutation]);

  const toggleMilestonePopup = (event?: any) => {
    console.log(event);
    // Prevent event propagation
    if (event) {
      event.stopPropagation();
    }
    setMilestonePopup(!milestonePopup);
  };
 /*  const handleReceivedPayment = (milestoneData: any) => {
    console.log(milestoneData);
    setPaymentMilestoneData(milestoneData);
    setPaymentRecievedPopup(!paymentRecievedPopup);
  }; */

  const handleMilestoneClosePopup = (isReload?: boolean) => {
    if (isReload) {
      fetchPaymentMilestoneDate();
    }
    setMilestonePopup(false);
    // setPaymentRecievedPopup(false);
  };

/*   const handlePaymentReceivedClosePopup = (isReload?: boolean) => {
    if (isReload) {
      fetchPaymentMilestoneDate();
    }
    setMilestonePopup(false);
    setPaymentRecievedPopup(false);
  };
 */
  const fetchPaymentMilestoneDate = () => {
    return new Promise((resolve, reject) => {
      getProjectPaymentMilestoneAPi(id)
        .then((response) => {
          let paymentMilestoneData = response.data;
          // if(viewType == 'F'){
          //   paymentMilestoneData = paymentMilestoneData.filter((obj: { key: string; isFileExists: boolean; }) => !(obj.key === "other" && obj.isFileExists === false));
          // }
          setPaymentData(paymentMilestoneData);
          setLoading(false);
          resolve(true);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" className="bg-white border-0">
              <Accordion.Header className="bg-white p-0 project-accordion-header ">
                <h3 className="fs-18 mb-0 info-text fw-bold d-inline-block">
                  Payment Milestones{" "}
                  {
                    checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.FINANCE_PROJECTS) && (
                      <span className="ms-2">
                        <button
                          type="button"
                          className="btn btn-link bg-white p-0"
                          onClick={toggleMilestonePopup}
                        >
                          <img src={DraftOrders} alt="Payment" />
                        </button>
                      </span>
                    )
                  }
                </h3>
              </Accordion.Header>
              <Accordion.Body className="bg-white p-0 mt-3">
                <div className="d-flex fs-14 mb-3 justify-content-between p-2 bg-primary-opacity rounded-1">
                  <span>Order Value</span>
                  <span className="fw-bold">
                    {currencyMask(paymentData?.amount)}
                  </span>
                </div>
                <div className="d-flex fs-14 mb-2 justify-content-between pb-2">
                  <div className="d-flex">
                    <span className="me-2">
                      {paymentData?.paid_amount > 0 && paymentData?.payment_date ? (
                        <CheckCircleIcon className="fs-18 text-green" />
                      ) : (
                        <img src={InfoGrayIcon} alt="Payment" />
                      )}
                    </span>
                    <div className="">
                      <span>Deposit</span>
                      {paymentData?.payment_date && (
                        <div className="mb-0 fs-12 ">
                          received on{" "}
                          {getLocalDateFormat(paymentData?.payment_date)}
                        </div>
                      )}
                    </div>
                  </div>
                  <span className="fw-bold ">
                    {paymentData?.paid_amount
                      ? currencyMask(paymentData?.paid_amount)
                      : "-"}
                  </span>
                </div>
                {paymentData.payment_milestone &&
                paymentData.payment_milestone.length ? (
                  paymentData.payment_milestone.map(
                    (paymentMilestone: any, index: any) => (
                      <div
                        key={`paymentMilestone${index}`}
                        className="d-flex fs-14 mb-2 justify-content-between pb-2"
                      >
                        <div className="d-flex">
                          <span className="me-2">
                            {paymentMilestone?.received_date ||  Number(paymentMilestone.amount) == 0 ? (
                              <CheckCircleIcon className="fs-18 text-green" />
                            ) : (
                              <img src={InfoGrayIcon} alt="Payment" />
                            )}
                          </span>
                          <div className="">
                            <span>{paymentMilestone?.name}</span>
                            { Number(paymentMilestone.amount) > 0 && (
                            <div className="mb-0 fs-12 fst-italic">
                              {paymentMilestone?.received_date ? (
                                <>
                                  received on{" "}
                                  {getLocalDateFormat(
                                    paymentMilestone?.received_date
                                  )}
                                </>
                              ) : paymentMilestone?.due_date && (
                                <div className="text-danger fst-italic">
                                  due on{" "}
                                  {getLocalDateFormat(
                                    paymentMilestone?.due_date
                                  )}
                                </div>
                              )}
                            </div>
                            )}
                          </div>
                        </div>
                        <span className="fw-bold text-end">
                          {currencyMask(paymentMilestone?.amount)}
                         {/*  {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.FINANCE_PROJECTS) && 
                          !paymentMilestone?.received_date && paymentMilestone?.amount > 0 && (
                            <div className="mb-0 fs-12">
                              <button
                                type="button"
                                className="btn btn-link p-0 m-0 fs-12 link-text text-decoration-none fw-semibold"
                                onClick={() => handleReceivedPayment(paymentMilestone)}
                              >
                                Received?
                              </button>
                            </div>
                          )} */}
                        </span>
                      </div>
                    )
                  )
                ) : (
                  <>
                    <div className="d-flex fs-14 mb-2 justify-content-between pb-2">
                      <div className="">
                        <span className="me-2">
                          <img src={InfoGrayIcon} alt="Payment" />
                        </span>
                        <span>Interim </span>
                      </div>
                      {
                        checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.FINANCE_PROJECTS) && (
                          <span className="fw-bold ">
                            <button
                              type="button"
                              className="btn btn-link p-0 m-0"
                              onClick={toggleMilestonePopup}
                            >
                              <AddCircleIcon className="fs-15 text-primary" />
                            </button>
                          </span>
                        )
                      }
                    </div>
                    <div className="d-flex fs-14 mb-2 justify-content-between pb-2">
                      <div className="">
                        <span className="me-2">
                          <img src={InfoGrayIcon} />
                        </span>
                        <span>Final</span>
                      </div>
                      { checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.FINANCE_PROJECTS) && (
                        <span className="fw-bold ">
                          <button
                            type="button"
                            className="btn btn-link p-0 m-0"
                            onClick={toggleMilestonePopup}
                          >
                            <AddCircleIcon className="fs-15 text-primary" />
                          </button>
                        </span>
                      )}
                    </div>
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {milestonePopup && (
            <EditProjectPaymentMilestone
              isOpen={milestonePopup}
              projectId={id}
              paymentData={paymentData}
              onClose={handleMilestoneClosePopup}
              label="Edit Payment Milestone"
              text="Edit Payment Milestone"
            />
          )}
         {/*  {paymentRecievedPopup && (
            <EditProjectPaymentReceivedMilestone
              isOpen={paymentRecievedPopup}
              projectId={id}
              paymentMilestoneData={paymentMilestoneData}
              onClose={handlePaymentReceivedClosePopup}
              label="Recieved Interim Payment"
              text="Edit Payment Milestone"
            />
          )} */}
        </>
      )}
    </>
  );
};
export default ProjectPaymentDetail;
