export default {
  datetimeFormats: {
    hyphenSeparatedDateTime: "MM-DD-YYYY | hh:mm A",
    slashSeparatedDateTime: "DD/MM/YYYY [at] hh:mm A",
  },
  dateFormats: {
    slashSeparatedDate: "DD/MM/YYYY",
    hyphenSeparatedDate: "MM-DD-YYYY",
    databaseFormat: "YYYY-MM-DD",
    //formate like 08-june-2023
    monthNameWithDate: "DD-MMM-YYYY",
  },
  timeFormats: {
    timeIn24HoursFormat: "HH:mm:ss",
    timeIn12HoursFormat: "hh:mm A",
    //time format liek 10:00 only
  },
};
export const rolesDefaultParams = {
  page: 1,
  limit: 500,
  sortColumn: "id",
  sortDirection: "desc",
};

export const departmentOptions = [
  { value: "Operations", key: "Operations" },
  { value: "Sales", key: "Sales" },
  { value: "HR Department", key: "HR Department" },
  { value: "Finance Department", key: "Finance Department" },
];

export const designationOptions = [
  {
    value: "Project Manager",
    key: "Project Manager",
    department: "Operations",
  },
  {
    value: "Operations Senior Manager",
    key: "Operations Senior Manager",
    department: "Operations",
  },
  {
    value: "Installation Engineer",
    key: "Installation Engineer",
    department: "Operations",
  },
  {
    value: "Warehouse & Procurement Manager",
    key: "Warehouse & Procurement Manager",
    department: "Operations",
  },
  {
    value: "Driver Support",
    key: "Driver Support",
    department: "Operations",
  },
  {
    value: "Operations Administration",
    key: "Operations Administration",
    department: "Operations",
  }, 
  { value: "Sub Contractor", key: "Sub Contractor", department: "Operations" },
  { value: "Home Assessor", key: "Home Assessor", department: "Sales" },
  {
    value: "Sales Administration",
    key: "Sales Administration",
    department: "Sales",
  },
  { value: "Sales Manager", key: "Sales Manager", department: "Sales" },
  {
    value: "Sales Senior Manager",
    key: "Sales Senior Manager",
    department: "Sales",
  },
  {
    value: "HR Administration",
    key: "HR Administration",
    department: "HR Department",
  },
  {
    value: "HR Senior Manager",
    key: "HR Senior Manager",
    department: "HR Department",
  },
  {
    value: "Finance Administration",
    key: "Finance Administration",
    department: "Finance Department",
  },
  {
    value: "Finance Senior Manager",
    key: "Finance Senior Manager",
    department: "Finance Department",
  },
];

export const PURCHASE_ORDER_STATUS = {
  INITIATED: 1,
  INPROGRESS: 2,
  PARTIAL_RECEIVED: 3,
  COMPLETED: 4,
  RETURNED: 5,
};

export const DISPATCH_ORDER_STATUS = {
  PENDING: 0,
  INITIATED: 1,
  DISPATCH: 2,
  RECEIVED: 3,
};
export const PoStatus = [
  {
    value: "PO Raised/Initiated",
    key: PURCHASE_ORDER_STATUS.INITIATED,
    tagClass: "info-tag",
  },
  {
    value: "In Progress",
    key: PURCHASE_ORDER_STATUS.INPROGRESS,
    tagClass: "orange-tag",
  },
  {
    value: "Partial Goods Received",
    key: PURCHASE_ORDER_STATUS.PARTIAL_RECEIVED,
    tagClass: "yellow-tag",
  },
  {
    value: "Completed",
    key: PURCHASE_ORDER_STATUS.COMPLETED,
    tagClass: "primary-tag",
  },
  {
    value: "Goods Returned",
    key: PURCHASE_ORDER_STATUS.RETURNED,
    tagClass: "danger-tag",
  },
];

export const DpStatus = [
  {
    value: "Pending",
    key: DISPATCH_ORDER_STATUS.PENDING,
    tagClass: "yellow-tag",
  },
  {
    value: "Initiated",
    key: DISPATCH_ORDER_STATUS.INITIATED,
    tagClass: "orange-tag",
  },
  {
    value: "Dispatch",
    key: DISPATCH_ORDER_STATUS.DISPATCH,
    tagClass: "info-tag",
  },
  {
    value: "Received",
    key: DISPATCH_ORDER_STATUS.RECEIVED,
    tagClass: "primary-tag",
  },
];

export const LeaveStatus = [
  { value: "Pending", key: 'pending', tagClass:"orange-tag"},
  { value: "Approved", key: 'approve' , tagClass:"primary-tag"},
  { value: "Rejected", key:  'reject', tagClass:"danger-tag"},
  { value: "Approved by Line Manager", key: 'approve_lm' , tagClass:"primary-tag"},
  { value: "Cancelled", key: 'cancel' , tagClass:"yellow-tag"}
];


export const PoPaymentStatus = [
  { value: "Upcoming", key: "1", tagClass: "info-tag" },
  { value: "Un Resolved", key: "2", tagClass: "danger-tag" },
  { value: "Paid", key: "3", tagClass: "primary-tag" },
];

export const GrnQualityCheckStatus = [
  { key: "Pass", value: "Pass" },
  { key: "Fail", value: "Fail" },
];

export const tickerStatus = [
  { key: 0, value: "Open", tagClass:"info-tag" },
  { key: 1, value: "Overdue" , tagClass:"danger-tag"},
  { key: 2, value: "Closed" , tagClass:"primary-tag"},
]

export const supportTickerStatus = [
  { key: 0, value: "Open", tagClass:"info-tag" },
  { key: 1, value: "Overdue" , tagClass:"danger-tag"},
  { key: 2, value: "Closed" , tagClass:"primary-tag"},
]

export const vendorType = [
  { value: "Manual", key: "manual" },
  { value: "Website", key: "website" },
];

export const vendorCreditTerms = [
  { value: 10, key: 10 },
  { value: 15, key: 15 },
  { value: 20, key: 20 },
  { value: 30, key: 30 },
  { value: 45, key: 45 },
  { value: 60, key: 60 },
  { value: 90, key: 90 },
  { value: 120, key: 120 },
  { value: 180, key: 180 },
];

export const reportingManagerOptions = [
  { value: "Evelyn Audra", key: "Evelyn Audra" },
  { value: "Geraldine Rooney", key: "Geraldine Rooney" },
];
export const employeeTypeOptions = [
  { value: "Part-Time", key: "Part-Time", aKey: 1 },
  { value: "Full-Time", key: "Full-Time", aKey: 2  },
  { value: "Contractor", key: "Contractor", aKey: 3  },
  { value: "Worker", key: "Worker", aKey: 4  },
];

export const relatioinOptions = [
  { value: "Friend", key: "Friend" },
  { value: "Mother", key: "Mother" },
  { value: "Father", key: "Father" },
  { value: "Spouse", key: "Spouse" },
  { value: "Son", key: "Son" },
  { value: "Daughter", key: "Daughter" },
  { value: "Other", key: "Other" },
];

export const employmentStatusOptions = [
  { value: "Conditional Onboarding", key: "Conditional Onboarding" },
  { value: "Probation", key: "Probation" },
  { value: "Permanent", key: "Permanent" },
  { value: "Notice Period", key: "Notice Period" },
];

export const dealContractType = [
  { value: "B2B", key: "B2B" },
  { value: "B2C", key: "B2C" },
];

export const dealStatus = [
  { key: "up", value: "Under Process" },
  { key: "ca", value: "Client Accepted" },
];

export const projectStatus = [
  { key: "ob", value: "New Onboarded" },
  { key: "ip", value: "Survey In-Progress" },
  { key: "si", value: "Survey Initiated" },
  { key: "cp", value: "Completed" },
  { key: "cl", value: "Cancelled" },
  { key: "pro", value:"Procurement"},
  { key:"ins", value:"Installation"},
  { key:"sc", value:"Survey Completed"},
];

export const invoiceStatus = [
  { key: 0, value: "Due", tagClass: "orange-tag" },
  { key: 1, value: "Paid", tagClass:"primary-tag" }
]

export const announcementTypes = [
  { key: 0, value: "All" },
  { key: 1, value: "Internal" },
  { key: 2, value: "External" },
  { key: 3, value: "Department" }
]

export const commonMasking = "+1 999 999-9999" 

export const pageSetting = {
  defaultLimt: 10,
  defaultPage: 0,
};

export const smallPageSetting = {
  defaultLimt: 3,
  defaultPage: 0,
};

export const allowedFileTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml",
];
export const maxFileSizeMB = 5; //5 MB

export const formTemplateAllowedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
export const pdfFileTypes = ['application/pdf'];
export const formTemplateMaxFileSizeMB = 8; //8 MB

export const allowedImportFilesTypes = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export const importTypeForDropZone = {
  "application/vnd.ms-excel": [".xls", ".xlsx"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xls",
    ".xlsx",
  ],
};
export const importMaxFileSize = 20;
export const ImageTypeForDropZone = {
  "image/*": [".jpeg", ".png", ".jpg", ".svg"],
};
export const EmployeeDocumentTypeForDropZone = {
  "application/pdf": [".pdf"],
  "image/*": [".jpeg", ".png", ".jpg", ".svg"],
  "application/doc": [".doc", ".docx"],
  "application/ms-doc": [".doc", ".docx"],
  "application/msword": [".doc", ".docx"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".doc",
    ".docx",
  ],
};

export const CSVForDropZone = {
  "text/csv": [".csv"]
};

export const CSVTypeExtension =[
  "text/csv"
];
export const importCsvMaxFileSize = 2;

export const EmployeeDocumentTypeExtension = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/svg+xml",
];

export const pageSizeModel = [10, 20, 50, 100];
export const smallPageSizeModel = [3, 5, 10, 20];

export const SUPER_ADMIN = "SUPER ADMIN";
export const Reporting_Manager = "Reporting Manager";
export const Employee = "Employee";
export const visibilityOptions = [
  { value: "Visible", key: "Visible" },
  { value: "Invisible", key: "Invisible" },
];
export const formTypeOption = [
  { value: "Textbox", key: "text" },
  { value: "Dropdown", key: "dropdown" },
  { value: "Radio Button", key: "radio_button" },
  { value: "Message", key: "message" },
  { value: "Media Upload Button", key: "button" },
  { value: "Slider", key: "slider" },
];

export const actionOptions = [
  { value: "Hide", key: "Hide" },
  { value: "Show", key: "Show" },
];
export const statusOptions = [
  { value: "active", key: "Active" },
  { value: "inactive", key: "Inactive" },
];

export const conditionOptions = [
  { value: "Equal", key: "Equal" },
  { value: "Not Equal", key: "Not Equal" },
  { value: "Greater Than", key: "Greater Than" },
  { value: "Less Than", key: "Less Than" },
];

export const expensesCategories = [
  { value: "Printing & Stationery", key: "1" },
  { value: "Mileage", key: "2" },
  { value: "Postage, Freight or Courier", key: "3" },
  { value: "IT, software and Consumables", key: "4"}, 
  { value: "Cleaning", key: "5"}, 
  { value: "Travel Expenses", key: "6"}, 
  { value: "Accommodation", key: "7"}, 
  { value: "Sustenance (Food and Drink)", key: "8"}, 
  { value: "Fuel", key: "9"}, 
  { value: "Motor Vehicle Expenses", key: "10"}, 
  { value: "Staff Training", key: "11"}, 
  { value: "Gifts", key: "12"}, 
  { value: "Project Related Materials", key: "13"}, 
  { value: "General Expenses", key: "14"}, 
]

export const expensesStatus = [
  { value: "Pending", key: 0,  tagClass:"orange-tag" },
  { value: "Approved", key: 1, tagClass:"primary-tag" },
  { value: "Rejected", key: 2, tagClass:"danger-tag" },
  { value: "Paid", key: 3, tagClass:"info-tag" }, 
]

export const expensesFinanceStatus = [
  { value: "Due", key: 0,  tagClass:"orange-tag" },
  { value: "Invoiced", key: 1, tagClass:"info-tag" },
  { value: "Paid", key: 2, tagClass:"primary-tag" }, 
]

export const emailTemplateReplacableKeywords = {
  recipient_name: "{{ Recipient_Name }}",
  recipient_address: "{{ Recipient_Address }}",
  recipient_postcode: "{{ Recipient_Postcode }}",
  sender_name: "{{ Sender_Name }}",
  sender_designation: "{{ Sender_Designation }}",
  date: "{{ Date }}",
};

export const MEETING_TYPES = {
  one_on_one: "1 to 1",
  appraisal: "Annual Appraisal",
  other: "Other",
};
export const EmployeeDocumentListType = {
  'NeedFromEmp' : 0,
  'SentToHr' : 1
}
export const surveyProductStatus ={
  'PENDING': 0,
  'ACCEPTED': 1,
  'REJECTED': 2,
  'REPLACED': 3,
  'UPDATED': 4,
  'NEW_ADDED': 5,
  'ADDED_FROM_COMPARATIVE': 6,
  'REPLACED_FROM_COMPARATIVE': 7,

}

export const conditionsOperator = {
  Equal: "==",
  "Not Equal": "!=",
  "Greater Than": ">",
  "Less Than": "<",
};

export const surveyProductStatusOptions: { [key: string]: string } = {
  onBoarding: "Newly Onboarded",
  inProgress: "In Progress",
  delayed: "Delayed",
  completed: "Completed",
};

export const currencySymbol = {
  POUND: "£",
};

export const titleRoles = {
  A:  "ADMIN",
  PM: "PROJECT_MANAGER",
  WM: "WAREHOUSE",
  HR: "HR",
  FM: "FINANCE",
  IM: "INSTALLATION_MANAGER",
  E:  "EMPLOYEE",
};


export const desginationIsEmployee =[
  "Sub Contractor",
  "Operations Administration",
  "Driver Support"
]

export const departmentCheck: { [key: string]: string } = {
  OP: "Operations",
  HR: "HR Department",
  FD: "Finance Department",
  SA: "Sales"
  
  
}

export const isActiveStatus = [
  { key: true, value: "Yes" },
  { key: false, value: "No" }
]
export const OperationDesignation = {
  PM: "Project Manager",
  OSM: "Operations Senior Manager",
  IE: "Installation Engineer",
  WPM: "Warehouse & Procurement Manager",
  OA: "Operations Administration",
  SC: "Sub Contractor",
  DS: "Driver Support"
}


export const jobTypePlanner = [
  { value: "Projects", key: "Projects" },
  { value: "Electrician", key: "Electrician" },
  { value: "EA Install Floorer", key: "EA Install Floorer" },
  { value: "Sub - Contract Floorer" , key: "Sub - Contract Floorer"},
  { value: "Remedial Works" , key: "Remedial Works"},
  { value: "Delivery Collection", key: "Delivery Collection"},
]
export const MEETING_INITIAL_YEAR = 2023;

export const MEETING_SOURCE_PAGES = {
  'hr': 'hr',
  'employee': 'employee',
  'reporting_manager': 'reporting_manager',
  'reporting_manager_team': 'reporting_manager_team'
}

export const PROJECT_PREFIX = "GR";
export const DEPARTMENTLIST = {
  SALES: 'Sales',
  OPERATIONS: 'Operations',
  HR: 'HR Department',
  FINANCE: 'Finance Department',
}

export const MAP_KEY = process.env.REACT_APP_MAP_KEY
export const DEFAULT_BILLING_ADDRESS = "Head Office Hereford House, Hereford Way, King’s Lynn, Norfolk, UK PE30 4JD";

export const DISPATCH_PRODUCT_STATUS = {
  ordered: "Ordered",
  awaiting: "Awaiting Delivery",
  direct: "Direct Dispatch"
}