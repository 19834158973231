import { AbilityBuilder, defineAbility,createMongoAbility } from '@casl/ability';
import { useSelector } from 'react-redux';
import { selectAuthentication } from '../features/Auth/authSlice';


export const buildAbilitiesForSuperAdmin = () =>{
    const { can, rules } = new AbilityBuilder(createMongoAbility);
    can('manage', 'all');
    return rules
}
export const buildAbilities = (permissions: any[]) => {
    const { can, rules } = new AbilityBuilder(createMongoAbility);
  
    permissions.forEach((module) => {
      module.permission.forEach((permission:any) => {   
        
        if(permission.checked){
            can(permission.title, module.title);
            
        }     
        
      });
    });
  
    return rules;
  };


export default defineAbility((can:any, cannot:any) => {
    //default set all access 
    // can('manage', 'all');
});