import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { Chart as ChartJS,CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, } from "chart.js";
import { Line } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import { getDashboardPoRaisedCountChartApi } from "../../../services/warehouseService";
import FormSelect from "../../../components/common/FormSelect";
import ChartDataLabels from "chartjs-plugin-datalabels";
import BarChartSkeleton from "../../../components/common/Skeleton/BarChart.skeleton";
import InputDateRangePicker from "../../../components/common/InputDateRangePicker";
import constants from "../../../constants/constants";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const DashboardNumberPoRaised = () => {
  const [poRaisedData, setPoRaisedData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [defaultDateRange, setDefaultDateRange] = useState<any>(
    [
      moment().subtract("12", "months").format(constants.dateFormats.databaseFormat),
      moment().format(constants.dateFormats.databaseFormat)
    ]
  );
  //const [selectFilter, setSelectFilter] = useState<any>( { key: 1, value : "Quarter 1"});
  const [selectFilter, setSelectFilter] = useState<any>( `dateFrom=${defaultDateRange[0]}&dateTo=${defaultDateRange[1]}` );
  
  const changeDateRange = (
    data: any,
    fieldName: string,
    setFieldValue: any
  ) => {
    if (data && data[0] && data[1]) {
      const startDate = moment(data[0]).format(
        constants.dateFormats.databaseFormat
      );
      const endDate = moment(data[1]).format(
        constants.dateFormats.databaseFormat
      );
      setDefaultDateRange([startDate, endDate]);
      setSelectFilter(`dateFrom=${startDate}&&dateTo=${endDate}`);
    }
  }
  const getDashboardPoRaisedCountChartData = () => {
    // setLoading(true);
    //const selectQuarter = selectFilter?.key;
    const selectQuarter = selectFilter;

    getDashboardPoRaisedCountChartApi(selectQuarter)
       .then((response) => {
        convertToChartData(response.data);
        setLoading(false);
       })
       .catch((error) => {
         setLoading(false);
       })
       .finally(() => {
         setLoading(false);
       });
   };

   // Function to convert API response to Chart.js data
  const convertToChartData = (apiResponse: any[]) => {
    const labels = apiResponse.map((item) => item.label);
    const data = apiResponse.map((item) => item.po_count);

    setPoRaisedData({
      labels,
      datasets: [
        {
          data,
          borderColor: '#4ECB71',
          fill: false,
      
      tension: 0.1,
      pointBackgroundColor: 'black', // Change the color of the points
      pointBorderColor: 'black', // Change the border color of the points  
      pointSize: 25   
        },

      ],
    });
  };

  const generateQuarterArray = (numberOfqs: number): { key: number, value: string }[] => {
    const qsArray = [];
  
    for (let i = 1; i <= numberOfqs; i++) {
      qsArray.push({ key: i, value: `Quarter ${i}` });
    }
  
    return qsArray;
  };
  
  // Generate array for 12 months
  const quarterArray = generateQuarterArray(4); 

  useEffect(() => {
    getDashboardPoRaisedCountChartData();
  }, [selectFilter]);

/*   const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {        
        data: [65, 59, 80, 81, 56, 55, 40],        
        borderColor: '#4ECB71'
      }
    ]    
  }; */

  const options:any = {
    responsive: true,
   // aspectRatio: 2.5,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
        text: 'Line Chart',
      },
      datalabels: {
        display: false, // Disable datalabels for this specific chart
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        border: {
          display: false
        },
      },
      x: {
        grid:{
          drawOnChartArea: false
        }
      }  
    },
    elements: {
      point: {
        pointStyle: 'circle',
        radius: 5,
        backgroundColor: 'red',
      },
    },
  };

  return (
    <React.Fragment>
      {loading ? (
          <Card className="p-3 w-100 pageContentInner rounded-3 h-100">
            <BarChartSkeleton heigth={300} numbers={30} />
          </Card>
      ) : (
        <div className="d-flex flex-column pageContainer h-100 w-100 pb-sm-1">
          <Card className="p-3 w-100 pageContentInner rounded-3 h-100">
          <Row className="align-items-center">
              <Col md={6}>
              <h5 className="fs-18 fw-bold info-text mb-0">No. of PO Raised </h5>
              </Col>
              <Col md={6} className="warehouse-dashbord-select-month">                
                
                {/* <FormSelect
                                      placeholder=""
                                      label=""
                                      name="filter"
                                      options={quarterArray}
                                      getOptionLabel={(option: any) => option.value}
                                      getOptionValue={(option: any) => option.key}
                                      onChange={(name: string, value: string) => {
                                      setSelectFilter(value)
                                      }}
                                     value={selectFilter}
                                    />           */}
                      <div className="dashbord-input-rang">
                        <InputDateRangePicker
                          controlId="filter"
                          label=""
                          placeholder="Date Range"
                          touched={false}
                          handleBlur={false}
                          handleChange={changeDateRange}
                          handleClose={changeDateRange}
                          errorsField={false}
                          defaultValue={[
                            new Date(defaultDateRange[0]),
                            new Date(defaultDateRange[1])
                          ]}
                          position="auto"
                        />
                      </div>
              </Col>
            </Row>
            <div >
            <Line data={poRaisedData} options={options}   />
            </div>
          </Card>
        </div>
      )
    }
    </React.Fragment>
  );
};
