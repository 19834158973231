import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  getGridSingleSelectOperators,
  GridRowSelectionModel,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import FileUploadIcon from "../../assets/images/file-upload.svg";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import DeletePopup from "../../components/common/deletePopup";
import FileSave from "../../assets/images/file_save.svg";
import { toast } from "react-toastify";
import {
  getVendorList,
  updateVendorStatusApi,
  deleteVendorApi,
} from "../../services/vendorService";
import InputSwitchBox from "../../components/common/InputSwitchBox";
import { Card, Col, Row } from "react-bootstrap";
import AddCircle from "../../assets/images/add_circle.svg";
import { pageSetting, pageSizeModel } from "../../constants/constants";
import { checkAbility, currencyMask } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import BlockIcon from "@mui/icons-material/Block"
import { getProductList, syncProductsFromHubSpot } from "../../services/productService";
import ProductVendorAssign from "./assign-vendor";
import ExportProductData from "./exportData";
import { FileUpload } from "@mui/icons-material";
import ImportProductMaster from "./importData";

const AllProducts: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [exportPopup,setExportPopup] = useState(false);
  const [isImportPopupOpen, setImportPopupOpen] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isVendorAssignPopup, setVendorAssignPopup] = useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [availableStockValue, setAvailableStockValue] = React.useState(0);

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/product/edit/${id}`);
  };

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/product/view/${id}`);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteVendorApi(deletedId)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.ERROR });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
    setImportPopupOpen(false);
  };

  let {  minstock } = useParams<string>();

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    minQtyAlert: minstock == 'minstock' ? 1 : 0,
    ...(search ? { search } : {}),
  };
 // console.log(minstock);
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }

  const fetchData = (defaultParams: any) => {
    getProductList(defaultParams)
      .then((response) => {
        if(response.data?.stock_value)
        setAvailableStockValue(response.data?.stock_value); //set this for available stock values
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "hs_sku",
      headerName: "SKU Number",
      flex: 1.5,
      sortable: true,
      hideable: false,
    },
    {
      field: "name",
      headerName: "Product Name",
      flex: 3,
      sortable: true,
    },
    {
      field: "category",
      headerName: "Category Type",
      flex: 2,
      sortable: true,
      renderCell(params) {
        return params.row?.category ? params.row?.category : "-"
       }, 
    },
    {
      field: "$vendor.vendor_name$",
      headerName: "Vendor",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.vendor_id ? (<span title={params.row?.vendor?.vendor_name}>{params.row?.vendor?.vendor_name}</span>) : "-"
       }, 
    },
    {
      field: "is_fmp",
      headerName: "Warehouse Stock",
      flex: 1.5,
      sortable: true,
      type:"singleSelect",
      valueOptions: [{ value: true, label: 'Yes' },
      { value: false, label: 'No' }],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        return params.row.is_fmp ? "Yes" : "No";
      },
    },
    {
      field: "stock_qty",     
      headerName: "Qty /n (Available Stock)",
      flex: 1.5,
      sortable: true,
      type: "number",
      renderHeader: (params) => (
        <div className="MuiDataGrid-columnHeaderTitle lh-base">
          <div>Qty.<span className="fw-normal d-block">(Available Stock)</span></div>          
        </div>
      ),
      renderCell(params) {
        return <span title={params.row?.stock_qty} className={params.row?.stock_qty < params.row?.min_qty ? 'text-danger': ""}>{params.row?.stock_qty}</span>
      },
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return <span title={currencyMask(params.row?.price)}>{currencyMask(params.row?.price)}</span>
      },
    },
  ];

  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.PRODUCT_MASTER
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.PRODUCT_MASTER
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.PRODUCT_MASTER
    );

    if (
      checkEditAbilityCondition ||
      checkViewAbilityCondition ||
      checkDeleteAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];
          if (checkEditAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Edit"
                icon={<EditIcon />}
                showInMenu={false}
                onClick={handleEditClick(id)}
                className="text-primary"
              />
            );
          }
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                icon={<ViewIcon />}
                showInMenu={false}
                onClick={handleViewClick(id)}
                className="text-primary"
              />
            );
          }
          if (checkDeleteAbilityCondition) {
            // gridActions.push(
            //   <GridActionsCellItem
            //     label="Delete"
            //     icon={<DeleteIcon />}
            //     showInMenu={false}
            //     onClick={handleDeleteClick(id)}
            //     className="text-primary"
            //   />
            // );
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  const toggleVendorPopup = () => {   
    setVendorAssignPopup(!isVendorAssignPopup);
    setIsMutation(!isMutation);
  };

  const syncDealsFromHubspot = () => {   
    syncProductsFromHubSpot()
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });        
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });   
  };

  const getRowClassName = (params: { row: {
    stock_qty: number; min_qty: number; 
}; }) => {
    // You can add your custom logic here to determine the background color based on row data
    if (params.row.min_qty > params.row.stock_qty) {
      return 'bg-danger-subtle'; // CSS class for rows that meet the condition
    }
    return ''; // Default class for other rows
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  const handleSelectionModelChange = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
    // console.log(data);
     const filteredArray:any = data.filter((itemTwo:any) =>
     rowSelectionModel.some((itemOne:any) => itemOne === itemTwo.id)
   ).map((item : any) => ({ id: item.id, code: item.code }))
  // console.log(filteredArray);
     setSelectionModel(filteredArray);
   };

  handleEditViewActionPermission();
  
  const handleImportStock = () => {    
    setImportPopupOpen(true);
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Product Master</h1>
              </Col>
              <Col className="text-end">
              <div className="d-inline-block me-2 pt-0 pe-2 product-border fw-bold border-end vertical-middle">Available Stock <span className="product-price fs-18 w-100 d-block">{currencyMask(availableStockValue)}</span></div>
              <button title="Sync Products from Hubspot" onClick={() => syncDealsFromHubspot()} className="fw-semibold fs-12  btn btn-outline-info me-2 px-2 btn-outline-secondary-hover">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.50002 3.16675V0.791748L6.33335 3.95842L9.50002 7.12508V4.75008C12.1204 4.75008 14.25 6.87967 14.25 9.50008C14.25 10.2997 14.0521 11.0597 13.6959 11.7167L14.8517 12.8726C15.4692 11.8988 15.8334 10.743 15.8334 9.50008C15.8334 6.00092 12.9992 3.16675 9.50002 3.16675ZM9.50002 14.2501C6.8796 14.2501 4.75002 12.1205 4.75002 9.50008C4.75002 8.7005 4.94794 7.9405 5.30419 7.28342L4.14835 6.12758C3.53085 7.10133 3.16669 8.25717 3.16669 9.50008C3.16669 12.9992 6.00085 15.8334 9.50002 15.8334V18.2084L12.6667 15.0417L9.50002 11.8751V14.2501Z"
                      fill="#21426B"
                    />
                  </svg>
                </button>
                {exportPopup && <ExportProductData isOpen={exportPopup} onClose={toggleExportPopup} exportType="deal" label="Export Product" text="Export Product" />} 
                  <button
                    className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                    title="Export Products"
                    onClick={toggleExportPopup}
                  >
                    <img src={FileUploadIcon} alt="File Upload Icon" />
                  </button>

                {
                  checkAbility(
                    PERMISSION_ACCESS.ADD,
                    PERMISSION_MODULE.PRODUCT_MASTER
                  ) &&
                  <Button
                      className="fw-semibold fs-12 text-white w-40 btn blue-btn px-2"
                      variant="info"
                      type="button"                    
                      title="Import"
                      onClick={() => handleImportStock()}
                    >
                      <img src={FileSave} alt="File Save Icon" />   
                  </Button>
                }

                { checkAbility(
                  PERMISSION_ACCESS.EDIT,
                  PERMISSION_MODULE.PRODUCT_MASTER
                ) && (
                  <button
                  className={`fw-semibold fs-12 me-2 btn ${selectionModel?.length ? 'btn-primary text-white' : 'btn-secondary text-black '}`}                 
                  type="button"
                  disabled={selectionModel?.length ? false : true}
                  onClick={() => toggleVendorPopup()}
                >
                <AssignmentInd  className="me-2 fs-18"/>                 
                    Assign Vendor
                </button>
                )}
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  className="border-0 rounded-2"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  onRowSelectionModelChange={handleSelectionModelChange}
                  checkboxSelection
                  disableRowSelectionOnClick
                  pageSizeOptions={pageSizeModel}
                  localeText={{ noRowsLabel: "No Products found" }}
                  getRowClassName={getRowClassName}
                  
                />
                {isVendorAssignPopup && (
                  <ProductVendorAssign
                    isOpen={isVendorAssignPopup}
                    onClose={toggleVendorPopup}
                    productData={selectionModel}                    
                  />
                )}
                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Vendor"
                  text="Are you sure you want to delete this vendor?"
                />
              </div>
            </Card>
          </div>
          {
            isImportPopupOpen && (
              <ImportProductMaster isOpen={isImportPopupOpen} onClose={handleClose}/>
            )
          }
        </div>
      )}
    </React.Fragment>
  );
};

export default AllProducts;
