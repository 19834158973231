import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,  
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";
import FileSave from "../../assets/images/file_save.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import { Card, Col, Row } from "react-bootstrap";
import AddCircle from "../../assets/images/add_circle.svg";
import constants, { PROJECT_PREFIX, pageSetting, pageSizeModel, tickerStatus } from "../../constants/constants";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import BlockIcon from "@mui/icons-material/Block";
import FileUploadIcon from "../../assets/images/file-upload.svg";
import { getServiceComplaintList } from "../../services/ticketService";
import moment from "moment";
import ExportServiceComplaintData from "./exportData";

import { selectAuthentication } from "../../features/Auth/authSlice";
import ImportHistoryData from "./importData";

const ServiceWarrantyList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  }); 
  const [exportPopup, setExportPopup] = useState(false);
  const [filterHistoryData, setFileterHistoryData] = useState([]);
  const [isImportPopupOpen, setImportPopupOpen] = useState(false);
  const {user }:any = useSelector(selectAuthentication);
  const userId = user.user.id;

  const addNewComplaint = () => {
    navigate("add");
  };

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/service-warranty/edit/${id}`);
  };

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/service-warranty/view/${id}`);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getServiceComplaintList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
        setFileterHistoryData(response.data?.ticketsSummary);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if(filterModel.items[0].field === "ticket_no"){
        filterModel.items[0].value = filterModel.items[0].value.replace(/[C-]/g, '');
      }

      if(filterModel.items[0].field === "$project.project_no$"){
        const regex = new RegExp(`[${PROJECT_PREFIX}]`, 'g');
        filterModel.items[0].value = filterModel.items[0].value.replace(regex, '');
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []); 

  const columns: GridColDef[] = [
    {
      field: "ticket_no",
      headerName: "Ticket Id",
      flex: 1,
      sortable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params) {
        return (
          <span title={params.row.ticket_no_str}>
            {params.row.ticket_no_str}
          </span>
        );
      },
    },
    {
      field: "title",
      headerName: "Subject",
      flex: 2,
      sortable: true,
    },
    {
      field: "$project.project_no$",
      headerName: "Project Order No.",
      flex: 1,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params) {
        return (
          <span title={params?.row?.project?.project_no_str ? params.row.project.project_no_str : (params?.row?.historicalProject?.order_no ? params.row.historicalProject.order_no : "")}>{params?.row?.project?.project_no_str ? params.row.project.project_no_str : (params?.row?.historicalProject?.order_no ? params.row.historicalProject.order_no : "")}</span>
        )
      },
    },
    {
      field: "$project.name$",
      headerName: "Customer Name",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params?.row?.project?.name ? params.row.project.name : (params?.row?.historicalProject?.customer_name ? params.row.historicalProject.customer_name : "")}>{params?.row?.project?.name ? params.row.project.name : (params?.row?.historicalProject?.customer_name ? params.row.historicalProject.customer_name : "")}</span>
        )
      },
    },
    {
      field: "$project.phone$",
      headerName: "Phone",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params?.row?.project?.phone ? params.row.project.phone : (params?.row?.historicalProject?.telephone ? params.row.historicalProject.telephone : "")}>{params?.row?.project?.phone ? params.row.project.phone : (params?.row?.historicalProject?.telephone ? params.row.historicalProject.telephone : "")}</span>
        )
      },
    },
    {
      field: "date",
      headerName: "Service Log Date",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.date),
      renderCell(params) {
        const orderDate = new Date(params.row.date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      type: "date",
      flex: 0.8,
      sortable: true,
      valueGetter: (params) => new Date(params.row.due_date),
      renderCell(params) {
        const orderDate = new Date(params.row.due_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "$assignedUser.full_name$",
      headerName: "Assignee",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row?.assignedUser?.full_name}>{params.row?.assignedUser?.full_name}</span>
        )
      },
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type: "singleSelect",
      valueOptions: tickerStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf"
      ),
      renderCell(params) {
        const statusName = tickerStatus.find(
          (item) => item.key == params.row.status
        );
        return (
          <span
            title={statusName?.value}
            className={`py-1 px-2 ms-0 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
          >
            {statusName?.value}
          </span>
        );
      },
    }
  ];

  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.SERVICE_WARRANTY_MANAGEMENT
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.SERVICE_WARRANTY_MANAGEMENT
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.SERVICE_WARRANTY_MANAGEMENT
    );

    if (
      checkEditAbilityCondition ||
      checkViewAbilityCondition ||
      checkDeleteAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          const gridActions = [];
          if (checkEditAbilityCondition && row.status !=2 && ((row.created_by === userId && user.user?.employeeDetails) || (user.user?.employeeDetails === null))) {
            gridActions.push(
              <GridActionsCellItem
                label="Edit"
                icon={<EditIcon />}
                className="text-primary"
                showInMenu={false}
                onClick={handleEditClick(id)}
              />
            );
          }
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                icon={<ViewIcon />}
                className="text-primary"
                showInMenu={false}
                onClick={handleViewClick(id)}
              />
            );
          }          
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu={false}
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setLimit(newPaginationModel.pageSize);
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  const historyDataColorClass = [ 'asset-card-open-ticket', 'asset-card-overdue-ticket', 'asset-card-close-ticket', 'asset-card-all-ticket']

  handleEditViewActionPermission();

  const handleImportHistoricalData = () => {    
    setImportPopupOpen(true);
  };

  const handleClose = () => {
    setImportPopupOpen(false);
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
          <div className="pageHeader px-0 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Service Warranty Management</h1>
              </Col>
              <Col className="text-end">
                <Button
                    className="fw-semibold fs-12 text-white w-40 btn blue-btn px-2 mx-2"
                    variant="info"
                    type="button"                    
                    title="Import Historical Data"
                    onClick={() => handleImportHistoricalData()}
                  >
                    <img src={FileSave} alt="File Save Icon" />   
                </Button>
                {exportPopup && (
                  <ExportServiceComplaintData
                    isOpen={exportPopup}
                    onClose={toggleExportPopup}                   
                    label="Export Service Logs"
                    text="Export Service Logs"
                  />
                )}
                <button
                  className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                  title="Extract Data"
                  onClick={toggleExportPopup}
                >
                  <img src={FileUploadIcon} alt="File Upload Icon" />
                </button>
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.SERVICE_WARRANTY_MANAGEMENT
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => addNewComplaint()}
                  >
                    <img
                      src={AddCircle}
                      className="me-2 fs-15"
                      alt="File Upload Icon"
                    />
                    Add Service Log
                  </Button>
                )}
              </Col>
            </Row>
          </div>
            <div className="asset-count row">
              { filterHistoryData.map((item:{
                key: any;title:string,value:number
}, index:number) => (
              <div className="col-md-3" key={`historyData_${index}`}>
                <div
                  className={`card p-4 mb-2 text-white asset-card ${historyDataColorClass[item.key] ? historyDataColorClass[item.key] : 'asset-card-all-ticket'}`}
                >
                  <div className="fs-16">{item.title}</div>
                  <div className="fs-25 fw-bold">{item.value}</div>
                </div>
              </div>
              ))}
            </div>
            <h1 className="fw-bold h4 my-2 fs-20 info-text">All Service Logs </h1>
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  className="border-0 rounded-2"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                />               
              </div>
            </Card>
          </div>
          {
            isImportPopupOpen && (
              <ImportHistoryData isOpen={isImportPopupOpen} onClose={handleClose}/>
            )
          }
        </div>
      )}
    </React.Fragment>
  );
};

export default ServiceWarrantyList;
