import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import InputTextArea from "../../../components/common/InputTextArea";
import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import {
  getAssetById,
  FormSelectType,
  assetApproveStatusDTO,
} from "../../../services/assetService";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { checkAbility } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import { approveFinanceDashboardPendingAssets } from "../../../services/financeService";
import { approveSchema } from "./validation";

export default function ApproveRejectAsset() {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [asset, setAsset] = useState<any>({});
  const navigate = useNavigate();
  let { id } = useParams();

  /**
   * Get existing asset from server
   * @async
   * @param {id} ID of Asset
   */
  const getAsset = async (id: string) => {
    setLoading(true);
    await getAssetById(id)
      .then((response: any) => {
        setAsset(response.data);
        if (response.asset_image_url) {
          setImagePreview(response.asset_image_url);
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {createAssetDTO, actions} values
   * @returns {*}
   */
  const submitForm = async (values: assetApproveStatusDTO, { setSubmitting }: any) => {
    console.log(values);
    const formData = {
      ...values,
      status : values.status == "approve" ? "in_inventory" : values.status
    }
    setLoading(true);
    if (id) {
      approveFinanceDashboardPendingAssets(id, formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/finance/dashboard");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);
          setImagePreview(null);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return;
   
  };

  useEffect(() => {
    if (id) {
      getAsset(id);
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Edit asset click handler
   */
  const handleEditClick = async () => {
    navigate(`/assets/edit-asset/${id}`);
  };

  const initialValues = {
    
    approve_reject_notes: asset?.approve_reject_notes ? asset?.approve_reject_notes : "",
    status: "",
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">Pending Assets Approval</h1>
              </Col>
              <Col className="text-end">
                {checkAbility(
                  PERMISSION_ACCESS.EDIT,
                  PERMISSION_MODULE.ASSET_MANAGEMENT
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => handleEditClick()}
                  >
                    <ModeEditIcon className="me-2 fs-15" />
                    Edit Asset
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Formik
              validationSchema={approveSchema}
              initialValues={initialValues}
              onSubmit={(values: assetApproveStatusDTO, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Card className="p-3 w-100 pageContentInner rounded-3">
                    <Row>
                      <div className="col-auto assets-approval-left">
                        <div className="assets-approval-img">
                        {imagePreview ? (
                          <Image
                            className="img-fluid"
                            src={imagePreview}
                            alt="Asset Image"
                          />
                        ) : (
                          <Image
                            className="img-fluid"
                            src={AddPhotoAlternate}
                            alt="No Image"
                          />
                        )} 
                        </div>                      
                      </div>
                      <div className="col-auto assets-approval-right">
                        <Row>
                          <Col>
                            <div className="mb-3 mt-2">
                              <label className=" fs-20 fw-bold  me-2 ">
                                {asset.name} {asset.asset_id}
                              </label>
                              <span className="asset-status-in-inventory">
                                {asset.type}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3} className="mb-2">
                            <label className="control-label fw-bold">
                              Invoice Number
                            </label>
                            <div className="fs-14">
                              {asset?.invoice_number ?? "-"}
                            </div>
                          </Col>
                          <Col md={3} className="mb-2">
                            <label className="control-label fw-bold">
                              Seriel Number
                            </label>
                            <div className="fs-14">
                              {asset?.serial_number ?? "-"}
                            </div>
                          </Col>
                          <Col md={3} className="mb-2">
                            <label className="control-label fw-bold">
                              Amount
                            </label>
                            <div className="fs-14">{asset?.amount ?? "-"}</div>
                          </Col>
                          <Col md={3} className="mb-2">
                            <label className="control-label fw-bold">
                              Vendor
                            </label>
                            <div className="fs-14">
                              {asset?.vendor_name ?? "-"}
                            </div>
                          </Col>
                          <Col md={12} className="mt-4">
                            <label className="control-label fw-bold">
                              Job Description
                            </label>
                            <div className="fs-14">
                              {asset?.description ?? "-"}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </Card>
                  <Card className="p-3 w-100 pageContentInner mt-2 rounded-3">
                    <Row>
                      <div className="col-md-12">
                        <InputTextArea
                          controlId="approve_reject_notes"
                          label="Approve / Denial Request"
                          placeholder="Enter Notes"
                          touched={touched.approve_reject_notes}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.approve_reject_notes}
                          name="approve_reject_notes"
                          value={values.approve_reject_notes}
                          className="max-height117 resize-none"
                        />
                      </div>
                    </Row>
                  </Card>

                  <div className="text-end mt-3 py-3 border-top">
                    
                    <CustomButton
                      type="submit"
                      name="submitType"
                      value="rejected"
                      loading={btnLoading}
                      className="fw-semibold fs-13 text-white mw-60 mt-2 me-2"
                      variant="danger"
                      onClick={()=>setFieldValue('status','rejected')}

                    >
                      Deny
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      name="submitType"
                      value="approve"
                      loading={btnLoading}
                      className="fw-semibold fs-13 text-white bg-green mw-60 mt-2"
                      variant="primary"
                      onClick={()=>setFieldValue('status','approve')}
                    >
                      Approve
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
