import React, { useEffect, useState } from "react";
import Loader from "../../components/common/Loader";
import { Card, Row, Col } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import contract from "../../assets/images/contract.svg";
import InfoIcon from "@mui/icons-material/Info";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate, useParams } from "react-router-dom";
import { getSurveyApi } from "../../services/survey/survey.service";
import { phoneMask } from "../../utility/common";
import constants, { PROJECT_PREFIX } from "../../constants/constants";
import moment from "moment";
interface ISurvey {
  id?: number;
  contract_id?: number;
  survey_date?: string;
  survey_time?: string;
  notes?: string;
  status?: string;
  project?: {
    id?: number;
    order_no?: string;
    address?: string;
    name?: string;
    phone?: string;
    contract_file?: string;
    project_no? : string;
  };
  updatedBy?: any
}

const ViewSurvey: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ISurvey>({});
  const navigate = useNavigate();
  let { id } = useParams();
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    getSurveyApi(id)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStartSurvery = () => {
    navigate("/survey/start-survey", { state: { survey_id: id } });
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader  px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-between">
              <div>
                <h1 className="fw-bold h4 my-2">
                  {data?.project?.name}
                  <span className="Onboarding-tag py-1 px-2 rounded-1 fs-14">
                    #{PROJECT_PREFIX}{data?.project?.project_no}
                  </span>
                </h1>
              </div>
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="p-3 w-100 pageContentInner mb-3">
              <div className="d-flex flex-column h-100 rounded-0 dataGridListMain">
                <Row className="d-flex">
                  <Col className="col-md-3 w-20">
                    <div className="mb-3">
                      <label className="fs-14 control-label fw-bold">
                        Address
                      </label>
                      <p style={{whiteSpace:'pre-line'}}> {data?.project?.address}</p>
                    </div>
                  </Col>
                  <Col className="col-md-3 w-20">
                    <div className="mb-3">
                      <label className="fs-14 control-label fw-bold">
                        Phone
                      </label>
                      <p> {phoneMask(data?.project?.phone)}</p>
                    </div>
                  </Col>
                  <Col className="col-md-3 w-20">
                    <div className="mb-3">
                      <label className="fs-14 control-label fw-bold">
                        Booked Date
                      </label>
                      <p>
                        {moment(data?.survey_date).format(
                          constants.dateFormats.slashSeparatedDate
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col className="col-md-3 w-20">
                    <div className="mb-3">
                      <label className="fs-14 control-label fw-bold">
                        Time
                      </label>
                      <p>{data?.survey_time}</p>
                    </div>
                  </Col>
                  <Col className="col-md-3 w-20">
                    <div className="mb-3">
                      <label className="fs-14 control-label fw-bold">
                        Prepared By:
                      </label>
                      <p>{data?.updatedBy?data?.updatedBy.full_name:"-"}</p>
                    </div>
                  </Col>
                </Row>
                <div className="row">
                  <Col className="col-md-3 w-20">
                    <a
                      href={`http://maps.google.com/?q=${data?.project?.address}`}
                      target="_blank"
                    >
                      <label className="fs-14 fw-bold cursor-pointer">
                        <LocationOnIcon className="text-primary" /> View
                        Location
                      </label>
                    </a>
                  </Col>
                  {data?.project?.contract_file && 
                  <Col className="col-md-3 w-20">
                    <a href={data?.project?.contract_file} target="_blank">
                      <label className="fs-14 fw-bold cursor-pointer">
                        <img src={contract} className="me-2 cursor-pointer" />
                        View Contract
                      </label>
                    </a>
                  </Col>}
                </div>
              </div>
            </Card>

            <Card className="p-3 w-100 pageContentInner">
              <div className="d-flex flex-column h-100 rounded-0 ">
                <div className="d-flex justify-content-between">
                  <div className="">
                    <div className="fs-18 info-text fw-bold">
                      Requirement Details
                    </div>
                    <p className="fs-14 control-label">What customer shared</p>
                  </div>
                  <div className="">
                    <a
                      href="javascript:void(0)"
                      className="fs-14 fw-bold text-black text-decoration-none"
                    >
                      <InfoIcon className="text-primary me-2" />
                      About Survey
                    </a>
                  </div>
                </div>

                <p className="fs-14">{data?.notes ? data?.notes : "-"}</p>
              </div>
            </Card>
            <div className="d-flex justify-content-end mt-3">
              <a href={`tel:${data?.project?.phone}`} target="_blank">
                <button className="btn btn-primary text-white me-3 green-bg-survery">
                  <CallIcon className="me-2 fs-17" />
                  Call Customer
                </button>
              </a>
              <button
                className="btn btn-primary text-white"
                onClick={handleStartSurvery}
              >
                Start Survey
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ViewSurvey;
