import * as yup from "yup";
import {  
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,    
  MAXIMUM_500_CHAR,
  INVALID_DATE,
} from "../../../validations/ValidationErrors";

export const complaintSchema = yup.object({
  contract_id: yup
    .object()
    .required()    
    .label("Project Order Number"),
  historical_project_id: yup
  .number()
  .optional()
  .nullable(),
  title: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(255, MAXIMUM_255_CHAR)
    .label("Service Log title"),
  description: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .label("Description"),  
  assigned_to: yup
    .object()
    .required()    
    .label("Assignee"),
  task_owner_id: yup
    .object()
    .label("Project Task Owner")
    .when("historical_project_id", {
    is: (historical_project_id: any) => historical_project_id,
    then: (schema) => schema.optional().nullable(),
    otherwise: (schema) => schema.required()
    /* .when("contract_id", {
    is: (contract_id: any) => contract_id?.is_historical == true,
    then: (schema) => schema.optional().nullable(),
    otherwise: (schema) => schema.required() */
  }),
  due_date: yup
    .date()
    .required()    
    .typeError(INVALID_DATE)
    .label("Due Date"),
  department: yup
    .object()
    .required()    
    .label("Department"),
  task_owner_name: yup
    .string()
    .label("Task Owner Name")
    .when("historical_project_id", {
      is: (historical_project_id: any) => historical_project_id,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable()
  }),
  new_task_owner_name: yup
  .string()
  .label("Project Owner Name")
  .when("task_owner_id", {
    is: (task_owner_id: any) => task_owner_id?.id === "_OTHER_",
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional().nullable()
   })
});