import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";

import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import {
  getEmployeeList,
  updateEmployeeStatusApi,
} from "../../services/employeeService";
import InputSwitchBox from "../../components/common/InputSwitchBox";
import { Card } from "react-bootstrap";
import AddCircle from "../../assets/images/add_circle.svg";
import constants, { PROJECT_PREFIX, pageSetting } from "../../constants/constants";
import {
  deleteFormApi,
  getFieldSheetApiCall,
} from "../../services/formbuilder/fieldsheet.services";
import moment from "moment";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import { getSurveyListApi } from "../../services/survey/survey.service";
import CompareAndFinish from "./Start_Survey/compareAndFinish";

const PreviousSurveyList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/survey/view-completed-survey/${id}`);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteFormApi(deletedId)
      .then((response: any) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const defaultParams = {
    type: "completed",
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  const convertDataToObjects = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      survey_date: new Date(item.survey_date),
    }));
  };

  const fetchData = (defaultParams: any) => {
    getSurveyListApi(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        const convertData = convertDataToObjects(response.data?.resultSet);
        setData(convertData);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      if(filterModel.items[0].field === "$project.project_no$"){
        const regex = new RegExp(`[${PROJECT_PREFIX}]`, 'g');
        filterModel.items[0].value = filterModel.items[0].value.replace(regex, '');
      }
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const getTagClass = (tag: string) => {
    switch (tag) {
      case "Conditional Onboarding":
        {
          return "Onboarding-tag";
        }
        break;
      case "Probation":
        {
          return "Probation-tag";
        }
        break;
      case "Permanent":
        {
          return "Permanent-tag";
        }
        break;
      case "Permanent":
        {
          return "Permanent-tag";
        }
        break;
      case "Notice Period":
        {
          return "Notice-Period-tag";
        }
        break;
      default:
        return "Onboarding-tag";
        break;
    }
  };
  const handleStatusChange = (data: any) => {
    const id = data.row.id;
    const formData = {
      status: data.row.status,
    };
    updateEmployeeStatusApi(id, formData)
      .then((response) => {
        setIsMutation(!isMutation);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      })
      .catch(() => {
        setIsMutation(!isMutation);
      })
      .finally(() => {
        setIsMutation(!isMutation);
      });
  };

  const columns: GridColDef[] = [
    {
      field: "$project.project_no$",
      headerName: "Project Order No",
      flex: 1,
      sortable: true,      
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals",
      ),
      renderCell(params){
        return `${PROJECT_PREFIX}${params.row.project?.project_no}`
      }
    },
    {
      field: "survey_date",
      headerName: "Booked Date",
      flex: 1,
      sortable: true,
      type: "date",
      renderCell(params) {
        return (
          <span className={`py-1 px-2 rounded- d-inline-block fs-11`}>
            {moment(params.row.survey_date).format(
              constants.dateFormats.slashSeparatedDate
            )}
          </span>
        );
      },
    },
    {
      field: "survey_time",
      headerName: "Time",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span className={`py-1 px-2 rounded- d-inline-block fs-11`}>
            {moment(params.row.survey_time, 'HH:mm:ss').format('HH:mm')}
          </span>
        );
      },
    },
    {
      field: "$project.name$",
      headerName: "Name",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span className={`py-1 px-2 rounded- d-inline-block fs-11`}>
            {params.row.project?.name}
          </span>
        );
      },
    },
    {
      field: "initiated_date",
      headerName: "Survey Confirmed",
      flex: 1,
      sortable: true,
      type: "date",
      valueGetter: (params) => {
        return new Date(params.row.initiated_date);
      },
      renderCell(params) {
        return (
          <span className={`py-1 px-2 rounded- d-inline-block fs-11`}>
            {moment(params.row.initiated_date).format(
              constants.dateFormats.slashSeparatedDate
            )}
          </span>
        );
      },
    },
    {
      field: "completed_on",
      headerName: "Completed",
      flex: 1,
      sortable: true,
      type: "date",
      valueGetter: (params) => {
        return new Date(params.row.completed_on);
      },
      renderCell(params) {
        return (
          <span className={`py-1 px-2 rounded- d-inline-block fs-11`}>
            {moment(params.row.completed_on).format(
              constants.dateFormats.slashSeparatedDate
            )}
          </span>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      filterable: false,
      renderCell(params) {
        return (
          <span
            className={`py-1 px-2 rounded-1 d-inline-block fs-11 bg-primary text-white `}
          >
            Completed
          </span>
        );
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => [
        <div className="table-tags d-flex">
          {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.SURVEY) && (
            <div
              className="me-3 cursor-pointer"
              onClick={handleViewClick(id)}
              title="View complated survey"
            >
              <svg
                width="22"
                height="15"
                viewBox="0 0 22 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 12C12.25 12 13.3125 11.5625 14.1875 10.6875C15.0625 9.8125 15.5 8.75 15.5 7.5C15.5 6.25 15.0625 5.1875 14.1875 4.3125C13.3125 3.4375 12.25 3 11 3C9.75 3 8.6875 3.4375 7.8125 4.3125C6.9375 5.1875 6.5 6.25 6.5 7.5C6.5 8.75 6.9375 9.8125 7.8125 10.6875C8.6875 11.5625 9.75 12 11 12ZM11 10.2C10.25 10.2 9.6125 9.9375 9.0875 9.4125C8.5625 8.8875 8.3 8.25 8.3 7.5C8.3 6.75 8.5625 6.1125 9.0875 5.5875C9.6125 5.0625 10.25 4.8 11 4.8C11.75 4.8 12.3875 5.0625 12.9125 5.5875C13.4375 6.1125 13.7 6.75 13.7 7.5C13.7 8.25 13.4375 8.8875 12.9125 9.4125C12.3875 9.9375 11.75 10.2 11 10.2ZM11 15C8.56667 15 6.35 14.3208 4.35 12.9625C2.35 11.6042 0.9 9.78333 0 7.5C0.9 5.21667 2.35 3.39583 4.35 2.0375C6.35 0.679167 8.56667 0 11 0C13.4333 0 15.65 0.679167 17.65 2.0375C19.65 3.39583 21.1 5.21667 22 7.5C21.1 9.78333 19.65 11.6042 17.65 12.9625C15.65 14.3208 13.4333 15 11 15Z"
                  fill="#21AC96"
                />
              </svg>
            </div>
          )}
        </div>,
      ],
    },
  ];

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  // useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     pageInfo?.totalRowCount !== undefined
  //       ? pageInfo?.totalRowCount
  //       : prevRowCountState,
  //   );
  // }, [pageInfo?.totalRowCount, setRowCountState]);
  const moveToSurvey = () => {
    navigate("/survey");
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader  px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-between">
              <div>
                <h1 className="fw-bold h4 my-2">Survey</h1>
              </div>
              <div className="d-flex align-items-center p-2 bg-white rounded-2">
                <div className="surver-tab " onClick={moveToSurvey}>
                  Current Survey
                </div>
                <div className="surver-tab active">Previous Survey </div>
              </div>
              <div>&nbsp;</div>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="w-100 pageContentInner">
              <div className="d-flex flex-column rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={[10, 20, 50, 100]}
                  className="border-0 rounded-2"
                />
                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Form"
                  text="Are you sure you want to delete this Form?"
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PreviousSurveyList;
