import React, { useEffect, useState } from "react";
import { Card, Col, Row, Image, Spinner } from "react-bootstrap";
import { MdHeight } from "react-icons/md";

import { Formik } from "formik";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";
import ProfileDummy from "../../../assets/images/profile-dummy.png";
import ProfileEditIcon from "../../../assets/images/profile-edit-icon.svg";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../components/common/InputText";
import { vendorSchema } from "./validation";
import { VendorData } from "./vendor.type";
import FormSelect from "../../../components/common/FormSelect";
import {
  commonMasking,
  vendorType,
  vendorCreditTerms,
} from "../../../constants/constants";
import CustomButton from "../../../components/common/CustomButton";
import { phoneMask } from "../../../utility/common";
import {
  createVendorApi,
  getVendorDetailsApi,
  updateVendorApi,
  uploadVendorImageApi,
} from "../../../services/vendorService";
import Loader from "../../../components/common/Loader";
import FileUploader from "../../../components/common/FileUploader";
interface ITableFilters {
  planData?: any;
  viewData?: any;
  filterByDate?: any;
  filterData?: any;
  setFilterData?: any;
}
/**
 * Data Table Common Reusable Component
 * @date 4/13/2023 - 1:56:39 PM
 *
 * @param {{ planData: any; viewData: any; filterByDate: any; filterData: any; setFilterData: any; }} {
    planData, viewData, filterByDate, filterData, setFilterData }
 * @returns {*}
 */

const planData = [
  { id: "1", name: "Plan B" },
  { id: "2", name: "Plan A" },
];
export const AddVendor = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [filterData, setFilterData] = useState({ search: "" });
  const [vendorData, setVendorData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
  ];
  const maxFileSizeMB = 1;

  let { id } = useParams();

  /**
   * Submit Method to call when vendor save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: VendorData, { setSubmitting }: any) => {
    const formData = {
      ...values,
    };
    //console.log(formData);
    //  return;
    setBtnLoading(true);
    if (id) {
      updateVendorApi(id, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/vendor");
          setBtnLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createVendorApi(values)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/vendor");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const getVendorDetails = (id: string) => {
    setLoading(true);
    getVendorDetailsApi(id)
      .then((response) => {
        setVendorData(response.data);
        if (response.vendorImage) {
          setImagePreview(response.vendorImage);
        }
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/vendor");

        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/vendor");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/vendor");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getVendorDetails(id);
    } else {
      setLoading(false);
    }
  }, []);

  const handleCancel = () => {
    navigate(-1);
  };

  const uploadProfileImage = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    //console.log("file", imageFile);

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${maxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setImageLoading(true);
      if (imageFile) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setImagePreview(reader.result);
        };

        reader.readAsDataURL(imageFile);
      } else {
        setImagePreview(null);
      }

      // Create FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append("image", imageFile);

      uploadVendorImageApi(formData)
        .then((response) => {
          setImageLoading(false);
          setFieldValue("vendor_image", response.data);
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };

  const initialValues: any = {
    vendor_name: vendorData?.vendor_name ? vendorData?.vendor_name : "",
    contact_person: vendorData?.contact_person
      ? vendorData?.contact_person
      : "",
    email: vendorData?.email ? vendorData?.email : "",
    vendor_address: vendorData?.vendor_address
      ? vendorData?.vendor_address
      : "",
    phone_number: vendorData?.phone_number ? vendorData?.phone_number : "",
    vendor_type: vendorData?.vendor_type
      ? vendorType.find((item) => item.key == vendorData?.vendor_type)
      : "",
    credit_terms: vendorData?.credit_terms
      ? vendorCreditTerms.find((item) => item.key == vendorData?.credit_terms)
      : "",
    vendor_image: vendorData?.vendor_image ? vendorData?.vendor_image : "",
    vendor_code: vendorData?.vendor_code ? vendorData.vendor_code : null,
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">{id ? "Edit" : "Add"} Vendor</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={vendorSchema}
              initialValues={initialValues}
              onSubmit={(values: VendorData, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <div className="col-md-8">
                            <Row>
                              <div className="col-md-6">
                                <InputText
                                  controlId="vendor_name"
                                  label="Vendor Company"
                                  placeholder="Enter vendor company name"
                                  touched={touched.vendor_name}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.vendor_name}
                                  value={values.vendor_name}
                                />
                              </div>
                              <div className="col-md-6">
                                <InputText
                                  controlId="contact_person"
                                  label="Contact Person"
                                  placeholder="Enter contact person name"
                                  touched={touched.contact_person}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.contact_person}
                                  value={values.contact_person}
                                />
                              </div>

                              <div className="col-md-6">
                                <InputText
                                  controlId="vendor_address"
                                  label="Vendor Address "
                                  placeholder="Enter address"
                                  touched={touched.vendor_address}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.vendor_address}
                                  value={values.vendor_address}
                                />
                              </div>
                              <div className="col-md-6">
                                <InputText
                                  controlId="vendor_code"
                                  label="Vendor Code"
                                  placeholder="Enter vendor code"
                                  touched={touched.vendor_code}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.vendor_code}
                                  value={values.vendor_code}
                                />
                              </div>
                              <div className="col-md-6">
                                <InputText
                                  controlId="email"
                                  label="Email/Website"
                                  placeholder="Enter email/website"
                                  touched={touched.email}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.email}
                                  value={values.email}
                                />
                              </div>
                              <div className="col-md-6">
                                <InputText
                                  controlId="phone_number"
                                  label="Phone Number"
                                  placeholder="Enter phone number"
                                  touched={touched.phone_number}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.phone_number}
                                  value={phoneMask(values.phone_number)}
                                  mask={commonMasking}
                                />
                              </div>
                              <div className="col-md-6">
                                <FormSelect
                                  placeholder="Select Manual/Website"
                                  label="Vendor Type"
                                  name="vendor_type"
                                  options={vendorType}
                                  getOptionLabel={(option: any) => option.value}
                                  getOptionValue={(option: any) => option.key}
                                  onChange={(name: string, value: string) => {
                                    setFieldValue(name, value);
                                  }}
                                  value={values.vendor_type}
                                  error={errors.vendor_type}
                                  touched={touched.vendor_type}
                                />
                              </div>
                              <div className="col-md-6">
                                <FormSelect
                                  placeholder="Select Credit Terms"
                                  label="Credit Terms"
                                  name="credit_terms"
                                  options={vendorCreditTerms}
                                  getOptionLabel={(option: any) => option.value}
                                  getOptionValue={(option: any) => option.key}
                                  onChange={(name: string, value: string) => {
                                    setFieldValue(name, value);
                                  }}
                                  value={values.credit_terms}
                                  error={errors.credit_terms}
                                  touched={touched.credit_terms}
                                />
                              </div>
                            </Row>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 custom-from-group">
                              <label className="lh-sm control-label fs-14 fw-bold form-label">
                                Profile Photo
                              </label>
                              <div className="position-relative ">
                              <FileUploader uploadProfileImage={(e) => uploadProfileImage(e, setFieldValue)} imageLoading={imageLoading} imagePreview={imagePreview} />
                            
                              </div>
                            </div>
                          </div>
                        </Row>
                        <Row></Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <div
                            className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                            onClick={handleCancel}
                          >
                            Cancel
                          </div>

                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={imageLoading || btnLoading}
                            className="fw-semibold fs-13 text-white mw-90 mt-2"
                            variant="primary"
                          >
                            {id ? "Update" : "Add"}
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
