import React, { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import {
  getDealList,  
  convertToProjectApi,
  syncDealsFromHubSpot
} from "../../../services/dealService";
import Loader from "../../../components/common/Loader";
import FileUploadIcon from "../../../assets/images/file-upload.svg";
import CachedIcon from "@mui/icons-material/Cached";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DeletePopup from "../../../components/common/deletePopup";
import { toast } from "react-toastify";
import AddCircle from "../../../assets/images/add_circle.svg";
import { Card, Col, Row } from "react-bootstrap";
import constants, {
  dealStatus,
  pageSetting,
  pageSizeModel,  
} from "../../../constants/constants";
import DeleteDealPopup from "../deleteDealPopup";
import moment from "moment";
import ExportData from "./exportData";
import { checkAbility, currencyMask } from "../../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../../constants/permissionUser";
import BlockIcon from "@mui/icons-material/Block"

interface RoleOption {
  key: string;
  value: string;
}

interface IDeletePopup {  
  deleteRemark: string;
}
const DealsList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null); 
  const [deletedData, setDeletedData] = useState<IDeletePopup | null>()
  const [exportPopup,setExportPopup] = useState(false);
  

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [isConvertPopupOpen, setConvertPopupOpen] = useState(false);
  const [popupLabel, setPopupLabel] = useState("");
  const [popupText, setPopupText] = useState("");

  const addLink = () => {
    navigate(nameBasedProtectedRoutes.addLink.path);
  };

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/deals/edit/${id}`);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setPopupLabel("Archive Deal");
    setPopupText("Are you sure you want to archive this deal?");    
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleConvertClick = (id: GridRowId) => () => {
    setPopupLabel("Convert Deal to Project");
    setPopupText("Are you sure you want to convert this deal to project?");    
    setConvertPopupOpen(true);
    setDeletedId(id);
  };

  const syncDealsFromHubspot = () => {   
    syncDealsFromHubSpot()
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });        
        setIsMutation(!isMutation);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });   
  };

  const converToProject = () => {
    setLoading(false);
  //  console.log("converToProject");
    //return;
    convertToProjectApi(deletedId)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setConvertPopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(error.response.data.message, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleClose = () => {
    setDeletePopupOpen(false);
    setConvertPopupOpen(false);
    setDeletedId(null);
    setIsMutation(!isMutation);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };

  const fetchData = (defaultParams: any) => {
    getDealList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
   // console.log("filterModel", filterModel);

    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);


  const columns: GridColDef[] = [
    {
      field: "order_no",
      headerName: "Order No.",
      flex: 1,
      sortable: true,
      hideable: false,
      renderCell(params) {
        return params.row?.order_no ? (
          <span title={params.row?.order_no}>{params.row?.order_no}</span>
        ) : (
          "-"
        );
      },
    },
    { field: "name", headerName: "Customer", flex: 1, sortable: true },
    {
      field: "contract_type",
      headerName: "Contract Type",
      flex: 1,
      sortable: true,
    },
    {
      field: "order_date",
      headerName: "Order Date",
      type: "date",
      flex: 1,
      sortable: true,
      valueGetter: (params) => new Date(params.row.order_date),
      renderCell(params) {
        const orderDate = new Date(params.row.order_date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "amount",
      type: "number",
      headerName: "Order Value",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.amount ? (
          <span title={params.row?.amount}>
            {currencyMask(params.row?.amount)}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      field: "$project_type.name$",
      headerName: "Project Type ",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.project_type.name}>
            {params.row.project_type.name}
          </span>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.phone ? (
          <span title={params.row?.phone}>{params.row?.phone}</span>
        ) : (
          "-"
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.address ? (
          <span title={params.row?.address}>{params.row?.address}</span>
        ) : (
          "-"
        );
      },
    },
    {
      field: "$postal_code.code$",
      headerName: "Postal Code",
      flex: 1,
      sortable: true,
      renderCell(params) {
        return params.row?.postal_code?.code ? (
          <span title={params.row?.postal_code?.code}>
            {params.row.postal_code.code}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type:"singleSelect",
      valueOptions: dealStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        const statusName = dealStatus.find(
          (item) => item.key == params.row.status
        );
        return (
          <span
            title={params.row.status}
            className={`py-1 px-2 rounded-1 ${
              params.row.status == "ca"
                ? "Permanent-tag"
                : "Probation-tag"
            } d-inline-block fs-11`}
          >
            {statusName?.value}
          </span>
        );
      },
    },
  ];
  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.DEALS
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.DEALS
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.DEALS
    );   

    if (
      checkEditAbilityCondition ||      
      checkDeleteAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];
          if (checkEditAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
              label="Convert To Project"
              icon={<CachedIcon />}
              showInMenu
              onClick={handleConvertClick(id)}
              />,
              <GridActionsCellItem
                label="Edit Deal"
                icon={<EditIcon />}
                showInMenu
                onClick={handleEditClick(id)}
              />,
            );
          }         
          if (checkDeleteAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
              label="Delete"
              icon={<DeleteIcon />}
              showInMenu
              onClick={handleDeleteClick(id)}
            />,
            );
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if(sortModel[0] && sortModel[0].field && sortModel[0].sort){
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
    
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]); 

  const addDeal = () => {
    navigate('add');
  };

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }
  handleEditViewActionPermission();

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {exportPopup && <ExportData isOpen={exportPopup} onClose={toggleExportPopup} exportType="deal" label="Export Deals" text="Export Deals" />} 
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Deals</h1>
                <p className="fs-12 mb-0">Ready for contract finalisation</p>
              </Col>
              <Col className="text-end">
               
                <button title="Sync Deals from Hubspot" onClick={() => syncDealsFromHubspot()} className="fw-semibold fs-12  btn btn-outline-info me-2 px-2 btn-outline-secondary-hover">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.50002 3.16675V0.791748L6.33335 3.95842L9.50002 7.12508V4.75008C12.1204 4.75008 14.25 6.87967 14.25 9.50008C14.25 10.2997 14.0521 11.0597 13.6959 11.7167L14.8517 12.8726C15.4692 11.8988 15.8334 10.743 15.8334 9.50008C15.8334 6.00092 12.9992 3.16675 9.50002 3.16675ZM9.50002 14.2501C6.8796 14.2501 4.75002 12.1205 4.75002 9.50008C4.75002 8.7005 4.94794 7.9405 5.30419 7.28342L4.14835 6.12758C3.53085 7.10133 3.16669 8.25717 3.16669 9.50008C3.16669 12.9992 6.00085 15.8334 9.50002 15.8334V18.2084L12.6667 15.0417L9.50002 11.8751V14.2501Z"
                      fill="#21426B"
                    />
                  </svg>
                </button>
                <button className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2" title="Export Deals" onClick={toggleExportPopup}>
                  <img src={FileUploadIcon} alt="File Upload Icon" />
                </button>
                {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.DEALS
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => addDeal()}
                  >
                    <img
                      src={AddCircle}
                      className="me-2 fs-15"
                      alt="File Upload Icon"
                    />
                    Add New Deal
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  disableRowSelectionOnClick
                  pageSizeOptions={pageSizeModel}
                  className="border-0 rounded-2"
                />
                {/* this popup will use for convert to project */}
                <DeletePopup
                  isOpen={isConvertPopupOpen}
                  onClose={handleClose}
                  onDelete={converToProject}
                  label={popupLabel}
                  text={popupText}
                  actionType="info"
                />
                <DeleteDealPopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  deletedData={deletedData}
                  deletedId={deletedId}
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DealsList;
