import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../../components/common/InputText";
import FormSelect from "../../../components/common/FormSelect";
import CustomButton from "../../../components/common/CustomButton";
import Loader from "../../../components/common/Loader";
import constants, {
  PROJECT_PREFIX,
  commonMasking,
  dealContractType,
  departmentCheck,  
} from "../../../constants/constants";
import { phoneMask } from "../../../utility/common";
import {
  getProductTypeList,
} from "../../../services/dealService";
import { getPostalCodes } from "../../../services/postalcode.service";
import InputTextArea from "../../../components/common/InputTextArea";
import InputDatePicker from "../../../components/common/InputDatePicker";
import { toast } from "react-toastify";
import moment from "moment";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";
import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import { projectSchema } from "./validation";
import { getProjectDetails, updateProjectApi } from "../../../services/project.service";
import ProjectDocumentList from "../View/documentList";
import { getAllManagerList } from "../../../services/authService";
import InputCurrencyText from "../../../components/common/InputCurrencyText";
import DeletePopup from "../../../components/common/deletePopup";

export const EditProject = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [projectData, setProjectData] = useState<any>({});
  const [productTypeData, setProductTypeData] = useState<any>({});
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();

  const confirmOptions = [
    {key : true, value : 'Yes'},
    {key : false, value : 'No'},
  ]

  /**
   * Submit Method to call when user save button
   * @async
   * @param values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
    //  const values = projectData;
    //  console.log(values);
    if(values?.contract_received_date && values?.contract_posted_date){
      const dayDiff = moment(values?.contract_received_date).diff(moment(values?.contract_posted_date),'days');
      console.log(dayDiff);
      if(dayDiff >=0 && dayDiff <= 14){
        setDeletePopupOpen(true)
        return;
      }
    }
    
    updateProjectData(values);
    return;
  }; 
  const updateProjectData = async (values: any) => {
    //  const values = projectData;
    //  console.log(values);
    const formData = {
      ...values,
      warranty_expiry_date: moment(values.warranty_expiry_date).isValid() ? moment(values.warranty_expiry_date).format(constants.dateFormats.databaseFormat) : null, 
      planned_completion_date: moment(values.planned_completion_date).isValid() ? moment(values.planned_completion_date).format(constants.dateFormats.databaseFormat) : null,
      planned_start_date: moment(values.planned_start_date).isValid() ? moment(values.planned_start_date).format(constants.dateFormats.databaseFormat) : null,
      survey_complete_date: moment(values.survey_complete_date).isValid() ? moment(values.survey_complete_date).format(constants.dateFormats.databaseFormat) : null,
      survey_plan_date: moment(values.survey_plan_date).isValid() ? moment(values.survey_plan_date).format(constants.dateFormats.databaseFormat) : null,
      contract_received_date: moment(values.contract_received_date).isValid() ? moment(values.contract_received_date).format(constants.dateFormats.databaseFormat) : null,
      contract_date: moment(values.contract_date).isValid() ? moment(values.contract_date).format(constants.dateFormats.databaseFormat) : null,    
      installation_confirm_date: moment(values.installation_confirm_date).isValid() ? moment(values.installation_confirm_date).format(constants.dateFormats.databaseFormat) : null,    
      is_install_date_confirmed: values.installation_confirm_date ? true : false,
      delivery_call_date: moment(values.delivery_call_date).isValid() ? moment(values.delivery_call_date).format(constants.dateFormats.databaseFormat) : null,
      mid_installation_call_date: moment(values.mid_installation_call_date).isValid() ? moment(values.mid_installation_call_date).format(constants.dateFormats.databaseFormat) : null,
      post_installation_review_call_date: moment(values.post_installation_review_call_date).isValid() ? moment(values.post_installation_review_call_date).format(constants.dateFormats.databaseFormat) : null,
    };
    // return;
   
    if (id) {
      setBtnLoading(true);
      updateProjectApi(id, formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/projects");
         // setLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
         // setLoading(false);
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      navigate("/projects");
    }
    return;
  }; 

  useEffect(() => {
    fetchProductType().then(() => {
        if (id) {
          fetchProjectDetails(id);
        } else {
          setLoading(false);
        }
      });    
  }, [id]);
 

  const fetchProjectDetails = async (id: any) => {
    setLoading(true);
    getProjectDetails(id)
      .then((response) => {
        if(response.data?.project.status == 'cp'){
          navigate("/projects");
        }
        setProjectData(response.data?.project);        
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // fetch product type data to set in the listing
  const fetchProductType = async () => {
    return new Promise((resolve, reject) => {
      getProductTypeList().then((response) => {
        const productTypeOptions = response.data?.map((item: any) => ({
          key: String(item.id),
          value: item.name,
        }));
        setProductTypeData(productTypeOptions);
        return resolve(true);
      });
    });
  };

  const getPostalCode = (params: any) => {
    return new Promise((resolve, reject) => {
      getPostalCodes(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  }; 
 const initialValues = {
    name: projectData?.name ? projectData?.name : "",
    email: projectData?.email ? projectData?.email : "",
    phone: projectData?.phone ? projectData?.phone : "",
    address: projectData?.address ? projectData?.address : "",
    city: projectData?.projectDetails?.city
      ? projectData?.projectDetails?.city
      : "",
    state: projectData?.projectDetails?.state
      ? projectData?.projectDetails?.state
      : "",
    postal_code: projectData?.postal_code ? projectData?.postal_code : null,
    contract_type: projectData?.contract_type
      ? dealContractType.find((item) => item.key == projectData?.contract_type)
      : null,
    project_type: projectData?.project_type
      ? productTypeData.find(
          (item: any) => item.key == projectData?.project_type_id
        )
      : null,
    amount: projectData?.amount ? projectData?.amount : "",
    paid_amount: projectData?.paid_amount ? projectData?.paid_amount : "",
    sales_rep: projectData?.sales_rep_id ? projectData?.sales_rep : null,
    is_cdm_completed:
      projectData?.projectDetails?.is_cdm_completed !== null
        ? confirmOptions.find(
            (item) => item.key == projectData?.projectDetails?.is_cdm_completed
          )
        : null,
    scheduled_days_to_complete:
      (projectData?.projectDetails?.survey_complete_date &&
      projectData?.projectDetails?.survey_plan_date)
        ? moment(projectData?.projectDetails?.survey_complete_date).diff(
            projectData?.projectDetails?.survey_plan_date,
            "days"
          )
        : "",
    completion_payment_collected: projectData?.projectDetails
      ?.is_completion_payment_collected
      ? confirmOptions.find(
          (item) =>
            item.key ==
            projectData?.projectDetails?.is_completion_payment_collected
        )
      : confirmOptions.find((item) => item.key == false),
    day_since_order_date: moment().diff(projectData?.order_date, "days"),
    /* is_install_date_confirmed:
      projectData?.projectDetails?.is_install_date_confirmed !== null
        ? confirmOptions.find(
            (item) =>
              item.key == projectData?.projectDetails?.is_install_date_confirmed
          )
        : null, */
    is_install_date_confirmed:
      projectData?.projectDetails?.installation_confirm_date != null
          ? true
          : false,    
    is_interim_payment_ready:
      projectData?.projectDetails?.is_interim_payment_ready !== null
        ? confirmOptions.find(
            (item) =>
              item.key == projectData?.projectDetails?.is_interim_payment_ready
          )
        : null,
    is_warranty_cert_posted:
      projectData?.projectDetails?.is_warranty_cert_posted !== null
        ? confirmOptions.find(
            (item) =>
              item.key == projectData?.projectDetails?.is_warranty_cert_posted
          )
        : null,

    contract_posted_date: projectData?.projectDetails?.contract_posted_date
      ? moment(projectData?.projectDetails?.contract_posted_date)
      : null,
    contract_received_date: projectData?.projectDetails?.contract_received_date
      ? moment(projectData?.projectDetails?.contract_received_date)
      : null,
    survey_plan_date: projectData?.projectDetails?.survey_plan_date
      ? moment(projectData?.projectDetails?.survey_plan_date)
      : null,
    survey_complete_date: projectData?.projectDetails?.survey_complete_date
      ? moment(projectData?.projectDetails?.survey_complete_date)
      : null,
    planned_start_date: projectData?.projectDetails?.planned_start_date
      ? moment(projectData?.projectDetails?.planned_start_date)
      : null,
    planned_completion_date: projectData?.projectDetails
      ?.planned_completion_date
      ? moment(projectData?.projectDetails?.planned_completion_date)
      : null,
    warranty_expiry_date: projectData?.projectDetails?.warranty_expiry_date
      ? moment(projectData?.projectDetails?.warranty_expiry_date)
      : null,
    installation_confirm_date: projectData?.projectDetails?.installation_confirm_date
    ? moment(projectData?.projectDetails?.installation_confirm_date)
    : null,
    delivery_call_date: projectData?.projectDetails
      ?.delivery_call_date
      ? moment(projectData?.projectDetails?.delivery_call_date)
      : null,
    mid_installation_call_date: projectData?.projectDetails
      ?.mid_installation_call_date
      ? moment(projectData?.projectDetails?.mid_installation_call_date)
      : null,
    post_installation_review_call_date: projectData?.projectDetails
      ?.post_installation_review_call_date
      ? moment(projectData?.projectDetails?.post_installation_review_call_date)
      : null,
  };
  
    const getProjectOwner = (params: any) => {
    return new Promise((resolve, reject) => {
      getAllManagerList(params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };
  
  const updateScheduledDaysToComplete = (
    survey_complete_date: any,
    survey_plan_date: any,
    setFieldValue: any
  ) => {
    const calculateBusinessDays = (startDate: moment.Moment, endDate: moment.Moment) => {
      let daysCount = 0;
      let currentDate = startDate.clone();
  
      while (currentDate.isSameOrBefore(endDate)) {
        const dayOfWeek = currentDate.day();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude Sunday (0) and Saturday (6)
          daysCount++;
        }
        currentDate.add(1, 'day');
      }
  
      return daysCount;
    };
    const noDays = calculateBusinessDays(
      moment(survey_plan_date),
      moment(survey_complete_date)
    );
  
    setFieldValue('scheduled_days_to_complete', noDays);
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1 edit-project">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  Edit Project{" "}
                  <span className="Onboarding-tag py-1 px-2 rounded-1 fs-14">
                    {PROJECT_PREFIX}{projectData.project_no}
                  </span>
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={projectSchema}
              initialValues={initialValues}
              onSubmit={(values: any, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Card className="p-3 w-100 pageContentInner">
                        <h6 className="fs-18 fw-bold mb-3 info-text">
                          Personal Information
                        </h6>
                        <Row>
                          <Col className="col-md-4">
                            <InputText
                              controlId="name"
                              label="Name"
                              placeholder="Enter customer name"
                              touched={touched.name}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.name}
                              value={values.name}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="email"
                              label="Email"
                              placeholder="Enter customer Email"
                              touched={touched.email}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.email}
                              value={values.email}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="phone"
                              label="Phone Number"
                              placeholder="Enter Phone Number"
                              touched={touched.phone}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.phone}
                              value={phoneMask(values.phone)}
                              mask={commonMasking}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-4">
                            <div className="mb-3 custom-from-group">
                              <div className="position-relative ">
                                <InputTextArea
                                  controlId="address"
                                  label=" Address"
                                  placeholder="Enter complete address"
                                  touched={touched.address}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.address}
                                  name="address"
                                  value={values.address}
                                  className="form-control textarea-height"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="city"
                              label="City"
                              placeholder="Enter city"
                              touched={touched.city}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.city}
                              value={values.city}
                            />
                            <FormAsyncSelect
                              id="postal_code"
                              name="postal_code"
                              label="Postal Code"
                              isAsync
                              isClearable
                              getOptions={getPostalCode}
                              value={values.postal_code}
                              error={errors.postal_code}
                              touched={touched.postal_code}
                              onChange={(name: any, value: any) => {
                                setFieldValue(name, value);
                              }}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="state"
                              label="County"
                              placeholder="Enter County"
                              touched={touched.state}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.state}
                              value={values.state}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="mt-2 p-3 w-100 pageContentInner">
                        <h6 className="fs-18 fw-bold mb-3 info-text">
                          Project Details
                        </h6>
                        <Row>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Contract Type"
                              name="contract_type"
                              options={dealContractType}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.contract_type}
                              error={errors.contract_type}
                              touched={touched.contract_type}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Project Type"
                              name="project_type"
                              options={productTypeData}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.project_type}
                              error={errors.project_type}
                              touched={touched.project_type}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputCurrencyText
                              name="amount"
                              label="Order Value (£)"
                              placeholder="Enter order value"
                              value={values.amount}
                              onChange={(value, name) => {
                                setFieldValue("amount", value);
                              }}
                              touched={touched.amount}
                              errors={errors.amount}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-4">
                          <InputCurrencyText
                              name="paid_amount"
                              label="Deposit Amount"
                              placeholder="Enter Deposit Amount"
                              value={values.paid_amount}
                              onChange={(value, name) => {
                                setFieldValue("paid_amount", value);
                              }}
                              touched={touched.paid_amount}
                              errors={errors.paid_amount}
                            />                            
                          </Col>
                          <Col className="col-md-4">
                            <FormAsyncSelect
                              id="sales_rep_id"
                              name="sales_rep"
                              label="Home Assessor"
                              isAsync
                              isClearable
                              defaultFilter={{"department": departmentCheck.SA}}
                              getOptions={getProjectOwner}
                              value={values.sales_rep}
                              error={errors.sales_rep}
                              touched={touched.sales_rep}
                              onChange={(name: any, value: any) => {
                                setFieldValue(name, value);
                              }}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="CDM Completed & Filed"
                              name="is_cdm_completed"
                              options={confirmOptions}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.is_cdm_completed}
                              error={errors.is_cdm_completed}
                              touched={touched.is_cdm_completed}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-4">
                            <InputText
                              controlId="scheduled_days_to_complete"
                              label="Scheduled Days to Complete "
                              placeholder="Scheduled Days to Complete"
                              touched={touched.scheduled_days_to_complete}
                              isDisable={true}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.scheduled_days_to_complete}
                              value={values.scheduled_days_to_complete}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Completion Payment Collected"
                              name="completion_payment_collected"
                              options={confirmOptions}
                              isDisabled={true}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.completion_payment_collected}
                              error={errors.completion_payment_collected}
                              touched={touched.completion_payment_collected}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="day_since_order_date"
                              label="Days Since Order Date"
                              placeholder="Days Since Order Date"
                              isDisable={true}
                              touched={touched.day_since_order_date}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.day_since_order_date}
                              value={values.day_since_order_date}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-4">
                            {/* <FormSelect
                              placeholder="Select"
                              label="Installation Date Confirmed"
                              name="is_install_date_confirmed"
                              options={confirmOptions}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.is_install_date_confirmed}
                              error={errors.is_install_date_confirmed}
                              touched={touched.is_install_date_confirmed}
                            /> */}
                            <InputDatePicker
                                name="installation_confirm_date"
                                label="Installation Confirmed Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "installation_confirm_date",
                                  },
                                }}
                                value={values.installation_confirm_date}
                                onChange={(name, newValue) => 
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.installation_confirm_date}
                                errors={errors.installation_confirm_date}
                              />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Interim Payment Ready for Collection"
                              name="is_interim_payment_ready"
                              options={confirmOptions}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.is_interim_payment_ready}
                              error={errors.is_interim_payment_ready}
                              touched={touched.is_interim_payment_ready}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormSelect
                              placeholder="Select"
                              label="Warranty Certificate Logged and Posted"
                              name="is_warranty_cert_posted"
                              options={confirmOptions}
                              getOptionLabel={(option: any) => option.value}
                              getOptionValue={(option: any) => option.key}
                              onChange={(name: string, value: string) => {
                                setFieldValue(name, value);
                              }}
                              value={values.is_warranty_cert_posted}
                              error={errors.is_warranty_cert_posted}
                              touched={touched.is_warranty_cert_posted}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-8">
                      <Card className="mt-2 p-3 w-100 pageContentInner">
                        <h6 className="fs-18 fw-bold mb-3 info-text">
                          Important Dates
                        </h6>
                        <Row>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="contract_posted_date"
                                label="Contract Posted"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "contract_posted_date",
                                  },
                                }}
                                value={values.contract_posted_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.contract_posted_date}
                                errors={errors.contract_posted_date}
                              />
                            </div>
                          </Col>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="contract_received_date"
                                label="Contract Signed"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "contract_received_date",
                                  },
                                }}
                                value={values.contract_received_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.contract_received_date}
                                errors={errors.contract_received_date}
                              />
                            </div>
                          </Col>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="survey_plan_date"
                                label="Planned Survey Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "survey_plan_date",
                                  },
                                }}
                                isDisable={projectData?.survey?.id ? true : false}
                                value={values.survey_plan_date}
                                onChange={(name, newValue) => {
                                  setFieldValue(name, newValue);
                                 
                                }}
                                touched={touched.survey_plan_date}
                                errors={errors.survey_plan_date}
                              />
                            </div>
                          </Col>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="survey_complete_date"
                                label="Date Survey Completed"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "survey_complete_date",
                                  },
                                }}
                                isDisable={projectData?.survey?.id ? true : false}
                                value={values.survey_complete_date}
                                onChange={(name, newValue) => {
                                  setFieldValue(name, newValue);
                                   
                                }}
                                touched={touched.survey_complete_date}
                                errors={errors.survey_complete_date}
                              />
                            </div>
                          </Col>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="planned_start_date"
                                label="Planned Start Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "planned_start_date",
                                  },
                                }}
                                value={values.planned_start_date}
                                onChange={(name, newValue) => {
                                  setFieldValue(name, newValue);
                                  updateScheduledDaysToComplete(
                                    values.planned_completion_date,
                                    newValue,
                                    setFieldValue
                                  );
                                  /* updateScheduledDaysToComplete(
                                    newValue,
                                    values.planned_completion_date,
                                    setFieldValue
                                  ); */
                                }}
                                touched={touched.planned_start_date}
                                errors={errors.planned_start_date}
                              />
                            </div>
                          </Col>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="planned_completion_date"
                                label="Planned Completion Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "planned_completion_date",
                                  },
                                }}
                                value={values.planned_completion_date}
                                onChange={(name, newValue) => {
                                  setFieldValue(name, newValue);
                                  updateScheduledDaysToComplete(
                                    newValue,
                                    values.planned_start_date,
                                    setFieldValue
                                  );
                                }}
                                touched={touched.planned_completion_date}
                                errors={errors.planned_completion_date}
                              />
                            </div>
                          </Col>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="warranty_expiry_date"
                                label="Warranty Expiry Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "warranty_expiry_date",
                                  },
                                }}
                                value={values.warranty_expiry_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.warranty_expiry_date}
                                errors={errors.warranty_expiry_date}
                              />
                            </div>
                          </Col>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="delivery_call_date"
                                label="Confirmation of Delivery Call"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "delivery_call_date",
                                  },
                                }}
                                value={values.delivery_call_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.delivery_call_date}
                                errors={errors.delivery_call_date}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="mid_installation_call_date"
                                label="Mid Installation Call"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "mid_installation_call_date",
                                  },
                                }}
                                value={values.mid_installation_call_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.mid_installation_call_date}
                                errors={errors.mid_installation_call_date}
                              />
                            </div>
                          </Col>
                          <Col className="col-md-6">
                            <div className="mb-3 custom-from-group">
                              <InputDatePicker
                                name="post_installation_review_call_date"
                                label="Post Installation Review Call"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "post_installation_review_call_date",
                                  },
                                }}
                                value={values.post_installation_review_call_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.post_installation_review_call_date}
                                errors={errors.post_installation_review_call_date}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col className="col-md-4">
                      <Card className="mt-2 p-3 w-100 edit-project-export mb-3">
                        <ProjectDocumentList id={id} projectsData={projectData} />
                      </Card>
                    </Col>
                  </Row>
                  <div className="text-end my-3">
                    <CustomButtonCancel label="Cancel" />
                    <CustomButton
                      type="submit"
                      loading={btnLoading}
                      className="fw-semibold fs-13 text-white mw-60 mt-2 btn btn-primary"
                      variant="primary"
                      disabled={btnLoading}
                    >
                      Update
                    </CustomButton>
                  </div>
                  <DeletePopup
                        isOpen={isDeletePopupOpen}
                        onClose={() => setDeletePopupOpen(false)}
                        onDelete={() => updateProjectData(values)}
                        actionType="info"
                        label="Are You Sure"
                        text="Are you sure contract start date is within 14 days of the contract being signed?"
                      />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};
