import React, { useCallback, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,  
  GridFilterModel,
  getGridSingleSelectOperators
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { Card, Col, Row } from "react-bootstrap";
import constants, { PoPaymentStatus, PoStatus, pageSetting } from "../../../constants/constants";
import { currencyMask } from "../../../utility/common";
import {  getProductRecievedList } from "../../../services/productService";
import moment from "moment";
import { getVendorPoHistoryList, vendorPoHistoryExport } from "../../../services/vendorService";
import InputDateRangePicker from "../../../components/common/InputDateRangePicker";
import FileUploadIcon from "../../../assets/images/file-upload.svg";


interface AddProps {
  vendorId: string | number | undefined;
}

const VendorPoHistory: React.FC<AddProps> = ({vendorId}) => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });

  const [orderDateRange, setOrderDateRange] = useState<any>(null);

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    date:orderDateRange,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getVendorPoHistoryList(vendorId,defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "po_number",
      headerName: "PO Number",
      flex: 1,
      sortable: true,
      filterable: false,
      hideable: false,     
      renderCell(params){
        return <Link target="_blank"  to={`/purchase-orders/view/${params.row.id}`} className="blue-text fw-semibold text-decoration-none">{params.row.po_number_str}</Link>
      }
    },
    {
      field: "$project.project_no$",
      headerName: "Project Order No",
      flex: 1.5,
      sortable: true,
      filterable: false,
      renderCell(params){
        return params.row.project?.id ? params.row.project?.project_no_str : "-"
        // return params.row.project?.id ? (<Link target="_blank"  to={`/projects/view/${params.row.project?.id}`} className="blue-text fw-semibold text-decoration-none">{params.row.project?.project_no_str}</Link>) : "-"
      } 
    },
    {
      field: "date",
      headerName: "Order Date",
      type: "date",
      flex: 1,
      sortable: true,
      filterable: false,
      valueGetter: (params) => new Date(params?.row?.date),
      renderCell(params) {
        const orderDate = new Date(params?.row?.date);
        return (
          <span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>
        );
      },
    },
    {
      field: "received_date",
      headerName: "Received Date",
      type: "date",
      flex: 1,
      sortable: true,
      filterable: false,
      valueGetter: (params) => new Date(params?.row?.received_date),
      renderCell(params) {
        const orderDate = new Date(params?.row?.received_date);
        return (
          params?.row?.received_date ? 
          (<span>
            {moment(orderDate).format(constants.dateFormats.slashSeparatedDate)}
          </span>) : "-"
        );
      },
    },
    {
      field: "$orderProducts.qty$",
      headerName: "Qty",
      flex: 1,
      sortable: false,
      filterable: false,
      type:"number",
      renderCell(params) {
        return params.row?.orderProducts?.qty ? params.row?.orderProducts?.qty : "-"
       }, 
    },
    {
      field: "$orderProducts.amount$",
      headerName: "Amount",
      flex: 1,
      sortable: false,
      filterable: false,
      type:"number",
      renderCell(params) {
        return params.row?.orderProducts?.amount ? currencyMask(params.row?.orderProducts?.amount) : "-"
       }, 
    },    
    {
      field: "hasGoodsReturned",
      headerName: "Goods Returned",      
      flex: 1.5,
      sortable: false,
      filterable: false,     
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      filterable: false,
      type:"singleSelect",
      valueOptions: PoStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        if (params.row.status) {
          const statusName = PoStatus.find(
            (item) => item.key == params.row.status
          );
          return (
            <span
              title={statusName?.value}
              className={`py-1 ms-0 px-2 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
            >
              {statusName?.value}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "pay_status",
      headerName: "Payment Status",
      flex: 1,
      sortable: true,
      filterable: false,
      type:"singleSelect",
      valueOptions: PoPaymentStatus.map(({ value, key }) => ({
        label: value,
        value: key,
      })),
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === "isAnyOf",
      ),
      renderCell(params) {
        if (params.row.pay_status) {
          const statusName = PoPaymentStatus.find(
            (item) => item.key == params.row.pay_status
          );
          return (
            <span
              title={statusName?.value}
              className={`py-1 px-2 ms-0 rounded-1 ${statusName?.tagClass} d-inline-block fs-11`}
            >
              {statusName?.value}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
  ];
 
  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };


  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection, orderDateRange]);

  const orderDateFilter = (data: any) => {
    console.log("test test", data);
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(constants.dateFormats.databaseFormat);
      const endDate = moment(dataRange[1]).format(constants.dateFormats.databaseFormat);
      setOrderDateRange(`${startDate},${endDate}`);
    } else {
      setOrderDateRange("");
    }
   // setIsMutation!(isMutation);
  };

  const exportPOHistory = () => {
    let dateParam: any = {startDate: null, endDate: null};;
    if (orderDateRange) {
      const dateList = orderDateRange.split(",");
      if (dateList && dateList.length === 2) {
        let startDate = dateList[0];
        let endDate = dateList[1];
        dateParam = {startDate: startDate, endDate: endDate};
      }
    }
    setLoading(true);
    vendorPoHistoryExport(vendorId, dateParam)
      .then((response) => {
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${+new Date()}_vendor_po_history.xlsx`;
        a.click();
        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
    });
  }

  
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="flex-grow-1 pageContent pb-2">
            <Row className=" mb-2">
                <Col className="col-9">
                  <h1 className="fw-bold h4 fs-20 info-text pt-1 mt-0 mb-0">PO History</h1>
                </Col>
                <Col className="col-auto">
                  <button
                    className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                    title="Export Vendors"
                    onClick={exportPOHistory}
                  >
                    <img src={FileUploadIcon} alt="File Upload Icon" />
                  </button>
                </Col>
                <Col className="text-end">
                  <InputDateRangePicker controlId="dateRange" position="auto" label={""} placeholder="Date Range" handleChange={orderDateFilter}
                      handleClose={orderDateFilter} handleBlur={orderDateFilter} errorsField={false} touched={false} value={null}  />
                </Col>
              </Row>
            <Card className=" p-0 w-100 pageContentInner mb-3">
              
              <Row>
                <Col>
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  className="border-0 rounded-2"
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"   
                  disableRowSelectionOnClick             
                  pageSizeOptions={[5]}
                  localeText={{ noRowsLabel: "No Po Found" }}
                /> 
                </Col>
              </Row>
            </Card>
          </div>        
      )}
    </React.Fragment>
  );
};

export default VendorPoHistory;
