import React, { useEffect, useState } from "react";
import { Card, Col, Row, Image, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../components/common/InputText";
import { fleetSchema } from "./validation";
import { fleetDataDto } from "./fleet.type";
import FormSelect from "../../../components/common/FormSelect";
import {
  isActiveStatus,
  departmentOptions,
} from "../../../constants/constants";
import CustomButton from "../../../components/common/CustomButton";
import Loader from "../../../components/common/Loader";
import FileUploader from "../../../components/common/FileUploader";
import InputDatePicker from "../../../components/common/InputDatePicker";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";
import {
  createFleetApi,
  getFleetDetailsApi,
  updateFleetApi,
} from "../../../services/fleetService";
import InputCurrencyText from "../../../components/common/InputCurrencyText";
import moment from "moment";
interface ITableFilters {
  planData?: any;
  viewData?: any;
  filterByDate?: any;
  filterData?: any;
  setFilterData?: any;
}
/**
 * Data Table Common Reusable Component
 * @date 4/13/2023 - 1:56:39 PM
 *
 * @param {{ planData: any; viewData: any; filterByDate: any; filterData: any; setFilterData: any; }} {
    planData, viewData, filterByDate, filterData, setFilterData }
 * @returns {*}
 */

const categoryData = [  
  { key: "Car", value: "Car" },
  { key: "Van", value: "Van" },
];

const AssetTypeData = [
  { key: "Company Owned", value: "Company Owned" },
  { key: "Hire Vehicle", value: "Hire Vehicle" },
  { key: "Lease Hire", value: "Lease Hire" },
];

const FuelTypeData = [
  { key: "Petrol", value: "Petrol" },
  { key: "Diesel", value: "Diesel" },
  { key: "Electric", value: "Electric" },
  { key: "Hybrid", value: "Hybrid" },
];

const TypeData = [
  { key: "Car", value: "Car" },
  { key: "Van", value: "Van" },
];
export const AddFleet = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [fleetData, setFleetData] = useState<any>({});
  const navigate = useNavigate();

  let { id } = useParams();

  /**
   * Submit Method to call when fleet save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: fleetDataDto, { setSubmitting }: any) => {
    const formData = {
      ...values,
    };
    //console.log(formData);
    //  return;
    setBtnLoading(true);
    if (id) {
      updateFleetApi(id, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/fleet");
          setBtnLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createFleetApi(values)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/fleet");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const getFleetDetails = (id: string) => {
    setLoading(true);
    getFleetDetailsApi(id)
      .then((response) => {
        setFleetData(response.data);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/fleet");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/fleet");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/fleet");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getFleetDetails(id);
    } else {
      setLoading(false);
    }
  }, []);

  const handleCancel = () => {
    navigate(-1);
  };

  const initialValues: any = {
    registration: fleetData.registration ? fleetData.registration : "",
    registration_date: fleetData.registration_date ? moment(fleetData.registration_date) : null,
    active: typeof fleetData.active == "boolean" ? isActiveStatus.find((item) => item.key == fleetData.active) :  null,
    category: fleetData.category ? categoryData.find((item) => item.key == fleetData.category)  :  null,
    asset_type: fleetData.asset_type ? AssetTypeData.find((item) => item.key == fleetData.asset_type)  :  null,
    insurance_company: fleetData.vin ? fleetData.vin :  "",
    vin: fleetData.vin ? fleetData.vin :  "",
    fleet_number: fleetData.fleet_number ? fleetData.fleet_number :  "",
    current_mileage: fleetData.current_mileage ? fleetData.current_mileage :  null,
    current_mileage_date: fleetData.current_mileage_date ? moment(fleetData.current_mileage_date) :  null,
    make: fleetData.make ? fleetData.make :  "",
    model: fleetData.model ? fleetData.model :  "",
    ownership: fleetData.ownership ? fleetData.ownership :  "",
    service_due_mileage: fleetData.service_due_mileage ? fleetData.service_due_mileage :  null,
    service_interval_months: fleetData.service_interval_months ? fleetData.service_interval_months :  null,
    service_interval_mileage: fleetData.service_interval_mileage ? fleetData.service_interval_mileage :  null,
    engine_size_cc: fleetData.engine_size_cc ? fleetData.engine_size_cc :  null,
    fuel_type: fleetData.fuel_type ? FuelTypeData.find((item) => item.key == fleetData.fuel_type) :  null,
    /* type: fleetData.type ? TypeData.find((item) => item.key == fleetData.type) :  null, */
    site: fleetData.site ? fleetData.site :  "",
    department: fleetData.department ? departmentOptions.find((item) => item.key == fleetData.department) :  null,
    co2: fleetData.co2 ? fleetData.co2 :  null,
    cost_center: fleetData.cost_center ? fleetData.cost_center :  "",
    mot_due_date: fleetData.mot_due_date ? moment(fleetData.mot_due_date) :  null,
    roadtax_due_date: fleetData.roadtax_due_date ? moment(fleetData.roadtax_due_date) :  null,
    warranty_due_date: fleetData.warranty_due_date ? moment(fleetData.warranty_due_date) :  null,
    service_due_date: fleetData.service_due_date ? moment(fleetData.service_due_date) :  null,
    insurance_due_date: fleetData.insurance_due_date ? moment(fleetData.insurance_due_date) :  null,
    breakdown_due_date: fleetData.breakdown_due_date ? moment(fleetData.breakdown_due_date) :  null,
    deactivated_date: fleetData.deactivated_date ? moment(fleetData.deactivated_date) :  null,
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">
                  {id ? "Edit" : "Add New"} Fleet
                </h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={fleetSchema}
              initialValues={initialValues}
              onSubmit={(values: fleetDataDto, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Row>
                      <Col className="col-12">
                        <Card className="p-3 w-100 pageContentInner mb-3">                         
                          <Row>
                            <Col className="col-md-4">
                              <InputText
                                controlId="registration"
                                label="Registration"
                                placeholder="Enter Registration No"
                                touched={touched.registration}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.registration}
                                value={values.registration}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputDatePicker
                                name="registration_date"
                                label="Registration Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "registration_date",
                                  },
                                }}
                                value={values.registration_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.registration_date}
                                errors={errors.registration_date}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <FormSelect
                                placeholder="Select"
                                label="Active"
                                name="active"
                                options={isActiveStatus}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.active}
                                error={errors.active}
                                touched={touched.active}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <FormSelect
                                placeholder="Select"
                                label="Category"
                                name="category"
                                options={categoryData}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.category}
                                error={errors.category}
                                touched={touched.category}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <FormSelect
                                placeholder="Select"
                                label="Asset Type"
                                name="asset_type"
                                options={AssetTypeData}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.asset_type}
                                error={errors.asset_type}
                                touched={touched.asset_type}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <FormSelect
                                placeholder="Select"
                                label="Department"
                                name="department"
                                options={departmentOptions}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.department}
                                error={errors.department}
                                touched={touched.department}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputText
                                controlId="fleet_number"
                                label="Fleet Number "
                                placeholder="Enter Fleet Number"
                                touched={touched.fleet_number}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.fleet_number}
                                value={values.fleet_number}
                              />
                            </Col>
                            {
                              !id && (<>
                              <Col className="col-md-4">
                                <InputCurrencyText
                                  name="current_mileage"
                                  label="Current Mileage"
                                  placeholder="Enter Current Mileage"
                                  value={values.current_mileage}
                                  isInteger={true}
                                  onChange={(value, name) => {
                                    setFieldValue("current_mileage", value);
                                  }}
                                  touched={touched.current_mileage}
                                  errors={errors.current_mileage}
                                />
                              </Col>
                              <Col className="col-md-4">
                                <InputDatePicker
                                  name="current_mileage_date"
                                  label="Current Mileage Date"
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      placeholder: "Select",
                                      className: "form-control",
                                      readOnly: true,
                                      id: "current_mileage_date",
                                    },
                                  }}
                                  value={values.current_mileage_date}
                                  onChange={(name, newValue) =>
                                    setFieldValue(name, newValue)
                                  }
                                  touched={touched.current_mileage_date}
                                  errors={errors.current_mileage_date}
                                />
                              </Col>
                              </>)
                            }
                            <Col className="col-md-4">
                              <InputText
                                controlId="make"
                                label="Make "
                                placeholder="Enter Maker name"
                                touched={touched.make}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.make}
                                value={values.make}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputText
                                controlId="model"
                                label="Model"
                                placeholder="Enter Model"
                                touched={touched.model}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.model}
                                value={values.model}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputText
                                controlId="ownership"
                                label="Ownership "
                                placeholder="Enter Ownership"
                                touched={touched.ownership}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.ownership}
                                value={values.ownership}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputCurrencyText
                                name="service_due_mileage"
                                label="Service Due Mileage"
                                placeholder="Enter Service Due Mileage"
                                value={values.service_due_mileage}
                                isInteger={true}
                                onChange={(value, name) => {
                                  setFieldValue("service_due_mileage", value);
                                }}
                                touched={touched.service_due_mileage}
                                errors={errors.service_due_mileage}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputCurrencyText
                                name="service_interval_months"
                                label="Service Interval Months"
                                placeholder="Enter"
                                value={values.service_interval_months}
                                isInteger={true}
                                onChange={(value, name) => {
                                  setFieldValue(
                                    "service_interval_months",
                                    value
                                  );
                                }}
                                touched={touched.service_interval_months}
                                errors={errors.service_interval_months}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputCurrencyText
                                name="service_interval_mileage"
                                label="Service Interval Mileage"
                                placeholder="Enter"
                                value={values.service_interval_mileage}
                                isInteger={true}
                                onChange={(value, name) => {
                                  setFieldValue(
                                    "service_interval_mileage",
                                    value
                                  );
                                }}
                                touched={touched.service_interval_mileage}
                                errors={errors.service_interval_mileage}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputCurrencyText
                                name="engine_size_cc"
                                label="Engine Size Cc"
                                placeholder="Enter Engine Size"
                                value={values.engine_size_cc}
                                isInteger={true}
                                onChange={(value, name) => {
                                  setFieldValue("engine_size_cc", value);
                                }}
                                touched={touched.engine_size_cc}
                                errors={errors.engine_size_cc}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <FormSelect
                                placeholder="Select"
                                label="Fuel Type"
                                name="fuel_type"
                                options={FuelTypeData}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.fuel_type}
                                error={errors.fuel_type}
                                touched={touched.fuel_type}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputDatePicker
                                  name="deactivated_date"
                                  label="Deactivated Date"
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      placeholder: "Select",
                                      className: "form-control",
                                      readOnly: true,
                                      id: "deactivated_date",
                                    },
                                  }}
                                  value={values.deactivated_date}
                                  onChange={(name, newValue) =>
                                    setFieldValue(name, newValue)
                                  }
                                  touched={touched.deactivated_date}
                                  errors={errors.deactivated_date}
                              />
                              {/* <FormSelect
                                placeholder="Select"
                                label="Type"
                                name="type"
                                options={TypeData}
                                getOptionLabel={(option: any) => option.value}
                                getOptionValue={(option: any) => option.key}
                                onChange={(name: string, value: string) => {
                                  setFieldValue(name, value);
                                }}
                                value={values.type}
                                error={errors.type}
                                touched={touched.type}
                              /> */}
                            </Col>
                            <Col className="col-md-4">
                              <InputText
                                controlId="vin"
                                label="Policy Number"
                                placeholder="Enter Policy Number"
                                touched={touched.vin}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.vin}
                                value={values.vin}
                              />
                            </Col>                           
                            <Col className="col-md-4">
                            <InputText
                                controlId="insurance_company"
                                label="Insurance Company"
                                placeholder="Enter"
                                touched={touched.insurance_company}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.insurance_company}
                                value={values.insurance_company}
                              />                              
                            </Col>
                            <Col className="col-md-4">
                              <InputText
                                controlId="site"
                                label="Tracker Serial No."
                                placeholder="Enter"
                                touched={touched.site}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.site}
                                value={values.site}
                              />
                            </Col>                             
                            <Col className="col-md-4">
                              <InputText
                                controlId="cost_center"
                                label="Cost Center"
                                placeholder="Enter"
                                touched={touched.cost_center}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.cost_center}
                                value={values.cost_center}
                              />
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12">
                        <Card className="mt-2 p-3 w-100 pageContentInner">
                          <Row>
                            <Col className="col-12">
                              <h6 className="fs-18 fw-bold mb-3 info-text">
                                Important Dates
                              </h6>
                            </Col>
                            <Col className="col-md-4">
                              <InputDatePicker
                                name="mot_due_date"
                                label="Mot Due Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "mot_due_date",
                                  },
                                }}
                                value={values.mot_due_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.mot_due_date}
                                errors={errors.mot_due_date}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputDatePicker
                                name="roadtax_due_date"
                                label="Road Tax Due Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "roadtax_due_date",
                                  },
                                }}
                                value={values.roadtax_due_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.roadtax_due_date}
                                errors={errors.roadtax_due_date}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputDatePicker
                                name="warranty_due_date"
                                label="Warranty Due Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "warranty_due_date",
                                  },
                                }}
                                value={values.warranty_due_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.warranty_due_date}
                                errors={errors.warranty_due_date}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputDatePicker
                                name="service_due_date"
                                label="Service Due Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "service_due_date",
                                  },
                                }}
                                value={values.service_due_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.service_due_date}
                                errors={errors.service_due_date}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputDatePicker
                                name="insurance_due_date"
                                label="Insurance Due Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "insurance_due_date",
                                  },
                                }}
                                value={values.insurance_due_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.insurance_due_date}
                                errors={errors.insurance_due_date}
                              />
                            </Col>
                            <Col className="col-md-4">
                              <InputDatePicker
                                name="breakdown_due_date"
                                label="Breakdown Due Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "breakdown_due_date",
                                  },
                                }}
                                value={values.breakdown_due_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.breakdown_due_date}
                                errors={errors.breakdown_due_date}
                              />
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <CustomButtonCancel label="Cancel" />
                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={btnLoading}
                            className="fw-semibold fs-13 text-white mw-60 mt-2"
                            variant="primary"
                          >
                            Submit
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
