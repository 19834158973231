import { Card } from "react-bootstrap";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { checkAbility, currencyMask } from "../../../utility/common";
import Loader from "../../../components/common/Loader";
import { getFleetDetailsApi } from "../../../services/fleetService";
import TicketComments from "../../Tickets/View/comments";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import moment from "moment";
import constants, { OperationDesignation } from "../../../constants/constants";
import { ViewFleetAlerts } from "./alert";
import FleetDriverHistory from "./driverHistory";
import FleetNotes from "./notes";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../features/Auth/authSlice";
import { Scrollbar } from 'react-scrollbars-custom';
export const ViewFleet: React.FC<Record<string, string>> = ({
  uType = "",
}) => {
  const [loading, setLoading] = useState(true);
  const [fleetData, setFleetData] = useState<any>({});
  const [isDriverHistoryPopupOpen, setIsDriverHistoryPopupOpen] = useState(false);
  let { id } = useParams();
  const navigate = useNavigate();
  const {user }:any = useSelector(selectAuthentication);
  const isDriverUser = user?.user?.employeeDetails?.designation == OperationDesignation.DS && uType == 'driver' && true;

  const editDriverHistory = () => {
    setIsDriverHistoryPopupOpen(true);    
  };

  const handleClose = (isReload:boolean = false) => {   
    setIsDriverHistoryPopupOpen(false);    
  };

  const getFleetDetails = (id: string) => {
    setLoading(true);
    getFleetDetailsApi(id,isDriverUser)
      .then((response) => {
        setFleetData(response.data);        
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/fleet");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/fleet");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/fleet");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getFleetDetails(id);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Row className="mt-2 g-2">
              <Col className="col-8">
               
                  <Row className="align-items-center mb-2">
                    <Col className="d-flex col-md-8">
                      <h1 className="fw-bold h4 mt-2 mb-0">
                        Fleet Manager Details
                      <div className="py-1 px-2 ms-2 rounded-1 info-tag d-inline-block fs-11">
                        Asset Id: {fleetData.asset_no_str}
                      </div>
                      </h1>
                    </Col>
                    <Col className="text-end">
                      {checkAbility(
                        PERMISSION_ACCESS.ADD,
                        PERMISSION_MODULE.FLEET_MANAGEMENT
                      ) && (
                        <Button
                          className="fw-semibold fs-12 text-white  btn btn-primary"
                          variant="primary"
                          type="button"
                          onClick={() => editDriverHistory()}
                        >
                          <DepartureBoardIcon className="me-2 fs-15" />
                          Driver History
                        </Button>
                      )}
                    </Col>
                  </Row>
                
                  <Row>
                    <Col className="col">
                      <Card className=" p-3 w-100 pageContentInner mb-3">
                        <Row>
                          <Col className="col-12">
                            <h6 className="fs-18 fw-bold mb-1 info-text">
                              Important Dates
                            </h6>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                              Mot Due Date
                            </label>
                            <div className={`fs-14 ${moment().diff(moment(fleetData?.mot_due_date)) > 0 ? 'text-danger' : ''}`}>
                              {
                              fleetData?.mot_due_date ?  
                                moment(fleetData.mot_due_date).format(
                                constants.dateFormats.slashSeparatedDate
                                ) : "-"
                              }
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                              Road Tax Date
                            </label>
                            <div className={`fs-14 ${moment().diff(moment(fleetData?.roadtax_due_date)) > 0 ? 'text-danger' : ''}`}>
                              {
                                fleetData?.roadtax_due_date ? 
                                moment(fleetData?.roadtax_due_date).format(
                                constants.dateFormats.slashSeparatedDate
                                ) : "-"
                              }
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                              Warranty Due Date
                            </label>
                            <div className={`fs-14 ${moment().diff(moment(fleetData?.warranty_due_date)) > 0 ? 'text-danger' : ''}`}>
                              {
                                fleetData?.warranty_due_date ? 
                                moment(fleetData?.warranty_due_date).format(
                                  constants.dateFormats.slashSeparatedDate
                                ) : "-"
                              }
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                              Service Due Date
                            </label>
                            <div className={`fs-14 ${moment().diff(moment(fleetData?.service_due_date)) > 0 ? 'text-danger' : ''}`}>
                              {
                                fleetData?.service_due_date ? 
                                moment(fleetData?.service_due_date).format(
                                  constants.dateFormats.slashSeparatedDate
                                ) : "-"
                              }
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                              Insurance Due Date
                            </label>
                            <div className={`fs-14 ${moment().diff(moment(fleetData?.insurance_due_date)) > 0 ? 'text-danger' : ''}`}>
                              {
                                fleetData?.insurance_due_date ? 
                                moment(fleetData?.insurance_due_date).format(
                                  constants.dateFormats.slashSeparatedDate
                                ) : "-"
                              }
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                              Breakdown Due Date
                            </label>
                            <div className={`fs-14 ${moment().diff(moment(fleetData?.breakdown_due_date)) > 0 ? 'text-danger' : ''}`}>
                              {
                                fleetData?.breakdown_due_date ? 
                                moment(fleetData?.breakdown_due_date).format(
                                  constants.dateFormats.slashSeparatedDate
                                ) : "-"
                              }
                            </div>
                          </Col>
                        </Row>
                      </Card>
                      <Card className=" p-3 w-100 pageContentInner mb-3">
                        <Row>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                              Registration
                            </label>
                            <div className="fs-14">
                              {fleetData?.registration}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Registration Date
                            </label>
                            <div className="fs-14">{fleetData?.registration_date ? moment(fleetData?.registration_date).format(constants.dateFormats.slashSeparatedDate) : "-"}</div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Active
                            </label>
                            <div className="fs-14">
                            {fleetData?.active ? 'Yes' : 'No'}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Category
                            </label>
                            <div className="fs-14">
                            {fleetData?.category}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Asset Type
                            </label>
                            <div className="fs-14">
                            {fleetData?.asset_type}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Department
                            </label>
                            <div className="fs-14">
                              {fleetData?.department}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Fleet Number
                            </label>
                            <div className="fs-14">
                              {fleetData?.fleet_number}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Current Mileage
                            </label>
                            <div className="fs-14">
                              {fleetData?.current_mileage}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Current Mileage Date
                            </label>
                            <div className="fs-14">
                              {moment(fleetData?.current_mileage_date).format(constants.dateFormats.slashSeparatedDate)}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Make
                            </label>
                            <div className="fs-14">
                              {fleetData?.make ? fleetData?.make : "-"}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Model
                            </label>
                            <div className="fs-14">
                              {fleetData?.model}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Ownership
                            </label>
                            <div className="fs-14">
                              {fleetData?.ownership}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Service Due Mileage 
                            </label>
                            <div className="fs-14">
                              {fleetData?.service_due_mileage ? fleetData?.service_due_mileage : "-"}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Service Interval Months
                            </label>
                            <div className="fs-14">
                              {fleetData?.service_interval_months ? fleetData?.service_interval_months : "-"}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Service Interval Mileage
                            </label>
                            <div className="fs-14">
                              {fleetData?.service_interval_mileage ? fleetData?.service_interval_mileage : "-"}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Engine Size Cc
                            </label>
                            <div className="fs-14">
                              {fleetData?.engine_size_cc ? fleetData?.engine_size_cc : "-"}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Fuel Type
                            </label>
                            <div className="fs-14">
                              {fleetData?.fuel_type}
                            </div>
                          </Col>
                          {/* <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Type
                            </label>
                            <div className="fs-14">
                              {fleetData?.type}
                            </div>
                          </Col>                                                     */}
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Deactivate Date
                            </label>
                            <div  className={`fs-14 ${moment().diff(moment(fleetData?.deactivated_date)) > 0 ? 'text-danger' : ''}`}>
                              {fleetData?.deactivated_date ? moment(fleetData.deactivated_date).format(constants.dateFormats.slashSeparatedDate) : "-"}
                            </div>
                          </Col>                                                    
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Insurance Number
                            </label>
                            <div className="fs-14">
                              {fleetData?.vin ?? "-"}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Insurance Company
                            </label>
                            <div className="fs-14">
                              {fleetData?.insurance_company ?? "-"}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Tracker Serial No.
                            </label>
                            <div className="fs-14">
                              {fleetData?.site ? fleetData?.site : "-"}
                            </div>
                          </Col>
                          <Col className="col-4 my-2">
                            <label className="control-label fw-bold">
                            Cost Center
                            </label>
                            <div className="fs-14">
                              {fleetData?.cost_center ? fleetData?.cost_center : "-"}
                            </div>
                          </Col>
                          
                        </Row>
                      </Card>
                    </Col>
                  </Row>
               
              </Col>
              <Col className="col-4  ">
                <Card className="pageHeader p-0 w-100 pageContentInner mb-3">
                  <Scrollbar  className="fleet-comment-scroll ">
                    <div className="p-3">
                    <h1 className="fw-bold h4 fs-20 info-text pt-1 mt-0 mb-2">
                      Alert
                    </h1> 
                    <ViewFleetAlerts fleetId={id}/>
                  </div>
                  </Scrollbar>               
                </Card>
                <Card className="pageHeader w-100 pageContentInner mb-3">
                  <h1 className="fw-bold h4 fs-20 info-text pt-3 pb-3 mt-0 mb-0 p-3 pb-0 border-bottom">
                    Notes
                  </h1>
                 
                  <FleetNotes fleetId={id} />
                </Card>
              </Col>
            </Row>
            { isDriverHistoryPopupOpen && (
                  <FleetDriverHistory
                    isOpen={isDriverHistoryPopupOpen}
                    onClose={handleClose} 
                    fleetId = {id}                
                  />

                )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
