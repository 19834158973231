import { FC, useEffect, useState } from 'react';
import { Button, Container, Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import { FaAngleDown, FaBell } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import './header.scss';
import Logo from '../../../assets/images/logo.svg';
import BackArrow from '../../../assets/images/page-back-arrow.svg';
import ToggleIcon from '../../../assets/images/toggle-icon.svg';
import {logoutAPI} from "../../../services/authService"
import storage from '../../../helpers/storage';
import {  useSelector , useDispatch } from 'react-redux';
import {logout,selectAuthentication} from "../../../features/Auth/authSlice"
import { nameBasePublicRouotes } from '../../../router/public';
import { SUPER_ADMIN } from "../../../constants/constants";
import UserPlaceholderImage from "../../../assets/images/user-placeholder-image.png";

interface IHeader {
  isActiveSidebar: boolean;
  toggleSidebarButton?: any;
}


/**
 * Header Sidebar & Toggle Manager
 * @date 4/12/2023 - 12:56:12 PM
 *
 * @param {{ isActiveSidebar: any; toggleSidebarButton: any; }} {
  isActiveSidebar, toggleSidebarButton
}
 * @returns {*}
 */

export const Header: FC<IHeader> = ({
  isActiveSidebar, toggleSidebarButton
}) => {
  const navigate = useNavigate()
  const token = storage.getRefreshToken()
  const dispatch = useDispatch();
  const {user }:any = useSelector(selectAuthentication);

  const  userName = user.user.full_name;
  const designation = user?.user?.employeeDetails?.designation ? user?.user?.employeeDetails?.designation : 'Super Admin';
  const userProfileImage = user?.user?.employeeDetails?.user_photo_url ? user?.user?.employeeDetails?.user_photo_url : UserPlaceholderImage;
  

const handleBack = () =>{
  navigate(-1)
}
  const handleLogout = () =>{
    const tokenObj = {
      refreshToken : token || ''
    }    
    logoutAPI(tokenObj).then(()=>{
      localStorage.removeItem('EA_token');
      localStorage.removeItem('EA_refreshToken');
      storage.clearPlannerFilter();
      dispatch(logout())
      navigate(nameBasePublicRouotes.login.path)
    }).catch(()=>{
      localStorage.removeItem('EA_token');
      localStorage.removeItem('EA_refreshToken');
      dispatch(logout())
      navigate(nameBasePublicRouotes.login.path)
    })
  }

  return (
    <Navbar bg="white" variant="light" className="py-0 px-md-1 shadow-sm d-print-none">
      <Container fluid className="px-3 mh-60 ps-0">
        <Button onClick={toggleSidebarButton} variant="link" className="align-items-center d-flex justify-content-center navMenuBtn p-0">
          <span className={`d-inline-block  position-relative text-center ${isActiveSidebar ? 'active' : ''}`}><Image className="img-fluid" src={ToggleIcon} alt="Toggle Icon"/></span>
        </Button>
        <span onClick={handleBack} className='mx-2 mx-md-1 fs-15 fw-bold control-label text-decoration-none cursor-pointer '><Image className="img-fluid cursor-pointer" src={BackArrow} alt="back arrow"/> Back</span>
        <Link to="/" className="d-xl-none mx-2 mx-md-3 px-1">
          <Image className="img-fluid" src={Logo} alt="Logo" width={80} height={32} />
        </Link>
        <Nav className="ms-auto align-items-center">
          {/*<Dropdown className="notificationDropdown me-md-2 me-1">
            <Dropdown.Toggle variant="link" id="dropdown-notification" className="fs-14 fw-semibold text-decoration-none position-relative rounded-circle d-inline-flex align-items-center justify-content-center text-light p-2">
              <FaBell size={22} />
              <span className="align-items-center badge bg-danger border border-white customBadge d-flex fs-11 fw-semibold justify-content-center position-absolute px-1 rounded-pill start-100 top-0">5
                <span className="visually-hidden">unread messages</span>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" className="fs-14 shadow-sm px-3">
              Coming soon...
            </Dropdown.Menu>
          </Dropdown>
          <div className="bg-white mx-1 mx-md-3 vr"></div>*/}
          <Dropdown className="profileDropdown ms-md-2 ms-1">
            <Dropdown.Toggle variant="link" id="dropdown-profile" className="border-0 fs-14 fw-bold text-decoration-none p-0 text-light">
              <Image className="object-fit-cover rounded-circle" src={userProfileImage} alt="Profile Image" width={40} height={40} />
              <div className="align-middle d-none d-md-inline-block ms-1 px-2 text-truncate fs-13 text-black text-start">
                <span  className="w-100 d-block">{userName}</span>
                

                <span  className="fw-bold p-1 py-0 fs-11 text-white mw-60  btn btn-primary role-tag">{designation}</span>
              </div>
              <FaAngleDown size={16} className='text-black' />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="fs-14 shadow-sm">
              {<Dropdown.Item as={Link} to={designation !== 'Super Admin' ? "/profile/view" : "profile/edit"} className="fw-medium">Profile</Dropdown.Item>}
              {<Dropdown.Item as={Link} to={"/profile/change-password"} className="fw-medium">Change Password</Dropdown.Item>}
              {/* <Dropdown.Item as={Link} to="/app/settings" className="fw-medium">Settings</Dropdown.Item> */}
              {/* <Dropdown.Divider className="mb-1" /> */}
              <Dropdown.Item className="fw-medium" onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  )
}
