import * as yup from "yup";
import {
  CONFIRM_PASSWORD_REQUIRED,
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MINIMUM_6_CHAR,
  MAXIMUM_255_CHAR,
  ALPHABETS_ALLOW,
  PASSWORD_MUST_MATCH,
  PASSWORD_REGEX,
  PASSWORD_REQUIRED,
  ALPHABET_WITH_SPECIAL_CHAR_ALLOW
} from "../../validations/ValidationErrors";
import { EMAIL_REGEX, ONLY_ALPHABET, ALPHABET_WITH_SPECIAL_CHAR } from "../../validations/regex";

export const profileValidationSchema = (profileUpdateReqData: any) => {
  const yupSchema: Record<string, any> = {};

  if (!profileUpdateReqData.users?.first_name) {
    yupSchema["first_name"] = yup
      .string()
      .trim()
      .required()
      //.matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .matches(ALPHABET_WITH_SPECIAL_CHAR, ALPHABET_WITH_SPECIAL_CHAR_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("First Name");
  }
  if (!profileUpdateReqData.users?.last_name) {
    yupSchema["last_name"] = yup
      .string()
      .trim()
      .required()
      //.matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .matches(ALPHABET_WITH_SPECIAL_CHAR, ALPHABET_WITH_SPECIAL_CHAR_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Family Name");
  }
  if (!profileUpdateReqData.users?.phone_no) {
    yupSchema["phone_no"] = yup
      .number()
      .required()
      .typeError("Contact number must be a number")
      .max(999999999999999, "Contact number must be at most 15 digits") // Maximum 15 digits
      .label("Contact No");
  }

  if (!profileUpdateReqData.Employ_details?.address) {
    yupSchema["address"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Address");
  }

  if (!profileUpdateReqData.Employ_details?.city) {
    yupSchema["city"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("City");
  }

  if (!profileUpdateReqData.Employ_details?.state) {
    yupSchema["state"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("County");
  }

  if (!profileUpdateReqData.Employ_details?.postal_code) {
    yupSchema["postal_code"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Postal Code");
  }

  if (!profileUpdateReqData.Employ_details?.ni_number) {
    yupSchema["ni_number"] = yup
      .string()
      .trim()
      /* .required() */
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("NI Number");
  }

  if (!profileUpdateReqData.Kin_Details?.keen_name1) {
    yupSchema["keen_name1"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Kin Name 1");
  }

  if (!profileUpdateReqData.Kin_Details?.keen_contact_no1) {
    yupSchema["keen_contact_no1"] = yup
      .number()
      .required()
      .typeError("Kin Contact number must be a number")
      .positive("Kin Contact number must be positive")
      .integer("Kin Contact number must be an integer")
      .max(999999999999999, "Kin Contact number must be at most 15 digits") // Maximum 15 digits
      .label("Kin Contact 1");
  }

  if (!profileUpdateReqData.Kin_Details?.keen_relation1) {
    yupSchema["keen_relation1"] = yup
      .object()
      .required()
      .label("Keen Relation 1 ");
  }

  if (!profileUpdateReqData.Kin_Details?.keen_name2) {
    yupSchema["keen_name2"] = yup
      .string()
      .trim()
      /* .required() */
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Kin  Name 2");
  }

  if (!profileUpdateReqData.Kin_Details?.keen_contact_no2) {
    yupSchema["keen_contact_no2"] = yup
      .number()
      /* .required() */
      .typeError("Kin Contact number must be a number")
      .positive("Kin Contact number must be positive")
      .integer("Kin Contact number must be an integer")
      .max(999999999999999, "Kin Contact number must be at most 15 digits") // Maximum 15 digits
      .label("Kin Contact 2");
  }

  if (!profileUpdateReqData.Kin_Details?.keen_relation2) {
    yupSchema["keen_relation2"] = yup
      .object()
      /* .required() */
      .label("Keen Relation 1 ");
  }

  if (!profileUpdateReqData.Employee_Bank_Details?.bank_name) {
    yupSchema["bank_name"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Bank Name");
  }

  if (!profileUpdateReqData.Employee_Bank_Details?.account_name) {
    yupSchema["account_name"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Account Name");
  }

  if (!profileUpdateReqData.Employee_Bank_Details?.account_number) {
    yupSchema["account_number"] = yup
      .number()
      .required()
      .typeError("Account Number must be a number")
      .positive("Account Number must be positive")
      .integer("Account Number must be an integer")
      .min(9999, "Account Number  must be minimum 4 digits") // Maximum 15 digits
      .max(999999999999999, "Account Number must be at most 15 digits"); // Maximum 15 digits
  }

  if (!profileUpdateReqData.Employee_Bank_Details?.sort_code) {
    yupSchema["sort_code"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Sort Code");
  }

  if (!profileUpdateReqData.Employ_details?.job_description) {
    yupSchema["job_description"] = yup
      .string()
      .trim()
      .required()
      .min(3, MINIMUM_3_CHAR)
      .label("Job Description");
  }

  return yup.object(yupSchema);
};

export const adminProfileValidationSchema = () => {
  return yup.object({
    first_name: yup
      .string()
      .trim()
      .required()
      .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("First Name"),
    last_name: yup
      .string()
      .trim()
      .required()
      .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
      .min(3, MINIMUM_3_CHAR)
      .max(30, MAXIMUM_30_CHAR)
      .label("Family Name"),
  });
};

/**
 * ResetPasswordSchema for validation
 * @type {*}
 */
export const changePasswordSchema = yup.object().shape({
  current_password: yup
    .string()
    .required(PASSWORD_REQUIRED)
    .min(6, MINIMUM_6_CHAR)
    .max(30, MAXIMUM_30_CHAR),
  password: yup
    .string()
    .required(PASSWORD_REQUIRED)
    .min(6, MINIMUM_6_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .matches(/^(?=.*[a-z])(?=.*[A-Z]).+$/, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], PASSWORD_MUST_MATCH),
});
