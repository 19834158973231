import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { addSchema } from "./validation";
import { toast } from "react-toastify";
import { Card, Col, Row } from "react-bootstrap";
import InputText from "../../../components/common/InputText";
import InputTextArea from "../../../components/common/InputTextArea";
import Loader from "../../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/common/CustomButton";
import {
  allowedFileTypes,
  maxFileSizeMB,
} from "../../../constants/constants";
import FormSelect from "../../../components/common/FormSelect";
import FileUploader from "../../../components/common/FileUploader";
import { FILE_SIZE_ERROR, FILE_TYPE_ERROR } from "../../../validations/ValidationErrors";
import {
  createAsset,
  getAssetById,
  getAssetTypes,
  updateAsset,
  FormSelectType,
  createAssetDTO
} from "../../../services/assetService";
import { getAllVendorsName } from "../../../services/vendorService";
import CustomButtonCancel from "../../../components/common/CustomButtonCalcel";

export default function AddAsset() {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [assetTypes, setAssetTypes] = useState<FormSelectType[]>([]);
  const [vendors, setVendors] = useState<FormSelectType[]>([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [asset, setAsset] = useState<any>({});
  const navigate = useNavigate();
  let { id } = useParams();

  /**
   * Get existing asset from server
   * @async
   * @param {id} ID of Asset
   */
  const getAsset = async (id: string) => {
    setLoading(true);
    await getAssetById(id)
      .then((response: any) => {
        setAsset(response.data);
        if(response.asset_image_url){
          setImagePreview(response.asset_image_url)
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      });
  };

  const updateKeys = (arr: any[]) => {
    return arr.map((item: { type: any; options: any; }) => {
      // Create a new object with the updated keys
      let newItem = {
        ...item,
        key: item.type,
        value: item.type        
      };
      
      // If the item has options, recursively update the keys in the options array
      if (item.options) {
        newItem.options = updateKeys(item.options);
      }
  
      return newItem;
    });
  };

  /**
   * fetch asset types to list in the type drop down
   * @async
   */
  const fetchAssetTypes = async () => {
    return new Promise((resolve, reject) => {
      getAssetTypes().then((response) => {
        const assetTypeOptions = updateKeys(response.data)
        setAssetTypes(assetTypeOptions);
        return resolve(true);
      });
    });
  };

  /**
   * fetch vendors to list in the vendor drop down
   * @async
   */
  const fetchVendors = async () => {
    return new Promise((resolve, reject) => {
      getAllVendorsName().then((response) => {
        const vendorOptions = response.data.map((item: {id: number, vendor_name: string}) => ({
          key: item.id,
          value: item.vendor_name
        }));
        setVendors(vendorOptions);
        return resolve(true);
      });
    });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param {createAssetDTO, actions} values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
    setLoading(true);
    console.log('value>>', values)
    if (id) {
      updateAsset(id, values)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/assets");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);
          setImagePreview(null);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        }).finally(() => {
          setLoading(false);
        });
    } else {
      createAsset(values)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/assets");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);
          setImagePreview(null);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        }).finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    Promise.all([fetchAssetTypes(), fetchVendors()])
    .then(() => {
      if (id) {
        getAsset(id);
      }else{
        setLoading(false)
      }
    });
  }, []);

  /**
   * Upload Asset Image
   * @param {events, setFieldValue}
   * @returns {*}
   */
  const uploadAssetImage = (event: any, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    if(imageFile){
      // Check file type
      if (!allowedFileTypes.includes(imageFile.type)) {
        toast(FILE_TYPE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
        toast(FILE_SIZE_ERROR, { type: toast.TYPE.ERROR });
        return;
      } else {
        setFieldValue('asset_image', imageFile);
        if (imageFile) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result);
          };
          reader.readAsDataURL(imageFile);
        } else {
            setImagePreview(null);
        }
      }
    }
  };

  const initialValues = {
    name: asset?.name ? asset?.name : "",
    type: asset?.type ? {'key': asset?.type, 'value': asset?.type} : "",
    serial_number: asset?.serial_number ? asset?.serial_number : "",
    amount: asset?.amount ? asset?.amount : "",
    invoice_number: asset?.invoice_number ? asset?.invoice_number : "",
    //vendor_id: asset?.vendor_id ? {'key': asset.vendor_id, 'value': asset.vendor.vendor_name} : "",
    vendor_name: asset?.vendor_name ? asset.vendor_name : "",
    description: asset?.description ? asset?.description : "",
    asset_image: asset?.asset_image ? asset?.asset_image : "",
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">{id ? "Edit" : "Add"} Asset</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
            <Formik
              validationSchema={() => addSchema(id)}
              initialValues={initialValues}
              onSubmit={(values: any, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                setFieldTouched,
                handleBlur,
                values,
                touched,
                isValid,
                errors
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  
                  <Card className="p-3 w-100 pageContentInner">
                    <Row>
                    <div className="col-md-8">
                      <Row>
                        <div className="col-md-6">
                        <FormSelect
                            placeholder="Select Asset Type"
                            label="Asset Type"
                            name="type"
                            options={assetTypes}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                            }}
                            value={values.type}
                            error={errors.type}
                            touched={touched.type}
                          />
                        </div>
                        <div className="col-md-6">
                        <InputText
                            controlId="name"
                            label="Asset Name"
                            placeholder="Enter Asset Name"
                            touched={touched.name}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.name}
                            value={values.name}
                          />
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                        <InputText
                            controlId="serial_number"
                            label="Serial Number"
                            placeholder="Enter Serial Number"
                            touched={touched.serial_number}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.serial_number}
                            value={values.serial_number}
                          />
                        </div>
                        <div className="col-md-6">
                        <InputText
                            controlId="amount"
                            label="Amount (£)"
                            placeholder="Enter Amount"
                            touched={touched.amount}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.amount}
                            value={values.amount}
                          />
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                        {/* <FormSelect
                            placeholder="Select Vendor"
                            label="Vendor"
                            name="vendor_id"
                            options={vendors}
                            getOptionLabel={(option: any) => option.value}
                            getOptionValue={(option: any) => option.key}
                            onChange={(name: string, value: string) => {
                              setFieldValue(name, value);
                            }}
                            value={values.vendor_id}
                            error={errors.vendor_id}
                            touched={touched.vendor_id}
                          /> */}
                          <InputText
                            controlId="vendor_name"
                            label="Vendor"
                            placeholder="Enter Vendor Name"
                            touched={touched.vendor_name}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.vendor_name}
                            value={values.vendor_name}
                          />
                        </div>
                        <div className="col-md-6">
                        <InputText
                            controlId="invoice_number"
                            label="Invoice Number"
                            placeholder="Enter Invoice Number"
                            touched={touched.invoice_number}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.invoice_number}
                            value={values.invoice_number}
                          />
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-12">
                          <InputTextArea
                            controlId="description"
                            label="Description"
                            placeholder="Enter Description"
                            touched={touched.description}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errorsField={errors.description}
                            name="description"
                            value={values.description}
                            className="max-height78 resize-none"
                          />
                        </div>
                      </Row>
                    </div>
                    <div className="col-md-4">
                          <label className="lh-sm control-label fs-14 fw-bold form-label">
                              Asset Photo
                          </label>
                          <div className="position-relative ">
                            <FileUploader uploadProfileImage={(e) => uploadAssetImage(e, setFieldValue)} imageLoading={imageLoading} imagePreview={imagePreview} />
                          </div>
                    </div>
                    </Row>
                  </Card>
                  

                  <div className="text-end my-3 px-3">
                  <CustomButtonCancel label="Cancel" />
                    <CustomButton
                      type="submit"
                      loading={btnLoading}
                      className="fw-semibold fs-13 text-white mw-60 mt-2"
                      variant="primary"
                    >
                      {id ? "Update" : "Add"}
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
            </div>
        </div>
      )}
    </React.Fragment>
  );
}
