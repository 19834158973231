import { Card } from "react-bootstrap";
import { Button, Col, Row } from "react-bootstrap";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CheckIcon from "@mui/icons-material/Check";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  checkAbility,
  currencyMask,
  getFirstLetters,
} from "../../../utility/common";
import AddPhotoAlternate from "../../../assets/images/add_photo_alternate.svg";

import ticketDateIcon from "../../../assets/images/ticket-date-icon.svg";
import Loader from "../../../components/common/Loader";
import {
  getServiceComplaintDetailsApi,
  getServiceComplaintCommentsApi,
  updateServiceComplaintStatusApi,
} from "../../../services/ticketService";
import moment from "moment";
import constants, { tickerStatus } from "../../../constants/constants";
import DeletePopup from "../../../components/common/deletePopup";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import TicketComments from "./comments";
import { selectAuthentication } from "../../../features/Auth/authSlice";
import LightboxGallery from "../../../components/common/LightboxGallery";
import MarkAsComplete from "./markAsComplete";

export const ViewServiceWarrantyComplaint = () => {
  const [loading, setLoading] = useState(true);
  const [serviceComplaintData, setServiceComplaintData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(AddPhotoAlternate);
  const [copied, setCopied] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [ticketChatData, setTicketChatData] = useState([]);
  const { user }: any = useSelector(selectAuthentication);
  const [signedImages, setSignedImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [imagesSet, setImagesSet] = useState<any>([]);
  const userId = user.user.id;
  const [isServiceCompleteDetailsOpen, setIsServiceCompleteDetailsOpen] = useState(false);
  const [serviceCompletionDetails, setServiceCompletionDetails]:any = useState({});

  let { id } = useParams();
  const navigate = useNavigate();

  const editTicket = () => {
    navigate(`/service-warranty/edit/${id}`);
  };

  /**
   * fetch Categories to list in the category type drop down
   * @async
   */
  const getServiceComplaintsComments = async (
    ticketId: string | number | undefined
  ) => {
    return new Promise((resolve, reject) => {
      if (ticketId) {
        getServiceComplaintCommentsApi(ticketId)
          .then((response) => {
            setTicketChatData(response.data.ticketData);
            setCommentData(response.data.chatData);
            // setLoading(false);
            return resolve(true);
          })
          .catch(() => {
            // setLoading(false);
          });
      }
    });
  };

  const getServiceComplaintDetails = (id: string) => {
    setLoading(true);
    getServiceComplaintDetailsApi(id)
      .then((response) => {
        setServiceComplaintData(response.data);
        setSignedImages(response.data.filesSignedURL);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/service-warranty");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/service-warranty");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/service-warranty");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getServiceComplaintDetails(id);
      getServiceComplaintsComments(id);
    } else {
      setLoading(false);
    }
  }, []);

  const renderStatusDiv = (checkedVal: number | string | undefined) => {
    const statsuArr: any = tickerStatus;
    const statusName = statsuArr.find(
      (item: { key: string | number | undefined }) => item.key == checkedVal
    );
    return (
      <span
        className={`${statusName?.tagClass} py-1 px-2 ms-0 rounded-1 d-inline-block fs-11 fw-bold vertical-middle`}
      >
        {statusName?.value}
      </span>
    );
  };

  const handleStatusChangeClick = () => {
    setDeletePopupOpen(true);
  };

  const handleStatusSave = () => {
    setLoading(false);
    updateServiceComplaintStatusApi(id, serviceCompletionDetails)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        setDeletePopupOpen(false);
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setIsServiceCompleteDetailsOpen(false);
    if (id) {
      getServiceComplaintDetails(id);
    }
  };

  const handleCloseImage = () => {
    setCurrentIndex(0);
    setLightboxIsOpen(false);
  };
  const openLightbox = (imageIndex: any) => {
    const updatedImgSet = signedImages.map((item: any) => {
      return { ...item, src: item };
    });
    setImagesSet(updatedImgSet);
    setCurrentIndex(imageIndex);
    setLightboxIsOpen(true);
  };

  const getRootCause = (values: any) => {
    if (values?.root_cause && values?.time_spent_on_call) {
      setIsServiceCompleteDetailsOpen(false);
      setServiceCompletionDetails(values);
      handleStatusChangeClick();
    }
  };

  const taskComplete = () => {
    setIsServiceCompleteDetailsOpen(true);
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1 pt-3">
          <Row>
            <Col className="col-8 pe-0">
              <div className="pageHeader px-3 pb-2 mb-1">
                <Row className="align-items-center">
                  <Col>
                    <h1 className="fw-bold h4 mt-2 mb-0">
                      {serviceComplaintData?.title}
                    </h1>
                    <div className="py-1 px-2 rounded-1 info-tag d-inline-block fs-11 mt-2">
                      <span className="fw-bold">Service Log No :</span>{" "}
                      {serviceComplaintData.ticket_no_str}
                    </div>
                  </Col>
                  <Col className="text-end">
                    {checkAbility(
                      PERMISSION_ACCESS.EDIT,
                      PERMISSION_MODULE.SERVICE_WARRANTY_MANAGEMENT
                    ) &&
                      serviceComplaintData.status != 2 &&
                      ((serviceComplaintData.created_by === userId &&
                        user.user?.employeeDetails) ||
                        user.user?.employeeDetails === null) && (
                        <Button
                          className="fw-semibold fs-12 text-white  btn btn-info"
                          variant="info"
                          type="button"
                          //onClick={() => handleStatusChangeClick()}
                          onClick={() => taskComplete()}
                        >
                          <CheckIcon className="me-2 fs-15" />
                          Mark Complete
                        </Button>
                      )}
                  </Col>
                </Row>
              </div>
              <div className="flex-grow-1 overflow-auto pageContent px-3 pb-2">
                <Row>
                  <Col className="col">
                    <Card className=" p-3 w-100 pageContentInner mb-3">
                      <Row>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Project Order No
                          </label>
                          <div className="fs-14 d-flex">
                            {serviceComplaintData?.project?.project_no ? 
                            serviceComplaintData.project.project_no : 
                            (serviceComplaintData?.historicalProject?.order_no ? serviceComplaintData.historicalProject.order_no : "-")}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Customer
                          </label>
                          <div className="fs-14 d-flex">
                            {serviceComplaintData?.project?.name ? 
                            serviceComplaintData.project.name : 
                            (serviceComplaintData?.historicalProject?.customer_name ? serviceComplaintData.historicalProject.customer_name : "-")}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">Phone</label>
                          <div className="fs-14 d-flex">
                            {serviceComplaintData?.project?.phone ? 
                            serviceComplaintData.project.phone : 
                            (serviceComplaintData?.historicalProject?.telephone ? serviceComplaintData.historicalProject.telephone : "-")}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            {serviceComplaintData?.project?.order_date ? "Project Order Date" : "Project Completion Date"}
                          </label>
                          <div className="fs-14 d-flex">
                            {serviceComplaintData?.project?.order_date ? 
                              moment(
                                serviceComplaintData.project.order_date
                              ).format(constants.dateFormats.slashSeparatedDate) : 
                              (serviceComplaintData?.historicalProject?.completion_date ? moment(serviceComplaintData?.historicalProject?.completion_date).format(constants.dateFormats.slashSeparatedDate) : "-")}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Project Owner
                          </label>
                          <div className="fs-14 d-flex">
                            {serviceComplaintData?.project?.projectOwner
                              ?.full_name
                              ? serviceComplaintData?.project?.projectOwner
                                  ?.full_name
                              : (serviceComplaintData?.historicalProject?.project_owner ? serviceComplaintData.historicalProject.project_owner : "-")}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Task Owner
                          </label>
                          <div className="fs-14 d-flex">
                            <span className="ticket-user-tag me-2">
                              {getFirstLetters(
                                serviceComplaintData?.taskOwner?.full_name ? 
                                serviceComplaintData.taskOwner.full_name : 
                                (serviceComplaintData?.task_owner_name ? serviceComplaintData.task_owner_name : "-" )
                              )}
                            </span>
                            {serviceComplaintData?.taskOwner?.full_name ? 
                                serviceComplaintData.taskOwner.full_name : 
                                (serviceComplaintData?.task_owner_name ? serviceComplaintData.task_owner_name : "-" )}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Assignee
                          </label>
                          <div className="fs-14 d-flex">
                            <span className="ticket-user-tag me-2">
                              {getFirstLetters(
                                serviceComplaintData?.assignedUser?.full_name
                              )}
                            </span>
                            {serviceComplaintData?.assignedUser?.full_name}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Due Date
                          </label>
                          <div className="fs-14 d-flex">
                            <span className="me-2">
                              <img src={ticketDateIcon} />
                            </span>
                            {moment(serviceComplaintData?.due_date).format(
                              constants.dateFormats.slashSeparatedDate
                            )}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Status
                          </label>
                          <div className="fs-14">
                            {renderStatusDiv(serviceComplaintData.status)}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Created By
                          </label>
                          <div className="fs-14 d-flex">
                            <span className="ticket-user-tag me-2 green">
                              {getFirstLetters(
                                serviceComplaintData?.createdUser?.full_name
                              )}
                            </span>
                            {serviceComplaintData?.createdUser?.full_name}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Department Name
                          </label>
                          <div className="fs-14">
                            {serviceComplaintData?.department}
                          </div>
                        </Col>
                        <Col className="col-4 mb-3">
                          <label className="control-label fw-bold">
                            Created Date
                          </label>
                          <div className="fs-14">
                            {moment(serviceComplaintData?.createdAt).format(
                              constants.dateFormats.slashSeparatedDate
                            )}
                          </div>
                        </Col>
                        {
                          serviceComplaintData.status == 2 && 
                          <>
                            <Col className="col-4 mb-3">
                              <label className="control-label fw-bold">
                                Root Cause
                              </label>
                              <div className="fs-14">
                                {serviceComplaintData?.root_cause ? serviceComplaintData.root_cause : "-"}
                              </div>
                            </Col>
                            <Col className="col-4 mb-3">
                              <label className="control-label fw-bold">
                                Spend Time to Completion
                              </label>
                              <div className="fs-14">
                                {serviceComplaintData?.time_spent_on_call ? `${serviceComplaintData.time_spent_on_call} Hours` : "-"}
                              </div>
                            </Col>
                          </>
                        }
                        <Col className="col-12 mb-3">
                          <label className="control-label fw-bold">
                            Task Description
                          </label>
                          <div className="fs-14">
                            {serviceComplaintData.description}
                          </div>
                        </Col>
                        {serviceComplaintData?.filesSignedURL.length > 0 && (
                          <Col className="col-12">
                            <div className="fs-14">
                             
                                  {" "}
                                  {/*  <img
                                    className="img-fluid"
                                    src={serviceComplaintData?.filesSignedURL[0]}
                                    alt="Logo"
                                  /> */}
                                  
                                  {signedImages.map(
                                    (image: any, index: number) => {
                                      return (
                                        <label className="file-box me-3 ticket-flex-box">
                                <span>
                                        <div key={index} className="m-2">
                                          <img
                                            src={image}
                                            alt="preview"
                                            loading="lazy"
                                            className="img-fluid"
                                            onClick={() => openLightbox(index)}
                                          />
                                        </div>
                                        </span>
                              </label>
                                      );
                                    }
                                  )}
                                
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-4 ps-0">
              <div className="pe-3">
                <Card className="pageHeader w-100 pageContentInner mb-3">
                  <h1 className="fw-bold h4 fs-20 info-text pt-3 pb-3 mt-0 mb-0 p-3 pb-0 border-bottom">
                    Ticket Thread
                  </h1>
                  <div className="">
                    <TicketComments
                      ticketId={id}
                      ticketData={ticketChatData}
                      commentData={commentData}
                      onSubmitData={getServiceComplaintsComments}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
          <DeletePopup
            isOpen={isDeletePopupOpen}
            onClose={handleClose}
            onDelete={handleStatusSave}
            label="Mark Service Log as Complete"
            text="Are you sure you want to mark this Log as complete?"
            actionType="info"
          />
          {
            isServiceCompleteDetailsOpen && 
            <MarkAsComplete
              isOpen={isServiceCompleteDetailsOpen}
              onClose={handleClose}
              submitDetails={getRootCause}
            />
          }
        </div>
      )}
      <LightboxGallery
        images={imagesSet}
        currentIndex={currentIndex}
        open={lightboxIsOpen}
        onClose={handleCloseImage}
      />
    </React.Fragment>
  );
};
