import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import events from "./events";
import AddScheduleModal from "./AddScheduleModal";
import CalendarEventModal from "./CalendarEventModal";
import CalendarIcon from "../../assets/images/calendar_today.svg";

import Tactic from "../../assets/images/tactic.svg";
import FormAsyncSelect from "../../components/common/AsyncSelect/FormAsyncSelect";
import { getOrderNumberListApi } from "../../services/dealService";
import storage from '../../helpers/storage';
import moment from "moment";
import { Toggle } from "rsuite";
import {
   
  getEmployeeUnderPM,
  getProjectAssignTeamAndDetailsAPi,
  getTaskApiCall,
} from "../../services/task.service";
import Loader from "../../components/common/Loader";
import DynamicClassComponent from "./DynamicClassComponent";
import constants, { PROJECT_PREFIX } from "../../constants/constants";
import Groups2Icon from "@mui/icons-material/Groups2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import Ipayment from "../../assets/images/plannerImg/Ipayment.svg";
import Fpayment from "../../assets/images/plannerImg/Fpayment.svg";
import FirstDay from "../../assets/images/plannerImg/firstDay.svg";
import LastDay from "../../assets/images/plannerImg/lastDay.svg";
import SurveyDate from "../../assets/images/plannerImg/survetDate.svg";
import Leave from "../../assets/images/plannerImg/leave.svg";
import { getJobTypeClass, getUniqueDates } from "../../utility/common";

interface CalendarProps {
  setSelectedTeamMembers: any;
  setTeamview: any;
  teamview: Boolean;
  onlyEmployeeView?: Boolean;
  setIsMapView: any;
  isMapView?: Boolean;
  setSelectedContract?: any;
  checkedMembers?: any;
  setCheckedMembers?: any;
  selectedTeamMembers ?: any;
}

const Calendar: React.FC<CalendarProps> = ({
  setSelectedTeamMembers,
  setTeamview,
  teamview,
  onlyEmployeeView,
  setIsMapView,
  isMapView,
  projectId,
  setSelectedContract,
  checkedMembers,
  setCheckedMembers,
  selectedTeamMembers
}) => {
  const calendarRef = useRef();
  const [title, setTitle] = useState("");
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState("Month View");
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState({});
  const [scheduleStartDate, setScheduleStartDate] = useState();
  const [scheduleEndDate, setScheduleEndDate] = useState();

  const [selectedProject, setSelectedProject] = useState(null);
  const [memberList, setMemberList] = useState([]);
  const [tasksData, setTasksData] = useState([]);
  const [projectDate, setProjectDate] = useState({});
  const [projectPaymentMilestone, setProjectPaymentMilestone] = useState({});
  const [projectDocuments, setProjectDocuments] = useState([]);

  const [projectStartDate, setProjectStartDate] = useState(null);
  const [projectEndDate, setProjectEndDate] = useState(null);

  const exportType = "project";
  const getOrderNumber = (params) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType,
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };
  const [intermPaymentDates, setIntermPaymentDates] = useState([]);
  const [finalPaymentDate, setFinalPaymentDate] = useState([]);
  const [leaveDates, setLeaveDates] = useState([]);
  const currentDate = new Date();
  //end date will be corrent month end date and start date will be current month start date
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const [startDate, setStartDate] = React.useState(startOfMonth);
  const [endDate, setEndDate] = React.useState(endOfMonth);
  const [loader, setLoader] = useState(true);
  const eventList = events.reduce(
    (acc, event) =>
      event.schedules
        ? acc.concat(event.schedules.concat(event))
        : acc.concat(event),
    []
  );

  const getTeam = (id) => {
    return new Promise((resolve, reject) => {
      getProjectAssignTeamAndDetailsAPi(id)
        .then((response) => {
          setSelectedTeamMembers(response.data.project_members);
          setMemberList(response.data.project_members);
          
          //save planner start and completion date in storage 
          let projectData = storage.getPlannerFilter("plannerProject");
          Object.assign(projectData, response.data.projectDetails);
          storage.savePlannerProject(projectData);
          setProjectDate(response.data.projectDetails);

          setProjectPaymentMilestone(response.data.payment_milestone);
          getIntermPaymentDates(response.data.payment_milestone);
          // getLeaveDateForEmployee(response.data.project_members);
          setProjectDocuments(response.data.project_documents);
          if (
            response.data.projectDetails &&
            response.data.projectDetails.planned_start_date
          ) {
            setProjectStartDate(
              response.data.projectDetails.planned_start_date
            );
            setProjectEndDate(
              response.data.projectDetails.planned_completion_date
            );
          } else {
            setProjectStartDate(null);
            setProjectEndDate(null);
          }

          if (response.data.project_members) {
            setCheckedMembers(
              response.data.project_members.map((member) => member.user.id)
            );
            storage.savePlannerTeam(response.data.project_members);
          }

          setLoader(false);
          resolve(true);
        })
        .catch((error) => {
          setLoader(false);
          reject(error);
        });
    });
  };


  const getEmployeeUnderProjectManager = () => {
    getEmployeeUnderPM()
      .then((response) => {
        if(response.data){
          if (response.data.length) {
           setMemberList(response.data)
          } else {
            let teamData = storage.getPlannerFilter("plannerTeam");
            if (teamData) {console.log("listteam", teamData)
              setMemberList(teamData);
            }
          }
        }
      })
      .catch((error) => {
        console.log("e", error);
      });
  };

  const handleProjectChange = (name, value) => {
    let selectedProjectValue = value?.id ? value : null;
    storage.savePlannerTeam([]);
    storage.savePlannerProject(selectedProjectValue);
    setSelectedProject(selectedProjectValue);
    setSelectedContract(value?.id ? value.id : null);
    setCheckedMembers([]);
    setMemberList([]);
    setSelectedTeamMembers([]);
    setLoader(true);
    if (value?.id) {
      getTeam(value.id);
    }
  };

  const getLeaveDateForEmployee = (members) => {
    let userLeaveDates = {};

    members.forEach((member) => {
      
      if (member?.employeeLeave) {
        const leaveDates = getUniqueDates(member?.employeeLeave);
        leaveDates.forEach((item) => {
         
          if (!userLeaveDates[item.date]) {
            userLeaveDates[item.date] = [];
          }
          userLeaveDates[item.date].push(
            member?.full_name +
              " " +
              (+item.type ? (item.type == "1" ? "(AM)" : "(PM)") : "")
          );
        });
      }
    });
     setLeaveDates(userLeaveDates);
  };
  /** 
 *  const getLeaveDateForEmployee = (members) => {
    let userLeaveDates = {};

    members.forEach((member) => {
      if (member?.user?.employeeLeave) {
        const leaveDates = getUniqueDates(member?.user?.employeeLeave);
        console.log("leaveDates", leaveDates);
        leaveDates.forEach((item) => {
          if (!userLeaveDates[item.date]) {
            userLeaveDates[item.date] = [];
          }
          userLeaveDates[item.date].push(
            member?.user?.full_name +
              " " +
              (+item.type ? (item.type == '1' ? "(AM)" : "(PM)") : "")
          );
        });
      }
    });
    console.log("userLeaveDates", userLeaveDates);
    setLeaveDates(userLeaveDates);
  };
  **/
  const viewName = [
    "Month View",
    "Week View",
    ///"Day View",
    //"List View",
    //"Year View",
  ];
  const eventTimeFormat = {
    hour: "numeric",
    minute: "2-digit",
    omitZeroMinute: true,
    meridiem: true,
  };

  const handleEventClick = (info) => {
    console.log("info check", info.event.publicId);

    if (
      info.event.extendedProps.job_type != "Meeting" &&
      info.event.extendedProps.job_type != "Available"
      // &&
      //info.event.extendedProps.taskFromCurrentProject
    ) {
      if (info.event.url) {
        window.open(info.event.url);
        info.jsEvent.preventDefault();
      } else {
        setModalEventContent(info);
        setIsOpenEventModal(true);
      }
    }
  };

  const [initialEvents, setInitialEvents] = useState([]);

  const handleFilter = (filter) => {
    console.log("filter", filter);
    setCurrentFilter(filter);
    switch (filter) {
      case "Month View":
        calendarApi.changeView("dayGridMonth");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case "Week View":
        calendarApi.changeView("timeGridWeek");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case "Day View":
        calendarApi.changeView("timeGridDay");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case "List View":
        calendarApi.changeView("listWeek");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView("listYear");
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
    setMemberList(selectedTeamMembers)
  }, [selectedTeamMembers]);



   //first render set default employee
   useEffect(()=>{
    if (!onlyEmployeeView) {
      getSavedProject();
    }
      getEmployeeUnderProjectManager();
   },[])
  
  useEffect (()=>{
    setLoader(true);
  },[checkedMembers, startDate,
    endDate])

  useEffect(() => {
    getTaskList();
    console.log("clicked ");
  }, [
    selectedProject,
    startDate,
    endDate,
    isOpenScheduleModal,
    teamview,
    onlyEmployeeView,
    isOpenEventModal,
    checkedMembers,
  ]);

  const getTaskList = () => {
    let params = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      project_id: selectedProject?.id ? selectedProject?.id : null,
      page_view: teamview ? "available" : "task",
      checkedMembers: checkedMembers,
      isWeekView: currentFilter == "Month View" ? false : true,
    };

    //setLoader(true);
    getTaskApiCall(params)
      .then((res) => {
        setLoader(false);
        setTasksData(res.data.taskFormated);
        setInitialEvents(res.data.taskFormated);
        getLeaveDateForEmployee(res.data.membersLeaves);

        if (res.data?.employeeLeave && res.isEmployee) {
          setLeaveDates(res.data?.employeeLeave);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  // Define handleDateNavigation function
  function handleDateNavigation(info) {
    console.log("info new", info);
    const start = info.view.activeStart; // Start date of the current view
    const end = info.view.activeEnd; // End date of the current view
    console.log("Start date of the current view:", start);
    console.log("End date of the current view:", end);
    // You can perform any additional logic or state updates here based on the current view dates
    setStartDate(start);
    setEndDate(end);
  }

  const handleCalandarClick = (info) => {
    if (!onlyEmployeeView) {
      setModalEventContent({});
      const dayNumber = new Date(info.start).getDay();
      console.log("dayNumber", dayNumber);
      const disabledDays = [0, 6];
      if (!disabledDays.includes(dayNumber)) {
        setIsOpenScheduleModal(true);
        setScheduleStartDate(info.start);
        setScheduleEndDate(info.start);
      }
    }
  };

  const holidays = [
    "2024-04-24", // Date 1
    "2024-04-25", // Date 2
  ];

  const getIntermPaymentDates = (paymentMilestone) => {
    let paymentDate = [];
    let finalPaymentDate = [];
    if (paymentMilestone.length > 0) {
      paymentDate = paymentMilestone.map((milestone) => {
        if (milestone.type === "1") {
          return moment(milestone.due_date).format("YYYY-MM-DD");
        } else if (milestone.type === "2") {
          finalPaymentDate.push(
            moment(milestone.due_date).format("YYYY-MM-DD")
          );
        }
      });
    }
    console.log("finalPaymentDate", finalPaymentDate);
    setIntermPaymentDates(paymentDate);
    setFinalPaymentDate(finalPaymentDate);
  };

  const renderHolidayIcon = (arg) => {
    const { date } = arg;
    const iconDate = new Date(date.getTime());
    iconDate.setDate(iconDate.getDate() + 1);
    const updatedDate = iconDate.toISOString().slice(0, 10);

    const iconsToRender = [];

    // Check if payment interm date is available then show the icon
    if (intermPaymentDates.includes(updatedDate)) {
      iconsToRender.push(
        <div className="holiday-icon" key="interm-payment">
          <img src={Ipayment} alt="Interm Payment" title="Interm Payment" />
        </div>
      );
    }

    // Check if final payment date is available then show the icon
    if (finalPaymentDate.includes(updatedDate)) {
      iconsToRender.push(
        <div className="holiday-icon" key="final-payment">
          <img src={Fpayment} alt="Final Payment" title="Final Payment" />
        </div>
      );
    }

    // First Date of project
    if (projectDate && projectDate.planned_start_date === updatedDate) {
      iconsToRender.push(
        <div className="holiday-icon" key="first-day">
          <img src={FirstDay} alt="First Day" title="First Day" />
        </div>
      );
    }

    // Last Date of project
    if (projectDate && projectDate.planned_completion_date === updatedDate) {
      iconsToRender.push(
        <div className="holiday-icon" key="last-day">
          <img src={LastDay} alt="Last Day" title="Last Day" />
        </div>
      );
    }

    // Survey date
    if (projectDate && projectDate.survey_plan_date === updatedDate) {
      iconsToRender.push(
        <div className="holiday-icon" key="survey-date">
          <img src={SurveyDate} alt="Survey Date" title="Survey Date" />
        </div>
      );
    }

    // Leave date (not only employee view)
    if (leaveDates[updatedDate]) {
      iconsToRender.push(
        <div className="holiday-icon" key="leave-date">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip placement="top" className="in" id="tooltip-top">
                {leaveDates[updatedDate].map((date, index) => (
                  <div key={index}>{date}</div>
                ))}
              </Tooltip>
            }
          >
            <img src={Leave} alt="Leave" />
          </OverlayTrigger>
        </div>
      );
    }

    // Leave date (only employee view)
    /*  if (onlyEmployeeView && leaveDates.includes(updatedDate)) {
      iconsToRender.push(
        <div className="holiday-icon" key="leave-date-employee">
          <img src={Leave} alt="Leave" title="Leave Date" />
        </div>
      );
    } */

    return (
      <div>
        <div className="fc-daygrid-day-number d-flex ">{arg.dayNumberText}</div>
        <div className="holiday-icon-cover">{iconsToRender}</div>
      </div>
    );
  };

  //handle schedule task edit
  const handleEventEdit = (id) => {
    console.log("edit task", id);
    setIsOpenEventModal(false);
    setIsOpenScheduleModal(true);
    setScheduleStartDate(modalEventContent.event.start);
  };

  //handle Team availability view switch
  const handleTeamAvailablityView = () => {
    setTeamview(!teamview);
  };


  
  //handle render event content
  const handleRenderEventContent = (arg) => {
    const { event } = arg;

    return (
      <div className="w-100 ">
        {teamview ? (
          <DynamicClassComponent className={event.classNames[0]}>
            <div>{event._def?.extendedProps?.assignee?.full_name} </div>
          </DynamicClassComponent>
        ) : (
          <>
            <div
              className={`fs-14 fw-semibold rounded-1 ${
                event._def?.extendedProps?.isPrevious
                  ? ""
                  : " ms-2" + " " + event.classNames[0]
              }  g-2`}
            >
              {event._def?.extendedProps?.assignee?.full_name && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip placement="top" className="in" id="tooltip-top">
                      {event._def?.extendedProps?.assignee?.full_name} |
                      {event._def?.extendedProps?.job_type == "Meeting" ? (
                        <span>&nbsp;Meeting-{event.title}</span>
                      ) : event._def?.extendedProps?.job_type == "Leave" &&
                        currentFilter != "Month View" ? (
                        <span>{event.title}</span>
                      ) : (
                        <span>
                          &nbsp;GR-
                          {event._def?.extendedProps?.project?.project_no}(
                          {event._def?.extendedProps?.project?.name}) -
                          <span className="">&nbsp;{event.title}</span>
                        </span>
                      )}
                    </Tooltip>
                  }
                >
                  <div className="text-truncate mw-100">
                    <span className={`${event.classNames[0]}`}>
                      {event._def?.extendedProps?.isPrevious && (
                        <span
                          className={`previous-${getJobTypeClass(
                            event._def?.extendedProps?.job_type
                          )}`}
                        ></span>
                      )}
                      {teamview} {title}
                      &nbsp; {
                        event._def?.extendedProps?.assignee?.full_name
                      } |{" "}
                    </span>
                    {event._def?.extendedProps?.job_type == "Meeting" ? (
                      <span>
                        <span>Meeting-{event.title}</span>
                      </span>
                    ) : event._def?.extendedProps?.job_type == "Leave" ? (
                      <span>
                        <span>
                          {title} - {event.title}
                        </span>
                      </span>
                    ) : (
                      <>
                        <span>
                          GR-{event._def?.extendedProps?.project?.project_no} -
                        </span>
                        <span className="">{event.title}</span>
                      </>
                    )}
                    {event._def?.extendedProps?.status == "completed" && (
                      <span className="completedIndecate" title="completed">
                        &nbsp;
                      </span>
                    )}
                    {event._def?.extendedProps?.status == "pending" &&
                      event._def?.extendedProps?.isPrevious && (
                        <span className="pendingIndecate" title="pending">
                          &nbsp;
                        </span>
                      )}
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </>
        )}
      </div>
    );

    /* return (
      <div className="d-flex justify-content-between">
        <div>{arg.timeText}</div>
        <div>{arg.event.title}</div>
      </div>
    ); */
  };

  //for week day custom header
  const customTimeGridDayHeaderContent = ({ date }) => {
    const dayOfWeek = date.toLocaleString("default", { weekday: "short" });
    const dayOfMonth = date.getDate();
    //if date is today then show title as today
    const today = new Date();
    return (
      <div
        className={
          today.toDateString() === date.toDateString() ? "bg-today" : ""
        }
      >
        <div>{dayOfWeek}</div>
        <div className="fs-18 fw-bold">{dayOfMonth}</div>
      </div>
    );
  };

  const customDayGridDayHeaderContent = ({ date }) => {
    const dayOfWeek = date.toLocaleString("default", { weekday: "long" });
    const dayOfMonth = date.getDate();
    //if date is today then show title as today
    const today = new Date();
    return (
      <div className="  month-view-header">
        <div>{dayOfWeek}</div>
      </div>
    );
  };

  const handleMapChange = () => {
    setIsMapView((isMapView) => !isMapView);
  };

  const handleDayRender = (info) => {
    console.log("infor=============", info);
    const dateToHighlight = "2024-05-07"; // Date to highlight
    if (info.date.toISOString().split("T")[0] === dateToHighlight) {
      const dayCell = info.el;
      dayCell.style.backgroundColor = "red"; // Set background color
      dayCell.style.borderRadius = "50%"; // Optional: make the cell circular
    }
  };

  const getSavedProject = () => {
    let projectData = storage.getPlannerFilter("plannerProject");
    if (projectData) {
      setSelectedProject(projectData);
      if (projectData?.planned_completion_date && projectData?.planned_start_date) {
        setProjectDate(projectData);
        setProjectStartDate(projectData.planned_start_date);
        setProjectEndDate(projectData.planned_completion_date);
      }
    }
  }

  return (
    <>
      <Card className="bg-primary-opacity mb-2 rounded-3">
        <Card.Body className="card-body py-1">
          <Row className="row align-items-center">
            {!onlyEmployeeView && (
              <Col md={6}>
                <Row>
                  <Col className="col-auto d-flex planner-select">
                    <img src={Tactic} className="w-16" />
                    <FormAsyncSelect
                      id="orderNumber"
                      name="orderNumber"
                      label=""
                      isAsync
                      getOptions={getOrderNumber}
                      value={selectedProject}
                      isClearable
                      onChange={(name, value) => {
                        handleProjectChange(name, value);
                      }}
                    />
                  </Col>
                  {projectStartDate && (
                    <Col className="col-auto planner-border-left">
                      <div className="d-flex">
                        <div className="me-2">
                          <label className="fs-12">Start Date:</label>
                          <div className="fs-14">
                            {moment(projectStartDate).format(
                              constants.dateFormats.slashSeparatedDate
                            )}
                          </div>
                        </div>
                        <div>
                          <label className="fs-12">End Date: </label>
                          <div className="fs-14">
                            {moment(projectEndDate).format(
                              constants.dateFormats.slashSeparatedDate
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            )}

            <Col md={onlyEmployeeView ? 12 : 6}>
              <Row className="justify-content-end align-items-center">
                {!onlyEmployeeView && selectedProject?.id && (
                  <Col className="col-auto planner-border-left py-2">
                    <div
                      className="team-view cursor-pointer link-text"
                      onClick={handleTeamAvailablityView}
                    >
                      <Groups2Icon className="fs-18 me-2" />
                      {!teamview ? "Team Availability View" : "Task View"}
                    </div>
                  </Col>
                )}
                <Col className="col-auto planner-border-left py-2 d-flex">
                  <img src={CalendarIcon} />
                  <select
                    className="bg-transparent border-0"
                    id="view-select"
                    onChange={(e) => handleFilter(e.target.value)}
                  >
                    {viewName.map((view) => (
                      <option key={view} value={view}>
                        {view}
                      </option>
                    ))}
                  </select>
                </Col>
                {!onlyEmployeeView && (
                  <Col className="col-auto planner-border-left text-black py-2">
                    Map View{" "}
                    <Toggle
                      checked={isMapView}
                      className="ms-2"
                      onChange={handleMapChange}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className="">
          <Row className="align-items-center gx-0 mb-2">
            <Col xs="auto" className="d-flex justify-content-end ">
              <h4 className="mb-0 fs-16 fw-bold">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end ">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip
                    style={{
                      position: "fixed",
                    }}
                    id="nextTooltip"
                  >
                    Previous
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2 text-black"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <MdOutlineKeyboardArrowLeft />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip
                    style={{
                      position: "fixed",
                    }}
                    id="previousTooltip"
                  >
                    Next
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2 text-black"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <MdOutlineKeyboardArrowRight />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end ">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Today
              </Button>
            </Col>
          </Row>

          <div className="row">
            <div className="col-md-12 planner-calendar">
              <FullCalendar
                ref={calendarRef}
                headerToolbar={false}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                initialView="dayGridMonth"
                themeSystem="bootstrap"
                dayMaxEvents={20} //how many event want to show in the box
                direction={"ltr"}
                //slotMinTime="08:00:00"  // Start time
                //slotMaxTime="17:00:00"  // End time
                daysOfWeek={(0, 1)}
                rerenderDelay={1}
                height={800}
                stickyHeaderDates={false}
                editable={false}
                selectable={true}
                selectMirror
                firstDay={1}
                focus={true}
                select={(info) => {
                  handleCalandarClick(info);
                }}
                eventTimeFormat={eventTimeFormat}
                eventClick={handleEventClick}
                events={initialEvents}
                eventContent={handleRenderEventContent}
                datesSet={handleDateNavigation} // Handle date navigation event
                businessHours={
                  {
                    // daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
                    //startTime: "08:00", // 8am
                    // endTime: "17:00", // 5pm
                  }
                }
                //in the weekview the date show like Mon 13 not like Mon 13/4
                //adn in month view the day show full like Monday, Tuesday
                views={{
                  timeGrid: {
                    //dayHeaderFormat: { weekday: "long", day: "numeric",  },
                    dayHeaderContent: customTimeGridDayHeaderContent,
                  },
                  dayGrid: {
                    dayHeaderFormat: { weekday: "long" },
                  },
                }}
                dayCellContent={renderHolidayIcon}
                background="red"
                allDaySlot={true}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <AddScheduleModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        scheduleStartDate={scheduleStartDate}
        scheduleEndDate={scheduleEndDate}
        setScheduleStartDate={setScheduleStartDate}
        setScheduleEndDate={setScheduleEndDate}
        memberList={memberList}
        selectedTask={modalEventContent}
        projectID={selectedProject}
         
      />
      <CalendarEventModal
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
        modalEventContent={modalEventContent}
        handleEventEdit={handleEventEdit}
        onlyEmployeeView={onlyEmployeeView}
        projectDocuments={projectDocuments}
        projectId={selectedProject?.id}
      />
      <Loader isLoading={loader} />
    </>
  );
};

export default Calendar;
