import react, { useState, useEffect, useRef } from "react";
import React, { FC } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import CustomButton from "../../../components/common/CustomButton";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";
import { ButtonGroup } from "@mui/material";
//import FormAsyncSelect from "../../../components/common/AsyncSelect/FormAsyncSelect";
import FormSelect from "../../../components/common/FormSelect";
import { Formik } from "formik";
import * as Yup from "yup";
import InputText from "../../../components/common/InputText";


interface MarkAsCompleteProps {
    isOpen: boolean;
    onClose: () => void;
    submitDetails: (values: { rootCause: string | null; timeSpend: number | null }) => any;
}
const MarkAsComplete : FC<MarkAsCompleteProps>  = ({
    isOpen, onClose, submitDetails
}) => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [ rootCauseList, setRootCauseList ] = useState([
    {value: "Workmanship", key: "Workmanship"},
    {value: "Product fault / Within-warranty", key: "Product fault / Within-warranty"},
    {value: "Product fault / Outside-warranty", key: "Product fault / Outside-warranty"},
    {value: "Customer Decision", key: "Customer Decision"},
  ]);
  const [ spendTimeHoursList, setSpendTimeHoursList ] = useState([
    {value: 1, key: 1},
    {value: 2, key: 2},
    {value: 3, key: 3},
    {value: 4, key: 4},
    {value: 5, key: 5},
    {value: 6, key: 6},
    {value: 7, key: 7},
    {value: 8, key: 8},
    {value: 9, key: 9},
    {value: 10, key: 10},
    {value: 11, key: 11},
    {value: 12, key: 12},
  ]);

  
  useEffect(() => {
    
  }, []);

  const submitForm = async (values: any) => {
    let postValues: any = {
        root_cause: values.rootCause.value,
        time_spent_on_call: values.timeSpend.value,
    }
    submitDetails(postValues);
  }

  const validationSchema  = Yup.object().shape({
    rootCause: Yup.object().required().label("Root Cause")/* .typeError('Root Cause must be select') */,  
    timeSpend: Yup.object().required().label("Spend Time")/* .typeError('Time spend must be select') */,
  });

  const initialValues = {
    rootCause: null,
    timeSpend: null,
  };
  
  
  return (
    <React.Fragment>
      <Modal
        show={isOpen}
        onHide={onClose}
        centered
        className="modal-middle-size"
      >
        <Modal.Header closeButton className="border-0 mb-0 pb-0">
          <Modal.Title className="fs-24 fw-bold info-text ">Mark Complete</Modal.Title>
      
        </Modal.Header>
        <Modal.Body>
          {/* <p className="fs-14">
            {type=='add'?'You Are Add The Product Item On Behalf Of Survey.':'You Are Change The Product Item On Behalf Of Survey.'}
          </p> */}
 
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
          
            <Form className="text-start" noValidate onSubmit={handleSubmit}>
                
              <Row>
                <Col className="col-md-6 col-xl-6">
                    <FormSelect
                        placeholder="Select"
                        label="Root Cause"
                        name="rootCause"
                        options={rootCauseList}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.key}
                        onChange={(name: string, value: string) => {
                          setFieldValue(name, value);
                        }}
                        value={values.rootCause}
                        error={errors.rootCause}
                        touched={touched.rootCause}
                    />
                </Col>
                <Col className="col-md-6 col-xl-6">
                    <FormSelect
                        placeholder="Select"
                        label="Time Spend on Call"
                        name="timeSpend"
                        options={spendTimeHoursList}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.key}
                        onChange={(name: string, value: string) => {
                          setFieldValue(name, value);
                        }}
                        value={values.timeSpend}
                        error={errors.timeSpend}
                        touched={touched.timeSpend}
                    />
                </Col>
            </Row> 
            <Row>
              <Col className="col-md-12 col-xl-12">
                <div className="d-flex justify-content-end">
                  <button
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`fw-semibold fs-13 text-white mw-90 mt-2 btn btn-primary`}
                  >
                    {'Mark Complete'}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
          )}
          </Formik>
        </Modal.Body>
      </Modal>
      {loading && <Loader isLoading={loading} />}
    </React.Fragment>
  );
};
export default MarkAsComplete;
