import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridActionsCellItem,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewIcon from "@mui/icons-material/RemoveRedEye";

import DeletePopup from "../../components/common/deletePopup";
import { toast } from "react-toastify";
import {
  getVendorList,
  updateVendorStatusApi,
  deleteVendorApi,
} from "../../services/vendorService";
import InputSwitchBox from "../../components/common/InputSwitchBox";
import { Card, Col, Row } from "react-bootstrap";
import AddCircle from "../../assets/images/add_circle.svg";
import { pageSetting, pageSizeModel } from "../../constants/constants";
import { checkAbility } from "../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../constants/permissionUser";
import BlockIcon from "@mui/icons-material/Block"
import FileUploadIcon from "../../assets/images/file-upload.svg";
import ExportVendorData from "./exportData";

const AllVendor: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
  const [deletedId, setDeletedId] = useState<GridRowId | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "role",
      sort: "desc",
    },
  ]);
  const [exportPopup,setExportPopup] = useState(false);

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  const addVendor = () => {
    navigate("add");
  };

  const handleEditClick = (id: GridRowId) => () => {
    navigate(`/vendor/edit/${id}`);
  };

  const handleViewClick = (id: GridRowId) => () => {
    navigate(`/vendor/view/${id}`);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeletePopupOpen(true);
    setDeletedId(id);
  };

  const handleDelete = () => {
    setLoading(false);
    deleteVendorApi(deletedId)
      .then((response) => {
        toast(response.message, { type: toast.TYPE.ERROR });
        setDeletePopupOpen(false);
        setIsMutation(!isMutation);
      })
      .catch((error) => {
        setDeletePopupOpen(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    ...(search ? { search } : {}),
  };
  // const [paginationModel, setPaginationModel] = React.useState({
  //   page: 0,
  //   pageSize: PAGE_SIZE,
  // });
  const fetchData = (defaultParams: any) => {
    getVendorList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const handleStatusChange = (data: any) => {
    const id = data.row.id;
    const formData = {
      status: data.row.status,
    };
    updateVendorStatusApi(id, formData)
      .then((response) => {
        setIsMutation(!isMutation);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      })
      .catch((error) => {
        setDeletePopupOpen(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
        setIsMutation(!isMutation);
      })
      .finally(() => {
        setIsMutation(!isMutation);
      });
  };

  const columns: GridColDef[] = [
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      flex: 1,
      sortable: true,
      hideable:false,
    },
    {
      field: "contact_person",
      headerName: "Contact Person",
      flex: 1,
      sortable: true,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      sortable: true,
    },
    { field: "email", headerName: "Email/Website", flex: 2, sortable: true },
    { field: "vendor_address", headerName: "Address", flex: 2, sortable: true },
    {
      field: "preferred_payment_method",
      headerName: "Preferred Payment Method",
      flex: 2,
      sortable: true,
    },
    {
      field: "vendor_type",
      headerName: "PO Raised Status",
      flex: 2,
      sortable: true,
    },    
  ];

  const handleEditViewActionPermission = () => {
    const checkEditAbilityCondition = checkAbility(
      PERMISSION_ACCESS.EDIT,
      PERMISSION_MODULE.VENDOR_MANAGEMENT
    );
    const checkViewAbilityCondition = checkAbility(
      PERMISSION_ACCESS.VIEW,
      PERMISSION_MODULE.VENDOR_MANAGEMENT
    );
    const checkDeleteAbilityCondition = checkAbility(
      PERMISSION_ACCESS.DELETE,
      PERMISSION_MODULE.VENDOR_MANAGEMENT
    );

    if (checkEditAbilityCondition) {
      columns.push({
        field: "status",
        headerName: "Active",
        flex: 1,
        sortable: true,
        renderCell(params) {
          return (
            <InputSwitchBox
              handleChange={(event: any) => {
                handleStatusChange(params);
              }}
              controlId="status"
              placeholder="status"
              label=""
              value={params.row.status == "active" ? true : false}
            />
          );
        },
      });
    }

    if (
      checkEditAbilityCondition ||
      checkViewAbilityCondition ||
      checkDeleteAbilityCondition
    ) {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const gridActions = [];
          if (checkEditAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Edit"
                icon={<EditIcon />}
                showInMenu
                onClick={handleEditClick(id)}
              />
            );
          }
          if (checkViewAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="View"
                icon={<ViewIcon />}
                showInMenu
                onClick={handleViewClick(id)}
              />
            );
          }
          if (checkDeleteAbilityCondition) {
            gridActions.push(
              <GridActionsCellItem
                label="Delete"
                icon={<DeleteIcon />}
                showInMenu
                onClick={handleDeleteClick(id)}
              />
            );
          }
          return gridActions;
        },
      });
    } else {
      columns.push({
        field: "actions",
        type: "actions",
        headerName: "Action",
        flex: 1,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              label="No Action"
              icon={<BlockIcon />}
              showInMenu
              // You can handle the click event if needed
              onClick={() => {}}
            />,
          ];
        },
      });
    }
  };

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    //console.log("newPaginationModel",newPaginationModel)
    setLimit(newPaginationModel.pageSize);
    setPage(newPaginationModel.page + 1);
    setPaginationModel(newPaginationModel);
  };

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

  handleEditViewActionPermission();
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 mt-2 mb-0">Vendor Management</h1>
              </Col>
              <Col className="text-end">
              {exportPopup && <ExportVendorData isOpen={exportPopup} onClose={toggleExportPopup} exportType="deal" label="Export Vendors" text="Export Vendors" />} 
                  <button
                    className="fw-semibold fs-12 text-white  btn btn-info me-2 px-2"
                    title="Export Vendors"
                    onClick={toggleExportPopup}
                  >
                    <img src={FileUploadIcon} alt="File Upload Icon" />
                  </button>
                  {checkAbility(
                  PERMISSION_ACCESS.ADD,
                  PERMISSION_MODULE.VENDOR_MANAGEMENT
                ) && (
                  <Button
                    className="fw-semibold fs-12 text-white  btn btn-primary"
                    variant="primary"
                    type="button"
                    onClick={() => addVendor()}
                  >
                    <img
                      src={AddCircle}
                      className="me-2 fs-15"
                      alt="File Upload Icon"
                    />
                    Add Vendor
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className=" w-100 pageContentInner">
              <div className="d-flex flex-column  rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  className="border-0 rounded-2"
                />
                <DeletePopup
                  isOpen={isDeletePopupOpen}
                  onClose={handleClose}
                  onDelete={handleDelete}
                  label="Delete Vendor"
                  text="Are you sure you want to delete this vendor?"
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AllVendor;
