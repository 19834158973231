import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Toggle } from "rsuite";
import GoogleMapReact from "google-map-react";
import Loader from "../../components/common/Loader";
import locationIcon from "../../assets/images/location_marker.svg";
import { getProjectLocationsApi } from "../../services/task.service";
import { MAP_KEY } from "../../constants/constants";

interface MapLocationProps {
  isMapView: boolean; // Change the type to whatever type is appropriate
  setIsMapView: React.Dispatch<React.SetStateAction<boolean>>; // Assuming setIsMapView is a function that updates a boolean state
  address?: string;
}

interface AnyReactComponentProps {
  lat: number;
  lng: number;
  icon?: string;
  handleClick?: () => void;
  title?: string;
  address?: string;
  name?: string;
}

const MapLocation: React.FC<MapLocationProps> = ({
  isMapView,
  setIsMapView,
}) => {
  const [coordinates, setCoordinates] = useState([
    { lat: 52.732620, lng: 0.412750, projectOrderNo: '', address:'', name:'' },
  ]);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState<any[]>([]);

  const handleMapChange = () => {
    setIsMapView(!isMapView);
  };

  const defaultProps = {
    center: {
      lat: 23.18933,
      lng: 77.43939,
    },
    zoom: 15,
  };

  useEffect(() => {
    getProjectLocation();
  }, []);

  const getProjectLocation = () => {
    getProjectLocationsApi()
      .then((res) => {
        console.log("address", res.data);
        setAddress(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (address.length > 0) {
      fetchCoordinates();
    }else{
      setLoading(false);
    }
  }, [address]);

  const fetchCoordinates = async () => {
    try {
      let newCoordinates: any = [];
      for (let i = 0; i < address.length; i++) {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address[i].address
          )}&key=${MAP_KEY}`
        );

        const data = await response.json();
       
        if (data && data?.results.length > 0) {
          const location = data.results[0].geometry.location;
          console.log("location", location);
          newCoordinates = [
            ...newCoordinates,
            { ...location, projectOrderNo: address[i].project_no_str,
              address : address[i].address,
              name : address[i].name
            },
          ];
        }  
      }
       setCoordinates(newCoordinates);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  const handleRedirectToMap = (address?: string) => {
    //redirect to google map with address
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`);
  }
  const AnyReactComponent: React.FC<AnyReactComponentProps> = ({
    icon,     
    title,
    address,
    name,
    
  }) => (
    <div  title={title} onClick={()=>handleRedirectToMap(address)}>
      {title && 
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip placement="top" className="in" id="tooltip-top">
           <div><b>Project No : </b> {title}</div>
           <div><b>Address : </b> {address}</div>
            <div className="text-capitalize"><b>Client Name : </b> {name}</div>

          </Tooltip>
        }
      >
        <img src={icon} alt="" />
      </OverlayTrigger> }
    </div>
  );
 

  return (
    <>
      <Card className="bg-primary-opacity mb-2 rounded-3">
        <Card.Body className="card-body py-1">
          <Row className="row align-items-center">
            <Col md={12}>
              <Row className="justify-content-end align-items-center">
                <Col className="col-auto planner-border-left text-black py-2">
                  Map View{" "}
                  <Toggle
                    checked={isMapView}
                    className="ms-2"
                    onChange={handleMapChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className=" mb-2 rounded-3 overflow-hidden">
      <div style={{ height: "calc(100vh - 148px)", width: "100%" }}>
         
        {loading ? (
          <Loader isLoading={loading} />
        ) : (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBO1imYPo-qdinIh6lkqPAi2PZhlO1rLEo",
            }}
            center={coordinates[0]}
            zoom={15}
             
          >
            {coordinates.length &&
              coordinates.map((val) => {
                return (
                  <AnyReactComponent
                    lat={val.lat}
                    lng={val.lng}
                    icon={locationIcon}
                    title={val.projectOrderNo}
                    address={val.address}
                    name={val.name}
                     
                  />
                );
              })}
          </GoogleMapReact>
        )}
      </div>
       </Card> 
    </>
  );
};

export default MapLocation;
