import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row, Spinner } from "react-bootstrap";

import { ErrorMessage, FieldArray, Formik } from "formik";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../../components/common/InputText";
import { PoDataDTO, ReceivedProduct } from "./po.type";
import AddCircle from "../../../../assets/images/add_circle.svg";
import FormSelect from "../../../../components/common/FormSelect";
import FileAddIcon from "../../../../assets/images/file-add-icon.svg";
import constants, {
  GrnQualityCheckStatus,
} from "../../../../constants/constants";
import CustomButton from "../../../../components/common/CustomButton";

import Loader from "../../../../components/common/Loader";
import FileUploader from "../../../../components/common/FileUploader";
import {
  createNewGrnApi,
  getGrnNumber,
  getPODetailById,
  uploadGrnReturnImageApi,
} from "../../../../services/warehouseService";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import InputTextArea from "../../../../components/common/InputTextArea";
import moment from "moment";
import grnSchema from "./validation";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../../features/Auth/authSlice";
import AddPhotoAlternate from "../../../../assets/images/add_photo_alternate.svg";
import DeleteIcon from "@mui/icons-material/Cancel"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { currencyMask } from "../../../../utility/common";
import InputCurrencyText from "../../../../components/common/InputCurrencyText";

export const AddNewGoodsInwardNote = () => {
  const { user }: any = useSelector(selectAuthentication);

  const userName = user.user.full_name;
  const userId = user.user.id;
  const [grnNumber, setGrnNumbers] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [poData, setPoData] = useState<any>(null);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [productArray, setProductArray] = useState<ReceivedProduct[]>();
  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
  ];
  const maxFileSizeMB = 1;

  let { poId } = useParams();

  /**
   * Submit Method to call when grn save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: PoDataDTO, { setSubmitting }: any) => {
    const formData: any = {
      ...values,
      received_date: moment(values.received_date).format(constants.dateFormats.databaseFormat),
      return_date: moment(values.return_date).isValid() ? moment(values.return_date).format(constants.dateFormats.databaseFormat) : null,
    };
    delete formData.po_number;
    delete formData.product_id;
    delete formData.product_price;
    delete formData.product_qty;
    // console.log(formData);
    //return;
    setBtnLoading(true);
    if (poId) {
      createNewGrnApi(poId, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/good-inward");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } 
  };

  const updateRecevicedProductArray = (poData: any) => {
    // Create initial values based on products
    const initialPoProducts = poData.orderProducts ? poData.orderProducts : [];
    const initialReceivedProducts: any[] = initialPoProducts
      .map((product: ReceivedProduct) => ({
        inspected_by: userId,
        balance_qty: product.qty - product.received_qty,
        productDetail: product,
        purchase_order_product_id: product.id,
        product_id: product.product_id,
        qty_received: 0,
        qty_returned: 0,
        qty_discard: 0,
        quality_status: null,
        return_remarks: "",
        images: [],
        imagesPreview: [],
      }))
      .filter((receivedProduct: ReceivedProduct) => receivedProduct.balance_qty !== 0);

    setProductArray(initialReceivedProducts);
    initialValues.receivedProducts = initialReceivedProducts;
    setLoading(false)
  };

  const getPoDetail = (id: string) => {
    setLoading(true);
    getPODetailById(id)
      .then((response) => {
        setPoData(response.data);
        updateRecevicedProductArray(response.data);       
       // setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/good-inward");
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
          navigate("/good-inward");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
          navigate("/good-inward");
        }
      })
      .finally(() => {
       // setLoading(false);
      });
  };

  useEffect(() => {
    Promise.all([fetchGrnNumber()]).then(() => {
      if (poId) {
        getPoDetail(poId);
      } else {
        setLoading(false);
      }
    });
  }, [poId]);

  useEffect(() => {
    if (poData) {
      // console.log(poData);
      updateRecevicedProductArray(poData);
    }
  }, [poData]);

  const handleCancel = () => {
    navigate(-1);
  };

  const fetchGrnNumber = async () => {
    return new Promise((resolve, reject) => {
      if (poId) {
        getGrnNumber().then((response) => {
          setGrnNumbers(response?.data?.grn);
          return resolve(true);
        });
      } else {
      }
    });
  };

  const handleProducts = (formValues: any, setFieldValue: any) => {
    if (formValues.product_qty > 0 && formValues.product_price > 0) {
      const fieldDataObj = {
        product_id: formValues.product_id?.id,
        qty: formValues.product_qty,
        price: formValues.product_price,
        amount: Number(
          parseInt(formValues.product_qty) *
            parseFloat(formValues.product_price)
        ),
        product: {
          name: formValues.product_id?.name,
        },
      };
      const productArray = [...formValues.orderProducts];
      // console.log(fieldDataObj, productArray);
      productArray.push(fieldDataObj);
      setFieldValue("orderProducts", productArray);
      setFieldValue("product_id", null);
      setFieldValue("product_qty", "");
      setFieldValue("product_price", "");
    }
    //  shelvesArrr.push(sleveValue);
    /*  if (sleveValue.trim() !== "" && !sleveErrors) {
      const exists: boolean = shelfNumbers.some(
        (obj: any) => obj.shelf_number === sleveValue.trim()
      );
      // Check file type
      if (exists) {
        toast(`${sleveValue} is already added.`, {
          type: toast.TYPE.ERROR,
        });
        return;
      }
      const shelfNumberVal = { shelf_number: sleveValue.trim() };
      setShelfNumbers((prevShelfNumbers: any) => [
        ...prevShelfNumbers,
        shelfNumberVal,
      ]);
      // Clear the input value
      setFieldValue("shelf_number", "");
    } */
  };

  const handleDeleteReturnImage = (
    formValues: any,
    indexNumber: number,
    imageIndex: number,
    setFieldValue: any
  ) => {
    const failedGrnImageDetails = formValues.receivedProducts[indexNumber].images;
    const failedGrnImagePreview = formValues.receivedProducts[indexNumber].imagesPreview;
    failedGrnImageDetails.splice(imageIndex, 1);
    failedGrnImagePreview.splice(imageIndex, 1);
    setFieldValue(`receivedProducts[${indexNumber}].images`, failedGrnImageDetails);
    setFieldValue(`receivedProducts[${indexNumber}].imagesPreview`, failedGrnImagePreview);
  };
  // console.log(poData);
  const uploadProfileImage = (event: any, formValues: any, indexNumber: number, setFieldValue: any) => {
    const imageFile = event.target.files[0];
    // console.log("file", imageFile);

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${maxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setImageLoading(true);
      
      setImagePreview(null);

      // Create FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append("file", imageFile);
      const failedGrnImageDetails = formValues.receivedProducts[indexNumber].images;
      const failedGrnImagePreview = formValues.receivedProducts[indexNumber].imagesPreview;     
    //  return;
      uploadGrnReturnImageApi(formData)
        .then((response) => {
          failedGrnImageDetails.push(response.data.file)
          failedGrnImagePreview.push(response.data.signedURL)
          setImageLoading(false);
          setFieldValue(`receivedProducts[${indexNumber}].images`, failedGrnImageDetails);
          setFieldValue(`receivedProducts[${indexNumber}].imagesPreview`, failedGrnImagePreview);
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };

  const initialValues: any = {
    received_date: null,
    remarks: "",
    return_date: null,
    receivedProducts: poData?.orderProducts?.length ? productArray : [],    
  };
  // console.log(initialValues, productArray);
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">Goods Inward Note</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={grnSchema(poId)}
              initialValues={initialValues}
              onSubmit={(values: PoDataDTO, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row className="purchase-top-form">
                          <Col className="col-md-2">
                            <label className="control-label fs-14 fw-bold">
                              PO Number
                            </label>
                            <div className="fs-14 mb-3">
                              <Link
                                target="_blank"
                                className="blue-text text-decoration-none fw-semibold"
                                to={`/purchase-orders/view/${poId}`}
                              >
                                {" "}
                                {poData.po_number_str}
                              </Link>
                            </div>
                          </Col>
                          <Col className="col-md-2">
                            <label className="control-label fs-14 fw-bold">
                              Good Recieved No.
                            </label>
                            <div className="fs-14 mb-3">{grnNumber}</div>
                          </Col>
                          <Col className="col-md-2">
                            <label className="control-label fs-14 fw-bold">
                              Project Order Number
                            </label>
                            <div className="fs-14 mb-3 blue-text">
                              {poData?.contract_id ? (
                                <>
                                {/* {poData?.project?.project_no} */}
                                <Link
                                  target="_blank"
                                  className="blue-text text-decoration-none fw-semibold"
                                  to={`/projects/view/${poData?.contract_id}`}
                                >
                                  {" "}
                                  {poData?.project?.project_no}
                                </Link>
                                </>
                              ) : (
                                "-"
                              )}
                            </div>
                          </Col>
                          <Col className="col-md-2">
                            <label className="control-label fs-14 fw-bold">
                              Ref Number
                            </label>
                            <div className="fs-14 mb-3">
                              {poData?.ref_number}
                            </div>
                          </Col>
                          <Col className="col-md-2">
                            <label className="control-label fs-14 fw-bold">
                              Order Date
                            </label>
                            <div className="fs-14 mb-3">
                              {moment(poData.date).format(
                                constants.dateFormats.slashSeparatedDate
                              )}
                            </div>
                          </Col>
                          <Col className="col-md-2">
                            <label className="control-label fs-14 fw-bold">
                              Vendor
                            </label>
                            <div className="fs-14 mb-3">
                              {poData.vendor.vendor_name}
                            </div>
                          </Col>

                          <Col className="col-md-2">
                            <InputDatePicker
                              name="received_date"
                              label="Recieved Date"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  placeholder: "Select",
                                  className: "form-control",
                                  readOnly: true,
                                  id: "received_date",
                                },
                              }}
                              value={values.received_date}
                              onChange={(name, newValue) =>
                                setFieldValue(name, newValue)
                              }
                              touched={touched.received_date}
                              errors={errors.received_date}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    <FieldArray
                      name="receivedProducts"
                      render={(arrayHelpers) => (
                        <div>
                          {values.receivedProducts.map(
                            (entry: any, index: any) => (
                              <div key={`order_products_grn${index}`}>
                                <Card className="p-3 w-100 pageContentInner mb-3">
                                  <Row className="border-bottom py-3 pt-0">
                                    <Col className="fs-16 fw-bold">
                                      {entry?.productDetail?.product?.hs_sku ? entry?.productDetail.product?.hs_sku + " - " : ""}
                                      {entry?.productDetail?.product?.name}
                                    </Col>

                                    <Col className="text-end fs-14 d-flex align-items-center justify-content-end">
                                      <span className="control-label fs-14 fw-bold me-2">
                                        Quality Inspector:
                                      </span>
                                      {userName}
                                      <span className="ms-2">
                                        {values.receivedProducts[index]
                                          ?.quality_status?.key === "Fail" ? (
                                          <Button
                                            type="button"
                                            className="btn btn-primary text-white w-32 h-32 p-0"
                                          >
                                            <ExpandLessIcon />
                                          </Button>
                                        ) : (
                                          <Button
                                            type="button"
                                            className="btn btn-secondary w-32 h-32 p-0"
                                          >
                                            <ExpandMoreIcon />
                                          </Button>
                                        )}
                                      </span>
                                    </Col>
                                  </Row>

                                  <Row className="py-3">
                                    <Col className="col-md-2">
                                      <div>
                                        <span className="control-label fs-14 fw-bold me-2">
                                          Price:
                                        </span>{" "}
                                        {currencyMask(
                                          entry?.productDetail?.price
                                        )}
                                      </div>
                                      <div>
                                        <span className="control-label fs-14 fw-bold me-2">
                                          Qty Ordered:
                                        </span>{" "}
                                        {entry?.balance_qty}
                                      </div>
                                    </Col>
                                    <Col className="col-md-2">
                                      <div className="fs-14">
                                        <span className="control-label fs-14 fw-bold me-2">
                                          Amount:
                                        </span>{" "}
                                        {currencyMask(
                                          entry?.productDetail?.amount
                                        )}
                                      </div>
                                    </Col>
                                    <Col className="col-md-2">
                                      <InputCurrencyText
                                        name={`receivedProducts[${index}].qty_received`}
                                        label="Qty. Received"
                                        placeholder="Qty."                        
                                        value={
                                          values.receivedProducts[index]
                                            ?.qty_received
                                        }
                                        isInteger={true}
                                        onChange={(value, name) =>{
                                          setFieldValue(`receivedProducts[${index}].qty_received`, value);
                                        }}                                       
                                      />                                      
                                      <ErrorMessage
                                        name={`receivedProducts[${index}].qty_received`}
                                        component="div"
                                        className="text-danger fs-14"
                                      />
                                    </Col>
                                    <Col className="col-md-2">
                                    <InputCurrencyText
                                        name={`receivedProducts[${index}].qty_returned`}
                                        label="Qty. Returned"
                                        placeholder="Qty."                        
                                        value={
                                          values.receivedProducts[index]
                                            ?.qty_returned
                                        }
                                        isInteger={true}
                                        onChange={(value, name) =>{
                                          setFieldValue(`receivedProducts[${index}].qty_returned`, value);
                                        }}                                       
                                      /> 
                                      {/* <InputText
                                        controlId={`receivedProducts[${index}].qty_returned`}
                                        label="Qty. Returned"
                                        placeholder="Qty."
                                        // touched={touched?.receivedProducts[index]?.qty_returned}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        // errorsField={errors?.receivedProducts[index]?.qty_returned}
                                        value={
                                          values.receivedProducts[index]
                                            ?.qty_returned
                                        }
                                      /> */}
                                      <ErrorMessage
                                        name={`receivedProducts[${index}].qty_returned`}
                                        component="div"
                                        className="text-danger fs-14"
                                      />
                                    </Col>
                                    <Col className="col-md-2">
                                      <InputCurrencyText
                                        name={`receivedProducts[${index}].qty_discard`}
                                        label="Qty. Discard"
                                        placeholder="Qty."                      
                                        value={
                                          values.receivedProducts[index]
                                            ?.qty_discard
                                        }
                                        isInteger={true}
                                        onChange={(value, name) =>{
                                          setFieldValue(`receivedProducts[${index}].qty_discard`, value);
                                        }}                                       
                                      />                                       
                                      <ErrorMessage
                                        name={`receivedProducts[${index}].qty_discard`}
                                        component="div"
                                        className="text-danger fs-14"
                                      />
                                    </Col>
                                    <Col className="col-md-2">
                                      <FormSelect
                                        placeholder="Select"
                                        label="Quality Check"
                                        name={`receivedProducts[${index}].quality_status`}
                                        options={GrnQualityCheckStatus}
                                        getOptionLabel={(option: any) =>
                                          option.value
                                        }
                                        getOptionValue={(option: any) =>
                                          option.key
                                        }
                                        onChange={(
                                          name: string,
                                          value: string
                                        ) => {
                                          setFieldValue(name, value);
                                        }}
                                        value={
                                          values.receivedProducts[index]
                                            ?.quality_status
                                        }
                                      />
                                      <ErrorMessage
                                        name={`receivedProducts[${index}].quality_status`}
                                        component="div"
                                        className="text-danger fs-14"
                                      />
                                    </Col>
                                  </Row>
                                  {values.receivedProducts[index]
                                    ?.quality_status?.key === "Fail" && (
                                    <Row className="py-3 pb-0">
                                      <Col className="col-md-12">
                                        <InputTextArea
                                          controlId={`receivedProducts[${index}].return_remarks`}
                                          label="Return Reason"
                                          placeholder="Enter return reason"
                                          // touched={touched.receivedProducts[index]?.return_remarks}
                                          handleBlur={handleBlur}
                                          handleChange={handleChange}
                                          // errorsField={errors.receivedProducts[index]?.return_remarks}
                                          name={`receivedProducts[${index}].return_remarks`}
                                          value={
                                            values.receivedProducts[index]
                                              ?.return_remarks
                                          }
                                          className="form-control textarea-height"
                                        />
                                        <ErrorMessage
                                          name={`receivedProducts[${index}].return_remarks`}
                                          component="div"
                                          className="text-danger fs-14"
                                        />
                                      </Col>
                                      <Col className="col-md-12">
                                        <h3 className="fs-18 fw-bold info-text mt-2">
                                          Products Photos
                                        </h3>
                                      </Col>
                                      <Col className="col-md-12 d-flex good-inward-box-cover">
                                        {imageLoading ? (
                                          <label className="file-box">
                                            <span>
                                              <Spinner />
                                            </span>
                                          </label>
                                        ) : (
                                          <label className="file-box me-3">
                                            <input
                                              type="file"
                                              name="image"
                                              onChange={(e) =>
                                                uploadProfileImage(
                                                  e,
                                                  values,
                                                  index,
                                                  setFieldValue
                                                )
                                              }
                                              accept="image/png, image/gif, image/jpeg"
                                            />
                                            <span>
                                              {" "}
                                              <Image
                                                className="img-fluid"
                                                src={FileAddIcon}
                                                alt="Logo"
                                              />
                                            </span>
                                          </label>
                                        )}

                                        {/* <FileUploader uploadProfileImage={(e) => uploadProfileImage(e, values, index, setFieldValue)} imageLoading={imageLoading} imagePreview={imagePreview} /> */}
                                        {values.receivedProducts[
                                          index
                                        ].imagesPreview.map(
                                          (imagePath: any, imageIndex: any) => (
                                            <div key={`returnImage_${index}_${imageIndex}`}>
                                              <label className="file-box good-inward-box-cover me-3">
                                                <span>
                                                  {" "}
                                                  <Image
                                                    className="img-fluid"
                                                    src={
                                                      imagePath
                                                        ? imagePath
                                                        : AddPhotoAlternate
                                                    }
                                                    alt="Logo"
                                                  />
                                                  <div
                                                    className="good-inward-delete-icon cursor-pointer"
                                                    onClick={() =>
                                                      handleDeleteReturnImage(
                                                        values,
                                                        index,
                                                        imageIndex,
                                                        setFieldValue
                                                      )
                                                    }
                                                  >
                                                    <DeleteIcon className="text-danger fs-16" />
                                                  </div>
                                                </span>
                                              </label>
                                            </div>
                                          )
                                        )}                                        
                                      </Col>
                                      <ErrorMessage
                                          name={`receivedProducts[${index}].images`}
                                          component="div"
                                          className="text-danger fs-14"
                                        />
                                    </Row>
                                  )}
                                </Card>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    />
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-6">
                            <InputTextArea
                              controlId="remarks"
                              label="Add Note"
                              placeholder="Enter Note"
                              touched={touched.remarks}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.remarks}
                              name="remarks"
                              value={values.remarks}
                              className="form-control textarea-height"
                            />
                          </Col>
                          <Col className="col-md-4">
                            <InputDatePicker
                              name="return_date"
                              label="Goods Return Date"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  placeholder: "Select",
                                  className: "form-control",
                                  readOnly: true,
                                  id: "return_date",
                                },
                              }}
                              value={values.return_date}
                              onChange={(name, newValue) =>
                                setFieldValue(name, newValue)
                              }
                              touched={touched.return_date}
                              errors={errors.return_date}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <div
                            className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                            onClick={handleCancel}
                          >
                            Cancel
                          </div>

                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={imageLoading || btnLoading}
                            className="fw-semibold fs-13 text-white mw-65 mt-2"
                            variant="primary"
                          >
                            Submit
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
