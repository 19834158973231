import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Row, Spinner } from "react-bootstrap";

import { ErrorMessage, Formik } from "formik";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputText from "../../../../components/common/InputText";
import { DpDataDTO, ReceivedProduct } from "./dp.type";
import FileAddIcon from "../../../../assets/images/file-add-icon.svg";
import CancelIcon from '@mui/icons-material/Cancel';
import constants, {
  DISPATCH_ORDER_STATUS,
  DISPATCH_PRODUCT_STATUS
} from "../../../../constants/constants";
import CustomButton from "../../../../components/common/CustomButton";

import Loader from "../../../../components/common/Loader";
import {
  createNewDispatchOrder,
  getDPNumber,
  getDispatchOrderById,
  getProductListAsync,
  getProductToDispatchApi,
  updateDispatchOrder,
  uploadDispatchOrderImageApi,
} from "../../../../services/warehouseService";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import InputTextArea from "../../../../components/common/InputTextArea";
import moment from "moment";
import grnSchema from "./validation";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../../../features/Auth/authSlice";
import AddPhotoAlternate from "../../../../assets/images/add_photo_alternate.svg";
import FormAsyncSelect from "../../../../components/common/AsyncSelect/FormAsyncSelect";
import { getOrderNumberListApi } from "../../../../services/dealService";
import { getAllManagerList } from "../../../../services/authService";
import InputCheckBox from "../../../../components/common/InputCheckBox";
import AddIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Close";
import InputCurrencyText from "../../../../components/common/InputCurrencyText";
import DeletePopup from "../../../../components/common/deletePopup";

export const AddNewDispatchOrder = () => {  
  const [dpNumber, setDpNumber] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dpData, setDpData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [productArray, setProductArray] = useState<ReceivedProduct[]>();
  const [projectId, setProjectId] = useState();
  const [selectedContract, setSelectedContract] = useState(null)
  const [productKey, setProductKey] = useState(0)
  const [dispatchDateAllowed, setDispatchDateAllowed] = useState(1)
  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState(false);
  const [tempProductStatus, setTempProductStatus] = useState<any>(null);

  
  const navigate = useNavigate();
  const allowedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
  ];
  const maxFileSizeMB = 1;

  let { id } = useParams();

  /**
   * Submit Method to call when grn save button
   * @async
   * @param {FormValues} values
   * @returns {*}
   */
  const submitForm = async (values: any/* DpDataDTO */, { setSubmitting }: any) => {
    const formData: any = {
      ...values,
      dispatched_date: moment(values.dispatched_date).isValid()
        ? moment(values.dispatched_date).format(
            constants.dateFormats.databaseFormat
          )
        : null,
      contract_id: values?.project_id?.id,
      driver_id: values?.driver_id?.id,
      status: values?.status ? String(DISPATCH_ORDER_STATUS.DISPATCH) : String(DISPATCH_ORDER_STATUS.INITIATED),
    };
    if(!values.status) {
      formData.dispatched_date = null;
    }
    // delete formData.driver_id;
    delete formData.project_id;
    delete formData.dp_number;
    delete formData.po_date;
    delete formData.received_date;
    delete formData.product_id;
    delete formData.product_price;
    delete formData.product_qty;
    delete formData.imagesPreview;
    //console.log(formData);
    const hasUnFilledProductStatus = formData.dispatchProducts.some((product: { availableStatus: any; product_status: any}) => (!product.availableStatus && !product.product_status));
    if (hasUnFilledProductStatus) {
      toast("Please select the product status for the out of stock products", { type: toast.TYPE.ERROR });
      return;
    }

    //In edit case - if all product not direct status and dispatch date is filled than user cannot submit the form
    const isProductStatusIsNotDirectDispatch = formData.dispatchProducts.filter((product: { availableStatus: any; product_status: any}) => (!product.availableStatus && product.product_status && product.product_status != "direct"));
    if (isProductStatusIsNotDirectDispatch && isProductStatusIsNotDirectDispatch.length && formData.dispatched_date) {
      toast("You cannot dispatch out of stock product with product status (Ordered or Awaiting Delivery)", { type: toast.TYPE.ERROR });
      return;
    }
    
    /* const hasUnavailableProduct = formData.dispatchProducts.some((product: { availableStatus: any; }) => !product.availableStatus);
    if(hasUnavailableProduct){
      toast("Please remove the out of stock product or add another product to continue.", { type: toast.TYPE.ERROR });
      return;
    } */
    setBtnLoading(true);
    if (id) {
      delete formData.contract_id;
      updateDispatchOrder(id, formData)
        .then((response: any) => {
          toast(response.message, { type: toast.TYPE.SUCCESS });
          navigate("/dispatch-orders");
          setBtnLoading(false);
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setBtnLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    } else {
      createNewDispatchOrder(formData)
        .then((response: any) => {
          toast(response.msg, { type: toast.TYPE.SUCCESS });
          navigate("/dispatch-orders");
          setBtnLoading(false);
        })
        .catch((error: any) => {
          setSubmitting(false);
          setBtnLoading(false);

          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
          }
        });
    }
  };

  const getOrderNumber = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
        exportType: "project",
        showAll : true,
        showCp : "No"
      };
      //console.log(requestParam);
      getOrderNumberListApi(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const getProjectOwner = (params: any) => {
    return new Promise((resolve, reject) => {
      const transformParam = {
        ...params,
        isDriver: true,
      };
      getAllManagerList(transformParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const getDpDetail = (id: string) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getDispatchOrderById(id)
        .then((response) => {
          response.data.dispatchProducts.map((product: any) => ({
            ...product,
            product_status: product?.product_status ? product.product_status : null, // Use nullish coalescing for better clarity
          }));
          const dpObj = response.data;
          dpObj.dispatchProducts = dpObj.dispatchProducts.map((product: { product: { stock_qty: number; }; qty: number;  }) => ({
            ...product,
            availableStatus: product.product.stock_qty >= product.qty,//true
          }));
          if (!isProductStatusDirectDispatch(dpObj)) {
            setDispatchDateAllowed(0);
          }
          setDpData(dpObj);
          setProductArray(response.data.dispatchProducts);
          // updateRecevicedProductArray();
          // setLoading(false);
          resolve(true);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            toast(`Error: ${error.response.data.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/dispatch-orders");
          } else if (error.request) {
            // The request was made but no response was received
            toast("No response from the server", { type: toast.TYPE.ERROR });
            navigate("/dispatch-orders");
          } else {
            // Something happened in setting up the request that triggered an Error
            toast(`Request error: ${error.message}`, {
              type: toast.TYPE.ERROR,
            });
            navigate("/dispatch-orders");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  useEffect(() => {
    //console.log("121212");
    Promise.all([fetchDpNumber()]).then(() => {
      if (id) {
        Promise.all([getDpDetail(id)]).then(() => {   
          setLoading(false);       
        });
      } else {
        setLoading(false);
      }
    });
  }, [id]);

  useEffect(() => {
    if (dpData) {      
      if (dpData.contract_id && dpData.status == DISPATCH_ORDER_STATUS.PENDING) {
        getProductToDispatch(dpData.contract_id);
      }
    }
  }, [dpData.contract_id]);

  const handleCancel = () => {
    navigate(-1);
  };

  const getProductToDispatch = async (
    projectId: string | number | undefined,
    setFieldValue?: any
  ) => {
    //  setLoading(true);
    getProductToDispatchApi(projectId).then((response) => {
    //  const initialReceivedProducts = response?.data;
      const initialReceivedProducts = response?.data.map((product: { product: { stock_qty: number; }; qty: number; product_status: string }) => ({
        ...product,
        availableStatus: product.product.stock_qty >= product.qty,
        product_status: product?.product_status ? product.product_status : null,
      }));
      setProductArray(initialReceivedProducts);
      if (setFieldValue)
        setFieldValue("dispatchProducts", initialReceivedProducts);
      else {
        const dpDataUp = { ...dpData, dispatchProducts: response?.data };
        setDpData(dpDataUp);
        initialValues.dispatchProducts = initialReceivedProducts;
      }
      //  setDpNumber(response?.data?.dpn);
      setLoading(false);
    });
  };

  const fetchDpNumber = async () => {
    return new Promise((resolve, reject) => {
      if (!id) {
        getDPNumber().then((response) => {
          setDpNumber(response?.data?.dpn);
          return resolve(true);
        });
      } else {
        return resolve(true);
      }
    });
  };

  const getProductsList = (params: any) => {
    return new Promise((resolve, reject) => {
      const requestParam = {
        ...params,
         //projectId : selectedContract,
        calledFrom : 'dispatch'
      };
      //console.log(requestParam);
      getProductListAsync(requestParam)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log("err", err);
          console.log(err);
        });
    });
  };

  const handleProducts = (formValues: any, setFieldValue: any) => {
    const productArray = [...formValues.dispatchProducts];
    if (formValues.product_qty > 0 && formValues.product_id?.id) {
      const foundProduct = productArray.find(
        (product) => product.product_id === formValues.product_id?.id
      );
      if (foundProduct) {
        toast(
          `Error: Product with SKU ${formValues.product_id?.name} already added.`,
          {
            type: toast.TYPE.ERROR,
          }
        );
        return;
      }
      const fieldDataObj = {
        product_id: formValues.product_id?.id,
        qty: formValues.product_qty,
        product: {
          name: formValues.product_id?.name,
        },
        availableStatus:
          parseInt(formValues.product_id?.stock_qty) >=
          parseInt(formValues.product_qty)
            ? true
            : false,
        product_status: formValues?.product_status ? formValues.product_status : null
      };

      console.log(fieldDataObj, productArray);
      productArray.push(fieldDataObj);
      setFieldValue("dispatchProducts", productArray);
      setFieldValue("product_id", null);
      setFieldValue("product_qty", "");
    }
    else{
      toast(`Error: Please ensure you have selected a product, provided the quantity (Qty).`, {
        type: toast.TYPE.ERROR,
      });
      return;
    } 
  };

  const handleDeleteProduct = (
    formValues: any,
    productIndex: number,
    setFieldValue: any
  ) => {
    const productArray = [...formValues.dispatchProducts];
    productArray.splice(productIndex, 1);
    setFieldValue("dispatchProducts", productArray);
    // setShelfNumbers((prevShelfNumbers: any) => {
    //   const updatedShelfNumbers = [...prevShelfNumbers];
    //   updatedShelfNumbers.splice(shelfIndex, 1);
    //   return updatedShelfNumbers;
    // });
  };

  const handleDeleteReturnImage = (
    formValues: any,
    indexNumber: number,
    setFieldValue: any
  ) => {
    const failedGrnImageDetails = formValues.images;
    const failedGrnImagePreview = formValues.imagesPreview;
    failedGrnImageDetails.splice(indexNumber, 1);
    failedGrnImagePreview.splice(indexNumber, 1);
    setFieldValue(`images`, failedGrnImageDetails);
    setFieldValue(`imagesPreview`, failedGrnImagePreview);
  };

  // console.log(dpData);
  const uploadProfileImage = (
    event: any,
    formValues: any,
    setFieldValue: any
  ) => {
    const imageFile = event.target.files[0];
   // console.log("file", imageFile);

    // Check file type
    if (!allowedFileTypes.includes(imageFile.type)) {
      toast("Only PNG, JPG, JPEG, and SVG file types are allowed.", {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (imageFile.size > maxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${maxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setImageLoading(true);

      setImagePreview(null);

      // Create FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append("file", imageFile);
      const failedGrnImageDetails = formValues.images;
      const failedGrnImagePreview = formValues.imagesPreview;
      //  return;
      uploadDispatchOrderImageApi(formData)
        .then((response) => {
          failedGrnImageDetails.push(response.data.file);
          failedGrnImagePreview.push(response.data.signedURL);
          setImageLoading(false);
          setFieldValue(`images`, failedGrnImageDetails);
          setFieldValue(`imagesPreview`, failedGrnImagePreview);
        })
        .catch(() => {
          setImageLoading(false);
        });
    }
  };
  const initialValues: any = {
    dp_number: id ? dpData?.dispatch_number_str : dpNumber,
    po_date:
      dpData?.contract_id && dpData?.project?.order_date
        ? moment(dpData?.project?.order_date)
        : "",
    remarks: dpData?.remarks ? dpData?.remarks : "",
    address: dpData?.address ? dpData?.address : "",
    dispatched_date: dpData?.dispatched_date
      ? moment(dpData?.dispatched_date)
      : null,
    status: dpData?.status == DISPATCH_ORDER_STATUS.DISPATCH ? true : false,
    ref_number: dpData?.ref_number ? dpData?.ref_number : "",
    driver_id: dpData?.driver_id ? dpData?.driver : "",
    project_id: dpData?.contract_id ? dpData?.project : null,
    product_id: null,
    product_qty: "",
    dispatchProducts: dpData.dispatchProducts?.length ? productArray : [],
    images: dpData.images ? dpData.images : [],
    imagesPreview: dpData.imagesSigned ? dpData.imagesSigned : [],
  };
 // console.log(initialValues, productArray);

  const manageDispatchDate = (formProducts: any, setFieldValue: any) => {
    /**
      * When user select Direct Dispatch option for all than dispatch date will be allow
      * If user select other option except Direct Dispatch, than dispatch date will not allow
      * In edit Dispatch, If dispatch date already filled and user select the other option than date will be disabled
      * In edit Dispatch, If dispatch date not filled and user select the other option than date will be hide.
     */
    if (formProducts?.dispatchProducts) {
      if (!isProductStatusDirectDispatch(formProducts)) {
        setDispatchDateAllowed(0);
        if (formProducts.status) {
          /* setFieldValue('dispatched_date', dpData?.dispatched_date ? moment(dpData?.dispatched_date) : null);
          if (!dpData?.dispatched_date) {
            setFieldValue('status', false);
          } */
          setFieldValue('dispatched_date', null);
          setFieldValue('status', false);
        }
      } else {
        setDispatchDateAllowed(1);
      }
    }
  }

  const isProductStatusDirectDispatch = (productData: any) => {
    const isProductStatusIsNotDirectDispatch = productData.dispatchProducts.filter((product: { availableStatus: any; product_status: any}) => (!product.availableStatus && product.product_status && product.product_status != "direct"/* DISPATCH_PRODUCT_STATUS.DIRECT_DISPATCH */));
    return (isProductStatusIsNotDirectDispatch && isProductStatusIsNotDirectDispatch.length) ? false : true;
  }

  const productStatusConfirm = () => {
    if (tempProductStatus?.formValue?.dispatchProducts && tempProductStatus?.currentIndex != undefined && tempProductStatus?.currentValue) {
      tempProductStatus.formValue.dispatchProducts[tempProductStatus.currentIndex]['product_status'] = tempProductStatus.currentValue;
      if (tempProductStatus?.setFieldValue) {
        tempProductStatus?.setFieldValue("dispatchProducts", tempProductStatus.formValue.dispatchProducts);
      }
      manageDispatchDate(tempProductStatus.formValue, tempProductStatus?.setFieldValue);
      setIsAlertPopupOpen(false);
    }
  }
 
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          <div className="pageHeader px-3 py-2 my-1">
            <Row className="align-items-center">
              <Col>
                <h1 className="fw-bold h4 my-2">{ id ? 'Edit' : 'Add'} Dispatch Order</h1>
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Formik
              validationSchema={grnSchema(id)}
              initialValues={initialValues}
              onSubmit={(values: any/* DpDataDTO */, actions) => {
                submitForm(values, actions);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                setFieldError,
                setFieldValue,
              }) => (
                <Form className="" noValidate onSubmit={handleSubmit}>
                  <div>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-4">
                            {id ? (
                              <>
                                <label className="control-label fs-14 fw-bold">
                                  Dispatch Number
                                </label>
                                <div className="fs-14 mb-3">
                                  {dpData?.dispatch_number_str}
                                </div>
                              </>
                            ) : (
                              <InputText
                                controlId="dp_number"
                                label="Dispatch Number"
                                placeholder="Enter Dispatch Number"
                                touched={touched.dp_number}
                                isDisable
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.dp_number}
                                value={values.dp_number}
                              />
                            )}
                          </Col>
                          <Col className="col-md-4">
                            {id ? (
                              <>
                                {/* {getProductToDispatch(dpData.contract_id, setFieldValue)} */}
                                <label className="control-label fs-14 fw-bold">
                                  Project Order Number
                                </label>
                                <div className="fs-14 mb-3">
                                  <Link
                                    target="_blank"
                                    className="blue-text text-decoration-none fw-semibold"
                                    to={`/projects/view/${dpData.contract_id}`}
                                  >
                                    {" "}
                                    {dpData?.project?.project_no}
                                  </Link>
                                </div>
                              </>
                            ) : (
                              <FormAsyncSelect
                                id="project_id"
                                name="project_id"
                                label="Project Order Number"
                                isAsync
                                isClearable
                                getOptions={getOrderNumber}
                                value={values.project_id}
                                error={errors.project_id}
                                touched={touched.project_id}
                                onChange={(name: any, value: any) => {
                                  setFieldValue(name, value);
                                  setFieldValue(
                                    "po_date",
                                    moment(value?.order_date).format(
                                      constants.dateFormats.slashSeparatedDate
                                    )
                                  );
                                  getProductToDispatch(
                                    value?.id,
                                    setFieldValue
                                  );
                                  if(value){
                                    setSelectedContract(value.id)
                                  }
                                  setFieldValue('product_id', null);
                                  setProductKey((productKey + 1));
                                }}
                              />
                            )}
                          </Col>

                          <Col className="col-md-4">
                            {id ? (
                              <>
                                <label className="control-label fs-14 fw-bold">
                                  Purchase Order Date
                                </label>
                                <div className="fs-14 mb-3">
                                  {moment(dpData?.project?.order_date).format(
                                    constants.dateFormats.slashSeparatedDate
                                  )}
                                </div>
                              </>
                            ) : (
                              <InputText
                                controlId="po_date"
                                label="Purchase Order Date"
                                placeholder="Purchase Order Date"
                                isDisable
                                touched={touched.po_date}
                                handleBlur={handleBlur}
                                handleChange={handleChange}                                 
                                errorsField={errors.po_date}
                                value={values.po_date}
                              />
                            )}
                          </Col>
                          <Col className="col-md-4">
                            <InputText
                              controlId="ref_number"
                              label="Reference Number"
                              placeholder="Enter ref no."
                              touched={touched.ref_number}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.ref_number}
                              value={values.ref_number}
                            />
                          </Col>
                          <Col className="col-md-4">
                            <FormAsyncSelect
                              id="driver_id"
                              name="driver_id"
                              label="Driver"
                              isAsync
                              isClearable
                              getOptions={getProjectOwner}
                              value={values.driver_id}
                              error={errors.driver_id}
                              touched={touched.driver_id}
                              onChange={(name: any, value: any) => {
                                setFieldValue(name, value);
                              }}
                            />
                          </Col>
                          <Col className="col-md-12 d-inline-flex">
                            <InputCheckBox
                              controlId="status"
                              label="Add Dispatch Date"
                              touched={touched.status}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.status}
                              value={values.status}
                              disabled={(!dispatchDateAllowed) ? true : false}
                            />
                            <span className="ms-1 text-muted fs-14 gray-text">
                              (Current status)
                            </span>                            
                          </Col>
                          {values.status && (
                            <Col className="col-md-4">
                              <InputDatePicker
                                name="dispatched_date"
                                label="Dispatch Date"
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    placeholder: "Select",
                                    className: "form-control",
                                    readOnly: true,
                                    id: "dispatched_date",
                                  },
                                }}
                                value={values.dispatched_date}
                                onChange={(name, newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                touched={touched.dispatched_date}
                                errors={errors.dispatched_date}
                                isDisable={(id && !dispatchDateAllowed) ? true : false}
                              />
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Card>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-12">
                            <h6 className="fs-18 fw-bold mb-3 info-text">
                              Products
                            </h6>
                            {/* { JSON.stringify(values)} */}
                            <Row>
                              <Col className="col-md-4 col-xl-4">
                                <FormAsyncSelect
                                  changeKey={productKey}
                                  id="productSelect"
                                  name="product_id"
                                  label="Products"
                                  isAsync
                                  isClearable
                                  value={values.product_id}
                                  defaultFilter={{
                                    projectId: (values?.project_id?.id) ? values?.project_id?.id : null
                                  }}
                                  getOptions={getProductsList}
                                  onChange={(name: any, value: any) => {
                                    setFieldValue(name, value);
                                  }}
                                />
                              </Col>
                              <Col className="col-md-3 col-xl-1">
                              <InputCurrencyText
                                  name="product_qty"
                                  label="Quantity"
                                  placeholder="Qty."                          
                                  value={values.product_qty}
                                  isInteger={true}
                                  onChange={(value, name) =>{
                                    setFieldValue("product_qty", value);
                                  }}
                                  touched={touched.product_qty}
                                  errors={errors.product_qty}
                                />                              
                              </Col>
                              <Col className="col-md-1 col-xl-1">
                                <Button
                                  className="fw-semibold fs-12  btn btn-outline-primary mt-4 w-40 h-40"
                                  variant="custom"
                                  type="button"
                                  onClick={() =>
                                    handleProducts(values, setFieldValue)
                                  }
                                >
                                  <AddIcon className="fs-15" />
                                </Button>
                              </Col>                              
                            </Row>
                            <Row>
                              <Col className="col-md-10 col-xl-8">
                                <div className="table-responsive purchase-order-product-table">
                                  <table className="table">
                                    {values.dispatchProducts && (
                                      <thead>
                                        <tr key="productHeader0">
                                          <th className="info-text fs-14">
                                            Name
                                          </th>
                                          <th className="info-text fs-14">
                                            Qty.
                                          </th>
                                          <th className="info-text fs-14">
                                            Availability
                                          </th>
                                          <th className="info-text fs-14">
                                            Product Status
                                          </th>
                                          <th className="info-text fs-14"></th>
                                        </tr>
                                      </thead>
                                    )}
                                    {values.dispatchProducts && values.dispatchProducts.map(
                                      (orderProduct: any, index: any) => (
                                        <tbody key={`productlist_${index}`}>
                                          <tr>
                                            <td className="fs-14">
                                              {orderProduct?.product?.name}
                                            </td>
                                            <td className="fs-14 text-nowrap">
                                              {orderProduct?.qty}
                                            </td>
                                            <td className="fs-14 text-nowrap">
                                              <span
                                                className={
                                                  !orderProduct?.availableStatus
                                                    ? "text-danger"
                                                    : ""
                                                }
                                              >
                                                {orderProduct?.availableStatus
                                                  ? "Available"
                                                  : "Out of stock"}
                                              </span>
                                            </td>
                                            <td className="fs-14 col-3">
                                              {
                                                orderProduct?.availableStatus ? "-" : 
                                                <select
                                                  name="product_status"
                                                  onChange={(e) => {
                                                    /* values.dispatchProducts[index]['product_status'] = e.target.value;
                                                    setFieldValue("dispatchProducts", values.dispatchProducts);
                                                    manageDispatchDate(values, setFieldValue); */
                                                    if (e.target.value && e.target.value != "direct" && values.dispatched_date) {
                                                      setIsAlertPopupOpen(true);
                                                      setTempProductStatus({
                                                        formValue: values,
                                                        currentIndex: index,
                                                        currentValue: e.target.value,
                                                        setFieldValue: setFieldValue
                                                      });
                                                    } else {
                                                      setTempProductStatus(null);
                                                      values.dispatchProducts[index]['product_status'] = e.target.value;
                                                      setFieldValue("dispatchProducts", values.dispatchProducts);
                                                      manageDispatchDate(values, setFieldValue);
                                                    }
                                                  }}
                                                  value={values.dispatchProducts[index]['product_status'] || ""}
                                                  className={`form-control form-select mb-3 ${
                                                    errors.product_status && touched.product_status
                                                      ? "is-invalid"
                                                      : ""
                                                  }`}
                                                >
                                                  <option value="" key={parseInt(index + 0)}>Select</option>
                                                  {Object.keys(DISPATCH_PRODUCT_STATUS).map((pstatus: any, i: number) => (
                                                    <option value={pstatus} key={parseInt(index + i + 1)}>
                                                      {DISPATCH_PRODUCT_STATUS[pstatus as keyof typeof DISPATCH_PRODUCT_STATUS]}
                                                    </option>
                                                  ))}
                                                </select>
                                              }
                                            </td>
                                            <td className="fs-14">
                                              {!orderProduct?.received_qty && (
                                                <Button
                                                  className="fw-semibold fs-12 p-0  btn btn-link text-danger"
                                                  variant="custom"
                                                  type="button"
                                                  onClick={() =>
                                                    handleDeleteProduct(
                                                      values,
                                                      index,
                                                      setFieldValue
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon className="fs-20" />
                                                </Button>
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      )
                                    )}
                                  </table>
                                </div>
                              <ErrorMessage
                                name="dispatchProducts"
                                component="div"
                                className="text-danger fs-14"
                              />
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-md-12">
                            <h3 className="fs-18 fw-bold info-text mt-2">
                              Products Photos
                            </h3>
                          </Col>
                          <Col className="col-md-12 d-flex good-inward-box-cover">
                            {imageLoading ? (
                              <label className="file-box">
                                <span>
                                  <Spinner />
                                </span>
                              </label>
                            ) : (
                              <label className="file-box me-3">
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    uploadProfileImage(e, values, setFieldValue)
                                  }
                                  accept="image/png, image/gif, image/jpeg"
                                />
                                <span>
                                  {" "}
                                  <Image
                                    className="img-fluid"
                                    src={FileAddIcon}
                                    alt="Logo"
                                  />
                                </span>
                              </label>
                            )}

                            {/* <FileUploader uploadProfileImage={(e) => uploadProfileImage(e, values, index, setFieldValue)} imageLoading={imageLoading} imagePreview={imagePreview} /> */}
                            {values.imagesPreview && values.imagesPreview.map(
                              (imagePath: any, index: any) => (                                
                                  <label
                                    key={`productImages${index}`}
                                    className="file-box good-inward-box-cover me-3"
                                  >
                                    <span>
                                      {" "}
                                      <Image
                                        className="img-fluid"
                                        src={
                                          imagePath
                                            ? imagePath
                                            : AddPhotoAlternate
                                        }
                                        alt="Logo"
                                      />
                                      <div
                                        className="good-inward-delete-icon cursor-pointer"
                                        onClick={() =>
                                          handleDeleteReturnImage(
                                            values,
                                            index,
                                            setFieldValue
                                          )
                                        }
                                      >
                                        <CancelIcon className="text-danger bg-white rounded-5 fs-16" />
                                        
                                      </div>
                                    </span>
                                  </label>
                              )
                            )}                            
                          </Col>
                          <ErrorMessage
                                name="images"
                                component="div"
                                className="text-danger fs-14"
                              />
                        </Row>
                      </div>
                    </Card>
                    <Card className="p-3 w-100 pageContentInner mb-3">
                      <div>
                        <Row>
                          <Col className="col-md-8">
                            <InputTextArea
                              controlId="remarks"
                              label="Remarks"
                              placeholder="Enter remarks"
                              touched={touched.remarks}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.remarks}
                              name="remarks"
                              value={values.remarks}
                              className="form-control textarea-height"
                            />
                          </Col>
                          <Col className="col-md-8">
                            <InputText
                              controlId="address"
                              label="Site address"
                              placeholder="Enter Site address"
                              touched={touched.address}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errorsField={errors.address}
                              value={values.address}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-end my-3">
                          <div
                            className="fw-semibold fs-13  mw-60 me-2 mt-2 btn btn-outline-info"
                            onClick={handleCancel}
                          >
                            Cancel
                          </div>

                          <CustomButton
                            type="submit"
                            loading={btnLoading}
                            disabled={imageLoading || btnLoading}
                            className="fw-semibold fs-13 text-white mw-65 mt-2"
                            variant="primary"
                          >
                            Submit
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <DeletePopup
            isOpen={isAlertPopupOpen}
            onClose={() => setIsAlertPopupOpen(false)}
            onDelete={() => productStatusConfirm()}
            actionType="info"
            label="Are You Sure"
            text="Dispatched date cannot be entered when the product status is 'Ordered' or 'Awaiting Delivery.' If a dispatched date has already been entered, it will be cleared. Please confirm to proceed."
          />
        </div>
      )}
    </React.Fragment>
  );
};
