import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ProjectChart from "./Charts/Index";
import { getDashboardChartCount } from "../../services/dashboardMaster.service";
import Loader from "../../components/common/Loader";
import ProjectCount from "./projectCount";
import Installation from "./Installation/index";
import PurchaseOrder from "./PurchaseOrder";
import Invoice from "./Invoice";
import TicketManagement from "./TicketManagement";
import ServiceWaranty from "./ServiceWaranty";
import InputDateRangePicker from "../../components/common/InputDateRangePicker";
import constants from "../../constants/constants";
import moment from "moment";
import PieChartSkeleton from "../../components/common/Skeleton/PieChart.skeleton";
import { useSelector } from "react-redux";
import { selectAuthentication } from "../../features/Auth/authSlice";
export const DashboardMaster = () => {
  const dummeyD = [
    {
      label: "Completed",
      status_count: 50,
      status_percent: 50.0,
      color: "#21AC59",
    },
    {
      label: "Pending",
      status_count: 30,
      status_percent: 30.0,
      color: "#EFA73C",
    },
    {
      label: "Delayed",
      status_count: 20,
      status_percent: 20.0,
      color: "#EE3030",
    },
  ];
  const [projectData, setProjectData] = useState<any[]>([]);
  const [dealData, setDealData] = useState<any[]>(dummeyD);
  const [plannerData, setPlannerData] = useState<any[]>([]);
  const [serviceLogData, setServiceLogData] = useState<any[]>(dummeyD);

  const [loading, setLoading] = useState(true);
  const [orderDateRange, setOrderDateRange] = useState({
    dateFrom: moment()
      .subtract("12", "months")
      .format(constants.dateFormats.databaseFormat),
    dateTo: moment().format(constants.dateFormats.databaseFormat),
  });
  const { user }: any = useSelector(selectAuthentication);

  const designation = user.user?.employeeDetails?.designation;
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getChartCount(signal);
    return () => {
      controller.abort();
    };
  }, [orderDateRange]);
  const getChartCount = (signal: any) => {
    setLoading(true);
    getDashboardChartCount(orderDateRange, signal)
      .then((res) => {
        setProjectData(res.data.projectCount);
        setPlannerData(res.data.plannerCount);
        // setDealData(res.data.dealCount);
        setServiceLogData(res.data.serviceDataCount);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const orderDateFilter = (
    data: any,
    fieldName: string,
    setFieldValue: any
  ) => {
    const dataRange = data;
    if (dataRange) {
      // since date range picker gives array containing start and end date, we are converting both the dates to YYYY-MM-DD format
      const startDate = moment(data[0]).format(
        constants.dateFormats.databaseFormat
      );
      const endDate = moment(dataRange[1]).format(
        constants.dateFormats.databaseFormat
      );
      setOrderDateRange({
        dateFrom: startDate,
        dateTo: endDate,
      });
    } else {
      setOrderDateRange({
        dateFrom: moment()
          .subtract("12", "months")
          .format(constants.dateFormats.databaseFormat),
        dateTo: moment().format(constants.dateFormats.databaseFormat),
      });
    }
  };

  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      <div className="pageHeader px-3 py-2 my-1">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="fw-bold h4 my-2">Dashboard</h1>
          </div>

          <div className="dashbord-input-rang">
            <InputDateRangePicker
              controlId="date"
              label=""
              placeholder="Date Range"
              touched={false}
              handleBlur={false}
              handleChange={orderDateFilter}
              handleClose={orderDateFilter}
              errorsField={false}
              defaultValue={[
                new Date(orderDateRange.dateFrom),
                new Date(orderDateRange.dateTo),
              ]}
              position="auto"
            />
          </div>
        </div>
      </div>

      <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
        <>
          {loading ? (
            <Row className="gx-3">
              <Col md={4}>
                <PieChartSkeleton />
              </Col>
              <Col md={4}>
                <PieChartSkeleton />
              </Col>
              <Col md={4}>
                <PieChartSkeleton />
              </Col>
            </Row>
          ) : (
            <Row className="gx-3">
              <Col md={4}>
                <ProjectChart
                  data={projectData}
                  title="Projects"
                  subTitle="Projects"
                />
              </Col>
              <Col md={4}>
                <ProjectChart
                  data={serviceLogData}
                  title="Service/Warranty Log"
                  subTitle="Service Log"
                />
              </Col>
              <Col md={4}>
                <ProjectChart
                  data={plannerData}
                  title="Planning"
                  subTitle="Plans"
                />
              </Col>
            </Row>
          )}
          <Row>
            <ProjectCount orderDateRange={orderDateRange} />
          </Row>
          <Row>
            <Installation orderDateRange={orderDateRange} />
          </Row>
         {/*  as per client requirement we have hide this puchase order tile */}
         {/*  <Row>
            <PurchaseOrder orderDateRange={orderDateRange} />
          </Row> */}
          {designation && (
            <Row>
              <Invoice orderDateRange={orderDateRange} />
            </Row>
          )}

          <Row>
            <ServiceWaranty orderDateRange={orderDateRange} />
          </Row>
          <Row>
            <TicketManagement orderDateRange={orderDateRange} />
          </Row>
        </>
      </div>
    </div>
  );
};
