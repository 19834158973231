import { axios } from "./axios";
import {
  PRODUCT_ASSIGN_VENDOR,
  PRODUCT_CATEGORY_LIST,
  PRODUCT_DETAIL,
  PRODUCT_DISPATCH_LIST,
  PRODUCT_EDIT,
  PRODUCT_EXPORT,
  PRODUCT_LIST,
  PRODUCT_RECIEVED_LIST,
  PRODUCT_SYNC,
  PRODUCT_UPLOAD_IMAGE,
  ADD_CUSTOM_PRODUCT,
  PRODUCT_MASTER_CSV_TEMPLATE,
  IMPORT_PRODUCT_FOR_COST_ALIGNMENT,
  UPDATE_IMPORT_PRODUCT_FOR_COST_ALIGNMENT
} from "../constants/paths";



export type AssignProjectOwnerDTO = {
  products_id: number[];  
  vendor_id: any;
};

export const getProductList = (formData: any): Promise<any> => {
  console.log(formData);
  return axios.get(PRODUCT_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...( formData.minQtyAlert ? {minQtyAlert : formData.minQtyAlert} : {}),
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

/**
 * @param No params
 * @returns Confirm message
 */
export const syncProductsFromHubSpot = (): Promise<any> => {
  return axios.get(PRODUCT_SYNC);
};

export const assingProductVendorApi = (data: AssignProjectOwnerDTO): Promise<any> => {
  const transformData = {
    products_id: data?.products_id,
    vendor_id: data?.vendor_id    
  };
  return axios.patch(PRODUCT_ASSIGN_VENDOR, transformData);
};

/**
 * @param {id: string } params
 * @returns Product details
 */
export const getProductDetailsApi = (id: string): Promise<any> => {
  return axios.get(PRODUCT_DETAIL + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateProductApi = (id: string, data: any): Promise<any> => {
  const transformData = {
    ...data,
    category : data?.category?.key,
    shelf_id : data?.shelf?.key,
    vendor_id : data?.vendor?.key,
    is_fmp : data?.is_fmp?.key,   
  };
  delete transformData.vendor;
  delete transformData.shelf;
  delete transformData.hs_sku;
  //delete transformData.image;
  return axios.put(PRODUCT_EDIT + "/" + id, transformData);
};


export const uploadProductImageApi = (productId: string | number | undefined,data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${PRODUCT_UPLOAD_IMAGE}/${productId}`, data, config);
};

export const getAllProductCategory = ():Promise<any> =>{
  return axios.get(PRODUCT_CATEGORY_LIST);
}

export const getProductRecievedList = (productId: string | number | undefined,formData: any): Promise<any> => {
  return axios.get(`${PRODUCT_RECIEVED_LIST}/${productId}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const getProductDispatchList = (productId: string | number | undefined,formData: any): Promise<any> => {
  return axios.get(`${PRODUCT_DISPATCH_LIST}/${productId}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const exportProductData = (params:any = {}) : Promise<any> => {
  return axios.get(PRODUCT_EXPORT,{params,responseType: 'arraybuffer',});
}

export const createCustomProductAPI = (data: any) : Promise<any> =>{
  return axios.post(`${ADD_CUSTOM_PRODUCT}`, data)
}

export const getProductMasterCSVTemplate= () : Promise<any> => {
  return axios.get(`${PRODUCT_MASTER_CSV_TEMPLATE}`, {
    responseType: 'blob'
  });
}

export const importProductCostAlignment = (formData: any) : Promise<any> => {
  return axios.post(`${IMPORT_PRODUCT_FOR_COST_ALIGNMENT}`,formData);
}

export const importUpdateProductCostAlignmentApi = (formData: any) : Promise<any> => {
  return axios.post(`${UPDATE_IMPORT_PRODUCT_FOR_COST_ALIGNMENT}`,formData);
}