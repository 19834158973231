import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  VALID_EMAIL,
  INVALID_DATE,
} from "../../../validations/ValidationErrors";
import { EMAIL_REGEX } from "../../../validations/regex";

export const fleetSchema = yup.object({
  registration: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Registration"),
  registration_date: yup
    .date() 
    .nullable()
    .typeError(INVALID_DATE)
    .label("Registration Date"),
  active: yup.object().required().label("Active"),
  category: yup.object().required().label("Category"),
  asset_type: yup.object().required().label("Asset Type"),
  fleet_number: yup
    .string()
    .trim()
    .required()
    .min(3, MINIMUM_3_CHAR)
    .max(30, MAXIMUM_30_CHAR)
    .label("Fleet Number"),
  vin: yup.string().optional().label("Policy Number"),
  insurance_company: yup.string().optional().label("Insurance Company"),
  current_mileage: yup.number().positive().required().label("Current Mileage"),
  current_mileage_date: yup
    .date()
    .required()   
    .label("Current Mileage Date"),
  make: yup.string().trim().optional().label("Make"),
  model: yup.string().trim().required().label("Model"),
  ownership: yup.string().trim().required().label("Ownership"),
  service_due_mileage: yup
    .number()
    .positive()
    .nullable()
    .optional()
    .label("Service Due Mileage"),
  service_interval_months: yup
    .number()
    .positive()
    .nullable()
    .optional()
    .label("Service Interval Months"),
  service_interval_mileage: yup
    .number()
    .positive()
    .nullable()
    .optional()
    .label("Service Interval Mileage"),
  engine_size_cc: yup.number().positive().nullable().optional().label("Engine Size CC"),
  fuel_type: yup.object().required().label("Fuel Type"),
  /* type: yup.object().required().label("Type"), */
  deactivated_date: yup.date().nullable().optional().label("Deactivated Date"),
  site: yup.string().trim().optional().label("Tracker Serial No."),
  co2: yup.number().positive().nullable().optional().label("Co2"),
  department: yup.object().optional().label("Department"),
  cost_center: yup.string().trim().optional().label("Cost Center"),
  mot_due_date: yup.date().nullable().optional().label("Mot Due Date"),
  roadtax_due_date: yup.date().nullable().optional().label("Roadtax Due Date"),
  warranty_due_date: yup.date().nullable().optional().label("Warranty Due Date"),
  service_due_date: yup.date().nullable().optional().label("Service Due Date"),
  insurance_due_date: yup.date().nullable().optional().label("Insurance Due Date"),
  breakdown_due_date: yup.date().nullable().optional().label("Breakdown Due Date")
  /* mot_due_date: yup
    .date()
    .required()
    .typeError(INVALID_DATE)
    .label("Mot Due Date"),
  roadtax_due_date: yup
    .date()
    .required()
    .typeError(INVALID_DATE)
    .label("Roadtax Due Date"),
  warranty_due_date: yup
    .date()
    .required()
    .typeError(INVALID_DATE)
    .label("Warranty Due Date"),
  service_due_date: yup
    .date()
    .required()
    .typeError(INVALID_DATE)
    .label("Service Due Date"),
  insurance_due_date: yup
    .date()
    .required()
    .typeError(INVALID_DATE)
    .label("Insurance Due Date"),
  breakdown_due_date: yup
    .date()
    .required()
    .typeError(INVALID_DATE)
    .label("Breakdown Due Date")   */
});
