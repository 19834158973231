import { Formik, FieldArray, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Card,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import * as yup from "yup";
import CustomButton from "../../components/common/CustomButton";
import { toast } from "react-toastify";
import Loader from "../../components/common/Loader";
import {
  CSVForDropZone,
  CSVTypeExtension,
  importCsvMaxFileSize,
} from "../../constants/constants";
import Dropzone from "react-dropzone";
import {
  CSV_FILE_ALLOWED,
} from "../../validations/ValidationErrors";
import {
  getProductMasterCSVTemplate,
  importProductCostAlignment,
  importUpdateProductCostAlignmentApi
} from "../../services/productService";
import {
  importHistoricalData,
  getHistoricalCSVTemplate
} from "../../services/ticketService";
import InfoIcon from "@mui/icons-material/Info";
import CsvBoxIcon from "../../assets/images/csv-box.svg";
import deleteIconRed from "../../assets/images/awesome-trash-red.svg";
import ExternalDownload from "../../assets/images/external-download.svg";

import Papa from "papaparse";
import CheckCircle from "@mui/icons-material/CheckCircle";

const addSchema = yup.object({
  // project_owner: yup.object().required().label("Project Owner"),
});

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ProjectOption {
  id: string;
  full_name: string;
}

const ImportHistoryData: React.FC<AddProps> = ({
  isOpen,
  onClose,
}) => {
  const [docLoading, setDocLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentFile, setDocumentFile] = useState("");
  const [documentFileError, setDocumentFileError] = useState("");
  const [csvFileData, setCsvFileData] = useState<any>({
    errors: [],
    incorrectRecords: 0,
    totalRecords: 0,
    import_key:""
  });

  useEffect(() => {
    if (isOpen) {
      setDocumentFile("");
      setLoading(false);
    }
  }, [isOpen]);

  const downloadSampleTemplate = () => {
    setLoading(true);
    getHistoricalCSVTemplate()
      .then((response: any) => {
        const signedFileUrl = response.data;
        window.open(signedFileUrl, "_blank");
      })
      .catch((error: any) => {
        setLoading(false);
        toast(error?.response?.data?.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeCsvFile = () => {
    setDocumentFile("");
    setCsvFileData({
      errors: [],
      incorrectRecords: 0,
      totalRecords: 0,
      import_key:""
    });
  }

  /**
   * Submit Method to call when user save button
   * @async
   * @param values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
    if(csvFileData.import_key){
      const transformData = {
        import_key : csvFileData.import_key
      }

      importUpdateProductCostAlignmentApi(transformData)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });        
        onClose();
      })
      .catch((error) => {
        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, {
            type: toast.TYPE.ERROR,
          });
        }
      });

    }

  };

  const handleFileUpload = (event: any, setFieldValue: any, type: string) => {
    //   console.log("file", event);
    const csvFile = event[0];
    // return;
    // Check file type
    if (!csvFile?.type || !CSVTypeExtension.includes(csvFile?.type)) {
      toast(CSV_FILE_ALLOWED, {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (csvFile.size > importCsvMaxFileSize * 1024 * 1024) {
      toast(`File size must be less than ${importCsvMaxFileSize} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      setDocumentFileError("");
      Papa.parse(csvFile, {
        complete: (result) => {
          console.log(result);
          if (result.data.length > 10) {
            setDocumentFileError(
              "The CSV file has more than 100 rows. Please check the file and re-upload."
            );
          } else {
            const formData = new FormData();
            formData.append("file", csvFile);
            const reader = new FileReader();
            setDocLoading(true);
            console.log(csvFile);
            importHistoricalData(formData)
              .then((response) => {
                setDocLoading(false);
                if (response?.data?.recordsImported) {
                  toast("Historical data imported successfully!", { type: toast.TYPE.SUCCESS });        
                  onClose();  
                } else {
                  setDocumentFile(csvFile?.name);
                  setCsvFileData(response?.data);
                }
                /* // console.log(type);
                setDocumentFile(csvFile?.name);
                setCsvFileData(response.data);                 */
              })
              .catch((error) => {
                toast(error.message, { type: toast.TYPE.ERROR });        
                setDocLoading(false);
              });
          }
        },
        header: true,
      });

      /*   */
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="upload-external-modal" show={isOpen} onHide={onClose} centered>
          <Formik
            validationSchema={""}
            initialValues={{}}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    disabled={docLoading}
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-0 fs-20">
                    Upload Historical Project
                  </h4>
                  <Row className="mb-2">
                    <Col md={8} className="text-start">
                      <Button
                        className="btn btn-link ps-0 blue-text text-decoration-none fw-semibold me-1 fs-14"
                        variant="link"
                        type="button"
                        title="Download sample template"
                        onClick={() => downloadSampleTemplate()}
                      >
                        <img src={ExternalDownload} className="me-2" alt="External Download Icon" />   
                       
                        Template Download
                      </Button>
                    </Col>
                    <Col md={4} className="text-end">
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip className="p-0 bg-primary">
                            <p className="mb-0 p-0 fs-13 fw-bold ">
                            Please ensure that the columns in your CSV file match the template provided, referring to the template for column details and arranging the data accordingly before uploading. Additionally, use the proper date format DD/MM/YYYY in the CSV
                            </p>
                          </Tooltip>
                        }
                      >
                        <Button
                          className="align-baseline d-inline-block ms-1 p-0 text-light"
                          variant="link"
                        >
                          <InfoIcon className="fs-20 me-1 text-primary" />
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md">
                      <div className="drag-drop">
                        {docLoading ? (
                          <label className="file-box1">
                            <span>
                              <Spinner />
                            </span>
                          </label>
                        ) : (
                          <Dropzone
                            multiple={false}
                            accept={CSVForDropZone}
                            onDrop={(e) =>
                              handleFileUpload(e, setFieldValue, "contractFile")
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="upload-image">
                                  <svg
                                    width="33"
                                    height="25"
                                    viewBox="0 0 33 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.2222 24.1666H8.65975C6.44794 24.1666 4.55819 23.401 2.99048 21.8697C1.42277 20.3385 0.638916 18.4669 0.638916 16.2551C0.638916 14.3593 1.2101 12.6701 2.35246 11.1874C3.49482 9.70478 4.98961 8.75686 6.83683 8.34367C7.44447 6.10756 8.65975 4.29679 10.4827 2.91138C12.3056 1.52596 14.3716 0.833252 16.6806 0.833252C19.5243 0.833252 21.9367 1.8237 23.9176 3.80461C25.8985 5.78551 26.8889 8.19784 26.8889 11.0416C28.566 11.236 29.9575 11.9591 31.0634 13.2109C32.1693 14.4626 32.7222 15.927 32.7222 17.6041C32.7222 19.427 32.0842 20.9765 30.8082 22.2525C29.5321 23.5286 27.9827 24.1666 26.1597 24.1666H18.1389V13.7395L20.4722 15.9999L22.5139 13.9583L16.6806 8.12492L10.8472 13.9583L12.8889 15.9999L15.2222 13.7395V24.1666Z"
                                      fill="#889EAE"
                                    />
                                  </svg>
                                </div>
                                <div className="drag-text">
                                  Drag & drop the file here or
                                </div>
                                <div className="upload-text">
                                  Upload Historical Project CSV
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </div>
                      <div className="mt-2">
                        <p className="file-size-text mb-0">
                          File Size: {importCsvMaxFileSize} MB max
                        </p>
                      </div>
                    </Col>
                  </Row>
                  {documentFileError && (
                    <div className="upload-text text-danger">
                      {documentFileError}
                    </div>
                  )}
                  {documentFile && (
                    <div className="upload-text mb-3">
                      <div className="d-flex bg-sec-light pt-2 ps-2 pe-2 justify-content-between rounded-1">
                        <p className="fs-14 mb-2 text-black">
                          <img
                            src={CsvBoxIcon}
                            alt="Icon"
                            className="me-2"
                          />
                          {documentFile}
                        </p>
                        <p className="file-size-text mb-2">
                          <img
                            src={deleteIconRed}
                            className="cursor-pointer"
                            onClick={() => removeCsvFile()}
                          />
                        </p>
                      </div>
                    </div>
                  )}
                  {(csvFileData?.incorrectRecords > 0 || csvFileData?.duplicatedRecords > 0) ? (
                    <div className="border-danger border px-3 py-2  m-0 rounded-3">
                    <Row className="">
                      <Col md={12} className="fs-16 fw-bold text-danger mb-2">
                        Error Found
                      </Col>
                      <Col md={12} className="fs-14 text-black mb-3">
                        Please ensure that all details are accurately provided
                        in the respective columns of the CSV file. Once the
                        necessary corrections have been made, please proceed
                        with the re-upload process.
                      </Col>
                      {
                        csvFileData.incorrectRecords > 0 && 
                        <Col md={12} className="text-danger fs-14">
                          {csvFileData.incorrectRecords} out of{" "}
                          {csvFileData.totalRecords} records have incorrect
                          details
                        </Col>
                      }
                      {
                        csvFileData.duplicatedRecords > 0 && 
                        <Col md={12} className="text-danger fs-14">
                          {csvFileData.duplicatedRecords} out of{" "}
                          {csvFileData.totalRecords} records have duplicate
                          details
                        </Col>
                      }
                      
                      <Col md={12}>
                        <Card className="max-h-156 mb-2">
                          <Card.Body className="p-2">
                              {csvFileData?.errors.map(
                              (_dataError: any, index: number) => (
                                <Row key={_dataError.row} className="mb-2 g-2">
                                  <Col md={1} className="text-center fw-bold pe-0">
                                    {index + 1}.{" "}
                                  </Col>
                                  <Col md={11}>
                                    <div className="fw-bold fs-14 control-label">
                                      Row No. {_dataError?.row}
                                    </div>
                                    <div className="fs-14 text-black">{_dataError?.msg}</div>
                                  </Col>
                                </Row>
                              )
                            )}
                          </Card.Body>
                        </Card>
                        
                      </Col>
                    </Row>
                    </div>
                  ): (
                    <>
                      {
                      documentFile && (
                       <div className="fs-14"> <span className="text-green"><CheckCircle className="fs-16" /> Everything's fine,</span> Please click "Submit" to proceed.</div>
                      ) 
                      }
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    type="button"
                    disabled={docLoading}
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  {/* <CustomButton
                    type="submit"
                    loading={docLoading}
                    disabled={!csvFileData?.import_key}
                    className="fw-semibold fs-13 text-white  mt-2"
                    variant="primary"
                  >
                    Submit
                  </CustomButton> */}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default ImportHistoryData;
