import { Formik, FieldArray, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { getAllUsersApiCall } from "../../../../services/authService";
import CustomButton from "../../../../components/common/CustomButton";
import {
  saveProjectDocumentInfo,
  uploadProjectDocApi,
} from "../../../../services/project.service";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader";
import constants, { EmployeeDocumentTypeExtension, EmployeeDocumentTypeForDropZone, formTemplateMaxFileSizeMB } from "../../../../constants/constants";
import Dropzone from "react-dropzone";
import InputText from "../../../../components/common/InputText";
import InputDatePicker from "../../../../components/common/InputDatePicker";
import { ONLY_ALPHABET } from "../../../../validations/regex";
import {
  ALPHABETS_ALLOW,
  MINIMUM_3_CHAR,
  MAXIMUM_30_CHAR,
  INVALID_DATE,
  DOCUMENT_TYPE_ERROR,
} from "../../../../validations/ValidationErrors";
import moment from "moment";

const addSchema = yup.object({
  // project_owner: yup.object().required().label("Project Owner"),
});

interface AddProps {
  isOpen: boolean;
  onClose: () => void;
  projectsData: any;
}

interface ProjectOption {
  id: string;
  full_name: string;
}

const ProjectDocumentUpload: React.FC<AddProps> = ({
  isOpen,
  onClose,
  projectsData,  
}) => {
  const [projectOwnerOption, setProjectOwnerOption] = useState([]);
  const [projectOwnerList, setProjectOwnerList] = useState([]);
  const [docLoading, setDocLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [documentFile, setDocumentFile] = useState("");
  const notToShowSubmitButtonDocType = [
    "cad2d",
    "riskassess",
    "cad3d",
    "other",
  ];

  useEffect(() => {
    if(isOpen){
      setDocumentFile("")
    }
    fetchData().then(() => {
      // Call this function after fetchData completes
    });
  }, [isOpen]);

  //console.log("projectsData",projectsData);

  const fetchData = async () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getAllUsersApiCall()
        .then((response) => {
          const modifiedOption = response.data.map((item: any) => ({
            key: item.id,
            value: item.full_name,
            employeeDetails: item.employeeDetails,
          }));
          //console.log(modifiedOption);
          setProjectOwnerList(modifiedOption);
          setLoading(false);
          resolve(true);
        })
        .catch((error) => {
          setLoading(false);
          onClose();
          reject(error);
        });
    });
  };

  /**
   * Submit Method to call when user save button
   * @async
   * @param values
   * @returns {*}
   */
  const submitForm = async (values: any, { setSubmitting }: any) => {
    // console.log(values);
    // return;
    // const projectIds = [];
    // const transformedArray = values.entries.map((item: any) => ({
    //   user_id: item.teamMember.key,
    // }));
    const projectId = projectsData.id;
    const docType = projectsData.docType;
    const formData: any = {
      ...values,
      contract_date: moment(values.contract_date).isValid()
        ? moment(values.contract_date).format(
            constants.dateFormats.databaseFormat
          )
        : "",
      payment_date: moment(values.payment_date).isValid()
        ? moment(values.payment_date).format(
            constants.dateFormats.databaseFormat
          )
        : "",
      //  userids: transformedArray,
      //  project_id: projectIds[0],
    };
  //  console.log(formData);
  //  return;
    setDocLoading(true);

    saveProjectDocumentInfo(projectId,docType,formData)
      .then((response) => {
        setDocLoading(false);
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        onClose();
      })
      .catch((error) => {
        setDocLoading(false);

        if (error.response) {
          // The request was made, but the server responded with a status code that falls out of the range of 2xx
          toast(`Error: ${error.response.data.message}`, {
            type: toast.TYPE.ERROR,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toast("No response from the server", { type: toast.TYPE.ERROR });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast(`Request error: ${error.message}`, { type: toast.TYPE.ERROR });
        }
      });
    return;
  };

  // const transformToInitialValues = () => {
  //   return {
  //     entries: selectedTeamMembers.map((item:any)=> ({
  //       category: {
  //         value: item.user.employeeDetails.designation,
  //         key: item.user.employeeDetails.designation.toLowerCase().replace(/\s/g, '_')
  //       },
  //       teamMember: {
  //         key: item.user_id,
  //         value: `${item.user.first_name} ${item.user.last_name}`
  //       }
  //     }))
  //   };
  // };
  // console.log(selectedTeamMembers);
  // Define a function to dynamically generate the validation schema based on document type
  const getValidationSchema = (documentType: any) => {
    // Define a base schema with common fields
    let schema = yup.object().shape({
      documentType: yup.string().required("Document type is required"),
    });

    // Add conditional validation based on document type
    if (documentType === "contract") {
      schema = schema.shape({
        contract_signee: yup
          .string()
          .required()
          .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
          .trim()
          .min(3, MINIMUM_3_CHAR)
          .max(30, MAXIMUM_30_CHAR)
          .label("Contract Signee"),
        contract_date: yup
          .date()
          .required()
          .typeError(INVALID_DATE)
          .label("Contract Date"),
        contract_file: yup.string().required().label("Contract Document"),
      });
    } else if (documentType === "deposit") {
      schema = schema.shape({
        payment_deposited_by: yup
          .string()
          .required()
          .matches(ONLY_ALPHABET, ALPHABETS_ALLOW)
          .trim()
          .min(3, MINIMUM_3_CHAR)
          .max(30, MAXIMUM_30_CHAR)
          .label("Payment Depositor name"),
        payment_date: yup
          .date()
          .required()
          .label("Payment Date")
          .typeError(INVALID_DATE),
        paid_amount: yup
          .number()
          .positive()
          .typeError("Paid Amount must be a number")
          .max(projectsData?.projectDetail?.amount  )
          .required()
          .label("Paid Amount"),
        payment_file: yup
        .string()
        //.required()
        .label("Deposit Paid Document"),
      });
    }

    return schema;
  };
  const initialValues = {
    documentType: projectsData?.docType,
    contract_signee: "",
    contract_date: null,
    contract_file: "",
    payment_deposited_by: "",
    payment_date: null,
    paid_amount: projectsData?.projectDetail?.paid_amount ?? "",
    payment_file: "",
  };  
  
  const handleFileUpload = (event: any, setFieldValue: any, type: string) => {
    //   console.log("file", event);
    const pdfFile = event[0];
    // return;
    // Check file type
    if (!pdfFile?.type || !EmployeeDocumentTypeExtension.includes(pdfFile?.type)) {
      toast(DOCUMENT_TYPE_ERROR, {
        type: toast.TYPE.ERROR,
      });
      return;
    } else if (pdfFile.size > formTemplateMaxFileSizeMB * 1024 * 1024) {
      toast(`File size must be less than ${formTemplateMaxFileSizeMB} MB.`, {
        type: toast.TYPE.ERROR,
      });

      return;
    } else {
      const formData = new FormData();
      formData.append("file", pdfFile);
      const reader = new FileReader();
      setDocLoading(true);
      uploadProjectDocApi(projectsData.docType, projectsData.id, formData)
        .then((response) => {
          setDocLoading(false);
          // console.log(type);
          setDocumentFile(pdfFile?.name)
          if (projectsData.docType == "deposit") {
            setFieldValue("payment_file", response.data.file);
          }

          if (projectsData.docType == "contract") {
            setFieldValue("contract_file", response.data.file);
          }
          if (notToShowSubmitButtonDocType.includes(projectsData.docType)) {
            toast(response.msg, { type: toast.TYPE.SUCCESS });
            onClose();
          }          
        })
        .catch(() => {
          setDocLoading(false);
          // setContractFileData(PreviouscontractFileData);
          // setPaymentFileData(PreviouspaymentFileData);
        });
    }
  };  
  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Modal className="export-excel-modal" show={isOpen} onHide={onClose}>
          <Formik
            validationSchema={getValidationSchema(projectsData.docType)}
            initialValues={initialValues}
            onSubmit={(values: any, actions) => {
              submitForm(values, actions);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="" noValidate onSubmit={handleSubmit}>
                <Modal.Body className="position-relative">
                  <button
                    type="button"
                    disabled={docLoading}
                    className="btn-close text-right  btn-option-cover"
                    aria-label="Close"
                    onClick={onClose}
                  ></button>
                  <h4 className="fw-bold info-text mb-3">
                    Upload Document ({projectsData?.docLabel})
                  </h4>
                  {projectsData?.docType == "contract" && (
                    <Row>
                      <Col>
                        <InputText
                          controlId="contract_signee"
                          label="Contract signee"
                          placeholder="Enter contract signee name"
                          touched={touched.contract_signee}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.contract_signee}
                          value={values.contract_signee}
                        />
                      </Col>
                      <Col>
                        <InputDatePicker
                          name="contract_date"
                          label="Contract Date"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "contract_date",
                            },
                          }}
                          value={values.contract_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          touched={touched.contract_date}
                          errors={errors.contract_date}
                        />
                      </Col>
                    </Row>
                  )}
                  {projectsData?.docType == "deposit" && (
                    <Row>
                      <Col className="col-md-4">
                        <InputText
                          controlId="payment_deposited_by"
                          label="Depositor Name"
                          placeholder="Depositor name"
                          touched={touched.payment_deposited_by}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.payment_deposited_by}
                          value={values.payment_deposited_by}
                        />
                      </Col>
                      <Col className="col-md-4 date-icon1">
                        <InputDatePicker
                          name="payment_date"
                          label="Payment Date"
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select",
                              className: "form-control",
                              readOnly: true,
                              id: "payment_date",
                            },
                          }}
                          value={values.payment_date}
                          onChange={(name, newValue) =>
                            setFieldValue(name, newValue)
                          }
                          touched={touched.payment_date}
                          errors={errors.payment_date}
                        />
                      </Col>
                      <Col className="col-md-4">
                        <InputText
                          controlId="paid_amount"
                          label="Deposit Amount"
                          placeholder="Deposit amount"
                          touched={touched.paid_amount}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errorsField={errors.paid_amount}
                          value={values.paid_amount}
                        />
                      </Col>
                    </Row>
                  )}
                  <div className="row">
                    <div className="col-md">
                      <div className="drag-drop">
                        {docLoading ? (
                          <label className="file-box1">
                            <span>
                              <Spinner />
                            </span>
                          </label>
                        ) : (
                          <Dropzone
                            multiple={false}
                            accept={EmployeeDocumentTypeForDropZone}
                            onDrop={(e) =>
                              handleFileUpload(e, setFieldValue, "contractFile")
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="upload-image">
                                  <svg
                                    width="33"
                                    height="25"
                                    viewBox="0 0 33 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.2222 24.1666H8.65975C6.44794 24.1666 4.55819 23.401 2.99048 21.8697C1.42277 20.3385 0.638916 18.4669 0.638916 16.2551C0.638916 14.3593 1.2101 12.6701 2.35246 11.1874C3.49482 9.70478 4.98961 8.75686 6.83683 8.34367C7.44447 6.10756 8.65975 4.29679 10.4827 2.91138C12.3056 1.52596 14.3716 0.833252 16.6806 0.833252C19.5243 0.833252 21.9367 1.8237 23.9176 3.80461C25.8985 5.78551 26.8889 8.19784 26.8889 11.0416C28.566 11.236 29.9575 11.9591 31.0634 13.2109C32.1693 14.4626 32.7222 15.927 32.7222 17.6041C32.7222 19.427 32.0842 20.9765 30.8082 22.2525C29.5321 23.5286 27.9827 24.1666 26.1597 24.1666H18.1389V13.7395L20.4722 15.9999L22.5139 13.9583L16.6806 8.12492L10.8472 13.9583L12.8889 15.9999L15.2222 13.7395V24.1666Z"
                                      fill="#889EAE"
                                    />
                                  </svg>
                                </div>
                                <div className="drag-text">
                                  Drag & drop the document here or
                                </div>
                                <div className="upload-text">
                                  Upload Document
                                </div>
                                { documentFile && (<div className="upload-text">
                                 {documentFile}
                                </div>)}
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </div>
                      <div className="d-flex mt-2 justify-content-between">
                        <p className="file-size-text">
                          File Size: {formTemplateMaxFileSizeMB} MB max
                        </p>
                        <p className="file-size-text">
                          Upload Document (only pdf, images and word doc files are allowed)
                        </p>
                      </div>
                    </div>
                    <ErrorMessage
                      name="contract_file"
                      component="div"
                      className="text-danger fs-14"
                    />
                    <ErrorMessage
                      name="payment_file"
                      component="div"
                      className="text-danger fs-14"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end mb-2 pt-0">
                  <button
                    type="button" disabled={docLoading}
                    className="fw-semibold fs-13  mw-65 me-2 mt-2 btn btn-outline-info"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  {notToShowSubmitButtonDocType.includes(
                    projectsData.docType
                  ) ? (
                    ""
                  ) : (
                    <CustomButton
                      type="submit"
                      loading={docLoading}
                      disabled={docLoading}
                      className="fw-semibold fs-13 text-white mw-90 mt-2"
                      variant="primary"
                    >
                      Apply
                    </CustomButton>
                  )}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
export default ProjectDocumentUpload;
